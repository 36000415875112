@import 'abstracts';

cap-action-menu {
  i.menu-action__button-icon {
    color: $white;
  }
}

app-header {
  cap-action-menu {
    .menu-action__button-icon {
      box-shadow: none;
      border-color: transparent;
      background-color: transparent;
      height: auto;
      width: auto;
      margin: 0;
      
      &:before {
        font-size: 25px;
      }
    }

    .menu-action__items::before {
      display: none;
    }

    .menu-action__items--open {
      margin: 0;
    }
  }
}