@use 'sass:math';

@use "sass:math" as mat;

$root-font-size: 16px !default;

@function getFontPath($font-name) {
  $url: '../fonts/';

  @return $url+ $font-name;
}

@mixin proportions($size) {
  width: $size;
  height: $size;
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function toRem($value) {
  @if is-number($value) and index('em' 'rem', unit($value)) != null {
    @return $value;
  } @else {
    $rem-value: mat.div($value, $root-font-size) + rem;

    @return $rem-value;
  }
}

@function breakpoint-exist($name, $breakpoints: $cap-media-breakpoints) {
  $exist: map-get($breakpoints, $name);
  @return if($exist, $exist, null);
}

@mixin image() {
  width: 100%;
  height: 100%;
}

@mixin proportions($size) {
  width: $size;
  height: $size;
}

@mixin notification-oval($width, $height, $bgColor: null, $color: initial, $border: none) {
  height: $height;
  width: $width;
  border: $border;
  background-color: $bgColor;
  border-radius: 50%;
  color: $color;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin no-scrollbar() {
  // Microsoft Edge (non Chromium version)
  -ms-overflow-style: none;

  // Mozilla Firefox
  scrollbar-width: none;

  // Webkit based browsers (e.g. Chromium based or Safari)
  &::-webkit-scrollbar {
    width: 0;
  }
}

@mixin setObjectFit($type) {
  width: 100%;
  height: 100%;
  object-fit: $type;
}

@mixin setAbsoluteCenter() {
  $size: 50%;
  position: absolute;
  top: $size;
  left: $size;
  transform: translate(-$size, -$size);
}

@mixin setAbsoluteVerticalCenter() {
  $size: 50%;
  position: absolute;
  top: $size;
  transform: translateY(-$size);
}

@mixin setAbsoluteHorizontalCenter() {
  $size: 50%;
  position: absolute;
  left: $size;
  transform: translateX(-$size);
}

@mixin setBottomGutters($value, $lastGutter: 0, $start: 1n, $iteration: 1) {
  & > *:nth-child(#{$start} + #{$iteration}) {
    margin-bottom: $value;

    &:last-child {
      margin-bottom: $lastGutter;
    }
  }
}

@mixin setTopGutters($value, $lastGutter: 0, $start: 1n, $iteration: 1) {
  & > *:nth-child(#{$start} + #{$iteration}) {
    margin-top: $value;

    &:last-child {
      margin-top: $lastGutter;
    }
  }
}

@mixin setBottomMargin($value) {
  margin-bottom: $value;

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin setLeftGutters($value) {
  & > * {
    margin-left: $value;

    &:first-child {
      margin-left: 0;
    }
  }
}

@mixin setRightGutters($value) {
  & > * {
    margin-right: $value;

    &:last-child {
      margin-right: 0;
    }
  }
}

@mixin flexCentering() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin truncateAndDots($maxWidth) {
  max-width: $maxWidth;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin cap-media-breakpoint($name, $breakpoints: $cap-media-breakpoints) {
  $exist: breakpoint-exist($name, $breakpoints);
  @if $exist {
    @media #{$exist} {
      @content;
    }
  } @else {
    @content;
  }
}

// Bootstrap functions
//
// Utility mixins and functions for evaluating source code across our variables, maps, and mixins.

// Ascending
// Used to evaluate Sass maps like our grid breakpoints.
@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;
  @each $key, $num in $map {
    @if $prev-num == null or unit($num) == "%" or unit($prev-num) == "%" {
      // Do nothing
    } @else if not comparable($prev-num, $num) {
      @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    } @else if $prev-num >= $num {
      @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    }
    $prev-key: $key;
    $prev-num: $num;
  }
}

// Starts at zero
// Used to ensure the min-width of the lowest breakpoint starts at 0.
@mixin _assert-starts-at-zero($map, $map-name: "$grid-breakpoints") {
  @if length($map) > 0 {
    $values: map-values($map);
    $first-value: nth($values, 1);
    @if $first-value != 0 {
      @warn "First breakpoint in #{$map-name} must start at 0, but starts at #{$first-value}.";
    }
  }
}

// Colors
@function to-rgb($value) {
  @return red($value), green($value), blue($value);
}

// stylelint-disable scss/dollar-variable-pattern
@function rgba-css-var($identifier, $target) {
  @if $identifier == "body" and $target == "bg" {
    @return rgba(var(--#{$prefix}#{$identifier}-bg-rgb), var(--#{$prefix}#{$target}-opacity));
  } @if $identifier == "body" and $target == "text" {
  @return rgba(var(--#{$prefix}#{$identifier}-color-rgb), var(--#{$prefix}#{$target}-opacity));
} @else {
  @return rgba(var(--#{$prefix}#{$identifier}-rgb), var(--#{$prefix}#{$target}-opacity));
}
}

@function map-loop($map, $func, $args...) {
  $_map: ();

  @each $key, $value in $map {
    // allow to pass the $key and $value of the map as an function argument
    $_args: ();
    @each $arg in $args {
      $_args: append($_args, if($arg == "$key", $key, if($arg == "$value", $value, $arg)));
    }

    $_map: map-merge($_map, ($key: call(get-function($func), $_args...)));
  }

  @return $_map;
}
// stylelint-enable scss/dollar-variable-pattern

@function varify($list) {
  $result: null;
  @each $entry in $list {
    $result: append($result, var(--#{$prefix}#{$entry}), space);
  }
  @return $result;
}

// Internal Bootstrap function to turn maps into its negative variant.
// It prefixes the keys with `n` and makes the value negative.
@function negativify-map($map) {
  $result: ();
  @each $key, $value in $map {
    @if $key != 0 {
      $result: map-merge($result, ("n" + $key: (-$value)));
    }
  }
  @return $result;
}

// Get multiple keys from a sass map
@function map-get-multiple($map, $values) {
  $result: ();
  @each $key, $value in $map {
    @if (index($values, $key) != null) {
      $result: map-merge($result, ($key: $value));
    }
  }
  @return $result;
}

// Merge multiple maps
@function map-merge-multiple($maps...) {
  $merged-maps: ();

  @each $map in $maps {
    $merged-maps: map-merge($merged-maps, $map);
  }
  @return $merged-maps;
}

// Replace `$search` with `$replace` in `$string`
// Used on our SVG icon backgrounds for custom forms.
//
// @author Kitty Giraudel
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// See https://codepen.io/kevinweber/pen/dXWoRw
//
// Requires the use of quotes around data URIs.

@function escape-svg($string) {
  @if str-index($string, "data:image/svg+xml") {
    @each $char, $encoded in $escaped-characters {
      // Do not escape the url brackets
      @if str-index($string, "url(") == 1 {
        $string: url("#{str-replace(str-slice($string, 6, -3), $char, $encoded)}");
      } @else {
        $string: str-replace($string, $char, $encoded);
      }
    }
  }

  @return $string;
}

// Color contrast
// See https://github.com/twbs/bootstrap/pull/30168

// A list of pre-calculated numbers of pow(divide((divide($value, 255) + .055), 1.055), 2.4). (from 0 to 255)
// stylelint-disable-next-line scss/dollar-variable-default, scss/dollar-variable-pattern
$_luminance-list: .0008 .001 .0011 .0013 .0015 .0017 .002 .0022 .0025 .0027 .003 .0033 .0037 .004 .0044 .0048 .0052 .0056 .006 .0065 .007 .0075 .008 .0086 .0091 .0097 .0103 .011 .0116 .0123 .013 .0137 .0144 .0152 .016 .0168 .0176 .0185 .0194 .0203 .0212 .0222 .0232 .0242 .0252 .0262 .0273 .0284 .0296 .0307 .0319 .0331 .0343 .0356 .0369 .0382 .0395 .0409 .0423 .0437 .0452 .0467 .0482 .0497 .0513 .0529 .0545 .0561 .0578 .0595 .0612 .063 .0648 .0666 .0685 .0704 .0723 .0742 .0762 .0782 .0802 .0823 .0844 .0865 .0887 .0908 .0931 .0953 .0976 .0999 .1022 .1046 .107 .1095 .1119 .1144 .117 .1195 .1221 .1248 .1274 .1301 .1329 .1356 .1384 .1413 .1441 .147 .15 .1529 .1559 .159 .162 .1651 .1683 .1714 .1746 .1779 .1812 .1845 .1878 .1912 .1946 .1981 .2016 .2051 .2086 .2122 .2159 .2195 .2232 .227 .2307 .2346 .2384 .2423 .2462 .2502 .2542 .2582 .2623 .2664 .2705 .2747 .2789 .2831 .2874 .2918 .2961 .3005 .305 .3095 .314 .3185 .3231 .3278 .3325 .3372 .3419 .3467 .3515 .3564 .3613 .3663 .3712 .3763 .3813 .3864 .3916 .3968 .402 .4072 .4125 .4179 .4233 .4287 .4342 .4397 .4452 .4508 .4564 .4621 .4678 .4735 .4793 .4851 .491 .4969 .5029 .5089 .5149 .521 .5271 .5333 .5395 .5457 .552 .5583 .5647 .5711 .5776 .5841 .5906 .5972 .6038 .6105 .6172 .624 .6308 .6376 .6445 .6514 .6584 .6654 .6724 .6795 .6867 .6939 .7011 .7084 .7157 .7231 .7305 .7379 .7454 .7529 .7605 .7682 .7758 .7835 .7913 .7991 .807 .8148 .8228 .8308 .8388 .8469 .855 .8632 .8714 .8796 .8879 .8963 .9047 .9131 .9216 .9301 .9387 .9473 .956 .9647 .9734 .9823 .9911 1;

@function color-contrast($background, $color-contrast-dark: $color-contrast-dark, $color-contrast-light: $color-contrast-light, $min-contrast-ratio: $min-contrast-ratio) {
  $foregrounds: $color-contrast-light, $color-contrast-dark, $white, $black;
  $max-ratio: 0;
  $max-ratio-color: null;

  @each $color in $foregrounds {
    $contrast-ratio: contrast-ratio($background, $color);
    @if $contrast-ratio > $min-contrast-ratio {
      @return $color;
    } @else if $contrast-ratio > $max-ratio {
      $max-ratio: $contrast-ratio;
      $max-ratio-color: $color;
    }
  }

  @warn "Found no color leading to #{$min-contrast-ratio}:1 contrast ratio against #{$background}...";

  @return $max-ratio-color;
}

@function contrast-ratio($background, $foreground: $color-contrast-light) {
  $l1: luminance($background);
  $l2: luminance(opaque($background, $foreground));

  @return if($l1 > $l2, divide($l1 + .05, $l2 + .05), divide($l2 + .05, $l1 + .05));
}

// Return WCAG2.1 relative luminance
// See https://www.w3.org/TR/WCAG/#dfn-relative-luminance
// See https://www.w3.org/TR/WCAG/#dfn-contrast-ratio
@function luminance($color) {
  $rgb: (
          "r": red($color),
          "g": green($color),
          "b": blue($color)
  );

  @each $name, $value in $rgb {
    $value: if(divide($value, 255) < .03928, divide(divide($value, 255), 12.92), nth($_luminance-list, $value + 1));
    $rgb: map-merge($rgb, ($name: $value));
  }

  @return (map-get($rgb, "r") * .2126) + (map-get($rgb, "g") * .7152) + (map-get($rgb, "b") * .0722);
}

// Return opaque color
// opaque(#fff, rgba(0, 0, 0, .5)) => #808080
@function opaque($background, $foreground) {
  @return mix(rgba($foreground, 1), $background, opacity($foreground) * 100%);
}

// scss-docs-start color-functions
// Tint a color: mix a color with white
@function tint-color($color, $weight) {
  @return mix(white, $color, $weight);
}

// Shade a color: mix a color with black
@function shade-color($color, $weight) {
  @return mix(black, $color, $weight);
}

// Shade the color if the weight is positive, else tint it
@function shift-color($color, $weight) {
  @return if($weight > 0, shade-color($color, $weight), tint-color($color, -$weight));
}
// scss-docs-end color-functions

// Return valid calc
@function add($value1, $value2, $return-calc: true) {
  @if $value1 == null {
    @return $value2;
  }

  @if $value2 == null {
    @return $value1;
  }

  @if type-of($value1) == number and type-of($value2) == number and comparable($value1, $value2) {
    @return $value1 + $value2;
  }

  @return if($return-calc == true, calc(#{$value1} + #{$value2}), $value1 + unquote(" + ") + $value2);
}

@function subtract($value1, $value2, $return-calc: true) {
  @if $value1 == null and $value2 == null {
    @return null;
  }

  @if $value1 == null {
    @return -$value2;
  }

  @if $value2 == null {
    @return $value1;
  }

  @if type-of($value1) == number and type-of($value2) == number and comparable($value1, $value2) {
    @return $value1 - $value2;
  }

  @if type-of($value2) != number {
    $value2: unquote("(") + $value2 + unquote(")");
  }

  @return if($return-calc == true, calc(#{$value1} - #{$value2}), $value1 + unquote(" - ") + $value2);
}

@function divide($dividend, $divisor, $precision: 10) {
  $sign: if($dividend > 0 and $divisor > 0 or $dividend < 0 and $divisor < 0, 1, -1);
  $dividend: abs($dividend);
  $divisor: abs($divisor);
  @if $dividend == 0 {
    @return 0;
  }
  @if $divisor == 0 {
    @error "Cannot divide by 0";
  }
  $remainder: $dividend;
  $result: 0;
  $factor: 10;
  @while ($remainder > 0 and $precision >= 0) {
    $quotient: 0;
    @while ($remainder >= $divisor) {
      $remainder: $remainder - $divisor;
      $quotient: $quotient + 1;
    }
    $result: $result * 10 + $quotient;
    $factor: $factor * .1;
    $remainder: $remainder * 10;
    $precision: $precision - 1;
    @if ($precision < 0 and $remainder >= $divisor * 5) {
      $result: $result + 1;
    }
  }
  $result: $result * $factor * $sign;
  $dividend-unit: unit($dividend);
  $divisor-unit: unit($divisor);
  $unit-map: (
          "px": 1px,
          "rem": 1rem,
          "em": 1em,
          "%": 1%
  );
  @if ($dividend-unit != $divisor-unit and map-has-key($unit-map, $dividend-unit)) {
    $result: $result * map-get($unit-map, $dividend-unit);
  }
  @return $result;
}

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue:    #0d6efd !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #198754 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
        "blue":       $blue,
        "indigo":     $indigo,
        "purple":     $purple,
        "pink":       $pink,
        "red":        $red,
        "orange":     $orange,
        "yellow":     $yellow,
        "green":      $green,
        "teal":       $teal,
        "cyan":       $cyan,
        "black":      $black,
        "white":      $white,
        "gray":       $gray-600,
        "gray-dark":  $gray-800
) !default;
// scss-docs-end colors-map

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   4.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark:      $black !default;
$color-contrast-light:     $white !default;

// fusv-disable
$blue-100: tint-color($blue, 80%) !default;
$blue-200: tint-color($blue, 60%) !default;
$blue-300: tint-color($blue, 40%) !default;
$blue-400: tint-color($blue, 20%) !default;
$blue-500: $blue !default;
$blue-600: shade-color($blue, 20%) !default;
$blue-700: shade-color($blue, 40%) !default;
$blue-800: shade-color($blue, 60%) !default;
$blue-900: shade-color($blue, 80%) !default;

$indigo-100: tint-color($indigo, 80%) !default;
$indigo-200: tint-color($indigo, 60%) !default;
$indigo-300: tint-color($indigo, 40%) !default;
$indigo-400: tint-color($indigo, 20%) !default;
$indigo-500: $indigo !default;
$indigo-600: shade-color($indigo, 20%) !default;
$indigo-700: shade-color($indigo, 40%) !default;
$indigo-800: shade-color($indigo, 60%) !default;
$indigo-900: shade-color($indigo, 80%) !default;

$purple-100: tint-color($purple, 80%) !default;
$purple-200: tint-color($purple, 60%) !default;
$purple-300: tint-color($purple, 40%) !default;
$purple-400: tint-color($purple, 20%) !default;
$purple-500: $purple !default;
$purple-600: shade-color($purple, 20%) !default;
$purple-700: shade-color($purple, 40%) !default;
$purple-800: shade-color($purple, 60%) !default;
$purple-900: shade-color($purple, 80%) !default;

$pink-100: tint-color($pink, 80%) !default;
$pink-200: tint-color($pink, 60%) !default;
$pink-300: tint-color($pink, 40%) !default;
$pink-400: tint-color($pink, 20%) !default;
$pink-500: $pink !default;
$pink-600: shade-color($pink, 20%) !default;
$pink-700: shade-color($pink, 40%) !default;
$pink-800: shade-color($pink, 60%) !default;
$pink-900: shade-color($pink, 80%) !default;

$red-100: tint-color($red, 80%) !default;
$red-200: tint-color($red, 60%) !default;
$red-300: tint-color($red, 40%) !default;
$red-400: tint-color($red, 20%) !default;
$red-500: $red !default;
$red-600: shade-color($red, 20%) !default;
$red-700: shade-color($red, 40%) !default;
$red-800: shade-color($red, 60%) !default;
$red-900: shade-color($red, 80%) !default;

$orange-100: tint-color($orange, 80%) !default;
$orange-200: tint-color($orange, 60%) !default;
$orange-300: tint-color($orange, 40%) !default;
$orange-400: tint-color($orange, 20%) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 20%) !default;
$orange-700: shade-color($orange, 40%) !default;
$orange-800: shade-color($orange, 60%) !default;
$orange-900: shade-color($orange, 80%) !default;

$yellow-100: tint-color($yellow, 80%) !default;
$yellow-200: tint-color($yellow, 60%) !default;
$yellow-300: tint-color($yellow, 40%) !default;
$yellow-400: tint-color($yellow, 20%) !default;
$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 20%) !default;
$yellow-700: shade-color($yellow, 40%) !default;
$yellow-800: shade-color($yellow, 60%) !default;
$yellow-900: shade-color($yellow, 80%) !default;

$green-100: tint-color($green, 80%) !default;
$green-200: tint-color($green, 60%) !default;
$green-300: tint-color($green, 40%) !default;
$green-400: tint-color($green, 20%) !default;
$green-500: $green !default;
$green-600: shade-color($green, 20%) !default;
$green-700: shade-color($green, 40%) !default;
$green-800: shade-color($green, 60%) !default;
$green-900: shade-color($green, 80%) !default;

$teal-100: tint-color($teal, 80%) !default;
$teal-200: tint-color($teal, 60%) !default;
$teal-300: tint-color($teal, 40%) !default;
$teal-400: tint-color($teal, 20%) !default;
$teal-500: $teal !default;
$teal-600: shade-color($teal, 20%) !default;
$teal-700: shade-color($teal, 40%) !default;
$teal-800: shade-color($teal, 60%) !default;
$teal-900: shade-color($teal, 80%) !default;

$cyan-100: tint-color($cyan, 80%) !default;
$cyan-200: tint-color($cyan, 60%) !default;
$cyan-300: tint-color($cyan, 40%) !default;
$cyan-400: tint-color($cyan, 20%) !default;
$cyan-500: $cyan !default;
$cyan-600: shade-color($cyan, 20%) !default;
$cyan-700: shade-color($cyan, 40%) !default;
$cyan-800: shade-color($cyan, 60%) !default;
$cyan-900: shade-color($cyan, 80%) !default;

$blues: (
        "blue-100": $blue-100,
        "blue-200": $blue-200,
        "blue-300": $blue-300,
        "blue-400": $blue-400,
        "blue-500": $blue-500,
        "blue-600": $blue-600,
        "blue-700": $blue-700,
        "blue-800": $blue-800,
        "blue-900": $blue-900
) !default;

$indigos: (
        "indigo-100": $indigo-100,
        "indigo-200": $indigo-200,
        "indigo-300": $indigo-300,
        "indigo-400": $indigo-400,
        "indigo-500": $indigo-500,
        "indigo-600": $indigo-600,
        "indigo-700": $indigo-700,
        "indigo-800": $indigo-800,
        "indigo-900": $indigo-900
) !default;

$purples: (
        "purple-100": $purple-100,
        "purple-200": $purple-200,
        "purple-300": $purple-300,
        "purple-400": $purple-400,
        "purple-500": $purple-500,
        "purple-600": $purple-600,
        "purple-700": $purple-700,
        "purple-800": $purple-800,
        "purple-900": $purple-900
) !default;

$pinks: (
        "pink-100": $pink-100,
        "pink-200": $pink-200,
        "pink-300": $pink-300,
        "pink-400": $pink-400,
        "pink-500": $pink-500,
        "pink-600": $pink-600,
        "pink-700": $pink-700,
        "pink-800": $pink-800,
        "pink-900": $pink-900
) !default;

$reds: (
        "red-100": $red-100,
        "red-200": $red-200,
        "red-300": $red-300,
        "red-400": $red-400,
        "red-500": $red-500,
        "red-600": $red-600,
        "red-700": $red-700,
        "red-800": $red-800,
        "red-900": $red-900
) !default;

$oranges: (
        "orange-100": $orange-100,
        "orange-200": $orange-200,
        "orange-300": $orange-300,
        "orange-400": $orange-400,
        "orange-500": $orange-500,
        "orange-600": $orange-600,
        "orange-700": $orange-700,
        "orange-800": $orange-800,
        "orange-900": $orange-900
) !default;

$yellows: (
        "yellow-100": $yellow-100,
        "yellow-200": $yellow-200,
        "yellow-300": $yellow-300,
        "yellow-400": $yellow-400,
        "yellow-500": $yellow-500,
        "yellow-600": $yellow-600,
        "yellow-700": $yellow-700,
        "yellow-800": $yellow-800,
        "yellow-900": $yellow-900
) !default;

$greens: (
        "green-100": $green-100,
        "green-200": $green-200,
        "green-300": $green-300,
        "green-400": $green-400,
        "green-500": $green-500,
        "green-600": $green-600,
        "green-700": $green-700,
        "green-800": $green-800,
        "green-900": $green-900
) !default;

$teals: (
        "teal-100": $teal-100,
        "teal-200": $teal-200,
        "teal-300": $teal-300,
        "teal-400": $teal-400,
        "teal-500": $teal-500,
        "teal-600": $teal-600,
        "teal-700": $teal-700,
        "teal-800": $teal-800,
        "teal-900": $teal-900
) !default;

$cyans: (
        "cyan-100": $cyan-100,
        "cyan-200": $cyan-200,
        "cyan-300": $cyan-300,
        "cyan-400": $cyan-400,
        "cyan-500": $cyan-500,
        "cyan-600": $cyan-600,
        "cyan-700": $cyan-700,
        "cyan-800": $cyan-800,
        "cyan-900": $cyan-900
) !default;
// fusv-enable

// scss-docs-start theme-color-variables
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
        "primary":    $primary,
        "secondary":  $secondary,
        "success":    $success,
        "info":       $info,
        "warning":    $warning,
        "danger":     $danger,
        "light":      $light,
        "dark":       $dark
) !default;
// scss-docs-end theme-colors-map

// Characters which are escaped by the escape-svg function
$escaped-characters: (
                ("<", "%3c"),
                (">", "%3e"),
                ("#", "%23"),
                ("(", "%28"),
                (")", "%29"),
) !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                true !default;
$enable-rounded:              true !default;
$enable-shadows:              false !default;
$enable-gradients:            false !default;
$enable-transitions:          true !default;
$enable-reduced-motion:       true !default;
$enable-smooth-scroll:        true !default;
$enable-grid-classes:         true !default;
$enable-container-classes:    true !default;
$enable-cssgrid:              false !default;
$enable-button-pointers:      true !default;
$enable-rfs:                  true !default;
$enable-validation-icons:     true !default;
$enable-negative-margins:     false !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities:  true !default;

// Prefix for :root CSS variables

$variable-prefix:             bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix:                      $variable-prefix !default;

// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
// scss-docs-start variable-gradient
$gradient: linear-gradient(180deg, rgba($white, .15), rgba($white, 0)) !default;
// scss-docs-end variable-gradient

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
) !default;
// scss-docs-end spacer-variables-maps

// Position
//
// Define the edge positioning anchors of the position utilities.

// scss-docs-start position-map
$position-values: (
        0: 0,
        50: 50%,
        100: 100%
) !default;
// scss-docs-end position-map

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white !default;
$body-color:                $gray-900 !default;
$body-text-align:           null !default;

// Links
//
// Style anchor elements.

$link-color:                              $primary !default;
$link-decoration:                         underline !default;
$link-shade-percentage:                   20% !default;
$link-hover-color:                        shift-color($link-color, $link-shade-percentage) !default;
$link-hover-decoration:                   null !default;

$stretched-link-pseudo-element:           after !default;
$stretched-link-z-index:                  1 !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
) !default;
// scss-docs-end grid-breakpoints

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1320px
) !default;
// scss-docs-end container-max-widths

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           1.5rem !default;
$grid-row-columns:            6 !default;

// Container padding

$container-padding-x: $grid-gutter-width !default;


// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$border-width:                1px !default;
$border-widths: (
        1: 1px,
        2: 2px,
        3: 3px,
        4: 4px,
        5: 5px
) !default;

$border-style:                solid !default;
$border-color:                $gray-300 !default;
$border-color-translucent:    rgba($black, .175) !default;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius:               .375rem !default;
$border-radius-sm:            .25rem !default;
$border-radius-lg:            .5rem !default;
$border-radius-xl:            1rem !default;
$border-radius-2xl:           2rem !default;
$border-radius-pill:          50rem !default;
// scss-docs-end border-radius-variables

// scss-docs-start box-shadow-variables
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;
$box-shadow-inset:            inset 0 1px 2px rgba($black, .075) !default;
// scss-docs-end box-shadow-variables

$component-active-color:      $white !default;
$component-active-bg:         $primary !default;

// scss-docs-start caret-variables
$caret-width:                 .3em !default;
$caret-vertical-align:        $caret-width * .85 !default;
$caret-spacing:               $caret-width * .85 !default;
// scss-docs-end caret-variables

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
// scss-docs-start collapse-transition
$transition-collapse:         height .35s ease !default;
$transition-collapse-width:   width .35s ease !default;
// scss-docs-end collapse-transition

// stylelint-disable function-disallowed-list
// scss-docs-start aspect-ratios
$aspect-ratios: (
        "1x1": 100%,
        "4x3": calc(3 / 4 * 100%),
        "16x9": calc(9 / 16 * 100%),
        "21x9": calc(9 / 21 * 100%)
) !default;
// scss-docs-end aspect-ratios
// stylelint-enable function-disallowed-list

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif:      system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
// stylelint-enable value-keyword-case
$font-family-base:            var(--#{$prefix}font-sans-serif) !default;
$font-family-code:            var(--#{$prefix}font-monospace) !default;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root:              null !default;
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875 !default;
$font-size-lg:                $font-size-base * 1.25 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-semibold:        600 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;

$line-height-base:            1.5 !default;
$line-height-sm:              1.25 !default;
$line-height-lg:              2 !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
        1: $h1-font-size,
        2: $h2-font-size,
        3: $h3-font-size,
        4: $h4-font-size,
        5: $h5-font-size,
        6: $h6-font-size
) !default;
// scss-docs-end font-sizes

// scss-docs-start headings-variables
$headings-margin-bottom:      $spacer * .5 !default;
$headings-font-family:        null !default;
$headings-font-style:         null !default;
$headings-font-weight:        500 !default;
$headings-line-height:        1.2 !default;
$headings-color:              null !default;
// scss-docs-end headings-variables

// scss-docs-start display-headings
$display-font-sizes: (
        1: 5rem,
        2: 4.5rem,
        3: 4rem,
        4: 3.5rem,
        5: 3rem,
        6: 2.5rem
) !default;

$display-font-family: null !default;
$display-font-style:  null !default;
$display-font-weight: 300 !default;
$display-line-height: $headings-line-height !default;
// scss-docs-end display-headings

// scss-docs-start type-variables
$lead-font-size:              $font-size-base * 1.25 !default;
$lead-font-weight:            300 !default;

$small-font-size:             .875em !default;

$sub-sup-font-size:           .75em !default;

$text-muted:                  $gray-600 !default;

$initialism-font-size:        $small-font-size !default;

$blockquote-margin-y:         $spacer !default;
$blockquote-font-size:        $font-size-base * 1.25 !default;
$blockquote-footer-color:     $gray-600 !default;
$blockquote-footer-font-size: $small-font-size !default;

$hr-margin-y:                 $spacer !default;
$hr-color:                    inherit !default;

// fusv-disable
$hr-bg-color:                 null !default; // Deprecated in v5.2.0
$hr-height:                   null !default; // Deprecated in v5.2.0
// fusv-enable

$hr-border-color:             null !default; // Allows for inherited colors
$hr-border-width:             $border-width !default;
$hr-opacity:                  .25 !default;

$legend-margin-bottom:        .5rem !default;
$legend-font-size:            1.5rem !default;
$legend-font-weight:          null !default;

$dt-font-weight:              $font-weight-bold !default;

$list-inline-padding:         .5rem !default;

$mark-padding:                .1875em !default;
$mark-bg:                     $yellow-100 !default;
// scss-docs-end type-variables


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y:        .5rem !default;
$table-cell-padding-x:        .5rem !default;
$table-cell-padding-y-sm:     .25rem !default;
$table-cell-padding-x-sm:     .25rem !default;

$table-cell-vertical-align:   top !default;

$table-color:                 var(--#{$prefix}body-color) !default;
$table-bg:                    transparent !default;
$table-accent-bg:             transparent !default;

$table-th-font-weight:        null !default;

$table-striped-color:         $table-color !default;
$table-striped-bg-factor:     .05 !default;
$table-striped-bg:            rgba($black, $table-striped-bg-factor) !default;

$table-active-color:          $table-color !default;
$table-active-bg-factor:      .1 !default;
$table-active-bg:             rgba($black, $table-active-bg-factor) !default;

$table-hover-color:           $table-color !default;
$table-hover-bg-factor:       .075 !default;
$table-hover-bg:              rgba($black, $table-hover-bg-factor) !default;

$table-border-factor:         .1 !default;
$table-border-width:          $border-width !default;
$table-border-color:          var(--#{$prefix}border-color) !default;

$table-striped-order:         odd !default;
$table-striped-columns-order: even !default;

$table-group-separator-color: currentcolor !default;

$table-caption-color:         $text-muted !default;

$table-bg-scale:              -80% !default;
// scss-docs-end table-variables

// scss-docs-start table-loop
$table-variants: (
        "primary":    shift-color($primary, $table-bg-scale),
        "secondary":  shift-color($secondary, $table-bg-scale),
        "success":    shift-color($success, $table-bg-scale),
        "info":       shift-color($info, $table-bg-scale),
        "warning":    shift-color($warning, $table-bg-scale),
        "danger":     shift-color($danger, $table-bg-scale),
        "light":      $light,
        "dark":       $dark,
) !default;
// scss-docs-end table-loop


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y:         .375rem !default;
$input-btn-padding-x:         .75rem !default;
$input-btn-font-family:       null !default;
$input-btn-font-size:         $font-size-base !default;
$input-btn-line-height:       $line-height-base !default;

$input-btn-focus-width:         .25rem !default;
$input-btn-focus-color-opacity: .25 !default;
$input-btn-focus-color:         rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-blur:          0 !default;
$input-btn-focus-box-shadow:    0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-font-size-sm:      $font-size-sm !default;

$input-btn-padding-y-lg:      .5rem !default;
$input-btn-padding-x-lg:      1rem !default;
$input-btn-font-size-lg:      $font-size-lg !default;

$input-btn-border-width:      $border-width !default;
// scss-docs-end input-btn-variables


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-padding-y:               $input-btn-padding-y !default;
$btn-padding-x:               $input-btn-padding-x !default;
$btn-font-family:             $input-btn-font-family !default;
$btn-font-size:               $input-btn-font-size !default;
$btn-line-height:             $input-btn-line-height !default;
$btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
$btn-font-size-sm:            $input-btn-font-size-sm !default;

$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
$btn-font-size-lg:            $input-btn-font-size-lg !default;

$btn-border-width:            $input-btn-border-width !default;

$btn-font-weight:             $font-weight-normal !default;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
$btn-focus-width:             $input-btn-focus-width !default;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

$btn-link-color:              var(--#{$prefix}link-color) !default;
$btn-link-hover-color:        var(--#{$prefix}link-hover-color) !default;
$btn-link-disabled-color:     $gray-600 !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius !default;
$btn-border-radius-sm:        $border-radius-sm !default;
$btn-border-radius-lg:        $border-radius-lg !default;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$btn-hover-bg-shade-amount:       15% !default;
$btn-hover-bg-tint-amount:        15% !default;
$btn-hover-border-shade-amount:   20% !default;
$btn-hover-border-tint-amount:    10% !default;
$btn-active-bg-shade-amount:      20% !default;
$btn-active-bg-tint-amount:       20% !default;
$btn-active-border-shade-amount:  25% !default;
$btn-active-border-tint-amount:   10% !default;
// scss-docs-end btn-variables


// Forms

// scss-docs-start form-text-variables
$form-text-margin-top:                  .25rem !default;
$form-text-font-size:                   $small-font-size !default;
$form-text-font-style:                  null !default;
$form-text-font-weight:                 null !default;
$form-text-color:                       $text-muted !default;
// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
$form-label-margin-bottom:              .5rem !default;
$form-label-font-size:                  null !default;
$form-label-font-style:                 null !default;
$form-label-font-weight:                null !default;
$form-label-color:                      null !default;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$input-padding-y:                       $input-btn-padding-y !default;
$input-padding-x:                       $input-btn-padding-x !default;
$input-font-family:                     $input-btn-font-family !default;
$input-font-size:                       $input-btn-font-size !default;
$input-font-weight:                     $font-weight-base !default;
$input-line-height:                     $input-btn-line-height !default;

$input-padding-y-sm:                    $input-btn-padding-y-sm !default;
$input-padding-x-sm:                    $input-btn-padding-x-sm !default;
$input-font-size-sm:                    $input-btn-font-size-sm !default;

$input-padding-y-lg:                    $input-btn-padding-y-lg !default;
$input-padding-x-lg:                    $input-btn-padding-x-lg !default;
$input-font-size-lg:                    $input-btn-font-size-lg !default;

$input-bg:                              $body-bg !default;
$input-disabled-color:                  null !default;
$input-disabled-bg:                     $gray-200 !default;
$input-disabled-border-color:           null !default;

$input-color:                           $body-color !default;
$input-border-color:                    $gray-400 !default;
$input-border-width:                    $input-btn-border-width !default;
$input-box-shadow:                      $box-shadow-inset !default;

$input-border-radius:                   $border-radius !default;
$input-border-radius-sm:                $border-radius-sm !default;
$input-border-radius-lg:                $border-radius-lg !default;

$input-focus-bg:                        $input-bg !default;
$input-focus-border-color:              tint-color($component-active-bg, 50%) !default;
$input-focus-color:                     $input-color !default;
$input-focus-width:                     $input-btn-focus-width !default;
$input-focus-box-shadow:                $input-btn-focus-box-shadow !default;

$input-placeholder-color:               $gray-600 !default;
$input-plaintext-color:                 $body-color !default;

$input-height-border:                   $input-border-width * 2 !default;

$input-height-inner:                    add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half:               add($input-line-height * .5em, $input-padding-y) !default;
$input-height-inner-quarter:            add($input-line-height * .25em, $input-padding-y * .5) !default;

$input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-height-sm:                       add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-height-lg:                       add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$form-color-width:                      3rem !default;
// scss-docs-end form-input-variables

// scss-docs-start form-check-variables
$form-check-input-width:                  1em !default;
$form-check-min-height:                   $font-size-base * $line-height-base !default;
$form-check-padding-start:                $form-check-input-width + .5em !default;
$form-check-margin-bottom:                .125rem !default;
$form-check-label-color:                  null !default;
$form-check-label-cursor:                 null !default;
$form-check-transition:                   null !default;

$form-check-input-active-filter:          brightness(90%) !default;

$form-check-input-bg:                     $input-bg !default;
$form-check-input-border:                 1px solid rgba($black, .25) !default;
$form-check-input-border-radius:          .25em !default;
$form-check-radio-border-radius:          50% !default;
$form-check-input-focus-border:           $input-focus-border-color !default;
$form-check-input-focus-box-shadow:       $input-btn-focus-box-shadow !default;

$form-check-input-checked-color:          $component-active-color !default;
$form-check-input-checked-bg-color:       $component-active-bg !default;
$form-check-input-checked-border-color:   $form-check-input-checked-bg-color !default;
$form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>") !default;
$form-check-radio-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;

$form-check-input-indeterminate-color:          $component-active-color !default;
$form-check-input-indeterminate-bg-color:       $component-active-bg !default;
$form-check-input-indeterminate-border-color:   $form-check-input-indeterminate-bg-color !default;
$form-check-input-indeterminate-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") !default;

$form-check-input-disabled-opacity:        .5 !default;
$form-check-label-disabled-opacity:        $form-check-input-disabled-opacity !default;
$form-check-btn-check-disabled-opacity:    $btn-disabled-opacity !default;

$form-check-inline-margin-end:    1rem !default;
// scss-docs-end form-check-variables

// scss-docs-start form-switch-variables
$form-switch-color:               rgba($black, .25) !default;
$form-switch-width:               2em !default;
$form-switch-padding-start:       $form-switch-width + .5em !default;
$form-switch-bg-image:            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>") !default;
$form-switch-border-radius:       $form-switch-width !default;
$form-switch-transition:          background-position .15s ease-in-out !default;

$form-switch-focus-color:         $input-focus-border-color !default;
$form-switch-focus-bg-image:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>") !default;

$form-switch-checked-color:       $component-active-color !default;
$form-switch-checked-bg-image:    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") !default;
$form-switch-checked-bg-position: right center !default;
// scss-docs-end form-switch-variables

// scss-docs-start input-group-variables
$input-group-addon-padding-y:           $input-padding-y !default;
$input-group-addon-padding-x:           $input-padding-x !default;
$input-group-addon-font-weight:         $input-font-weight !default;
$input-group-addon-color:               $input-color !default;
$input-group-addon-bg:                  $gray-200 !default;
$input-group-addon-border-color:        $input-border-color !default;
// scss-docs-end input-group-variables

// scss-docs-start form-select-variables
$form-select-padding-y:             $input-padding-y !default;
$form-select-padding-x:             $input-padding-x !default;
$form-select-font-family:           $input-font-family !default;
$form-select-font-size:             $input-font-size !default;
$form-select-indicator-padding:     $form-select-padding-x * 3 !default; // Extra padding for background-image
$form-select-font-weight:           $input-font-weight !default;
$form-select-line-height:           $input-line-height !default;
$form-select-color:                 $input-color !default;
$form-select-bg:                    $input-bg !default;
$form-select-disabled-color:        null !default;
$form-select-disabled-bg:           $gray-200 !default;
$form-select-disabled-border-color: $input-disabled-border-color !default;
$form-select-bg-position:           right $form-select-padding-x center !default;
$form-select-bg-size:               16px 12px !default; // In pixels because image dimensions
$form-select-indicator-color:       $gray-800 !default;
$form-select-indicator:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>") !default;

$form-select-feedback-icon-padding-end: $form-select-padding-x * 2.5 + $form-select-indicator-padding !default;
$form-select-feedback-icon-position:    center right $form-select-indicator-padding !default;
$form-select-feedback-icon-size:        $input-height-inner-half $input-height-inner-half !default;

$form-select-border-width:        $input-border-width !default;
$form-select-border-color:        $input-border-color !default;
$form-select-border-radius:       $input-border-radius !default;
$form-select-box-shadow:          $box-shadow-inset !default;

$form-select-focus-border-color:  $input-focus-border-color !default;
$form-select-focus-width:         $input-focus-width !default;
$form-select-focus-box-shadow:    0 0 0 $form-select-focus-width $input-btn-focus-color !default;

$form-select-padding-y-sm:        $input-padding-y-sm !default;
$form-select-padding-x-sm:        $input-padding-x-sm !default;
$form-select-font-size-sm:        $input-font-size-sm !default;
$form-select-border-radius-sm:    $input-border-radius-sm !default;

$form-select-padding-y-lg:        $input-padding-y-lg !default;
$form-select-padding-x-lg:        $input-padding-x-lg !default;
$form-select-font-size-lg:        $input-font-size-lg !default;
$form-select-border-radius-lg:    $input-border-radius-lg !default;

$form-select-transition:          $input-transition !default;
// scss-docs-end form-select-variables

// scss-docs-start form-range-variables
$form-range-track-width:          100% !default;
$form-range-track-height:         .5rem !default;
$form-range-track-cursor:         pointer !default;
$form-range-track-bg:             $gray-300 !default;
$form-range-track-border-radius:  1rem !default;
$form-range-track-box-shadow:     $box-shadow-inset !default;

$form-range-thumb-width:                   1rem !default;
$form-range-thumb-height:                  $form-range-thumb-width !default;
$form-range-thumb-bg:                      $component-active-bg !default;
$form-range-thumb-border:                  0 !default;
$form-range-thumb-border-radius:           1rem !default;
$form-range-thumb-box-shadow:              0 .1rem .25rem rgba($black, .1) !default;
$form-range-thumb-focus-box-shadow:        0 0 0 1px $body-bg, $input-focus-box-shadow !default;
$form-range-thumb-focus-box-shadow-width:  $input-focus-width !default; // For focus box shadow issue in Edge
$form-range-thumb-active-bg:               tint-color($component-active-bg, 70%) !default;
$form-range-thumb-disabled-bg:             $gray-500 !default;
$form-range-thumb-transition:              background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
// scss-docs-end form-range-variables

// scss-docs-start form-file-variables
$form-file-button-color:          $input-color !default;
$form-file-button-bg:             $input-group-addon-bg !default;
$form-file-button-hover-bg:       shade-color($form-file-button-bg, 5%) !default;
// scss-docs-end form-file-variables

// scss-docs-start form-floating-variables
$form-floating-height:            add(3.5rem, $input-height-border) !default;
$form-floating-line-height:       1.25 !default;
$form-floating-padding-x:         $input-padding-x !default;
$form-floating-padding-y:         1rem !default;
$form-floating-input-padding-t:   1.625rem !default;
$form-floating-input-padding-b:   .625rem !default;
$form-floating-label-opacity:     .65 !default;
$form-floating-label-transform:   scale(.85) translateY(-.5rem) translateX(.15rem) !default;
$form-floating-transition:        opacity .1s ease-in-out, transform .1s ease-in-out !default;
// scss-docs-end form-floating-variables

// Form validation

// scss-docs-start form-feedback-variables
$form-feedback-margin-top:          $form-text-margin-top !default;
$form-feedback-font-size:           $form-text-font-size !default;
$form-feedback-font-style:          $form-text-font-style !default;
$form-feedback-valid-color:         $success !default;
$form-feedback-invalid-color:       $danger !default;

$form-feedback-icon-valid-color:    $form-feedback-valid-color !default;
$form-feedback-icon-valid:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color !default;
$form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;
// scss-docs-end form-feedback-variables

// scss-docs-start form-validation-states
$form-validation-states: (
        "valid": (
                "color": $form-feedback-valid-color,
                "icon": $form-feedback-icon-valid
        ),
        "invalid": (
                "color": $form-feedback-invalid-color,
                "icon": $form-feedback-icon-invalid
        )
) !default;
// scss-docs-end form-validation-states

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-offcanvas-backdrop:         1040 !default;
$zindex-offcanvas:                  1045 !default;
$zindex-modal-backdrop:             1050 !default;
$zindex-modal:                      1055 !default;
$zindex-popover:                    1070 !default;
$zindex-tooltip:                    1080 !default;
$zindex-toast:                      1090 !default;
// scss-docs-end zindex-stack


// Navs

// scss-docs-start nav-variables
$nav-link-padding-y:                .5rem !default;
$nav-link-padding-x:                1rem !default;
$nav-link-font-size:                null !default;
$nav-link-font-weight:              null !default;
$nav-link-color:                    var(--#{$prefix}link-color) !default;
$nav-link-hover-color:              var(--#{$prefix}link-hover-color) !default;
$nav-link-transition:               color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out !default;
$nav-link-disabled-color:           $gray-600 !default;

$nav-tabs-border-color:             $gray-300 !default;
$nav-tabs-border-width:             $border-width !default;
$nav-tabs-border-radius:            $border-radius !default;
$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color:        $gray-700 !default;
$nav-tabs-link-active-bg:           $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

$nav-pills-border-radius:           $border-radius !default;
$nav-pills-link-active-color:       $component-active-color !default;
$nav-pills-link-active-bg:          $component-active-bg !default;
// scss-docs-end nav-variables


// Navbar

// scss-docs-start navbar-variables
$navbar-padding-y:                  $spacer * .5 !default;
$navbar-padding-x:                  null !default;

$navbar-nav-link-padding-x:         .5rem !default;

$navbar-brand-font-size:            $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) * .5 !default;
$navbar-brand-margin-end:           1rem !default;

$navbar-toggler-padding-y:          .25rem !default;
$navbar-toggler-padding-x:          .75rem !default;
$navbar-toggler-font-size:          $font-size-lg !default;
$navbar-toggler-border-radius:      $btn-border-radius !default;
$navbar-toggler-focus-width:        $btn-focus-width !default;
$navbar-toggler-transition:         box-shadow .15s ease-in-out !default;

$navbar-light-color:                rgba($black, .55) !default;
$navbar-light-hover-color:          rgba($black, .7) !default;
$navbar-light-active-color:         rgba($black, .9) !default;
$navbar-light-disabled-color:       rgba($black, .3) !default;
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($black, .1) !default;
$navbar-light-brand-color:          $navbar-light-active-color !default;
$navbar-light-brand-hover-color:    $navbar-light-active-color !default;
// scss-docs-end navbar-variables

// scss-docs-start navbar-dark-variables
$navbar-dark-color:                 rgba($white, .55) !default;
$navbar-dark-hover-color:           rgba($white, .75) !default;
$navbar-dark-active-color:          $white !default;
$navbar-dark-disabled-color:        rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color:  rgba($white, .1) !default;
$navbar-dark-brand-color:           $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color:     $navbar-dark-active-color !default;
// scss-docs-end navbar-dark-variables


// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$dropdown-min-width:                10rem !default;
$dropdown-padding-x:                0 !default;
$dropdown-padding-y:                .5rem !default;
$dropdown-spacer:                   .125rem !default;
$dropdown-font-size:                $font-size-base !default;
$dropdown-color:                    $body-color !default;
$dropdown-bg:                       $white !default;
$dropdown-border-color:             var(--#{$prefix}border-color-translucent) !default;
$dropdown-border-radius:            $border-radius !default;
$dropdown-border-width:             $border-width !default;
$dropdown-inner-border-radius:      subtract($dropdown-border-radius, $dropdown-border-width) !default;
$dropdown-divider-bg:               $dropdown-border-color !default;
$dropdown-divider-margin-y:         $spacer * .5 !default;
$dropdown-box-shadow:               $box-shadow !default;

$dropdown-link-color:               $gray-900 !default;
$dropdown-link-hover-color:         shade-color($dropdown-link-color, 10%) !default;
$dropdown-link-hover-bg:            $gray-200 !default;

$dropdown-link-active-color:        $component-active-color !default;
$dropdown-link-active-bg:           $component-active-bg !default;

$dropdown-link-disabled-color:      $gray-500 !default;

$dropdown-item-padding-y:           $spacer * .25 !default;
$dropdown-item-padding-x:           $spacer !default;

$dropdown-header-color:             $gray-600 !default;
$dropdown-header-padding-x:         $dropdown-item-padding-x !default;
$dropdown-header-padding-y:         $dropdown-padding-y !default;
// fusv-disable
$dropdown-header-padding:           $dropdown-header-padding-y $dropdown-header-padding-x !default; // Deprecated in v5.2.0
// fusv-enable
// scss-docs-end dropdown-variables

// scss-docs-start dropdown-dark-variables
$dropdown-dark-color:               $gray-300 !default;
$dropdown-dark-bg:                  $gray-800 !default;
$dropdown-dark-border-color:        $dropdown-border-color !default;
$dropdown-dark-divider-bg:          $dropdown-divider-bg !default;
$dropdown-dark-box-shadow:          null !default;
$dropdown-dark-link-color:          $dropdown-dark-color !default;
$dropdown-dark-link-hover-color:    $white !default;
$dropdown-dark-link-hover-bg:       rgba($white, .15) !default;
$dropdown-dark-link-active-color:   $dropdown-link-active-color !default;
$dropdown-dark-link-active-bg:      $dropdown-link-active-bg !default;
$dropdown-dark-link-disabled-color: $gray-500 !default;
$dropdown-dark-header-color:        $gray-500 !default;
// scss-docs-end dropdown-dark-variables


// Pagination

// scss-docs-start pagination-variables
$pagination-padding-y:              .375rem !default;
$pagination-padding-x:              .75rem !default;
$pagination-padding-y-sm:           .25rem !default;
$pagination-padding-x-sm:           .5rem !default;
$pagination-padding-y-lg:           .75rem !default;
$pagination-padding-x-lg:           1.5rem !default;

$pagination-font-size:              $font-size-base !default;

$pagination-color:                  var(--#{$prefix}link-color) !default;
$pagination-bg:                     $white !default;
$pagination-border-radius:          $border-radius !default;
$pagination-border-width:           $border-width !default;
$pagination-margin-start:           ($pagination-border-width * -1) !default;
$pagination-border-color:           $gray-300 !default;

$pagination-focus-color:            var(--#{$prefix}link-hover-color) !default;
$pagination-focus-bg:               $gray-200 !default;
$pagination-focus-box-shadow:       $input-btn-focus-box-shadow !default;
$pagination-focus-outline:          0 !default;

$pagination-hover-color:            var(--#{$prefix}link-hover-color) !default;
$pagination-hover-bg:               $gray-200 !default;
$pagination-hover-border-color:     $gray-300 !default;

$pagination-active-color:           $component-active-color !default;
$pagination-active-bg:              $component-active-bg !default;
$pagination-active-border-color:    $pagination-active-bg !default;

$pagination-disabled-color:         $gray-600 !default;
$pagination-disabled-bg:            $white !default;
$pagination-disabled-border-color:  $gray-300 !default;

$pagination-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$pagination-border-radius-sm:       $border-radius-sm !default;
$pagination-border-radius-lg:       $border-radius-lg !default;
// scss-docs-end pagination-variables


// Placeholders

// scss-docs-start placeholders
$placeholder-opacity-max:           .5 !default;
$placeholder-opacity-min:           .2 !default;
// scss-docs-end placeholders

// Cards

// scss-docs-start card-variables
$card-spacer-y:                     $spacer !default;
$card-spacer-x:                     $spacer !default;
$card-title-spacer-y:               $spacer * .5 !default;
$card-border-width:                 $border-width !default;
$card-border-color:                 var(--#{$prefix}border-color-translucent) !default;
$card-border-radius:                $border-radius !default;
$card-box-shadow:                   null !default;
$card-inner-border-radius:          subtract($card-border-radius, $card-border-width) !default;
$card-cap-padding-y:                $card-spacer-y * .5 !default;
$card-cap-padding-x:                $card-spacer-x !default;
$card-cap-bg:                       rgba($black, .03) !default;
$card-cap-color:                    null !default;
$card-height:                       null !default;
$card-color:                        null !default;
$card-bg:                           $white !default;
$card-img-overlay-padding:          $spacer !default;
$card-group-margin:                 $grid-gutter-width * .5 !default;
// scss-docs-end card-variables

// Accordion

// scss-docs-start accordion-variables
$accordion-padding-y:                     1rem !default;
$accordion-padding-x:                     1.25rem !default;
$accordion-color:                         $body-color !default; // Sass variable because of $accordion-button-icon
$accordion-bg:                            $body-bg !default;
$accordion-border-width:                  $border-width !default;
$accordion-border-color:                  var(--#{$prefix}border-color) !default;
$accordion-border-radius:                 $border-radius !default;
$accordion-inner-border-radius:           subtract($accordion-border-radius, $accordion-border-width) !default;

$accordion-body-padding-y:                $accordion-padding-y !default;
$accordion-body-padding-x:                $accordion-padding-x !default;

$accordion-button-padding-y:              $accordion-padding-y !default;
$accordion-button-padding-x:              $accordion-padding-x !default;
$accordion-button-color:                  $accordion-color !default;
$accordion-button-bg:                     var(--#{$prefix}accordion-bg) !default;
$accordion-transition:                    $btn-transition, border-radius .15s ease !default;
$accordion-button-active-bg:              tint-color($component-active-bg, 90%) !default;
$accordion-button-active-color:           shade-color($primary, 10%) !default;

$accordion-button-focus-border-color:     $input-focus-border-color !default;
$accordion-button-focus-box-shadow:       $btn-focus-box-shadow !default;

$accordion-icon-width:                    1.25rem !default;
$accordion-icon-color:                    $accordion-button-color !default;
$accordion-icon-active-color:             $accordion-button-active-color !default;
$accordion-icon-transition:               transform .2s ease-in-out !default;
$accordion-icon-transform:                rotate(-180deg) !default;

$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
// scss-docs-end accordion-variables

// Tooltips

// scss-docs-start tooltip-variables
$tooltip-font-size:                 $font-size-sm !default;
$tooltip-max-width:                 200px !default;
$tooltip-color:                     $white !default;
$tooltip-bg:                        $black !default;
$tooltip-border-radius:             $border-radius !default;
$tooltip-opacity:                   .9 !default;
$tooltip-padding-y:                 $spacer * .25 !default;
$tooltip-padding-x:                 $spacer * .5 !default;
$tooltip-margin:                    null !default; // TODO: remove this in v6

$tooltip-arrow-width:               .8rem !default;
$tooltip-arrow-height:              .4rem !default;
// fusv-disable
$tooltip-arrow-color:               null !default; // Deprecated in Bootstrap 5.2.0 for CSS variables
// fusv-enable
// scss-docs-end tooltip-variables

// Form tooltips must come after regular tooltips
// scss-docs-start tooltip-feedback-variables
$form-feedback-tooltip-padding-y:     $tooltip-padding-y !default;
$form-feedback-tooltip-padding-x:     $tooltip-padding-x !default;
$form-feedback-tooltip-font-size:     $tooltip-font-size !default;
$form-feedback-tooltip-line-height:   null !default;
$form-feedback-tooltip-opacity:       $tooltip-opacity !default;
$form-feedback-tooltip-border-radius: $tooltip-border-radius !default;
// scss-docs-end tooltip-feedback-variables


// Popovers

// scss-docs-start popover-variables
$popover-font-size:                 $font-size-sm !default;
$popover-bg:                        $white !default;
$popover-max-width:                 276px !default;
$popover-border-width:              $border-width !default;
$popover-border-color:              var(--#{$prefix}border-color-translucent) !default;
$popover-border-radius:             $border-radius-lg !default;
$popover-inner-border-radius:       subtract($popover-border-radius, $popover-border-width) !default;
$popover-box-shadow:                $box-shadow !default;

$popover-header-font-size:          $font-size-base !default;
$popover-header-bg:                 shade-color($popover-bg, 6%) !default;
$popover-header-color:              $headings-color !default;
$popover-header-padding-y:          .5rem !default;
$popover-header-padding-x:          $spacer !default;

$popover-body-color:                $body-color !default;
$popover-body-padding-y:            $spacer !default;
$popover-body-padding-x:            $spacer !default;

$popover-arrow-width:               1rem !default;
$popover-arrow-height:              .5rem !default;
// scss-docs-end popover-variables

// fusv-disable
// Deprecated in Bootstrap 5.2.0 for CSS variables
$popover-arrow-color:               $popover-bg !default;
$popover-arrow-outer-color:         var(--#{$prefix}border-color-translucent) !default;
// fusv-enable


// Toasts

// scss-docs-start toast-variables
$toast-max-width:                   350px !default;
$toast-padding-x:                   .75rem !default;
$toast-padding-y:                   .5rem !default;
$toast-font-size:                   .875rem !default;
$toast-color:                       null !default;
$toast-background-color:            rgba($white, .85) !default;
$toast-border-width:                $border-width !default;
$toast-border-color:                var(--#{$prefix}border-color-translucent) !default;
$toast-border-radius:               $border-radius !default;
$toast-box-shadow:                  $box-shadow !default;
$toast-spacing:                     $container-padding-x !default;

$toast-header-color:                $gray-600 !default;
$toast-header-background-color:     rgba($white, .85) !default;
$toast-header-border-color:         rgba($black, .05) !default;
// scss-docs-end toast-variables


// Badges

// scss-docs-start badge-variables
$badge-font-size:                   .75em !default;
$badge-font-weight:                 $font-weight-bold !default;
$badge-color:                       $white !default;
$badge-padding-y:                   .35em !default;
$badge-padding-x:                   .65em !default;
$badge-border-radius:               $border-radius !default;
// scss-docs-end badge-variables


// Modals

// scss-docs-start modal-variables
$modal-inner-padding:               $spacer !default;

$modal-footer-margin-between:       .5rem !default;

$modal-dialog-margin:               .5rem !default;
$modal-dialog-margin-y-sm-up:       1.75rem !default;

$modal-title-line-height:           $line-height-base !default;

$modal-content-color:               null !default;
$modal-content-bg:                  $white !default;
$modal-content-border-color:        var(--#{$prefix}border-color-translucent) !default;
$modal-content-border-width:        $border-width !default;
$modal-content-border-radius:       $border-radius-lg !default;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs:       $box-shadow-sm !default;
$modal-content-box-shadow-sm-up:    $box-shadow !default;

$modal-backdrop-bg:                 $black !default;
$modal-backdrop-opacity:            .5 !default;

$modal-header-border-color:         var(--#{$prefix}border-color) !default;
$modal-header-border-width:         $modal-content-border-width !default;
$modal-header-padding-y:            $modal-inner-padding !default;
$modal-header-padding-x:            $modal-inner-padding !default;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-footer-bg:                   null !default;
$modal-footer-border-color:         $modal-header-border-color !default;
$modal-footer-border-width:         $modal-header-border-width !default;

$modal-sm:                          300px !default;
$modal-md:                          500px !default;
$modal-lg:                          800px !default;
$modal-xl:                          1140px !default;

$modal-fade-transform:              translate(0, -50px) !default;
$modal-show-transform:              none !default;
$modal-transition:                  transform .3s ease-out !default;
$modal-scale-transform:             scale(1.02) !default;
// scss-docs-end modal-variables


// Alerts
//
// Define alert colors, border radius, and padding.

// scss-docs-start alert-variables
$alert-padding-y:               $spacer !default;
$alert-padding-x:               $spacer !default;
$alert-margin-bottom:           1rem !default;
$alert-border-radius:           $border-radius !default;
$alert-link-font-weight:        $font-weight-bold !default;
$alert-border-width:            $border-width !default;
$alert-bg-scale:                -80% !default;
$alert-border-scale:            -70% !default;
$alert-color-scale:             40% !default;
$alert-dismissible-padding-r:   $alert-padding-x * 3 !default; // 3x covers width of x plus default padding on either side
// scss-docs-end alert-variables


// Progress bars

// scss-docs-start progress-variables
$progress-height:                   1rem !default;
$progress-font-size:                $font-size-base * .75 !default;
$progress-bg:                       $gray-200 !default;
$progress-border-radius:            $border-radius !default;
$progress-box-shadow:               $box-shadow-inset !default;
$progress-bar-color:                $white !default;
$progress-bar-bg:                   $primary !default;
$progress-bar-animation-timing:     1s linear infinite !default;
$progress-bar-transition:           width .6s ease !default;
// scss-docs-end progress-variables


// List group

// scss-docs-start list-group-variables
$list-group-color:                  $gray-900 !default;
$list-group-bg:                     $white !default;
$list-group-border-color:           rgba($black, .125) !default;
$list-group-border-width:           $border-width !default;
$list-group-border-radius:          $border-radius !default;

$list-group-item-padding-y:         $spacer * .5 !default;
$list-group-item-padding-x:         $spacer !default;
$list-group-item-bg-scale:          -80% !default;
$list-group-item-color-scale:       40% !default;

$list-group-hover-bg:               $gray-100 !default;
$list-group-active-color:           $component-active-color !default;
$list-group-active-bg:              $component-active-bg !default;
$list-group-active-border-color:    $list-group-active-bg !default;

$list-group-disabled-color:         $gray-600 !default;
$list-group-disabled-bg:            $list-group-bg !default;

$list-group-action-color:           $gray-700 !default;
$list-group-action-hover-color:     $list-group-action-color !default;

$list-group-action-active-color:    $body-color !default;
$list-group-action-active-bg:       $gray-200 !default;
// scss-docs-end list-group-variables


// Image thumbnails

// scss-docs-start thumbnail-variables
$thumbnail-padding:                 .25rem !default;
$thumbnail-bg:                      $body-bg !default;
$thumbnail-border-width:            $border-width !default;
$thumbnail-border-color:            var(--#{$prefix}border-color) !default;
$thumbnail-border-radius:           $border-radius !default;
$thumbnail-box-shadow:              $box-shadow-sm !default;
// scss-docs-end thumbnail-variables


// Figures

// scss-docs-start figure-variables
$figure-caption-font-size:          $small-font-size !default;
$figure-caption-color:              $gray-600 !default;
// scss-docs-end figure-variables


// Breadcrumbs

// scss-docs-start breadcrumb-variables
$breadcrumb-font-size:              null !default;
$breadcrumb-padding-y:              0 !default;
$breadcrumb-padding-x:              0 !default;
$breadcrumb-item-padding-x:         .5rem !default;
$breadcrumb-margin-bottom:          1rem !default;
$breadcrumb-bg:                     null !default;
$breadcrumb-divider-color:          $gray-600 !default;
$breadcrumb-active-color:           $gray-600 !default;
$breadcrumb-divider:                quote("/") !default;
$breadcrumb-divider-flipped:        $breadcrumb-divider !default;
$breadcrumb-border-radius:          null !default;
// scss-docs-end breadcrumb-variables

// Carousel

// scss-docs-start carousel-variables
$carousel-control-color:             $white !default;
$carousel-control-width:             15% !default;
$carousel-control-opacity:           .5 !default;
$carousel-control-hover-opacity:     .9 !default;
$carousel-control-transition:        opacity .15s ease !default;

$carousel-indicator-width:           30px !default;
$carousel-indicator-height:          3px !default;
$carousel-indicator-hit-area-height: 10px !default;
$carousel-indicator-spacer:          3px !default;
$carousel-indicator-opacity:         .5 !default;
$carousel-indicator-active-bg:       $white !default;
$carousel-indicator-active-opacity:  1 !default;
$carousel-indicator-transition:      opacity .6s ease !default;

$carousel-caption-width:             70% !default;
$carousel-caption-color:             $white !default;
$carousel-caption-padding-y:         1.25rem !default;
$carousel-caption-spacer:            1.25rem !default;

$carousel-control-icon-width:        2rem !default;

$carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;
$carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !default;

$carousel-transition-duration:       .6s !default;
$carousel-transition:                transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)
// scss-docs-end carousel-variables

// scss-docs-start carousel-dark-variables
$carousel-dark-indicator-active-bg:  $black !default;
$carousel-dark-caption-color:        $black !default;
$carousel-dark-control-icon-filter:  invert(1) grayscale(100) !default;
// scss-docs-end carousel-dark-variables


// Spinners

// scss-docs-start spinner-variables
$spinner-width:           2rem !default;
$spinner-height:          $spinner-width !default;
$spinner-vertical-align:  -.125em !default;
$spinner-border-width:    .25em !default;
$spinner-animation-speed: .75s !default;

$spinner-width-sm:        1rem !default;
$spinner-height-sm:       $spinner-width-sm !default;
$spinner-border-width-sm: .2em !default;
// scss-docs-end spinner-variables


// Close

// scss-docs-start close-variables
$btn-close-width:            1em !default;
$btn-close-height:           $btn-close-width !default;
$btn-close-padding-x:        .25em !default;
$btn-close-padding-y:        $btn-close-padding-x !default;
$btn-close-color:            $black !default;
$btn-close-bg:               url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>") !default;
$btn-close-focus-shadow:     $input-btn-focus-box-shadow !default;
$btn-close-opacity:          .5 !default;
$btn-close-hover-opacity:    .75 !default;
$btn-close-focus-opacity:    1 !default;
$btn-close-disabled-opacity: .25 !default;
$btn-close-white-filter:     invert(1) grayscale(100%) brightness(200%) !default;
// scss-docs-end close-variables


// Offcanvas

// scss-docs-start offcanvas-variables
$offcanvas-padding-y:               $modal-inner-padding !default;
$offcanvas-padding-x:               $modal-inner-padding !default;
$offcanvas-horizontal-width:        400px !default;
$offcanvas-vertical-height:         30vh !default;
$offcanvas-transition-duration:     .3s !default;
$offcanvas-border-color:            $modal-content-border-color !default;
$offcanvas-border-width:            $modal-content-border-width !default;
$offcanvas-title-line-height:       $modal-title-line-height !default;
$offcanvas-bg-color:                $modal-content-bg !default;
$offcanvas-color:                   $modal-content-color !default;
$offcanvas-box-shadow:              $modal-content-box-shadow-xs !default;
$offcanvas-backdrop-bg:             $modal-backdrop-bg !default;
$offcanvas-backdrop-opacity:        $modal-backdrop-opacity !default;
// scss-docs-end offcanvas-variables

// Code

$code-font-size:                    $small-font-size !default;
$code-color:                        $pink !default;

$kbd-padding-y:                     .1875rem !default;
$kbd-padding-x:                     .375rem !default;
$kbd-font-size:                     $code-font-size !default;
$kbd-color:                         var(--#{$prefix}body-bg) !default;
$kbd-bg:                            var(--#{$prefix}body-color) !default;
$nested-kbd-font-weight:            null !default; // Deprecated in v5.2.0, removing in v6

$pre-color:                         null !default;

// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px)
//
// The map defined in the `$grid-breakpoints` global variable is used as the `$breakpoints` argument by default.

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl xxl))
//    md
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @if not $n {
    @error "breakpoint `#{$name}` not found in `#{$breakpoints}`";
  }
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Maximum breakpoint width.
// The maximum value is reduced by 0.02px to work around the limitations of
// `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(md, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $max: map-get($breakpoints, $name);
  @return if($max and $max > 0, $max - .02, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min:  breakpoint-min($name, $breakpoints);
  $next: breakpoint-next($name, $breakpoints);
  $max:  breakpoint-max($next, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($next, $breakpoints) {
      @content;
    }
  }
}


$input-border-color: #ddd !default;
$cap-input-border-color: #ddd !default;
$cap-input-height: 30px !default;
$cap-light-gray: #f0f0f0 !default;
$cap-dark-gray: #333 !default;
$cap-accordion-icon-color: #848484 !default;
$cap-white: #fff !default;
$cap-black: #000 !default;
$cap-gray: #ddd !default;
$cap-success: #68e76f !default;
$cap-warning: #faa200 !default;
$cap-danger: #fa4331 !default;
$cap-platinum: #f9f9f9 !default;
$cap-primary-font: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif !default; /* stylelint-disable-line value-keyword-case */

// Toast
$cap-toast-background-color: #fff !default;
$cap-toast-text-color: #333 !default;
$cap-toast-background-color-success: $cap-toast-background-color !default;
$cap-toast-background-color-warning: $cap-toast-background-color !default;
$cap-toast-background-color-info: $cap-toast-background-color !default;
$cap-toast-background-color-error: $cap-toast-background-color !default;
$cap-toast-summary-font-size: 18px !default;
$cap-toast-summary-font-weight: 600 !default;
$cap-toast-summary-font-family: $cap-primary-font !default;
$cap-toast-summary-color: $cap-toast-text-color !default;

$cap-toast-detail-font-size: 14px !default;
$cap-toast-detail-text-color: #4c4c4c !default;

$cap-toast-close-icon-color: rgba(51, 51, 51, 0.15) !default;
$cap-toast-close-icon-top: 0 !default;
$cap-toast-close-icon-bottom: 0 !default;
$cap-toast-close-icon-right: 15px !default;
$cap-toast-close-icon-justify-content: center !default;
$cap-toast-close-icon-display: flex !default;
$cap-toast-close-icon-flex-direction: column !default;
$cap-toast-close-icon-flex-font-size: 20px !default;

$cap-toast-icon-font-size: 29px !default;
$cap-toast-icon-margin-right: 30px !default;
$cap-toast-border-radius: 5px !default;
$cap-toast-message-content-padding: 15px 20px !default;

$cap-toast-message-error-background: $cap-toast-background-color-error !default;
$cap-toast-message-error-border-left: 4px solid $cap-danger !default;

$cap-toast-message-info-background: $cap-toast-background-color-error !default;
$cap-toast-message-info-border-left: 4px solid var(--cap-primary-color) !default;

// Action Menu
$cap-action-menu-disabled-text-color: #776f6f;

// Fab Button
$cap-fab-button-size: 56px !default;
$cap-fab-button-margin-size: 16px !default;
$cap-fab-button-icon-size: 22px !default;

$cap-fab-button-width: $cap-fab-button-size !default;
$cap-fab-button-height: $cap-fab-button-size !default;
$cap-fab-button-background-color: var(--cap-primary-color) !default;
$cap-fab-button-border-radius: 50% !default;
$cap-fab-button-display: flex !default;
$cap-fab-button-justify-content: center !default;
$cap-fab-button-align-items: center !default;
$cap-fab-button-border: none !default;
$cap-fab-button-position: fixed !default;
$cap-fab-button-bottom: $cap-fab-button-margin-size !default;
$cap-fab-button-z-index: 10 !default;

$cap-fab-button-icon-width: $cap-fab-button-icon-size !default;
$cap-fab-button-icon-height: $cap-fab-button-icon-size !default;
$cap-fab-button-icon-font-size: $cap-fab-button-icon-size !default;
$cap-fab-button-icon-color: $cap-white !default;
$cap-fab-button-text-align: center !default;

$cap-fab-button-top-right-right: $cap-fab-button-margin-size !default;
$cap-fab-button-top-right-top: $cap-fab-button-margin-size !default;

$cap-fab-button-bottom-left-left: $cap-fab-button-margin-size !default;
$cap-fab-button-bottom-left-bottom: $cap-fab-button-margin-size !default;

$cap-fab-button-bottom-top-left-left: $cap-fab-button-margin-size !default;
$cap-fab-button-bottom-top-left-top: $cap-fab-button-margin-size !default;

$cap-fab-button-bottom-right-right: $cap-fab-button-margin-size !default;
$cap-fab-button-bottom-right-bottom: $cap-fab-button-margin-size !default;

// File Upload
$cap-file-upload-padding: 15px !default;
$cap-file-upload-width: 100% !default;
$cap-file-upload-height: 140px !default;
$cap-file-upload-background-color: #fff !default;
$cap-file-upload-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !default;
$cap-file-upload-display: flex !default;
$cap-file-upload-flex-direction: column !default;
$cap-file-upload-justify-content: center !default;
$cap-file-upload-color: var(--cap-primary-color) !default;
$cap-file-upload-font-weight: 400 !default;

$cap-file-upload-dragover-border: 1px dashed var(--cap-primary-color) !default;
$cap-file-upload-icon-opacity: 0.5 !default;
$cap-file-upload-icon-font-size: 32px !default;
$cap-file-upload-icon-display: block !default;
$cap-file-upload-icon-margin-bottom: 8px !default;

// Form field
$cap-form-field-label-font-weight: 600 !default;

// Desktop Stepper
$cap-desktop-stepper-icon-size: 24px !default;
$cap-desktop-stepper-horizontal-background-color: transparent !default;
$cap-desktop-stepper-horizontal-header-container-white-space: nowrap !default;
$cap-desktop-stepper-horizontal-header-container-align-items: center !default;
$cap-desktop-stepper-horizontal-header-container-justify-content: space-between !default;
$cap-desktop-stepper-horizontal-header-height: 30px !default;
$cap-desktop-stepper-horizontal-align-items: center !default;

$cap-desktop-stepper-icon-content-align-self: center !default;
$cap-desktop-stepper-icon-content-line-height: 1.5 !default;
$cap-desktop-stepper-icon-border-radius: 50% !default;
$cap-desktop-stepper-icon-border: 1px solid var(--cap-primary-color) !default;
$cap-desktop-stepper-icon-height: $cap-desktop-stepper-icon-size !default;
$cap-desktop-stepper-icon-width: $cap-desktop-stepper-icon-size !default;
$cap-desktop-stepper-step-icon-background-color: $cap-white !default;
$cap-desktop-stepper-step-icon-color: var(--cap-primary-color) !default;
$cap-desktop-stepper-step-icon-justify-content: center !default;
$cap-desktop-stepper-step-icon-completed-background-color: var(--cap-primary-color) !default;
$cap-desktop-stepper-step-icon-color: white !default;

$cap-desktop-stepper-horizontal-line-border-top-style: solid !default;
$cap-desktop-stepper-horizontal-line-min-width: 32px !default;
$cap-desktop-stepper-horizontal-line-opacity: 0.3 !default;
$cap-desktop-stepper-horizontal-line-border-top: 5px solid var(--cap-primary-color) !default;
$cap-desktop-stepper-horizontal-line-color: var(--cap-primary-color) !default;

$cap-desktop-stepper-step-label-font-size: 10px !default;
$cap-desktop-stepper-step-label-active-color: var(--cap-primary-color) !default;
$cap-desktop-stepper-step-label-inactive-color: var(--text-color-2) !default;

// Dropdown
$cap-dropdown-background: $cap-white !default;
$cap-dropdown-border: 1px solid $input-border-color !default;
$cap-dropdown-transition: border-color 0.2s !default;
$cap-dropdown-height: $cap-input-height !default;
$cap-dropdown-label-container-height: $cap-input-height !default;

$cap-dropdown-multiselect-label-padding: .95rem .7rem !default;
$cap-dropdown-multiselect-label-padding-right: 2em !default;
$cap-dropdown-multiselect-label-height: calc(#{$cap-input-height} - 3px) !default;
$cap-dropdown-multiselect-justify-content: center !default;
$cap-dropdown-multiselect-flex-direction: column !default;

$cap-dropdown-multiselect-selected-img-width: 16px !default;
$cap-dropdown-multiselect-selected-line-height: 16px !default;
$cap-dropdown-multiselect-selected-icon-font-size: 16px !default;
$cap-dropdown-multiselect-selected-img-vertical-align: middle !default;
$cap-dropdown-multiselect-selected-label-vertical-align: middle !default;
$cap-dropdown-multiselect-selected-label-margin-left: 0.5em !default;

$cap-dropdown-multiselect-trigger-background-color: $cap-white !default;
$cap-dropdown-multiselect-trigger-width: 2rem !default;
$cap-dropdown-multiselect-trigger-line-height: 2rem !default;
$cap-dropdown-multiselect-trigger-text-align: center !default;
$cap-dropdown-multiselect-trigger-color: var(--primary-text) !default;
$cap-dropdown-multiselect-trigger-border-left: 1px solid transparent !default;

$cap-dropdown-multiselect-clear-icon: var(--primary-text) !default;
$cap-dropdown-multiselect-cap-dropdown-label-padding-right: 4em !default;
$cap-dropdown-multiselect-input-padding: 8px 12px 8px 40px !default;
$cap-dropdown-multiselect-icon-color: var(--cap-primary-color) !default;
$cap-dropdown-multiselect-icon-margin-right: 5px !default;

$cap-dropdown-multiselect-checkbox-border: 1px solid $input-border-color !default;
$cap-dropdown-multiselect-checkbox-background-color: $cap-white !default;
$cap-dropdown-multiselect-checkbox-width: 20px !default;
$cap-dropdown-multiselect-checkbox-height: 20px !default;
$cap-dropdown-multiselect-checkbox-text-align: center !default;
$cap-dropdown-multiselect-checkbox-border-radius: 3px !default;
$cap-dropdown-multiselect-checkbox-border-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s !default;

$cap-dropdown-multiselect-panel-padding: 0 !default;
$cap-dropdown-multiselect-panel-border: 1px solid $input-border-color !default;
$cap-dropdown-multiselect-panel-background-color: $cap-white !default;
$cap-dropdown-multiselect-panel-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !default;
$cap-dropdown-multiselect-panel-items-padding: 0 !default;

$cap-dropdown-multiselect-panel-item-group-margin: 0 !default;
$cap-dropdown-multiselect-panel-item-group-padding: 8px 12px !default;
$cap-dropdown-multiselect-panel-item-group-border: 0 none !default;
$cap-dropdown-multiselect-panel-item-group-color: var(--primary-text) !default;
$cap-dropdown-multiselect-panel-item-group-background-color: transparent !default;
$cap-dropdown-multiselect-panel-item-group-border-radius: 0 !default;
$cap-dropdown-multiselect-panel-item-group-border-top: 1px solid rgba(0, 0, 0, 0.15) !default;
$cap-dropdown-multiselect-panel-item-highlight-color: $cap-white !default;
$cap-dropdown-multiselect-panel-item-highlight-background-color: var(--cap-primary-color) !default;

$cap-dropdown-multiselect-panel-custom-item-height: 18px !default;
$cap-dropdown-multiselect-panel-custom-img-width: 24px !default;
$cap-dropdown-multiselect-panel-custom-img-top: 50% !default;
$cap-dropdown-multiselect-panel-custom-img-left: 0 !default;
$cap-dropdown-multiselect-panel-custom-img-transform: translateY(-50%) !default;

$cap-dropdown-multiselect-panel-custom-label-font-size: 14px !default;
$cap-dropdown-multiselect-panel-custom-label-top: 50% !default;
$cap-dropdown-multiselect-panel-custom-label-left: 30px !default;
$cap-dropdown-multiselect-panel-custom-label-position: absolute !default;
$cap-dropdown-multiselect-panel-custom-label-transform: translateY(-50%) !default;

$cap-dropdown-multiselect-panel-filter-container-border-bottom: 1px solid #eaeaea !default;
$cap-dropdown-multiselect-panel-filter-container-color: var(--primary-text) !default;
$cap-dropdown-multiselect-panel-filter-container-background-color: $cap-white !default;
$cap-dropdown-multiselect-panel-filter-container-margin: 0 !default;
$cap-dropdown-multiselect-panel-filter-container-padding: 0 !default;

$cap-dropdown-multiselect-panel-filter-container-input-padding: 8px 12px 8px 40px !default;
$cap-dropdown-multiselect-panel-filter-container-input-width: 100% !default;
$cap-dropdown-multiselect-panel-filter-container-input-border: none !default;

$cap-dropdown-multiselect-panel-filter-container-icon-position: absolute !default;
$cap-dropdown-multiselect-panel-filter-container-icon-left: 12px !default;
$cap-dropdown-multiselect-panel-filter-container-icon-top: 50% !default;
$cap-dropdown-multiselect-panel-filter-container-icon-color: var(--cap-primary-color) !default;

// Floating labels
$floating-input-border: 1px solid $input-border-color !default;
$floating-placeholder-text-color: #5f6062 !default;

// Editor
$cap-editor-tox-border-radius: 3px !default;
$cap-editor-tox-border-color: $cap-input-border-color !default;
$cap-editor-tox-edit-area-border-top: none !default;
$cap-editor-tox-toolbar-padding: 10px 0 !default;
$cap-editor-tox-toolbar-border: none !default;
$cap-editor-tox-toolbar-background: #ddd !default;
$cap-editor-tox-toolbar-color: rgba(51, 51, 51, 0.5) !default;

$cap-editor-tox-toolbar-btn-margin: 0 5px !default;
$cap-editor-tox-toolbar-btn-hover-background: darken(#ddd, 5%) !default;
$cap-editor-tox-toolbar-btn-select-background: #fff !default;

$cap-editor-svg-fill: rgba(51, 51, 51, 0.5) !default;

// PDF Viewer
$cap-pdf-viewer-button-size: 30px !default;
$cap-pdf-viewer-toolbar-icon-right: 15px !default;
$cap-pdf-viewer-toolbar-padding: 10px !default;

$cap-pdf-viewer-background-color: $cap-white !default;
$cap-pdf-viewer-width: 100% !default;
$cap-pdf-viewer-max-height: 100% !default;
$cap-pdf-viewer-display: block !default;
$cap-pdf-viewer-position: relative !default;

$cap-pdf-viewer-info-row-margin: 0 auto !default;
$cap-pdf-viewer-info-row-width: 100% !default;
$cap-pdf-viewer-info-row-text-align: center !default;
$cap-pdf-viewer-info-row-display: flex !default;
$cap-pdf-viewer-info-row-flex-direction: row !default;
$cap-pdf-viewer-info-row-flex-justify-content: center !default;

$cap-pdf-viewer-page-controls-hover-visibility: visible !default;
$cap-pdf-viewer-page-controls-display: inline-flex !default;
$cap-pdf-viewer-page-controls-visibility: hidden !default;
$cap-pdf-viewer-page-controls-width: 100% !default;
$cap-pdf-viewer-page-controls-justify-content: space-between !default;

$cap-pdf-viewer-page-controls-opacity: 0.4 !default;
$cap-pdf-viewer-page-controls-position: absolute !default;
$cap-pdf-viewer-page-controls-nav-width: 75px !default;
$cap-pdf-viewer-page-controls-nav-height: 200px !default;
$cap-pdf-viewer-page-controls-nav-background-color: var(--cap-primary-color) !default;
$cap-pdf-viewer-page-controls-nav-transition: opacity 0.25s linear !default;
$cap-pdf-viewer-page-controls-nav-icon-position: absolute !default;
$cap-pdf-viewer-page-controls-nav-icon-top: 50% !default;
$cap-pdf-viewer-page-controls-nav-icon-transform: translateY(-50%) !default;
$cap-pdf-viewer-page-controls-nav-icon-color: $cap-white !default;
$cap-pdf-viewer-page-controls-top: calc(50% - 0.5 * #{$cap-pdf-viewer-page-controls-nav-height}) !default;

$cap-pdf-viewer-toolbar-margin: 0 !default;
$cap-pdf-viewer-toolbar-info-column-float: left !default;
$cap-pdf-viewer-toolbar-info-column-padding: 10px !default;
$cap-pdf-viewer-toolbar-info-column-height: 100% !default;
$cap-pdf-viewer-toolbar-info-column-justify-content: center !default;
$cap-pdf-viewer-toolbar-info-column-display: flex !default;
$cap-pdf-viewer-toolbar-info-column-flex-direction: column !default;
$cap-pdf-viewer-toolbar-info-column-border-radius: 3px !default;

// Progress Bar
$cap-progress-bar-border: 0 none !default;
$cap-progress-bar-height: 24px !default;
$cap-progress-bar-background-color: #eaeaea !default;

$cap-progress-bar-progressbar-value-height: 100% !default;
$cap-progress-bar-progressbar-value-position: absolute !default;
$cap-progress-bar-progressbar-value-border: 0 none !default;
$cap-progress-bar-progressbar-value-margin: 0 !default;
$cap-progress-bar-progressbar-value-background: var(--cap-primary-color) !default;

$cap-progress-bar-label-height: 100% !default;
$cap-progress-bar-label-width: 100% !default;
$cap-progress-bar-label-position: absolute !default;
$cap-progress-bar-label-margin-top: 0 !default;
$cap-progress-bar-label-text-align: center !default;
$cap-progress-bar-label-font-weight: bold !default;
$cap-progress-bar-label-color: var(--primary-text) !default;
$cap-progress-bar-label-line-height: 24px !default;

// Progress Circle
$cap-progress-circle-position: relative !default;
$cap-progress-circle-display: inline-block !default;
$cap-progress-circle-custom: center !default;
$cap-progress-circle-position: absolute !default;
$cap-progress-circle-content-top: 0 !default;
$cap-progress-circle-subtitle-top: 50% !default;

// Loader
$cap-loader-image-width: fit-content !default;
$cap-loader-image-margin: 0 auto !default;

$cap-loader-position: fixed !default;
$cap-loader-top: 0 !default;
$cap-loader-left: 0 !default;
$cap-loader-bottom: 0 !default;
$cap-loader-width: 100% !default;
$cap-loader-background: rgba(0, 0, 0, 0.65) !default;
$cap-loader-z-index: 99 !default;
$cap-loader-text-align: center !default;
$cap-loader-display: flex !default;
$cap-loader-flex-direction: column !default;
$cap-loader-justify-content: center !default;
$cap-loader-text-color: var(--cap-primary-color) !default;
$cap-loader-progress-spinner-color-stroke: var(--cap-primary-color) !default;
$cap-loader-text-animation: dots 1s steps(5, end) infinite !default;

// Mobile Tab Bar
$cap-mobile-tab-bar-margin: 0.3rem !default;
$cap-mobile-tab-bar-display: flex !default;
$cap-mobile-tab-bar-justify-content: space-evenly !default;
$cap-mobile-tab-bar-margin-top: $cap-mobile-tab-bar-margin !default;
$cap-mobile-tab-bar-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.3) !default;
$cap-mobile-tab-bar-padding: 10px 0 !default;
$cap-mobile-tab-bar-background-color: #fff !default;
$cap-mobile-tab-bar-shape-font-size: 2rem !default;
$cap-mobile-tab-bar-menu-item-margin-bottom: $cap-mobile-tab-bar-margin !default;
$cap-mobile-tab-bar-menu-item-font-size: 0.8rem !default;
$cap-mobile-tab-bar-menu-item-weight: 600 !default;

$cap-mobile-tab-bar-active-text-color: var(--cap-primary-color) !default;
$cap-mobile-tab-bar-rectangle-text-align: center !default;
$cap-mobile-tab-bar-word-break: break-all !default;
$cap-mobile-tab-bar-max-width: 20% !default;

// Notification Bar
$cap-notification-bar-square-size: 2rem !default;
$cap-notification-bar-circle-size: 1.2rem !default;
$cap-notification-bar-icon-size: 50px !default;
$cap-notification-bar-box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.2) !default;

$cap-notification-bar-background-color: #fbfbfb !default;
$cap-notification-bar-height: 100% !default;
$cap-notification-bar-padding: 0 1.5rem !default;

$cap-notification-bar-head-display: flex !default;
$cap-notification-bar-head-justify-content: space-between !default;
$cap-notification-bar-head-align-items: center !default;
$cap-notification-bar-head-margin: 2.5rem 0 2rem 0 !default;

$cap-notification-bar-icon-angle-left-color: var(--text-color-2) !default;
$cap-notification-bar-icon-angle-font-size: 2rem !default;
$cap-notification-bar-times-color: var(--cap-primary-color) !default;
$cap-notification-bar-times-text-align: center !default;
$cap-notification-bar-times-font-size: 1.3rem !default;

$cap-notification-bar-bell-width: $cap-notification-bar-square-size !default;
$cap-notification-bar-bell-height: $cap-notification-bar-square-size !default;
$cap-notification-bar-bell-border-radius: 3px !default;
$cap-notification-bar-bell-box-shadow: 0 1px 1px 0 dimgrey !default;
$cap-notification-bar-bell-background-color: var(--cap-primary-color) !default;
$cap-notification-bar-bell-center: center !default;
$cap-notification-bar-bell-position: relative !default;
$cap-notification-bar-bell-color: white !default;
$cap-notification-bar-bell-padding-top: 25% !default;

$cap-notification-bottom-bar-z-index: 1 !default;
$cap-notification-bottom-bar-box-shadow: 0 -12px 10px -10px rgba(0, 0, 0, 0.2) !default;

$cap-notification-circle-text-align: center !default;
$cap-notification-circle-border-radius: 50% !default;
$cap-notification-circle-line-height: $cap-notification-bar-circle-size !default;
$cap-notification-bar-circle-position: absolute !default;
$cap-notification-circle-font-size: 0.65rem !default;
$cap-notification-circle-font-weight: 700 !default;

$cap-notification-bar-text-font-weight: 600 !default;
$cap-notification-bar-text-margin-bottom: 0 !default;
$cap-notification-bar-text-strong-color: var(--text-color-2) !default;
$cap-notification-bar-text-scroll: scroll !default;
$cap-notification-item-list-style: none !default;
$cap-notification-item-margin: 1rem 0 !default;
$cap-notification-trash-icon-width: $cap-notification-bar-square-size !default;
$cap-notification-trash-icon-height: $cap-notification-bar-square-size !default;
$cap-notification-trash-icon-line-height: $cap-notification-bar-square-size !default;
$cap-notification-trash-icon-cursor: pointer !default;
$cap-notification-trash-icon-color: var(--cap-primary-color) !default;
$cap-notification-trash-border: solid 1px var(--cap-primary-color) !default;
$cap-notification-trash-text-align: center !default;
$cap-notification-trash-border-radius: 4px !default;
$cap-notification-trash-margin: 1.5rem 1.5rem 1rem 0 !default;

$cap-notification-bar-item-card-display: flex !default;
$cap-notification-bar-item-card-align-items: center !default;
$cap-notification-bar-item-card-padding: 1rem !default;
$cap-notification-bar-item-card-margin-top: 1rem !default;
$cap-notification-bar-item-card-background-color: white !default;
$cap-notification-bar-item-card-border-radius: 4px !default;

$cap-notification-bar-item-card-status-icon-text-align: center !default;
$cap-notification-bar-item-card-status-icon-font-size: 1.3rem !default;
$cap-notification-bar-item-card-status-icon-margin-right: 1rem !default;
$cap-notification-bar-item-card-status-icon-line-height: 2.6rem !default;
$cap-notification-bar-item-card-status-icon-color: white !default;
$cap-notification-bar-item-card-word-break: break-word !default;
$cap-notification-bar-item-card-line-height: 1em !default;

$cap-notification-bar-item-card-title-font-weight: bold !default;
$cap-notification-bar-item-card-title-font-size: 18px !default;
$cap-notification-bar-item-card-title-margin-bottom: 0 !default;
$cap-notification-bar-item-card-title-color: var(--text-color-2) !default;

$cap-notification-bar-item-card-subtitle-font-weight: 500 !default;
$cap-notification-bar-item-card-subtitle-font-size: 12px !default;
$cap-notification-bar-item-card-subtitle-margin-bottom: 0.35rem !default;
$cap-notification-bar-item-card-subtitle-margin-top: 0.25rem !default;
$cap-notification-bar-item-card-subtitle-color: var(--text-color-3) !default;

$cap-notification-bar-item-card-body-margin-bottom: 0 !default;
$cap-notification-bar-item-card-body-padding: 0 !default;
$cap-notification-bar-item-card-body-line-height: 1em !default;
$cap-notification-bar-item-card-body-font-size: 13px !default;
$cap-notification-bar-item-card-body-color: var(--text-color-2) !default;
$cap-notification-unread-border: solid 1px var(--cap-primary-color) !default;
$cap-notification-tabs-padding-left: 10px !default;
$cap-notification-tabs-padding-right: 10px !default;
$cap-notification-tabs-col-padding-left: 5px !default;
$cap-notification-tabs-col-padding-right: 5px !default;

$cap-notification-tabs-tab-border: 1px solid var(--cap-primary-color) !default;
$cap-notification-tabs-tab-padding: 10px 15px !default;
$cap-notification-tabs-background-color: white !default;
$cap-notification-tabs-border-radius: 4px !default;
$cap-notification-tabs-text-align: center !default;
$cap-notification-tabs-font-size: 15px !default;
$cap-notification-tabs-font-weight: bold !default;
$cap-notification-tabs-transition: all ease 200ms !default;
$cap-notification-tabs-white-space: nowrap !default;
$cap-notification-tabs-margin-bottom: 10px !default;

$cap-notification-tabs-tab-selected-background-color: var(--cap-primary-color) !default;
$cap-notification-tabs-tab-selected-color: $cap-white !default;

// Forms
$cap-forms-placeholder-text-color: #5f6062 !default;
$cap-form-element-label-font-weight: 600 !default;
$cap-form-element-label-color: var(--primary-text) !default;

$cap-form-element-line-height: 26px !default;
$cap-form-element-table-display: table !default;
$cap-form-element-table-width: 100% !default;

$cap-form-element-table-first-child-width: 30% !default;
$cap-form-element-table-first-child-display: table-cell !default;
$cap-form-element-table-first-child-vertical-align: top !default;

$cap-form-element-table-label: 12px !default;
$cap-form-element-table-input-height: 26px !default;

// Horizontal Navbar
$cap-horizontal-navbar: #333 !default;
$cap-horizontal-navbar-width: 100% !default;
$cap-horizontal-navbar-z-index: 9 !default;
$cap-horizontal-navbar-padding: 0 !default;
$cap-horizontal-navbar-background-color: $cap-white !default;
$cap-horizontal-navbar-margin: 0 auto !default;

$cap-horizontal-navbar-nav-item-font-size: 1em !default;
$cap-horizontal-navbar-nav-item-font-weight: 600 !default;
$cap-horizontal-navbar-nav-item-border-bottom: 2px solid transparent !default;
$cap-horizontal-navbar-nav-item-color: $cap-horizontal-navbar !default;
$cap-horizontal-navbar-nav-item-width: 120px !default;
$cap-horizontal-navbar-nav-item-position: relative !default;

$cap-horizontal-navbar-nav-item-dropdown-menu-text-align: left !default;
$cap-horizontal-navbar-nav-item-dropdown-menu-width: 100% !default;

$cap-horizontal-navbar-nav-item-dropdown-menu-text-font-size: 1em !default;
$cap-horizontal-navbar-nav-item-dropdown-menu-text-font-weight: bold !default;
$cap-horizontal-navbar-nav-item-dropdown-menu-text-padding: 10px 10px 10px 35px !default;
$cap-horizontal-navbar-nav-item-dropdown-menu-text-border-bottom: 1px solid var(--cap-primary-color) !default;

$cap-horizontal-navbar-nav-item-dropdown-menu-text-hover-background-color: var(--cap-primary-color) !default;
$cap-horizontal-navbar-nav-item-dropdown-menu-text-hover-color: $cap-white !default;

$cap-horizontal-navbar-nav-item-dropdown-menu-icon-float: right !default;
$cap-horizontal-navbar-nav-item-dropdown-menu-icon-margin-top: 3px !default;

$cap-horizontal-navbar-nav-item-overlay-background: var(--cap-primary-color) !default;
$cap-horizontal-navbar-nav-item-overlay-z-index: -1 !default;
$cap-horizontal-navbar-nav-item-overlay-opacity: 0.03 !default;

$cap-horizontal-navbar-nav-bar-gradient-width: 75px !default;
$cap-horizontal-navbar-nav-item-overlay-background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 50%, rgba(255, 255, 255, 1) 100%) !default;
$cap-horizontal-navbar-icon-color: var(--cap-primary-color-shadow);

// Image Viewer
$cap-image-viewer-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.5) !default;
$cap-image-viewer-navigator-desktop-spacing: 24px !default;
$cap-image-viewer-navigator-table-spacing: -25px !default;
$cap-image-navigator-mobile-spacing: 5px !default;
$cap-image-viewer-selector-color: #4c4c4c !default;

$cap-image-viewer-position: fixed !default;
$cap-image-viewer-width: 100% !default;
$cap-image-viewer-height: 100% !default;
$cap-image-viewer-left: 0 !default;
$cap-image-viewer-z-index: 1000 !default;
$cap-image-viewer-background: rgba(51, 51, 51, 0.6) !default;

$cap-image-viewer-header-padding: 18px 25px !default;
$cap-image-viewer-header-display: flex !default;
$cap-image-viewer-header-justify-content: space-between !default;
$cap-image-viewer-actions-button-background-color: #f6f6f6;

$cap-image-viewer-title-font-weight: 900 !default;
$cap-image-viewer-header-closer-margin-left: 22px !default;
$cap-image-viewer-header-preview-wrapper-width: 100% !default;
$cap-image-viewer-header-preview-wrapper-height: 100% !default;
$cap-image-viewer-header-preview-wrapper-box-shadow: $cap-image-viewer-shadow !default;
$cap-image-viewer-header-preview-wrapper-overflow: auto !default;
$cap-image-viewer-header-preview-wrapper-background: $cap-white !default;

$cap-image-viewer-body-wrapper-display: flex !default;
$cap-image-viewer-body-wrapper-background: $cap-black !default;
$cap-image-viewer-body-wrapper-padding: 0 27px !default;
$cap-image-viewer-body-wrapper-border-radius: 5px !default;
$cap-image-viewer-body-wrapper-box-shadow: $cap-image-viewer-shadow !default;
$cap-image-viewer-body-wrapper-color: #4c4c4c !default;
$cap-image-viewer-body-wrapper-align-items: center !default;

$cap-image-viewer-body-container-background: $cap-white !default;

// Range Filters
$cap-range-filters-label-font-weight: 600 !default;
$cap-range-filters-label-font-size: 14px !default;
$cap-range-filters-label-color: var(--primary-text) !default;

$cap-range-filters-overlay-panel-z-index: 100 !default;
$cap-range-filters-overlay-panel-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.1) !default;
$cap-range-filters-overlay-panel-background-color: #fff !default;
$cap-range-filters-overlay-panel-border-radius: 3px !default;
$cap-range-filters-overlay-panel-min-width: 200px !default;

$cap-range-filters-button-background-color: #fff !default;
$cap-range-filters-button-color: var(--primary-text) !default;
$cap-range-filters-button-border: 1px solid var(--cap-primary-color) !default;
$cap-range-filters-button-border-radius: 20px !default;
$cap-range-filters-button-height: $cap-input-height !default;
$cap-range-filters-button-font-size: 1em !default;
$cap-range-filters-button-cursor: pointer !default;
$cap-range-filters-button-padding: 5px 0 5px 22px !default;
$cap-range-filters-button-display: flex !default;
$cap-range-filters-button-flex-direction: column !default;
$cap-range-filters-button-justify-content: center !default;

$cap-range-filters-button-pre-value-icon-color: var(--cap-primary-color) !default;
$cap-range-filters-button-pre-value-icon-opacity: 0.5 !default;
$cap-range-filters-button-pre-value-icon-margin-right: 8px !default;

$cap-range-filters-button-icon-chevron-color: #5f6062 !default;
$cap-range-filters-button-icon-chevron-opacity: 1 !default;
$cap-range-filters-button-icon-chevron-font-size: 1em !default;
$cap-range-filters-button-icon-chevron-margin-right: 12px !default;
$cap-range-filters-button-icon-chevron-margin-top: 3px !default;
$cap-range-filters-button-padding-left: 27px !default;

// Row Action
$cap-row-action-position: relative !default;
$cap-row-action-text-align: right !default;

$cap-row-action-items-padding: 0 !default;
$cap-row-action-items-list-style: none !default;
$cap-row-action-items-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.15) !default;
$cap-row-action-items-background-color: $cap-white !default;
$cap-row-action-items-position: absolute !default;
$cap-row-action-items-text-align: left !default;
$cap-row-action-items-right: 0 !default;
$cap-row-action-items-margin: 10px 0 0 !default;
$cap-row-action-items-z-index: 1 !default;
$cap-row-action-items-border-radius: 3px !default;
$cap-row-action-items-visibility: hidden !default;
$cap-row-action-items-height: 0 !default;
$cap-row-action-items-overflow: hidden !default;
$cap-row-action-items-cursor: pointer !default;

$cap-row-action-items-open-visibility: visible !default;
$cap-row-action-items-open-height: auto !default;
$cap-row-action-items-open-overflow: visible !default;

$cap-row-action-item-display: flex !default;
$cap-row-action-item-cursor: pointer !default;
$cap-row-action-item-padding: 10px 12px !default;
$cap-row-action-item-border-bottom: 1px solid #ddd !default;
$cap-row-action-item-hover-background-color: #f9f9f9 !default;
$cap-row-action-item-last-child-border: none !default;

$cap-row-action-items-icon-font-size: 18px !default;
$cap-row-action-items-icon-margin: auto 14px auto 0 !default;
$cap-row-action-items-icon-color: var(--cap-primary-color) !default;
$cap-row-action-items-icon-margin: 0 !default;

$cap-row-action-items-info-margin: auto 0 !default;
$cap-row-action-items-info-text-margin: 0 !default;

$cap-row-action-items-info-title-font-size: 14px !default;
$cap-row-action-items-info-title-font-weight: 700 !default;
$cap-row-action-items-info-title-font-size: 12px !default;

// Scanner
$cap-scanner-header-height: 4rem !default;
$cap-scanner-angle-size: 3rem !default;
$cap-scanner-margin-x: 15% !default;

$cap-scanner-scan-container-width: 100% !default;
$cap-scanner-scan-container-height: 100% !default;
$cap-scanner-scan-container-position: absolute !default;
$cap-scanner-scan-container-top: 50% !default;
$cap-scanner-scan-container-left: 50% !default;
$cap-scanner-scan-container-transform: translate(-50%, -50%) !default;
$cap-scanner-scan-background-color: #fff !default;

$cap-scanner-scan-header-color: var(--cap-primary-color) !default;
$cap-scanner-scan-header-font-size: 2rem !default;
$cap-scanner-scan-header-height: $cap-scanner-header-height !default;
$cap-scanner-scan-header-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.15) !default;
$cap-scanner-scan-header-background-color: #fff !default;
$cap-scanner-scan-header-cursor: pointer !default;
$cap-scanner-scan-header-hover-font-size: 1.1em !default;
$cap-scanner-scan-header-hover-filter: grayscale(20%) !default;

$cap-scanner-scan-message-position: absolute !default;
$cap-scanner-scan-message-right: 0 !default;
$cap-scanner-scan-message-left: 0 !default;
$cap-scanner-scan-message-z-index: 1 !default;
$cap-scanner-scan-message-height: 3rem !default;
$cap-scanner-scan-message-max-width: 22rem !default;
$cap-scanner-scan-message-border-radius: 25px !default;
$cap-scanner-scan-message-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.15) !default;
$cap-scanner-scan-message-background-color: #fff !default;

$cap-scanner-scan-message-text-overflow: hidden !default;
$cap-scanner-scan-message-font-size: 1rem !default;

$cap-scanner-zxing-position: absolute !default;
$cap-scanner-zxing-top: $cap-scanner-header-height !default;
$cap-scanner-zxing-height: calc(100% - #{$cap-scanner-header-height}) !default;
$cap-scanner-zxing-width: 100vw !default;
$cap-scanner-zxing-z-index: 1 !default;

$cap-scanner-zxing-video-width: 100% !default;
$cap-scanner-zxing-video-height: 100% !default;

$cap-scanner-error-message-position: relative !default;
$cap-scanner-error-message-z-index: 1 !default;
$cap-scanner-error-message-top: 45vh !default;

$cap-scanner-scan-list-strikethrough-text-decoration: line-through !default;
$cap-scanner-scan-list-text-color: var(--error-color) !default;
$cap-scanner-scan-list-text-cursor: pointer !default;
$cap-scanner-scan-list-text-font-size: 1.2rem !default;
$cap-scanner-scan-list-text-hover-color: #f00 !default;
$cap-scanner-scan-list-text-hover-font-size: 1.3em !default;

// Input Mask
$cap-input-mask-width: 100% !default;
$cap-input-mask-text-width: 100% !default;

// Inputs
$cap-input-group-addon-background-color: $cap-white !default;
$cap-input-group-addon-color: var(--primary-text) !default;
$cap-input-group-addon-border-color: #ddd !default;
$cap-input-group-addon-max-height: $cap-input-height !default;
$cap-input-group-addon-height: auto !default;
$cap-input-group-addon-white-space: nowrap !default;

$cap-input-text-border-radius: 3px !default;
$cap-input-text-padding-left: 11px !default;
$cap-input-text-font-size: 1em !default;
$cap-input-text-font-family: var(--primary-font) !default;
$cap-input-text-color: var(--primary-text) !default;
$cap-input-text-border: 1px solid $input-border-color !default;
$cap-input-text-height: $cap-input-height !default;
$cap-input-text-display: flex !default;
$cap-input-text-flex-grow: 1 !default;

$cap-input-text-rounded-border-radius: 20px !default;
$cap-input-text-rounded-border-color: var(--cap-primary-color) !default;
$cap-input-text-rounded-height: $cap-input-height !default;
$cap-input-text-rounded-width: 100% !default;

// Links
$cap-link-color: var(--cap-primary-color) !default;
$cap-link-font-size: 1em !default;
$cap-link-font-weight: 600 !default;

// Card
$cap-card-border-radius: 8px !default;
$cap-card-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !default;
$cap-card-border: solid 1px $cap-light-gray !default;
$cap-card-background-color: $cap-white !default;
$cap-card-padding: 20px !default;

$cap-card-header-border-bottom: 1px solid $cap-light-gray !default;
$cap-card-header-padding-bottom: 10px !default;
$cap-card-header-font-size: 22px !default;
$cap-card-header-font-weight: 600 !default;
$cap-card-header-title-rs-text-align: right !default;
$cap-card-header-settings-margin-left: 5px !default;
$cap-card-header-settings-color: var(--cap-primary-color) !default;
$cap-card-header-settings-width: 2rem !default;
$cap-card-header-settings-height: 2rem !default;
$cap-card-header-settings-line-height: 2rem !default;
$cap-card-header-settings-text-align: center !default;

$cap-card-content-gutter-margin-top: 25px !default;

// Accordion
$cap-accordion-header-not-active-background-color: $cap-white !default;
$cap-accordion-header-not-active-color: $cap-dark-gray !default;
$cap-accordion-header-not-active-toggle-icon-color: $cap-dark-gray !default;
$cap-accordion-header-not-active-hover-bow-shadow: 0 5px 0 $cap-white, 0 1px 3px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.1) !default;
$cap-accordion-header-not-active-background-color: $cap-white !default;
$cap-accordion-header-not-active-color: $cap-black !default;
$cap-accordion-header-not-active-color-toggle-icon: $cap-black !default;

$cap-accordion-header-color: var(--primary-text) !default;
$cap-accordion-header-font-size: 16px !default;
$cap-accordion-header-font-weight: 700 !default;
$cap-accordion-header-height: 64px !default;
$cap-accordion-header-border-radius: 3px !default;
$cap-accordion-header-bow-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1) !default;
$cap-accordion-header-background-color: $cap-white !default;
$cap-accordion-header-padding: 0 1em !default;
$cap-accordion-header-line-height: 64px !default;
$cap-accordion-header-transition: background-color 0.2s !default;

$cap-accordion-content-border-br-radius: 3px !default;
$cap-accordion-content-border-bl-radius: 3px !default;
$cap-accordion-content-padding: 0 1em 1em 0.57em !default;
$cap-accordion-content-background-color: $cap-white !default;
$cap-accordion-content-color: var(--primary-text) !default;
$cap-accordion-content-wrapper-border-br-radius: 3px !default;
$cap-accordion-content-wrapper-border-bl-radius: 3px !default;
$cap-accordion-content-wrapper-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1) !default;

$cap-accordion-tab-header-font-size: 16px !default;
$cap-accordion-header-title-font-size: 13px !default;

$cap-accordion-tab-header-block-width: calc(100% - 2em) !default;
$cap-accordion-header-font-size: 15px !default;
$cap-accordion-header-status-font-size: 13px !default;
$cap-accordion-toggle-icon-float: right !default;
$cap-accordion-toggle-icon-transform: translateY(150%) !default;

// Accordion card.
$cap-accordion-card-header-width: 50% !default;
$cap-accordion-card-header-left-display: flex !default;
$cap-accordion-card-header-left-text-align: right !default;
$cap-accordion-card-title-font-weight: bold !default;
$cap-accordion-card-title-font-size: 16px !default;
$cap-accordion-card-title-min-height: 17px !default;
$cap-accordion-card-title-icon-color: var(--cap-primary-color) !default;
$cap-accordion-card-title-icon-margin-right: 10px !default;

$cap-accordion-card-actions-margin-left: 10px !default;
$cap-accordion-card-actions-transition: all 0.5s ease !default;
$cap-accordion-card-actions-hover: scale(1.1) !default;

$cap-accordion-card-toggler-font-size: 14px !default;
$cap-accordion-card-toggler-color: var(--cap-primary-color) !default;
$cap-accordion-card-toggler-font-size: 10px !default;

$cap-accordion-card-header-display: flex !default;
$cap-accordion-card-header-justify-content: space-between !default;
$cap-accordion-card-header-padding: 15px 20px !default;
$cap-accordion-card-header-border-radius: 3px !default;
$cap-accordion-card-header-background-color: $cap-white !default;

$cap-accordion-card-content-border-bl-radius: 3px !default;
$cap-accordion-card-content-border-br-radius: 3px !default;
$cap-accordion-card-content-transition: all 0.25s ease !default;
$cap-accordion-card-content-background-color: $cap-white !default;
$cap-accordion-card-content-open-padding: 0 15px 20px !default;

$cap-accordion-card-delimiter-margin-bottom: 15px !default;
$cap-accordion-card-delimiter-background: #ddd !default;

// Autocomplete
$cap-dropdown-hover-color: #eaeaea !default;
$cap-autocomplete-button-background-color: $cap-white !default;
$cap-autocomplete-button-color: $cap-black !default;
$cap-autocomplete-button-border: 1px solid $input-border-color !default;

$cap-autocomplete-input-height: 38px !default;
$cap-autocomplete-input-border: 1px solid $input-border-color !default;
$cap-autocomplete-input-padding: 0.4em !default;
$cap-autocomplete-input-error-border-color: $input-border-color !default;

$cap-autocomplete-button-width: 2em !default;
$cap-autocomplete-highlighted-option-background-color: $cap-dropdown-hover-color !default;
$cap-autocomplete-highlighted-option-color: var(--primary-text) !default;

$cap-autocomplete-button-hover-background-color: $cap-white !default;
$cap-autocomplete-button-hover-color: $cap-black !default;
$cap-autocomplete-button-border-color: $input-border-color !default;

// Button
$cap-button-disabled-background: #ddd !default;
$cap-button-disabled-text-color: #fff !default;
$cap-button-label-color: #fff !default;
$cap-button-success-text-color: #fff !default;
$cap-button-success-background: $cap-success !default;
$cap-button-warning-text-color: #fff !default;
$cap-button-warning-background: $cap-warning !default;
$cap-button-error-text-color: #fff !default;
$cap-button-error-background: $cap-danger !default;
$cap-button-secondary-text-color: var(--cap-primary-color) !default;
$cap-button-secondary-background: #fff !default;
$cap-button-tertiary-text-color: var(--cap-primary-color) !default;
$cap-button-tertiary-background: #f0f0f0 !default;

// Calendar
$cap-calendar-input-border-radius: 20px !default;
$cap-calendar-input-height: $cap-input-height !default;
$cap-calendar-datepicker-border-bottom: 1px solid $input-border-color !default;
$cap-calendar-datepicker-title-font-family: var(--primary-font) !default;
$cap-calendar-datepicker-title-font-size: 17px !default;
$cap-calendar-datepicker-title-font-weight: 600 !default;
$cap-calendar-datepicker-title-text-align: center !default;
$cap-calendar-datepicker-title-color: var(--primary-text) !default;

$cap-calendar-datepicker-prev-next-color: #5f6062 !default;
$cap-calendar-datepicker-trigger-button-border: 1px solid $input-border-color !default;
$cap-calendar-datepicker-trigger-background: var(--cap-primary-color) !default;
$cap-calendar-datepicker-trigger-color: $cap-white !default;
$cap-calendar-datepicker-trigger-width: $cap-input-height !default;
$cap-calendar-datepicker-trigger-height: $cap-input-height !default;
$cap-calendar-datepicker-trigger-icon-left: 2em !default;

$cap-calendar-input-text-width: calc(100% - 2.357em) !default; /* stylelint-disable-line number-max-precision */
$cap-calendar-input-text-height: $cap-input-height !default;

$cap-calendar-input-border: 1px solid $input-border-color !default;
$cap-calendar-input-height: $cap-input-height !default;
$cap-calendar-input-width: calc(100% - #{$cap-input-height}) !default;
$cap-calendar-input-error-border: 1px solid $input-border-color !default;
$cap-calendar-input-error-border-right: 0 none !default;

$cap-calendar-datepicker-widget-content-text-align: center !default;
$cap-calendar-datepicker-widget-content-state-default-width: 34.5714px !default; /* stylelint-disable-line number-max-precision */
$cap-calendar-datepicker-widget-content-state-default-border-radius: 50% !default;
$cap-calendar-datepicker-widget-content-state-active-background-color: var(--cap-primary-color) !default;
$cap-calendar-datepicker-widget-content-state-active-color: $cap-white !default;
$cap-calendar-datepicker-widget-content-state-not-active-background-color: var(--cap-primary-color) !default;
$cap-calendar-datepicker-widget-content-state-not-active-color: $cap-white !default;

$cap-calendar-input-hover-border-color: var(--cap-primary-color) !default;
$cap-calendar-input-hover-border-tl-radius: $cap-calendar-input-border-radius !default;
$cap-calendar-input-hover-border-bl-radius: $cap-calendar-input-border-radius !default;
$cap-calendar-input-hover-border-height: $cap-calendar-input-height !default;

$cap-calendar-input-hover-trigger-border-color: var(--cap-primary-color) !default;
$cap-calendar-input-hover-trigger-border-tr-radius: $cap-calendar-input-border-radius !default;
$cap-calendar-input-hover-trigger-border-br-radius: $cap-calendar-input-border-radius !default;
$cap-calendar-input-hover-trigger-height: $cap-calendar-input-height !default;
$cap-calendar-input-hover-trigger-font-size: 0.8em !default;
$cap-calendar-input-hover-trigger-padding-top: 1px !default;
$cap-calendar-input-hover-trigger-button-icon-left-font-size: 1.5em !default;

$cap-calendar-double-date-width: 105% !default;

$cap-calendar-w-btn-hover-border: 1px solid var(--cap-primary-color) !default;
$cap-calendar-w-btn-hover-border-radius: 20px !default;
$cap-calendar-w-btn-hover-background-color: $cap-white !default;
$cap-calendar-w-btn-hover-height: $cap-calendar-input-height !default;

$cap-calendar-w-btn-hover-datepicker-background-color: $cap-white !default;
$cap-calendar-w-btn-hover-datepicker-color: var(--cap-primary-color) !default;
$cap-calendar-w-btn-hover-datepicker-left: 4px !default;
$cap-calendar-w-btn-hover-datepicker-border-radius: 20px 0 0 20px !default;
$cap-calendar-w-btn-hover-datepicker-opacity: 0.5 !default;
$cap-calendar-w-btn-hover-datepicker-width: 21px !default;

$cap-calendar-w-btn-hover-input-left: 21px !default;
$cap-calendar-w-btn-hover-input-width: calc(100% - 21px) !default;
$cap-calendar-w-btn-hover-input-border-radius: 0 20px 20px 0 !default;

// Time picker
$cap-timepicker-input-hover-border-color: #a6a6a6 !default;

// Checkbox filter
$cap-checkbox-filter-height: $cap-input-height !default;
$cap-checkbox-filter-border: 1px solid var(--cap-primary-color) !default;
$cap-checkbox-filter-border-radius: 25px !default;
$cap-checkbox-filter-padding: 5px 20px 0 !default;
$cap-checkbox-filter-justify-content: center !default;
$cap-checkbox-filter-background-color: $cap-white !default;
$cap-checkbox-filter-icon-left: 8px !default;

$cap-checkbox-filter-label-padding: 0 10px !default;
$cap-checkbox-filter-label-color: #5f6062 !default;
$cap-checkbox-filter-label-width: 80% !default;

// Checkbox
$cap-checkbox-border-color: $input-border-color !default;
$cap-checkbox-border-color-hover: $input-border-color !default;
$cap-checkbox-state-active-background: $cap-white !default;
$cap-checkbox-state-active-border-color: var(--cap-primary-color) !default;
$cap-checkbox-state-active-icon-margin-top: 1px !default;
$cap-checkbox-state-active-icon-font-weight: 900 !default;
$cap-checkbox-state-active-icon-font-size: 16px !default;

// Chips
$cap-chips-width-hover: 100% !default;
$cap-chips-border-color-hover: $input-border-color !default;
$cap-chips-border-padding-hover: .4rem !default;
$cap-chips-token-padding: .25rem .75rem !default;
$cap-chips-token-background-color: $cap-platinum !default;
$cap-chips-token-color: #5f6062 !default;
$cap-chips-token-border-color: $input-border-color !default;

// Collapsable-card
$cap-collapsable-card-nav-divider-color: #e9ecef !default;
$cap-collapsable-card-header-border-bottom: #e9ecef !default;
$cap-collapsable-card-padding-top: 10px !default;
$cap-collapsable-card-padding: $cap-collapsable-card-padding-top 20px 20px 20px !default;
$cap-collapsable-card-title-font-size: 20px !default;
$cap-collapsable-card-title-font-weight: bold !default;

$cap-collapsable-card-header-justify-content: space-between !default;

$cap-collapsable-card-link-color: var(--cap-primary-color) !default;
$cap-collapsable-card-link-font-weight: 600 !default;
$cap-collapsable-card-content-padding-top: $cap-collapsable-card-padding-top !default;

// Color-picker
$cap-colorpicker-input-display: inline-flex !default;
$cap-colorpicker-input-border-left: none !default;
$cap-colorpicker-with-input-border-right: none !default;
$cap-colorpicker-preview-width: 38px !default;
$cap-colorpicker-preview-height: 38px !default;

// Select Button
$cap-select-button-border-color: var(--cap-primary-color) !default;
$cap-select-button-background-color: $cap-white !default;
$cap-select-button-border-color: var(--cap-primary-color) !default;
$cap-select-button-color: var(--cap-primary-color) !default;
$cap-select-button-font-weight: bold !default;
$cap-select-button-padding: 5px 25px !default;
$cap-select-button-font-size: 1em !default;
$cap-select-button-hover-text-decoration: underline !important !default;
$cap-select-button-active-background-color: var(--cap-primary-color) !default;
$cap-select-button-active-color: $cap-white !default;

// Slide up panel
$cap-slide-up-panel-transition: height 0.3s ease-out 0s !default;
$cap-slide-up-panel-border-radius: 10px !default;
$cap-slide-up-panel-box-shadow: 0 -6px 13px 0 rgba(0, 0, 0, 0.15) !default;
$cap-slide-up-panel-position: fixed !default;
$cap-slide-up-panel-right: 0 !default;
$cap-slide-up-panel-bottom: 0 !default;
$cap-slide-up-panel-left: 0 !default;
$cap-slide-up-panel-z-index: 50 !default;
$cap-slide-up-panel-background-color: $cap-white !default;
$cap-slide-up-panel-overflow: scroll !default;

$cap-slide-up-panel-drag-handle-bar-cursor: grab !default;
$cap-slide-up-panel-drag-handle-bar-active-cursor: grabbing !default;

$cap-slide-up-panel-handle-bar-height: 0.33rem !default;
$cap-slide-up-panel-handle-bar-width: 2.5rem !default;
$cap-slide-up-panel-handle-bar-border-radius: 2.5px !default;
$cap-slide-up-panel-handle-bar-background-color: $cap-white !default;

$toast-background-color: #fff !default;
$toast-text-color: #333 !default;
$toast-background-color-success: $toast-background-color !default;
$toast-background-color-warning: $toast-background-color !default;
$toast-background-color-info: $toast-background-color !default;
$toast-background-color-error: $toast-background-color !default;
$toast-summary-font-size: 18px !default;
$toast-detail-font-size: 14px !default;
$toast-detail-text-color: #4c4c4c !default;
$toast-close-icon-color: rgba(51, 51, 51, 0.15) !default;
$toast-border-radius: 5px !default;

//Week switcher
$cap-week-switcher-divider-border: 0.5px solid #ddd !default;
$cap-week-switcher-border: 2px solid var(--cap-primary-color) !default;
$cap-week-switcher-border-radius: 3px !default;
$cap-week-switcher-display: inline-block !default;
$cap-week-switcher-content-display: inline-block !default;
$cap-week-switcher-content-color: var(--cap-primary-color) !default;
$cap-week-switcher-content-font-size: 16px !default;
$cap-week-switcher-content-font-weight: bold !default;
$cap-week-switcher-button-divider-padding: 10px 0 !default;
$cap-week-switcher-button-divider-width: 120px !default;
$cap-week-switcher-button-divider-text-align: center !default;
$cap-week-switcher-button-divider-border-right: $cap-week-switcher-divider-border !default;
$cap-week-switcher-button-divider-border-left: $cap-week-switcher-divider-border !default;
$cap-week-switcher-button-not-disabled-padding: 0 15px !default;

//Timeline
$cap-timeline-cards-divider-padding-left: 20px !default;
$cap-timeline-cards-divider-position: relative !default;
$cap-timeline-cards-divider-before-position: absolute !default;
$cap-timeline-cards-divider-before-top: 0 !default;
$cap-timeline-cards-divider-before-left: 0 !default;
$cap-timeline-cards-divider-before-width: 1px !default;
$cap-timeline-cards-divider-before-height: 100% !default;
$cap-timeline-cards-divider-before-border-left: 2px dashed var(--cap-primary-color) !default;
$cap-timeline-card-margin-bottom: 10px !default;
$cap-timeline-card-last-of-type-margin-bottom: 0 !default;
$cap-timeline-content-padding: 10px 15px !default;
$cap-timeline-content-border-radius: 3px !default;
$cap-timeline-content-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !default;
$cap-timeline-content-background-color: #fff !default;
$cap-timeline-created-color: rgba(51, 51, 51, 0.5) !default;
$cap-timeline-created-font-size: 13px !default;
$cap-timeline-title-font-weight: bold !default;
$cap-timeline-title-color: var(--primary-text) !default;
$cap-timeline-title-font-size: 15px !default;
$cap-timeline-comment-font-size: 15px !default;
$cap-timeline-comment-color: var(--text-color-2) !default;
$cap-timeline-comment-link-color: var(--cap-primary-color) !default;

//Textarea
$cap-textarea-width: 100% !default;
$cap-textarea-resize: vertical !default;
$cap-textarea-border-color: $input-border-color !default;
$cap-textarea-border-radius: 3px !default;
$cap-textarea-border-height: 100px !default;
$cap-textarea-padding: 10px !default;
$cap-textarea-font-size: 14px !default;
$cap-textarea-font-color: var(--primary-text) !default;

// Table
$cap-table-border-color: #ddd !default;
$cap-table-text-color: #5f6062 !default;
$cap-table-sort-icon-color: #ddd;
$cap-table-actions-col-width: 80px !default;
$cap-table-expand-col-width: 30px !default;
$cap-table-header-table-data-background-color: $cap-white !default;
$cap-table-header-table-data-color: #5f6062 !default;
$cap-table-header-table-border: none !default;
$cap-table-header-table-font-size: 1em !default;
$cap-table-header-table-padding: 0.571em 0 0.857em 0 !default; /* stylelint-disable-line number-max-precision */

$cap-table-header-font-weight: 600 !default;
$cap-table-header-color: #333 !default;
$cap-table-header-padding: 0.571em 0 0.857em 0 !default; /* stylelint-disable-line number-max-precision */
$cap-table-header-hover-background-color: $cap-white !default;

$cap-table-caption-background-color: $cap-white !default;
$cap-table-caption-border: none !default;

$cap-table-inputgroup-addon-border-right: none !default;
$cap-table-inputgroup-addon-color: var(--cap-primary-color) !default;

$cap-table-actions-wrapper-max-width: 100px !default;
$cap-table-actions-icon-color: var(--cap-primary-color) !default;
$cap-table-actions-margin-right: 10px !default;
$cap-table-actions-cursor: pointer !default;
$cap-table-actions-right-padding-left: 10px !default;

$cap-table-sortable-column-icon-color: var(--cap-primary-color) !important !default;

$cap-table-paginator-margin-top: 10px !default;
$cap-table-paginator-background-color: transparent !default;
$cap-table-paginator-border: none !default;
$cap-table-paginator-text-align: right !default;
$cap-table-paginator-bottom-text-align: right !default;
$cap-table-paginator-page-color: var(--cap-primary-color) !default;
$cap-table-paginator-font-weight: normal !default;
$cap-table-paginator-element-background: $cap-white !default;
$cap-table-paginator-element-color: var(--cap-primary-color) !default;

$cap-table-paginator-prev-next-width: 25px !default;
$cap-table-paginator-prev-next-line-height: 2rem !default;
$cap-table-paginator-prev-next-font-size: 1rem !default;

$cap-table-paginator-prev-margin-right: -1px !default;
$cap-table-paginator-prev-border-tl-radius: 3px !default;
$cap-table-paginator-prev-border-left-radius: 3px !default;

$cap-table-paginator-next-margin-left: -2px !default;
$cap-table-paginator-next-border-tr-radius: 3px !default;
$cap-table-paginator-next-border-br-radius: 3px !default;

$cap-table-filter-toggle-font-size: 0.8em !default;
$cap-table-filter-toggle-cursor: pointer !default;

$cap-table-filter-icon-position: absolute !default;
$cap-table-filter-icon-top: 0 !default;
$cap-table-filter-icon-left: 25px !default;
$cap-table-filter-icon-height: 100% !default;
$cap-table-filter-icon-display: flex !default;
$cap-table-filter-icon-flex-direction: column !default;
$cap-table-filter-icon-flex-justify-content: center !default;
$cap-table-filter-icon-color: var(--cap-primary-color) !default;
$cap-table-filter-icon-opacity: 0.5 !default;
$cap-table-filter-font-size: 0.9em !default;
$cap-table-filter-line-height: 0.9em !default;

$cap-table-reset-filter-position: absolute !default;
$cap-table-reset-filter-top: 0 !default;
$cap-table-reset-filter-right: 23px !default;
$cap-table-reset-filter-height: 100% !default;
$cap-table-reset-filter-display: flex !default;
$cap-table-reset-filter-flex-direction: column !default;
$cap-table-reset-filter-justify-content: center !default;
$cap-table-reset-filter-cursor: pointer !default;
$cap-table-reset-filter-background: $cap-white !default;
$cap-table-reset-filter-color: var(--error-color) !default;
$cap-table-reset-filter-opacity: 1 !default;
$cap-table-reset-filter-font-size: 0.9em !default;
$cap-table-reset-filter-line-height: 0.9em !default;

$cap-table-multiselect-label-padding-left: 27px !default;
$cap-table-multiselect-label-input-text-padding-left: 36px !important !default;

$cap-table-dropdown-display: inline-block !default;

$cap-table-filters-display: block !default;
$cap-table-filters-icon-color: var(--cap-primary-color) !default;
$cap-table-filters-font-size: 0.8em !default;
$cap-table-filters-cursor: pointer !default;

$cap-table-filters-label-display: inline !default;
$cap-table-filters-label-flex-grow: 1 !default;
$cap-table-filters-label-margin-left: 5px !default;
$cap-table-filters-label-color: var(--cap-primary-color) !default;

$cap-table-filters-dialog-width: 50% !default;
$cap-table-filters-settings-padding: 20px 40px !default;

$cap-table-filters-header-display: flex !default;
$cap-table-filters-header-justify-content: center !default;
$cap-table-filters-header-position: relative !default;
$cap-table-filters-header-margin-bottom: 15px !default;

$cap-table-filters-header-close-filters-position: absolute !default;
$cap-table-filters-header-close-filters-right: 0 !default;

$cap-table-filters-items-min-height: 300px !default;
$cap-table-filters-items-max-height: 300px !default;
$cap-table-filters-items-overflow-y: auto !default;

$cap-table-filters-item-width: 100% !default;
$cap-table-filters-item-border: 1px solid $cap-input-border-color !default;
$cap-table-filters-item-padding: 10px !default;
$cap-table-filters-item-border-radius: 3px !default;
$cap-table-filters-item-selected-border-color: var(--success-color) !default;
$cap-table-filters-item-checkbox-border-color: $cap-input-border-color !default;

$cap-table-item-cursor: default !default;
$cap-table-item-form-element-height: 23px !default;
$cap-table-item-label-font-weight: normal !default;
$cap-table-item-label-cursor: pointer !default;

$cap-table-columns-list-width: 10px !default;
$cap-table-columns-list-text-align: right !default;
$cap-table-columns-list-icon-cursor: pointer !default;
$cap-table-columns-list-color: var(--cap-primary-color) !default;

$cap-table-frozen-column-shadow: inset -7px 0 5px -5px $input-border-color;

/* Info table */
$cap-info-table-delimiter: #ddd;
$cap-info-table-header-color: rgba(58, 58, 58, 0.5);
$cap-info-table-header-bg: transparent;
$cap-info-table-header-height: 38px;
$cap-info-table-cell-height: 50px;
$cap-info-table-cell-padding: 10px;
$cap-info-table-cell-border: none;
$cap-info-table-cell-bg: #fff;
$cap-info-table-row-bg: #fff;
$cap-info-table-border-radius: 3px;
$cap-info-table-border-spacing: 0 10px;
$cap-info-table-card-breakpoint: 767.98px;
$cap-info-table-card-gutter: 10px;
$cap-info-table-card-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.2);

/* File preview list */
$cap-file-preview-item-background-color: #f9f9f9;

/* Tab view */
$cap-tab-view-bottom-border-color: #ccc;
$cap-tab-view-text-color: #4f5152;
$cap-tab-view-highlight-tab-background: #f9f9f9;
$cap-tab-view-highlight-tab-border-color: #ccc;

/* User menu */
$cap-user-menu-proportions: 38px;
$cap-user-menu-subtext-color: #4f5152;
$cap-user-menu-hover-color: #f9f9f9;
$cap-tab-view-highlight-tab-border-color: #ccc;

/* Sidebar menu */
$cap-sidebar-menu-initial-width: 250px;
$cap-sidebar-menu-collapsed-width: 90px;
$cap-sidebar-menu-button-proportions: 33px;
$cap-sidebar-menu-delimiter-color: #e4e4e4;

// Amount Input
$cap-amount-input-border: 1px solid var(--cap-primary-color) !default;
$cap-amount-input-border-radius: 3px !default;
$cap-amount-input-text-color: var(--cap-primary-color) !default;

// Form Card
$cap-form-card-border-radius: 10px !default;
$cap-form-card-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !default;
$cap-form-card-border: solid 1px $cap-light-gray !default;
$cap-form-card-background-color: $cap-white !default;
$cap-form-card-padding: 1.5rem !default;

$cap-form-card-header-border-bottom: 1px solid #dddddd !default;
$cap-form-card-header-padding-bottom: 10px !default;
$cap-form-card-header-font-size: 1.25rem !default;
$cap-form-card-header-font-weight: 700 !default;

$cap-form-card-content-padding-top: 26px !default;

$cap-media-breakpoints: (
        xs: '(max-width: 599.98px)',
        sm: '(min-width: 600px) and (max-width: 959.98px)',
        md: '(min-width: 960px) and (max-width: 1279.98px)',
        lg: '(min-width: 1280px) and (max-width: 1919.98px)',
        xl: '(min-width: 1920px)',
        handset: '(max-width: 599.98px) and (orientation: portrait), (max-width: 959.98px) and (orientation: landscape)',
        tablet: '(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)',
        web: '(min-width: 840px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape)',
        handset-portrait: '(max-width: 599.98px) and (orientation: portrait)',
        tablet-portrait: '(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait)',
        web-portrait: '(min-width: 840px) and (orientation: portrait)',
        handset-landscape: '(max-width: 959.98px) and (orientation: landscape)',
        tablet-landscape: '(min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)',
        web-landscape: '(min-width: 1280px) and (orientation: landscape)',
) !default;

/* break points */
$breakpoint-handheld: '(max-width: 599.98px) and (orientation: portrait), (max-width: 959.98px) and (orientation: landscape)';
$breakpoint-tablet: '(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)';

// Filters
$cap-dynamic-filter-border-radius: 25px !default;
$cap-dynamic-filters-hide-filters-on-mobile: false !default;
$cap-dynamic-filters-filter-flex: unset !default;
$cap-dynamic-filters-flex-wrap: wrap !default;
$cap-dynamic-filters-filter-height: 33px !default;
$cap-dynamic-filters-filter-width: 175px !default;


/* stylelint-disable */
@charset 'UTF-8';

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
}

body .p-component {
  font-family: 'Source Sans Pro', 'Helvetica Neue', sans-serif;
  font-size: toRem(13px);
}

body .p-corner-all {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

body .p-corner-top {
  border-radius: 3px 0 0 0;
}

body .p-corner-bottom {
  border-radius: 0 0 3px 0;
}

body .p-corner-left {
  border-radius: 0 0 0 3px;
}

body .p-corner-right {
  border-radius: 0 3px 0 0;
}

body .p-corner-none {
  border-radius: 0;
}

body a {
  color: var(--cap-primary-color);
  text-decoration: none;
}

body a:hover {
  color: var(--cap-primary-color);
}

body a:active {
  color: var(--cap-primary-color);
}

body .p-reset {
  line-height: normal;
}

body .p-disabled, body .p-component:disabled {
  opacity: 0.5;
  background: var(--third-color);
  filter: alpha(opacity=50);
  box-shadow: 0 2px 4px 0 var(--third-color);
}

body .pi {
  font-size: 1.25em;
}

body {
  /* Validations */
}

body .p-inputtext {
  font-size: toRem(14px);
  color: var(--primary-text);
  background: $cap-white;
  padding: 0.429em;
  border: 1px solid #a6a6a6;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

body .p-radiobutton {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: 20px;
  height: 20px;
}

body .p-radiobutton .p-radiobutton-box {
  border: 1px solid #a6a6a6;
  background-color: $cap-white;
  width: 20px;
  height: 20px;
  text-align: center;
  position: relative;
  -moz-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

body .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: var(--text-toast);
}

body .p-radiobutton .p-radiobutton-box.p-focus {
  border-color: var(--cap-primary-color);
  background-color: $cap-white;
  color: var(--cap-primary-color);
  -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
  -moz-box-shadow: 0 0 0 0.2em #8dcdff;
  box-shadow: 0 0 0 0.2em #8dcdff;
}

body .p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--cap-primary-color);
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-radiobutton .p-radiobutton-box.p-highlight .p-radiobutton-icon {
  background-color: $cap-white;
}

body .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--cap-primary-color);
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-radiobutton .p-radiobutton-box.p-highlight.p-focus {
  border-color: var(--cap-primary-color);
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background: transparent;
  width: 10px;
  height: 10px;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -5px;
  margin-top: -5px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

body .p-radiobutton .p-radiobutton-box .p-radiobutton-icon:before {
  display: none;
}

body .p-radiobutton-label {
  margin: 0 0 0 0.5em;
}

body .p-inputswitch {
  width: 3em;
  height: 1.75em;
}

body .p-inputswitch .p-inputswitch-slider {
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  border-radius: 30px;
  background: #cccccc;
}

body .p-inputswitch .p-inputswitch-slider:before {
  background-color: #fff;
  height: 1.25em;
  width: 1.25em;
  left: 0.25em;
  bottom: 0.25em;
  border-radius: 50%;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  -webkit-transform: translateX(1.25em);
  -ms-transform: translateX(1.25em);
  transform: translateX(1.25em);
}

body .p-inputswitch.p-inputswitch-focus .p-inputswitch-slider {
  background: #b7b7b7;
}

body .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background-color: #b7b7b7;
}

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: var(--cap-primary-color);
}

body .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background-color: var(--cap-primary-color);
}

body .p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider {
  background: var(--cap-primary-color);
}

body .p-autocomplete .p-autocomplete-input {
  padding: 0.429em;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container {
  padding: 0.2145em 0.429em;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: var(--text-toast);
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  border-color: var(--cap-primary-color);
  outline: 0 none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token {
  margin: 0;
  padding: 0.2145em 0;
  color: var(--primary-text);
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: toRem(14px);
  padding: 0;
  margin: 0;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token {
  font-size: toRem(14px);
  padding: 0.2145em 0.429em;
  margin: 0 0.286em 0 0;
  background: var(--cap-primary-color);
  color: $cap-white;
}

body .p-autocomplete-panel {
  padding: 0;
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-autocomplete-panel .p-autocomplete-items {
  padding: 0;
}

body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: var(--primary-text);
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item.p-highlight {
  color: $cap-white;
  background-color: var(--cap-primary-color);
}

body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-group {
  padding: 0.429em 0.857em;
  background-color: #d8dae2;
  color: var(--primary-text);
}

body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
  width: 2.357em;
}

body .p-fluid .p-autocomplete.p-autocomplete-multiple.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-right: 0 none;
  width: calc(100% - 2.357em);
}

body .p-fluid .p-autocomplete.p-autocomplete-dd .p-inputtext {
  border-right: 0 none;
  width: calc(100% - 2.357em);
}

body .p-chips > ul.p-inputtext {
  padding: 0.2145em 0.429em;
  display: inline-block;
}

body .p-chips > ul.p-inputtext:not(.p-disabled).p-focus {
  outline: 0 none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .p-chips > ul.p-inputtext .p-chips-input-token {
  padding: 0.2145em 0;
}

body .p-chips > ul.p-inputtext .p-chips-input-token input {
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: toRem(14px);
  padding: 0;
  margin: 0;
  color: var(--primary-text);
}

body .p-chips > ul.p-inputtext .p-chips-input-token input:hover {
  border: 0 none;
}

body .p-chips > ul.p-inputtext .p-chips-input-token input:focus {
  border: 0 none;
}

body .p-chips > ul.p-inputtext .p-chips-token {
  font-size: toRem(14px);
  padding: 0.2145em 0.429em;
  margin: 0 0.286em 0 0;
  background: var(--cap-primary-color);
  color: $cap-white;
}

body .p-multiselect {
  background: $cap-white;
  border: 1px solid #a6a6a6;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
}

body .p-multiselect:not(.p-disabled):hover {
  border-color: var(--text-toast);
}

body .p-multiselect:not(.p-disabled).p-focus {
  border-color: var(--cap-primary-color);
  outline: 0 none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .p-multiselect .p-multiselect-label {
  padding: 0.55em;
  padding-right: 2em;
  color: var(--primary-text);
}

body .p-multiselect .p-multiselect-trigger {
  background-color: $cap-white;
  width: 2em;
  line-height: 2em;
  text-align: center;
  padding: 0;
  color: #848484;
}

body .p-multiselect-panel {
  padding: 0;
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-multiselect-panel .p-multiselect-header {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-bottom: 1px solid #eaeaea;
  color: var(--primary-text);
  background-color: $cap-white;
  margin: 0;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
  float: none;
  width: 70%;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding: 0.429em;
  padding-right: 2em;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  color: var(--cap-primary-color);
  top: 50%;
  margin-top: -0.5em;
  right: 0.5em;
  left: auto;
}

body .p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5em;
  float: none;
  vertical-align: middle;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  color: #848484;
  top: 50%;
  margin-top: -0.5em;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-close:hover {
  color: var(--primary-text);
}

body .p-multiselect-panel .p-multiselect-items {
  padding: 0;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: var(--primary-text);
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: var(--primary-text);
  background-color: transparent;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: var(--primary-text);
  background-color: #eaeaea;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-disabled .p-checkbox-box {
  cursor: auto;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-disabled .p-checkbox-box:hover {
  border: 1px solid #a6a6a6;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-disabled .p-checkbox-box.p-highlight:hover {
  border-color: var(--cap-primary-color);
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.5em 0 0;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item label {
  display: inline-block;
  vertical-align: middle;
}

body .p-listbox {
  padding: 0;
  min-width: 12em;
  background: $cap-white;
  border: 1px solid #a6a6a6;
}

body .p-listbox .p-checkbox {
  margin: 0 0.5em 0 0;
}

body .p-listbox .p-listbox-header {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-bottom: 1px solid #eaeaea;
  color: var(--primary-text);
  background-color: $cap-white;
  margin: 0;
}

body .p-listbox .p-listbox-header .p-listbox-filter-container {
  width: calc(100% - (0.857em + 0.857em + 0.5em));
}

body .p-listbox .p-listbox-header .p-listbox-filter-container input {
  padding: 0.429em;
  padding-right: 2em;
}

body .p-listbox .p-listbox-header .p-listbox-filter-container .p-listbox-filter-icon {
  top: 50%;
  left: auto;
  margin-top: -0.5em;
  right: 0.5em;
  color: var(--cap-primary-color);
}

body .p-listbox .p-listbox-list {
  background-color: $cap-white;
}

body .p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: var(--primary-text);
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: $cap-white;
  background-color: var(--cap-primary-color);
}

body .p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.5em 0 0;
}

body .p-listbox .p-listbox-list .p-listbox-item label {
  display: inline-block;
  vertical-align: middle;
}

body .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: var(--primary-text);
  background-color: #eaeaea;
}

body .p-listbox.p-disabled .p-checkbox-box:not(.p-disabled):not(.p-highlight):hover {
  border: 1px solid #a6a6a6;
}

body .p-listbox .p-listbox-footer {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-top: 1px solid #eaeaea;
  color: var(--primary-text);
  background-color: $cap-white;
}

body .p-editor-container .p-editor-toolbar {
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
}

body .p-editor-container .p-editor-content {
  border: 1px solid #c8c8c8;
}

body .p-editor-container .p-editor-content .ql-editor {
  background-color: $cap-white;
  color: var(--primary-text);
}

body .p-editor-container .ql-picker.ql-expanded .ql-picker-label {
  color: var(--primary-text);
}

body .p-editor-container .ql-stroke {
  stroke: var(--primary-text);
}

body .p-editor-container .ql-picker-label {
  color: var(--primary-text);
}

body .p-editor-container .ql-snow.ql-toolbar button:hover,
body .p-editor-container .ql-snow .ql-toolbar button:hover,
body .p-editor-container .ql-snow.ql-toolbar button.ql-active,
body .p-editor-container .ql-snow .ql-toolbar button.ql-active,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--cap-primary-color);
}

body .p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
body .p-editor-container .ql-snow .ql-toolbar button:hover .ql-stroke,
body .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
body .p-editor-container .ql-snow .ql-toolbar button.ql-active .ql-stroke,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
body .p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
body .p-editor-container .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
body .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
body .p-editor-container .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: var(--cap-primary-color);
}

body .p-rating a {
  text-align: center;
  display: inline-block;
  color: var(--primary-text);
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

body .p-rating a.p-rating-cancel {
  color: #e4018d;
}

body .p-rating a .p-rating-icon {
  font-size: toRem(20px);
}

body .p-rating:not(.p-disabled):not(.p-rating-readonly) a:hover {
  color: var(--cap-primary-color);
}

body .p-rating:not(.p-disabled):not(.p-rating-readonly) a.p-rating-cancel:hover {
  color: #b5019f;
}

body .p-spinner .p-spinner-input {
  padding-right: 2.429em;
}

body .p-spinner .p-spinner-button {
  width: 2em;
}

body .p-spinner .p-spinner-button.p-spinner-up {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}

body .p-spinner .p-spinner-button.p-spinner-down {
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

body .p-spinner .p-spinner-button .p-spinner-button-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
}

body .p-fluid .p-spinner .p-spinner-input {
  padding-right: 2.429em;
}

body .p-fluid .p-spinner .p-spinner-button {
  width: 2em;
}

body .p-fluid .p-spinner .p-spinner-button .p-spinner-button-icon {
  left: 50%;
}

body .p-slider {
  background-color: #c8c8c8;
  border: 0 none;
}

body .p-slider.p-slider-horizontal {
  height: 0.286em;
}

body .p-slider.p-slider-horizontal .p-slider-handle {
  top: 50%;
  margin-top: -0.5715em;
}

body .p-slider.p-slider-vertical {
  width: 0.286em;
}

body .p-slider.p-slider-vertical .p-slider-handle {
  left: 50%;
  margin-left: -0.5715em;
}

body .p-slider .p-slider-handle {
  height: 1.143em;
  width: 1.143em;
  background-color: $cap-white;
  border: 2px solid #666;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
}

body .p-slider .p-slider-range {
  background-color: var(--cap-primary-color);
}

body .p-slider:not(.p-disabled) .p-slider-handle:hover {
  background-color: 2px solid #666;
  border: 2px solid var(--cap-primary-color);
}

body .p-datepicker {
  padding: 0.857em;
  min-width: 20em;
  background-color: $cap-white;
  color: var(--primary-text);
  border: 1px solid #a6a6a6;
}

body .p-datepicker:not(.p-datepicker-inline) {
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-prev:hover,
body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-next:hover {
  color: var(--cap-primary-color);
}

body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-prev:focus,
body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  color: var(--cap-primary-color);
}

body .p-datepicker:not(.p-disabled) table td a:not(.p-highlight):not(.p-highlight):hover {
  background-color: #eaeaea;
}

body .p-datepicker:not(.p-disabled) .p-monthpicker a.p-monthpicker-month:not(.p-highlight):hover {
  background-color: #eaeaea;
}

body .p-datepicker .p-datepicker-header {
  padding: 0.429em 0.857em 0.429em 0.857em;
  background-color: $cap-white;
  color: var(--primary-text);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .p-datepicker .p-datepicker-header .p-datepicker-prev,
body .p-datepicker .p-datepicker-header .p-datepicker-next {
  cursor: pointer;
  top: 0;
  color: #a6a6a6;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

body .p-datepicker .p-datepicker-header .p-datepicker-title {
  margin: 0;
  padding: 0;
  line-height: 1;
}

body .p-datepicker .p-datepicker-header .p-datepicker-title select {
  margin-top: -0.35em;
  margin-bottom: 0;
}

body .p-datepicker table {
  font-size: toRem(14px);
  margin: 0.857em 0 0 0;
}

body .p-datepicker table th {
  padding: 0.5em;
}

body .p-datepicker table td > a,
body .p-datepicker table td > span {
  display: block;
  text-align: center;
  color: var(--primary-text);
  padding: 0.5em;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

body .p-datepicker table td > a.p-highlight,
body .p-datepicker table td > span.p-highlight {
  color: $cap-white;
  background-color: var(--cap-primary-color);
}

body .p-datepicker table td > a {
  cursor: pointer;
}

body .p-datepicker table td.p-datepicker-today > a, body .p-datepicker table td.p-datepicker-today > span {
  background-color: #d0d0d0;
  color: var(--primary-text);
}

body .p-datepicker table td.p-datepicker-today > a.p-highlight, body .p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: $cap-white;
  background-color: var(--cap-primary-color);
}

body .p-datepicker .p-datepicker-buttonbar {
  border-top: 1px solid #d8dae2;
}

body .p-datepicker .p-timepicker {
  border: 0 none;
  border-top: 1px solid #d8dae2;
  padding: 0.857em;
}

body .p-datepicker .p-timepicker a {
  color: var(--primary-text);
  font-size: 1.286em;
}

body .p-datepicker .p-timepicker a:hover {
  color: var(--cap-primary-color);
}

body .p-datepicker .p-timepicker span {
  font-size: 1.286em;
}

body .p-datepicker .p-monthpicker .p-monthpicker-month {
  color: var(--primary-text);
}

body .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: $cap-white;
  background-color: var(--cap-primary-color);
}

body .p-datepicker.p-datepicker-timeonly {
  padding: 0;
}

body .p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}

body .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right: 1px solid #d8dae2;
  padding-right: 0.857em;
  padding-left: 0.857em;
  padding-top: 0;
  padding-bottom: 0;
}

body .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
}

body .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
  border-right: 0 none;
}

body .p-fileupload .p-fileupload-buttonbar {
  background-color: #f4f4f4;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  color: var(--primary-text);
  border-bottom: 0 none;
}

body .p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 8px;
}

body .p-fileupload .p-fileupload-content {
  background-color: $cap-white;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  color: var(--primary-text);
}

body .p-fileupload .p-progressbar {
  top: 0;
}

body .p-fileupload-choose:not(.p-disabled):hover {
  background-color: var(--cap-primary-color);
  color: $cap-white;
  border-color: var(--cap-primary-color);
}

body .p-fileupload-choose:not(.p-disabled):active {
  background-color: var(--cap-primary-color);
  color: $cap-white;
  border-color: var(--cap-primary-color);
}

body .p-fileupload-choose.p-focus {
  outline: 0 none;
  outline-offset: 0px;
}

body .p-password-panel {
  padding: 12px;
  background-color: $cap-white;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-inputgroup .p-inputgroup-addon {
  border-color: #a6a6a6;
  background-color: #eaeaea;
  color: #848484;
  padding: 0.429em;
  min-width: 2em;
}

body .p-inputgroup .p-inputgroup-addon:first-child {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

body .p-inputgroup .p-inputgroup-addon:last-child {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

body .p-inputgroup .p-inputgroup-addon.p-inputgroup-addon-checkbox {
  position: relative;
}

body .p-inputgroup .p-inputgroup-addon.p-inputgroup-addon-checkbox .p-checkbox {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
}

body .p-inputgroup .p-inputgroup-addon.p-inputgroup-addon-radiobutton {
  position: relative;
}

body .p-inputgroup .p-inputgroup-addon.p-inputgroup-addon-radiobutton .p-radiobutton {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
}

body .p-inputgroup .p-button:first-child {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

body .p-inputgroup .p-button:last-child {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

body .p-fluid .p-inputgroup .p-button {
  width: auto;
}

body .p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 2.357em;
}

body ::-webkit-input-placeholder {
  color: #666;
}

body :-moz-placeholder {
  color: #666;
}

body ::-moz-placeholder {
  color: #666;
}

body :-ms-input-placeholder {
  color: #666;
}

body .p-fluid .p-button-icon-only {
  width: 2.357em;
}

body .p-togglebutton {
  background-color: #dadada;
  border: 1px solid #dadada;
  color: var(--primary-text);
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}

body .p-togglebutton .p-button-icon-left {
  color: #666;
}

body .p-togglebutton:not(.p-disabled):not(.p-highlight):hover {
  background-color: #c8c8c8;
  border-color: #c8c8c8;
  color: var(--primary-text);
}

body .p-togglebutton:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left {
  color: var(--text-toast);
}

body .p-togglebutton:not(.p-disabled):not(.p-highlight).p-focus {
  background-color: #c8c8c8;
  border-color: #c8c8c8;
  color: var(--primary-text);
  outline: 0 none;
}

body .p-togglebutton:not(.p-disabled):not(.p-highlight).p-focus .p-button-icon-left {
  color: var(--text-toast);
}

body .p-togglebutton.p-highlight {
  background-color: var(--cap-primary-color);
  border-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-togglebutton.p-highlight .p-button-icon-left {
  color: $cap-white;
}

body .p-togglebutton.p-highlight:hover {
  background-color: var(--cap-primary-color);
  border-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-togglebutton.p-highlight:hover .p-button-icon-left {
  color: $cap-white;
}

body .p-togglebutton.p-highlight.p-focus {
  background-color: var(--cap-primary-color);
  border-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-togglebutton.p-highlight.p-focus .p-button-icon-left {
  color: $cap-white;
}

body .p-selectbutton .p-button {
  background-color: #dadada;
  border: 1px solid #dadada;
  color: var(--primary-text);
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}

body .p-selectbutton .p-button .p-button-icon-left {
  color: #666;
}

body .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background-color: #c8c8c8;
  border-color: #c8c8c8;
  color: var(--primary-text);
}

body .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left {
  color: var(--text-toast);
}

body .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight).p-focus {
  background-color: #c8c8c8;
  border-color: #c8c8c8;
  color: var(--primary-text);
  outline: 0 none;
}

body .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight).p-focus .p-button-icon-left {
  color: var(--text-toast);
}

body .p-selectbutton .p-button.p-highlight {
  background-color: var(--cap-primary-color);
  border-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-selectbutton .p-button.p-highlight .p-button-icon-left {
  color: $cap-white;
}

body .p-selectbutton .p-button.p-highlight:not(.p-disabled):hover {
  background-color: var(--cap-primary-color);
  border-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-selectbutton .p-button.p-highlight:not(.p-disabled):hover .p-button-icon-left {
  color: $cap-white;
}

body .p-selectbutton .p-button.p-highlight.p-focus {
  background-color: var(--cap-primary-color);
  border-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-selectbutton .p-button.p-highlight.p-focus .p-button-icon-left {
  color: $cap-white;
}

body .p-selectbutton .p-button:first-child {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

body .p-selectbutton .p-button:last-child {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

@media (max-width: 640px) {
  body .p-buttonset:not(.p-splitbutton) .p-button {
    margin-bottom: 1px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }
}

body .p-splitbutton.p-buttonset .p-button {
  border: 1px solid transparent;
}

body .p-splitbutton.p-buttonset .p-menu {
  min-width: 100%;
}

body .p-widget-content p {
  line-height: 1.5;
  margin: 0;
}

body .p-panel {
  padding: 0;
  border: 0 none;
}

body .p-panel .p-panel-titlebar {
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  background-color: #f4f4f4;
  color: var(--primary-text);
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}

body .p-panel .p-panel-titlebar .p-panel-title {
  vertical-align: middle;
  font-weight: 700;
}

body .p-panel .p-panel-titlebar .p-panel-titlebar-icon {
  margin: 0;
  position: relative;
  font-size: toRem(14px);
  color: #848484;
  border: 1px solid transparent;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

body .p-panel .p-panel-titlebar .p-panel-titlebar-icon:hover {
  color: var(--primary-text);
}

body .p-panel .p-panel-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0.571em 1em;
  border-top: 0 none;
}

body .p-panel .p-panel-footer {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  border-top: 0 none;
  margin: 0;
}

body .p-fieldset {
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
}

body .p-fieldset .p-fieldset-legend a {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  color: var(--primary-text);
  background-color: #f4f4f4;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

body .p-fieldset .p-fieldset-legend a .p-fieldset-toggler {
  float: none;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5em;
  color: #848484;
}

body .p-fieldset .p-fieldset-legend a .p-fieldset-legend-text {
  padding: 0;
}

body .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:hover {
  background-color: #dbdbdb;
  border: 1px solid #dbdbdb;
  color: var(--primary-text);
}

body .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:hover .p-fieldset-toggler {
  color: var(--primary-text);
}

body .p-fieldset .p-fieldset-content {
  padding: 0;
}

body .p-tabview.p-tabview-top, body .p-tabview.p-tabview-bottom, body .p-tabview.p-tabview-left, body .p-tabview.p-tabview-right {
  border: 0 none;
}

body .p-tabview.p-tabview-top .p-tabview-nav, body .p-tabview.p-tabview-bottom .p-tabview-nav, body .p-tabview.p-tabview-left .p-tabview-nav, body .p-tabview.p-tabview-right .p-tabview-nav {
  padding: 0;
  background: transparent;
  border: 0 none;
}

body .p-tabview.p-tabview-top .p-tabview-nav li, body .p-tabview.p-tabview-bottom .p-tabview-nav li, body .p-tabview.p-tabview-left .p-tabview-nav li, body .p-tabview.p-tabview-right .p-tabview-nav li {
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a, body .p-tabview.p-tabview-bottom .p-tabview-nav li a, body .p-tabview.p-tabview-left .p-tabview-nav li a, body .p-tabview.p-tabview-right .p-tabview-nav li a {
  float: none;
  display: inline-block;
  color: var(--primary-text);
  padding: 0.571em 1em;
  font-weight: 700;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a .p-tabview-left-icon, body .p-tabview.p-tabview-bottom .p-tabview-nav li a .p-tabview-left-icon, body .p-tabview.p-tabview-left .p-tabview-nav li a .p-tabview-left-icon, body .p-tabview.p-tabview-right .p-tabview-nav li a .p-tabview-left-icon {
  margin-right: 0.5em;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a .p-tabview-right-icon, body .p-tabview.p-tabview-bottom .p-tabview-nav li a .p-tabview-right-icon, body .p-tabview.p-tabview-left .p-tabview-nav li a .p-tabview-right-icon, body .p-tabview.p-tabview-right .p-tabview-nav li a .p-tabview-right-icon {
  margin-left: 0.5em;
}

body .p-tabview.p-tabview-top .p-tabview-nav li .p-tabview-close, body .p-tabview.p-tabview-bottom .p-tabview-nav li .p-tabview-close, body .p-tabview.p-tabview-left .p-tabview-nav li .p-tabview-close, body .p-tabview.p-tabview-right .p-tabview-nav li .p-tabview-close {
  color: #848484;
  margin: 0 0.5em 0 0;
  vertical-align: middle;
}

body .p-tabview.p-tabview-top .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover, body .p-tabview.p-tabview-bottom .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover, body .p-tabview.p-tabview-left .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover, body .p-tabview.p-tabview-right .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover {
  background-color: #dbdbdb;
  border: 1px solid #dbdbdb;
}

body .p-tabview.p-tabview-top .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a, body .p-tabview.p-tabview-bottom .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a, body .p-tabview.p-tabview-left .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a, body .p-tabview.p-tabview-right .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a {
  color: var(--primary-text);
}

body .p-tabview.p-tabview-top .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-close, body .p-tabview.p-tabview-bottom .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-close, body .p-tabview.p-tabview-left .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-close, body .p-tabview.p-tabview-right .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-close {
  color: var(--primary-text);
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight {
  background-color: var(--cap-primary-color);
  border: 1px solid var(--cap-primary-color);
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight a, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight a, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight a, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight a {
  color: $cap-white;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight .p-tabview-close, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight .p-tabview-close, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight .p-tabview-close, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight .p-tabview-close {
  color: $cap-white;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight:hover, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight:hover, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight:hover, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight:hover {
  border: 1px solid var(--cap-primary-color);
  background-color: var(--cap-primary-color);
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight:hover a, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight:hover a, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight:hover a, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight:hover a {
  color: $cap-white;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight:hover a .p-tabview-left-icon, body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight:hover a .p-tabview-right-icon, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight:hover a .p-tabview-left-icon, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight:hover a .p-tabview-right-icon, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight:hover a .p-tabview-left-icon, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight:hover a .p-tabview-right-icon, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight:hover a .p-tabview-left-icon, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight:hover a .p-tabview-right-icon {
  color: $cap-white;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-tabview-selected a, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-tabview-selected a, body .p-tabview.p-tabview-left .p-tabview-nav li.p-tabview-selected a, body .p-tabview.p-tabview-right .p-tabview-nav li.p-tabview-selected a {
  cursor: pointer;
}

body .p-tabview.p-tabview-top .p-tabview-nav {
  margin-bottom: -1px;
}

body .p-tabview.p-tabview-top .p-tabview-nav li {
  margin-right: 2px;
}

body .p-tabview.p-tabview-bottom .p-tabview-nav {
  margin-top: -1px;
}

body .p-tabview.p-tabview-bottom .p-tabview-nav li {
  margin-right: 2px;
}

body .p-tabview.p-tabview-left .p-tabview-nav {
  margin-right: -px;
}

body .p-tabview.p-tabview-left .p-tabview-nav li {
  margin-bottom: 2px;
}

body .p-tabview.p-tabview-right .p-tabview-nav {
  margin-right: -1px;
}

body .p-tabview.p-tabview-right .p-tabview-nav li {
  margin-bottom: 2px;
}

body .p-tabview .p-tabview-panels {
  background-color: $cap-white;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  color: var(--primary-text);
}

body .p-tabview .p-tabview-panels .p-tabview-panel {
  padding: 0;
}

body .p-toolbar {
  background-color: #f4f4f4;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
}

body .p-toolbar button {
  vertical-align: middle;
}

body .p-toolbar .p-toolbar-separator {
  vertical-align: middle;
  color: #848484;
  margin: 0 0.5em;
}

body .p-card {
  background-color: $cap-white;
  color: var(--primary-text);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

body .p-paginator {
  background-color: #f4f4f4;
  border: 1px solid #c8c8c8;
  padding: 0;
}

body .p-paginator .p-paginator-first,
body .p-paginator .p-paginator-prev,
body .p-paginator .p-paginator-next,
body .p-paginator .p-paginator-last {
  color: #848484;
  height: 2.286em;
  min-width: 2.286em;
  border: 0 none;
  line-height: 2.286em;
  padding: 0;
  margin: 0;
  vertical-align: top;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
body .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
body .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
body .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background-color: #e0e0e0;
  color: var(--primary-text);
}

body .p-paginator .p-paginator-current {
  color: #848484;
  height: 2.286em;
  min-width: 2.286em;
  line-height: 2.286em;
}

body .p-paginator .p-dropdown .p-dropdown-trigger,
body .p-paginator .p-dropdown .p-dropdown-label {
  color: #848484;
}

body .p-paginator .p-dropdown:hover .p-dropdown-trigger,
body .p-paginator .p-dropdown:hover .p-dropdown-label {
  color: var(--primary-text);
}

body .p-paginator .p-paginator-first:before {
  position: relative;
  top: 1px;
}

body .p-paginator .p-paginator-prev:before {
  position: relative;
}

body .p-paginator .p-paginator-next:before {
  position: relative;
  top: 1px;
}

body .p-paginator .p-paginator-last:before {
  position: relative;
  top: 1px;
}

body .p-paginator .p-paginator-pages {
  vertical-align: top;
  display: inline-block;
  padding: 0;
}

body .p-paginator span.p-paginator-pages {
  &:first-child {
    border-left: 1px solid var(--bs-border-color) !important;
  }
}

.cap-info-table.p-datatable .p-paginator .p-paginator-next {
  border-left: none !important;
}

body .p-paginator .p-paginator-pages .p-paginator-page {
  color: #848484;
  height: 2.286em;
  min-width: 2.286em;
  border: 0 none;
  line-height: 2.286em;
  padding: 0;
  margin: 0;
  vertical-align: top;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background-color: #e0e0e0;
  color: var(--primary-text);
}

body .p-paginator .p-dropdown {
  margin-left: 0.5em;
  min-width: auto;
}

body .p-datatable .p-datatable-caption,
body .p-datatable .p-datatable-summary {
  background-color: #f4f4f4;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  text-align: center;
}

body .p-datatable .p-datatable-caption {
  border-bottom: 0 none;
  font-weight: 700;
}

body .p-datatable .p-datatable-summary {
  border-top: 0 none;
  font-weight: 700;
}

body .p-datatable .p-datatable-thead > tr > th {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: var(--primary-text);
  background-color: #f4f4f4;
}

body .p-datatable .p-datatable-tbody > tr > td {
  padding: 0.571em 0.857em;
}

body .p-datatable .p-datatable-tfoot > tr > td {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: var(--primary-text);
  background-color: $cap-white;
}

body .p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #848484;
}

body .p-datatable .p-sortable-column:not(.p-highlight):hover {
  color: var(--primary-text);
  text-decoration: underline;
  background-color: transparent;
}

body .p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: var(--primary-text);
}

body .p-datatable .p-sortable-column:not(.p-highlight) {
  i {
    color: $cap-table-sort-icon-color;
  }
}

body .p-datatable .p-sortable-column.p-highlight:hover {
  background-color: transparent;
}

body .p-datatable .p-editable-column input {
  font-size: toRem(14px);
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

body .p-datatable .p-editable-column input:focus {
  outline: 1px solid var(--cap-primary-color);
  outline-offset: 2px;
}

body .p-datatable .p-datatable-tbody > tr {
  background-color: $cap-white;
  color: var(--primary-text);
}

body .p-datatable .p-datatable-tbody > tr > td {
  background-color: inherit;
  border: 1px solid #c8c8c8;
}

body .p-datatable .p-datatable-tbody > tr.p-highlight {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-datatable .p-datatable-tbody > tr.p-highlight a {
  color: $cap-white;
}

body .p-datatable .p-datatable-tbody > tr.p-contextmenu-selected {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  -webkit-box-shadow: inset 0 -2px 0 0 var(--cap-primary-color);
  -moz-box-shadow: inset 0 -2px 0 0 var(--cap-primary-color);
  box-shadow: inset 0 -2px 0 0 var(--cap-primary-color);
}

body .p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: #f9f9f9;
}

body .p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight a {
  color: $cap-white;
}

body .p-datatable .p-datatable-tbody > tr:nth-child(even).p-contextmenu-selected {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr.p-selectable-row:not(.p-highlight):not(.p-contextmenu-selected):hover {
  cursor: pointer;
  background-color: #eaeaea;
  color: var(--primary-text);
}

body .p-datatable .p-column-resizer-helper {
  background-color: var(--cap-primary-color);
}

@media screen and (max-width: 40em) {
  body .p-datatable.p-datatable-responsive .p-paginator-top {
    border-bottom: 1px solid #c8c8c8;
  }

  body .p-datatable.p-datatable-responsive .p-paginator-bottom {
    border-top: 1px solid #c8c8c8;
  }

  body .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
    border: 0 none;
  }
}

body .p-datagrid .p-datagrid-header {
  background-color: #f4f4f4;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
}

body .p-datagrid .p-datagrid-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0.571em 1em;
}

body .p-datagrid .p-datagrid-footer {
  background-color: $cap-white;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none;
}

body .p-datalist .p-datalist-header {
  background-color: #f4f4f4;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
}

body .p-datalist .p-datalist-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0.571em 1em;
}

body .p-datalist .p-datalist-footer {
  background-color: $cap-white;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none;
}

body .p-datascroller .p-datascroller-header {
  background-color: #f4f4f4;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
}

body .p-datascroller .p-datascroller-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0.571em 1em;
}

body .p-datascroller .p-datascroller-footer {
  background-color: $cap-white;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none;
}

body .p-virtualscroller .p-virtualscroller-header {
  background-color: #f4f4f4;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
}

body .p-virtualscroller .p-virtualscroller-content {
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
}

body .p-virtualscroller .p-virtualscroller-content .p-virtualscroller-list li {
  border-bottom: 1px solid #c8c8c8;
}

body .p-virtualscroller .p-virtualscroller-footer {
  background-color: $cap-white;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none;
}

body .p-dataview .p-dataview-header {
  background-color: #f4f4f4;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
}

body .p-dataview .p-dataview-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0.571em 1em;
}

body .p-dataview .p-dataview-footer {
  background-color: $cap-white;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none;
}

body .fc th {
  background-color: #f4f4f4;
  border: 1px solid #c8c8c8;
  color: var(--primary-text);
  padding: 0.571em 1em;
}

body .fc td.p-widget-content {
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
  color: var(--primary-text);
}

body .fc td.fc-head-container {
  border: 1px solid #c8c8c8;
}

body .fc .fc-row {
  border-right: 1px solid #c8c8c8;
}

body .fc .fc-event {
  background-color: var(--cap-primary-color);
  border: 1px solid var(--cap-primary-color);
  color: $cap-white;
}

body .fc .fc-toolbar .fc-button {
  color: $cap-white;
  background-color: var(--cap-primary-color);
  border: 1px solid var(--cap-primary-color);
  font-size: toRem(14px);
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

body .fc .fc-toolbar .fc-button:enabled:hover {
  background-color: var(--cap-primary-color);
  color: $cap-white;
  border-color: var(--cap-primary-color);
}

body .fc .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: 'PrimeIcons' !important;
  text-indent: 0;
}

body .fc .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: '';
}

body .fc .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: 'PrimeIcons' !important;
  text-indent: 0;
}

body .fc .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: '';
}

body .fc .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0px;
  -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
  -moz-box-shadow: 0 0 0 0.2em #8dcdff;
  box-shadow: 0 0 0 0.2em #8dcdff;
}

body .fc .fc-toolbar .fc-button-group .fc-button {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .fc .fc-toolbar .fc-button-group .fc-button:first-child {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

body .fc .fc-toolbar .fc-button-group .fc-button:last-child {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

body .fc .fc-divider {
  background-color: #f4f4f4;
  border: 1px solid #c8c8c8;
}

body .p-fluid .fc .fc-toolbar .fc-button {
  width: auto;
}

body .p-picklist .p-picklist-buttons button {
  font-size: toRem(16px);
}

body .p-picklist .p-picklist-caption {
  background-color: #f4f4f4;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}

body .p-picklist .p-picklist-filter-container {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border: 1px solid #c8c8c8;
  color: var(--primary-text);
  background-color: $cap-white;
  margin: 0;
  border-bottom: 0 none;
}

body .p-picklist .p-picklist-filter-container input.p-picklist-filter {
  width: 100%;
  padding-right: 2em;
  text-indent: 0;
}

body .p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  top: 50%;
  margin-top: -0.5em;
  left: auto;
  right: 1.357em;
  color: var(--cap-primary-color);
}

body .p-picklist .p-picklist-buttons {
  padding: 0.571em 1em;
}

body .p-picklist .p-picklist-list {
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0;
}

body .p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.429em 0.857em;
  margin: 0;
  border: 0 none;
  color: var(--primary-text);
  background-color: transparent;
}

body .p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
  background-color: #eaeaea;
  color: var(--primary-text);
}

body .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-picklist .p-picklist-list .p-picklist-droppoint-highlight {
  background-color: var(--cap-primary-color);
}

@media (max-width: 40em) {
  body .p-picklist.p-picklist-responsive .p-picklist-buttons {
    padding: 0.571em 1em;
  }
}

body .p-orderlist .p-orderlist-controls {
  padding: 0.571em 1em;
}

body .p-orderlist .p-orderlist-controls button {
  font-size: toRem(16px);
}

body .p-orderlist .p-orderlist-caption {
  background-color: #f4f4f4;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}

body .p-orderlist .p-orderlist-filter-container {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border: 1px solid #c8c8c8;
  color: var(--primary-text);
  background-color: $cap-white;
  margin: 0;
  border-bottom: 0 none;
}

body .p-orderlist .p-orderlist-filter-container input.p-inputtext {
  width: 100%;
  padding-right: 2em;
  text-indent: 0;
}

body .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
  top: 50%;
  margin-top: -0.5em;
  left: auto;
  right: 1.357em;
  color: var(--cap-primary-color);
}

body .p-orderlist .p-orderlist-list {
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0;
}

body .p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0.429em 0.857em;
  margin: 0;
  border: 0 none;
  color: var(--primary-text);
  background-color: transparent;
}

body .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
  background-color: #eaeaea;
  color: var(--primary-text);
}

body .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-orderlist .p-orderlist-list .p-orderlist-droppoint-highlight {
  background-color: var(--cap-primary-color);
}

body .p-tree {
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0.571em 1em;
}

body .p-tree .p-tree-container {
  padding: 0.286em;
  margin: 0;
}

body .p-tree .p-tree-container .p-treenode {
  padding: 0.143em 0;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content {
  padding: 0;
  border: 1px solid transparent;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  vertical-align: middle;
  display: inline-block;
  float: none;
  margin: 0 0.143em 0 0;
  color: #848484;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  vertical-align: middle;
  display: inline-block;
  margin: 0 0.143em 0 0;
  color: #848484;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label {
  margin: 0;
  vertical-align: middle;
  display: inline-block;
  padding: 0.286em;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label.p-highlight {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin: 0 0.5em 0 0;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-checkbox-icon {
  margin: 1px 0 0 0;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable .p-treenode-label:not(.p-highlight):hover {
  background-color: #eaeaea;
  color: var(--primary-text);
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content > span {
  line-height: inherit;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background-color: #eaeaea;
  color: var(--primary-text);
}

body .p-tree .p-tree-container .p-treenode-droppoint.p-treenode-droppoint-active {
  background-color: var(--cap-primary-color);
}

body .p-tree.p-tree-horizontal {
  padding-left: 0;
  padding-right: 0;
}

body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0.571em 1em;
}

body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox .p-icon {
  color: var(--cap-primary-color);
}

body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
  background-color: inherit;
  color: inherit;
}

body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background-color: #eaeaea;
  color: var(--primary-text);
}

body .p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 2em;
}

body .p-tree .p-tree-filter-container .p-tree-filter-icon {
  top: 50%;
  left: auto;
  margin-top: -0.5em;
  right: 0.8em;
  color: var(--cap-primary-color);
}

body .p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background-color: #eaeaea;
  color: var(--primary-text);
}

body .p-organizationchart .p-organizationchart-node-content.p-highlight {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
  color: #00325a;
}

body .p-organizationchart .p-organizationchart-line-down {
  background-color: #c8c8c8;
}

body .p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #c8c8c8;
  border-color: #c8c8c8;
}

body .p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #c8c8c8;
  border-color: #c8c8c8;
}

body .p-organizationchart .p-organizationchart-node-content {
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0.571em 1em;
}

body .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  bottom: -0.7em;
  margin-left: -0.46em;
  color: #848484;
}

body .p-carousel {
  padding: 0;
}

body .p-carousel .p-carousel-header {
  background-color: #f4f4f4;
  color: var(--primary-text);
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  margin: 0;
  font-weight: 700;
}

body .p-carousel .p-carousel-header .p-carousel-header-title {
  padding: 0;
}

body .p-carousel .p-carousel-viewport {
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0;
}

body .p-carousel .p-carousel-viewport .p-carousel-items .p-carousel-item {
  border: 1px solid #c8c8c8;
}

body .p-carousel .p-carousel-footer {
  background-color: #f4f4f4;
  color: var(--primary-text);
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  margin: 0;
}

body .p-carousel .p-carousel-button {
  color: #848484;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

body .p-carousel .p-carousel-button.p-carousel-next-button:before {
  content: '';
}

body .p-carousel .p-carousel-button.p-carousel-prev-button:before {
  content: '';
}

body .p-carousel .p-carousel-button:not(.p-disabled):hover {
  color: var(--primary-text);
}

body .p-carousel .p-carousel-page-links {
  margin: 0.125em 0.5em;
}

body .p-carousel .p-carousel-page-links .p-carousel-page-link {
  color: #848484;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

body .p-carousel .p-carousel-page-links .p-carousel-page-link:hover {
  color: var(--primary-text);
}

body .p-treetable .p-treetable-caption,
body .p-treetable .p-treetable-summary {
  background-color: #f4f4f4;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  text-align: center;
}

body .p-treetable .p-treetable-caption {
  border-bottom: 0 none;
  font-weight: 700;
}

body .p-treetable .p-treetable-summary {
  border-top: 0 none;
  font-weight: 700;
}

body .p-treetable .p-treetable-thead > tr > th {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: var(--primary-text);
  background-color: #f4f4f4;
}

body .p-treetable .p-treetable-tbody > tr > td {
  padding: 0.571em 0.857em;
}

body .p-treetable .p-treetable-tfoot > tr > td {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: var(--primary-text);
  background-color: $cap-white;
}

body .p-treetable .p-sortable-column .p-sortable-column-icon {
  color: #848484;
}

body .p-treetable .p-sortable-column:not(.p-highlight):hover {
  background-color: #e0e0e0;
  color: var(--primary-text);
}

body .p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: var(--primary-text);
}

body .p-treetable .p-sortable-column.p-highlight {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: $cap-white;
}

body .p-treetable .p-editable-column input {
  font-size: toRem(14px);
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

body .p-treetable .p-editable-column input:focus {
  outline: 1px solid var(--cap-primary-color);
  outline-offset: 2px;
}

body .p-treetable .p-treetable-tbody > tr {
  background-color: $cap-white;
  color: var(--primary-text);
}

body .p-treetable .p-treetable-tbody > tr > td {
  background-color: inherit;
  border: 1px solid #c8c8c8;
}

body .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  color: #848484;
  vertical-align: middle;
}

body .p-treetable .p-treetable-tbody > tr > td .p-treetable-chkbox {
  vertical-align: middle;
  margin-right: 0.5em;
}

body .p-treetable .p-treetable-tbody > tr.p-highlight {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-treetable .p-treetable-tbody > tr.p-highlight > td {
  background-color: inherit;
  border: 1px solid #c8c8c8;
}

body .p-treetable .p-treetable-tbody > tr.p-highlight > td .p-treetable-toggler {
  color: $cap-white;
}

body .p-treetable .p-treetable-tbody > tr.p-contextmenu-selected {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-treetable .p-treetable-tbody > tr.p-contextmenu-selected > td {
  background-color: inherit;
  border: 1px solid #c8c8c8;
}

body .p-treetable .p-treetable-tbody > tr.p-contextmenu-selected > td .p-treetable-toggler {
  color: $cap-white;
}

body .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):not(.p-contextmenu-selected):hover {
  cursor: pointer;
  background-color: #eaeaea;
  color: var(--primary-text);
}

body .p-treetable .p-column-resizer-helper {
  background-color: var(--cap-primary-color);
}

body .p-messages {
  padding: 1em;
  margin: 1em 0;
}

body .p-messages.p-messages-info {
  background-color: var(--info-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-messages.p-messages-info .p-messages-icon {
  color: var(--text-toast);
}

body .p-messages.p-messages-info .p-messages-close {
  color: var(--text-toast);
}

body .p-messages.p-messages-success {
  background-color: var(--success-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-messages.p-messages-success .p-messages-icon {
  color: var(--text-toast);
}

body .p-messages.p-messages-success .p-messages-close {
  color: var(--text-toast);
}

body .p-messages.p-messages-warn {
  background-color: var(--warning-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-messages.p-messages-warn .p-messages-icon {
  color: var(--text-toast);
}

body .p-messages.p-messages-warn .p-messages-close {
  color: var(--text-toast);
}

body .p-messages.p-messages-error {
  background-color: var(--confirm-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-messages.p-messages-error .p-messages-icon {
  color: var(--text-toast);
}

body .p-messages.p-messages-error .p-messages-close {
  color: var(--text-toast);
}

body .p-messages .p-messages-close {
  top: 0.25em;
  right: 0.5em;
  font-size: 1.5em;
}

body .p-messages .p-messages-icon {
  font-size: 2em;
}

body .p-message {
  padding: 0.429em;
  margin: 0;
}

body .p-message.p-message-info {
  background-color: var(--info-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-message.p-message-info .p-message-icon {
  color: var(--text-toast);
}

body .p-message.p-message-success {
  background-color: var(--success-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-message.p-message-success .p-message-icon {
  color: var(--text-toast);
}

body .p-message.p-message-warn {
  background-color: var(--warning-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-message.p-message-warn .p-message-icon {
  color: var(--text-toast);
}

body .p-message.p-message-error {
  background-color: var(--confirm-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-message.p-message-error .p-message-icon {
  color: var(--text-toast);
}

body .p-message .p-message-icon {
  font-size: 1.25em;
}

body .p-message .p-message-text {
  font-size: 1em;
}

body .p-growl {
  top: 70px;
}

body .p-growl .p-growl-item-container {
  margin: 0 0 1em 0;
  opacity: 0.9;
  filter: alpha(opacity=90);
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-growl .p-growl-item-container .p-growl-item {
  padding: 1em;
}

body .p-growl .p-growl-item-container .p-growl-item .p-growl-message {
  margin: 0 0 0 4em;
}

body .p-growl .p-growl-item-container .p-growl-item .p-growl-image {
  font-size: 2.571em;
}

body .p-growl .p-growl-item-container.p-growl-message-info {
  background-color: var(--info-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-growl .p-growl-item-container.p-growl-message-info .p-growl-image {
  color: var(--text-toast);
}

body .p-growl .p-growl-item-container.p-growl-message-success {
  background-color: var(--success-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-growl .p-growl-item-container.p-growl-message-success .p-growl-image {
  color: var(--text-toast);
}

body .p-growl .p-growl-item-container.p-growl-message-warn {
  background-color: var(--warning-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-growl .p-growl-item-container.p-growl-message-warn .p-growl-image {
  color: var(--text-toast);
}

body .p-growl .p-growl-item-container.p-growl-message-error {
  background-color: var(--confirm-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-growl .p-growl-item-container.p-growl-message-error .p-growl-image {
  color: var(--text-toast);
}

body .p-toast .p-toast-message {
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin: 0 0 1em 0;
}

body .p-toast .p-toast-message.p-toast-message-info {
  background-color: var(--info-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-toast .p-toast-message.p-toast-message-info .p-toast-close-icon {
  color: var(--text-toast);
}

body .p-toast .p-toast-message.p-toast-message-success {
  background-color: var(--success-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-toast .p-toast-message.p-toast-message-success .p-toast-close-icon {
  color: var(--text-toast);
}

body .p-toast .p-toast-message.p-toast-message-warn {
  background-color: var(--warning-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-toast .p-toast-message.p-toast-message-warn .p-toast-close-icon {
  color: var(--text-toast);
}

body .p-toast .p-toast-message.p-toast-message-error {
  background-color: var(--confirm-color);
  border: 0 none;
  color: var(--text-toast);
}

body .p-toast .p-toast-message.p-toast-message-error .p-toast-close-icon {
  color: var(--text-toast);
}

body {
  :not(.p-sidebar-mask) {
    &.p-component-overlay,
    &.p-overlay {
      z-index: 6001 !important;
    }
  }

  .p-component-overlay {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

body .p-overlaypanel {
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0;
  border: 1px solid #c8c8c8;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .p-overlaypanel .p-overlaypanel-content {
  padding: 0.571em 1em;
}

body .p-overlaypanel .p-overlaypanel-close {
  background-color: var(--cap-primary-color);
  color: $cap-white;
  width: 1.538em;
  height: 1.538em;
  line-height: 1.538em;
  text-align: center;
  position: absolute;
  top: -0.769em;
  right: -0.769em;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

body .p-overlaypanel .p-overlaypanel-close:hover {
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-overlaypanel .p-overlaypanel-close .p-overlaypanel-close-icon {
  line-height: inherit;
}

body .p-overlaypanel:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: $cap-white;
}

body .p-overlaypanel:before {
  border-color: rgba(200, 200, 200, 0);
  border-bottom-color: #c8c8c8;
}

body .p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: $cap-white;
}

body .p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: #c8c8c8;
}

body .p-dialog {
  padding: 0;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-dialog .p-dialog-titlebar {
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
  color: var(--primary-text);
  padding: 1em;
  font-weight: 700;
  border-bottom: 0 none;
}

body .p-dialog .p-dialog-titlebar .p-dialog-title {
  margin: 0;
  float: none;
}

body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon {
  color: #848484;
  border: 0 none;
  padding: 0;
  margin-left: 0.5em;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon:hover {
  color: var(--primary-text);
}

body .p-dialog .p-dialog-content {
  background-color: $cap-white;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
}

body .p-dialog .p-dialog-footer {
  border: 1px solid #c8c8c8;
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0.571em 1em;
  margin: 0;
  text-align: right;
  position: relative;
  top: -1px;
}

body .p-dialog .p-dialog-footer button {
  margin: 0 0.5em 0 0;
  width: auto;
}

body .p-dialog.p-confirm-dialog .p-dialog-content {
  padding: 1.5em;
}

body .p-dialog.p-confirm-dialog .p-dialog-content > span {
  float: none;
  display: inline-block;
  vertical-align: middle;
  line-height: 14px;
  margin: 0;
}

body .p-dialog.p-confirm-dialog .p-dialog-content > span.p-icon {
  margin-right: 0.35em;
  font-size: toRem(16px);
}

body .p-sidebar {
  background-color: $cap-white;
  color: var(--primary-text);
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-sidebar .p-sidebar-close {
  color: #848484;
}

body .p-sidebar .p-sidebar-close:hover {
  color: var(--primary-text);
}

body .p-tooltip {
  .p-tooltip-arrow {
    left: 1px;
  }

  .p-tooltip-text {
    background-color: $cap-black;
    color: $cap-white;
    padding: 0.429em;
    box-shadow: 1px 1px 1px rgb(0 0 0 / 4%)
  }

  &.cap-sidebar-tooltip .p-tooltip-text {
    color: var(--text-color-1);
    font-weight: 600;
  }
}

body .p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: $cap-black;
}

body .p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: $cap-black;
}

body .p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: $cap-black;
}

body .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: $cap-black;
}

body .p-lightbox {
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-lightbox .p-lightbox-caption {
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
  color: var(--primary-text);
  padding: 0.571em 1em;
  font-weight: 700;
}

body .p-lightbox .p-lightbox-caption .p-lightbox-caption-text {
  color: var(--primary-text);
  margin: 0;
}

body .p-lightbox .p-lightbox-caption .p-lightbox-close {
  padding: 0;
  color: #848484;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

body .p-lightbox .p-lightbox-caption .p-lightbox-close:hover {
  color: var(--primary-text);
}

body .p-lightbox .p-lightbox-content-wrapper {
  overflow: hidden;
  background-color: $cap-white;
  color: var(--primary-text);
  border: 0 none;
  padding: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-left, body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-right {
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 3em;
  color: $cap-white;
  margin-top: -0.5em;
}

body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-left:hover, body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-right:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-content.p-lightbox-loading ~ a {
  display: none;
}

body .p-breadcrumb {
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
}

body .p-breadcrumb ul li .p-menuitem-link {
  color: var(--primary-text);
  margin: 0;
}

body .p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5em 0 0.5em;
  color: #848484;
}

body .p-breadcrumb ul li:first-child a {
  color: #848484;
  margin: 0;
}

body .p-breadcrumb ul li .p-menuitem-icon {
  color: #848484;
}

body .p-steps {
  position: relative;
}

body .p-steps .p-steps-item {
  background-color: transparent;
  text-align: center;
}

body .p-steps .p-steps-item .p-menuitem-link {
  display: inline-block;
  text-align: center;
  background-color: transparent;
  overflow: hidden;
}

body .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  color: var(--primary-text);
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
  position: relative;
  top: 16px;
  margin-bottom: 14px;
  width: 28px;
  height: 28px;
  font-size: toRem(16px);
  line-height: 24px;
  text-align: center;
}

body .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  display: block;
  margin-top: 6px;
  color: #848484;
}

body .p-steps .p-steps-item.p-highlight .p-steps-number {
  background: var(--cap-primary-color);
  color: $cap-white;
}

body .p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 700;
  color: var(--primary-text);
}

body .p-steps .p-steps-item:last-child .p-menuitem-link {
  display: block;
}

body .p-steps:before {
  content: ' ';
  border-top: 1px solid #c8c8c8;
  width: 100%;
  top: 45%;
  left: 0;
  display: block;
  position: absolute;
}

body .p-menu .p-menuitem-link,
body .p-menubar .p-menuitem-link,
body .p-tieredmenu .p-menuitem-link,
body .p-contextmenu .p-menuitem-link,
body .p-megamenu .p-menuitem-link,
body .p-slidemenu .p-menuitem-link {
  padding: 0.714em 0.857em;
  color: var(--primary-text);
  font-weight: normal;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .p-menu .p-menuitem-link .p-menuitem-text,
body .p-menubar .p-menuitem-link .p-menuitem-text,
body .p-tieredmenu .p-menuitem-link .p-menuitem-text,
body .p-contextmenu .p-menuitem-link .p-menuitem-text,
body .p-megamenu .p-menuitem-link .p-menuitem-text,
body .p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: var(--primary-text);
}

body .p-menu .p-menuitem-link .p-menuitem-icon,
body .p-menubar .p-menuitem-link .p-menuitem-icon,
body .p-tieredmenu .p-menuitem-link .p-menuitem-icon,
body .p-contextmenu .p-menuitem-link .p-menuitem-icon,
body .p-megamenu .p-menuitem-link .p-menuitem-icon,
body .p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: var(--primary-text);
  margin-right: 0.5em;
}

body .p-menu .p-menuitem-link:hover,
body .p-menubar .p-menuitem-link:hover,
body .p-tieredmenu .p-menuitem-link:hover,
body .p-contextmenu .p-menuitem-link:hover,
body .p-megamenu .p-menuitem-link:hover,
body .p-slidemenu .p-menuitem-link:hover {
  background-color: #eaeaea;
}

body .p-menu .p-menuitem-link:hover .p-menuitem-text,
body .p-menubar .p-menuitem-link:hover .p-menuitem-text,
body .p-tieredmenu .p-menuitem-link:hover .p-menuitem-text,
body .p-contextmenu .p-menuitem-link:hover .p-menuitem-text,
body .p-megamenu .p-menuitem-link:hover .p-menuitem-text,
body .p-slidemenu .p-menuitem-link:hover .p-menuitem-text {
  color: var(--primary-text);
}

body .p-menu .p-menuitem-link:hover .p-menuitem-icon,
body .p-menubar .p-menuitem-link:hover .p-menuitem-icon,
body .p-tieredmenu .p-menuitem-link:hover .p-menuitem-icon,
body .p-contextmenu .p-menuitem-link:hover .p-menuitem-icon,
body .p-megamenu .p-menuitem-link:hover .p-menuitem-icon,
body .p-slidemenu .p-menuitem-link:hover .p-menuitem-icon {
  color: var(--primary-text);
}

body .p-menu {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
}

body .p-menu .p-menuitem {
  margin: 0;
}

body .p-menu.p-shadow {
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-menu .p-submenu-header {
  margin: 0;
  padding: 0.714em 0.857em;
  color: var(--primary-text);
  background-color: #f4f4f4;
  font-weight: 700;
  border: 0 none;
}

body .p-menu .p-menu-separator {
  border: 1px solid #c8c8c8;
  border-width: 1px 0 0 0;
}

body .p-menubar {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
}

body .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.714em 0.857em;
}

body .p-menubar .p-menubar-root-list > .p-menu-separator {
  border: 1px solid #c8c8c8;
  border-width: 0 0 0 1px;
}

body .p-menubar .p-submenu-list {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-menubar .p-submenu-list .p-menuitem {
  margin: 0;
}

body .p-menubar .p-submenu-list .p-menu-separator {
  border: 1px solid #c8c8c8;
  border-width: 1px 0 0 0;
}

body .p-menubar .p-menuitem {
  margin: 0;
}

body .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background-color: #eaeaea;
}

body .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: var(--primary-text);
}

body .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, body .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: var(--primary-text);
}

body .p-contextmenu {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-contextmenu .p-submenu-list {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-contextmenu .p-menuitem {
  margin: 0;
}

body .p-contextmenu .p-menuitem .p-menuitem-link .p-submenu-icon {
  right: 0.429em;
}

body .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background-color: #eaeaea;
}

body .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: var(--primary-text);
}

body .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, body .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: var(--primary-text);
}

body .p-contextmenu .p-menu-separator {
  border: 1px solid #c8c8c8;
  border-width: 1px 0 0 0;
}

body .p-tieredmenu {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
}

body .p-tieredmenu .p-submenu-list {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-tieredmenu .p-menuitem {
  margin: 0;
}

body .p-tieredmenu .p-menuitem .p-menuitem-link .p-submenu-icon {
  right: 0.429em;
}

body .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background-color: #eaeaea;
}

body .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: var(--primary-text);
}

body .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, body .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: var(--primary-text);
}

body .p-tieredmenu .p-menu-separator {
  border: 1px solid #c8c8c8;
  border-width: 1px 0 0 0;
}

body .p-slidemenu {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
}

body .p-slidemenu .p-submenu-list {
  padding: 0;
  background-color: $cap-white;
  border: 0 none;
}

body .p-slidemenu .p-menuitem {
  margin: 0;
}

body .p-slidemenu .p-menuitem .p-menuitem-link .p-submenu-icon {
  right: 0.429em;
}

body .p-slidemenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background-color: #eaeaea;
}

body .p-slidemenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: var(--primary-text);
}

body .p-slidemenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, body .p-slidemenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: var(--primary-text);
}

body .p-slidemenu .p-slidemenu-backward {
  margin: 0;
  padding: 0.571em 1em;
  color: var(--primary-text);
  background-color: #f4f4f4;
  font-weight: 700;
  border: 0 none;
}

body .p-slidemenu .p-slidemenu-backward:hover {
  background-color: #dbdbdb;
  color: var(--primary-text);
}

body .p-slidemenu .p-menu-separator {
  border: 1px solid #c8c8c8;
  border-width: 1px 0 0 0;
}

body .p-tabmenu {
  border: 0 none;
}

body .p-tabmenu .p-tabmenu-nav {
  padding: 0;
  background: transparent;
  border-bottom: 1px solid #c8c8c8;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  position: static;
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
  margin-right: 2px;
  margin-bottom: -1px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  color: var(--primary-text);
  padding: 0.571em 1em;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-text {
  color: var(--primary-text);
  margin-right: 0.5em;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  color: #848484;
  margin-right: 0.5em;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover {
  background-color: #dbdbdb;
  border: 1px solid #dbdbdb;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link .p-menuitem-text {
  color: var(--primary-text);
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link .p-menuitem-icon {
  color: var(--primary-text);
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight {
  background-color: var(--cap-primary-color);
  border: 1px solid var(--cap-primary-color);
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link .p-menuitem-text {
  color: $cap-white;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link .p-menuitem-icon {
  color: $cap-white;
}

body .p-megamenu {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
}

body .p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 0.571em 1em;
  color: var(--primary-text);
  background-color: #f4f4f4;
  font-weight: 700;
  border: 0 none;
}

body .p-megamenu .p-megamenu-panel {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-megamenu .p-menuitem {
  margin: 0;
}

body .p-megamenu .p-menuitem .p-menuitem-link .p-submenu-icon {
  right: 0.429em;
}

body .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background-color: #eaeaea;
}

body .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: var(--primary-text);
}

body .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, body .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: var(--primary-text);
}

body .p-megamenu.p-megamenu-vertical .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
  right: 0.429em;
}

body .p-panelmenu .p-icon {
  position: static;
}

body .p-panelmenu .p-panelmenu-header {
  padding: 0;
}

body .p-panelmenu .p-panelmenu-header > a {
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
  color: var(--primary-text);
  padding: 0.714em 0.857em;
  font-weight: 700;
  position: static;
  font-size: toRem(14px);
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

body .p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  color: #848484;
}

body .p-panelmenu .p-panelmenu-header:not(.p-highlight) > a:hover {
  outline: 0 none;
  border: 1px solid #dbdbdb;
  background-color: #dbdbdb;
  color: var(--primary-text);
}

body .p-panelmenu .p-panelmenu-header:not(.p-highlight) > a:hover .p-panelmenu-icon {
  color: var(--primary-text);
}

body .p-panelmenu .p-panelmenu-header.p-highlight > a {
  border: 1px solid var(--cap-primary-color);
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-panelmenu .p-panelmenu-header.p-highlight > a .p-panelmenu-icon {
  color: $cap-white;
}

body .p-panelmenu .p-panelmenu-header.p-highlight > a:hover {
  outline: 0 none;
  border: 1px solid var(--cap-primary-color);
  background-color: var(--cap-primary-color);
  color: $cap-white;
}

body .p-panelmenu .p-panelmenu-header.p-highlight > a:hover .p-panelmenu-icon {
  color: $cap-white;
}

body .p-panelmenu .p-panelmenu-panel {
  margin-top: 2px;
}

body .p-panelmenu .p-panelmenu-panel:first-child {
  margin-top: 0;
}

body .p-panelmenu .p-panelmenu-content {
  padding: 0;
  background-color: $cap-white;
  border: 1px solid #c8c8c8;
  margin-top: 0;
  position: static;
  border-top: 0 none;
}

body .p-panelmenu .p-panelmenu-content .p-menuitem {
  margin: 0;
}

body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.714em 0.857em;
  color: var(--primary-text);
}

body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:hover {
  background-color: #eaeaea;
}

body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:hover .p-menuitem-icon, body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:hover .p-panelmenu-icon {
  color: var(--primary-text);
}

body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:hover .p-menuitem-text {
  color: var(--primary-text);
}

body .p-progressbar {
  border: 0 none;
  height: 24px;
  background-color: #eaeaea;
}

body .p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: var(--cap-primary-color);
}

body .p-progressbar .p-progressbar-label {
  margin-top: 0;
  color: var(--primary-text);
  line-height: 24px;
}

body .p-galleria .p-galleria-nav-prev,
body .p-galleria .p-galleria-nav-next {
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: absolute;
  color: #848484;
}

body .p-galleria .p-galleria-nav-prev:hover,
body .p-galleria .p-galleria-nav-next:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

body .p-galleria .p-galleria-nav-prev {
  font-size: toRem(16px);
}

body .p-galleria .p-galleria-nav-next {
  font-size: toRem(16px);
}

body .p-terminal {
  background-color: $cap-white;
  color: var(--primary-text);
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
}

body .p-terminal .p-terminal-input {
  font-size: toRem(14px);
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  height: 16px;
}

body .p-terminal .p-terminal-command {
  height: 16px;
}

body .p-inplace {
  min-height: 2.357em;
}

body .p-inplace .p-inplace-display {
  padding: 0.429em;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

body .p-inplace .p-inplace-display:not(.p-disabled):hover {
  background-color: #eaeaea;
  color: var(--primary-text);
}

body .p-fluid .p-inplace.p-inplace-closable .p-inplace-content > :first-child {
  display: inline-block;
  width: calc(100% - 2.357em);
}


.p-datatable {
  position: relative;
}

.p-datatable table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.p-datatable .p-datatable-header,
.p-datatable .p-datatable-caption,
.p-datatable .p-datatable-footer {
  text-align: center;
  padding: .5em .75em;
  box-sizing: border-box;
}

.p-datatable .p-datatable-caption,
.p-datatable .p-datatable-header {
  border-bottom: 0 none;
}

.p-datatable .p-datatable-footer {
  border-top: 0 none;
}

.p-datatable thead th, .p-datatable tfoot td {
  text-align: center;
}

.p-datatable thead tr {
  border-width: 0;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tfoot > tr > td,
.p-datatable .p-datatable-data > tr > td {
  border-color: inherit;
  box-sizing: border-box;
  padding: .25em .5em;
  border-width: 1px;
  border-style: solid;
}


.p-datatable.p-datatable-resizable .p-datatable-thead > tr > th,
.p-datatable.p-datatable-resizable .p-datatable-tfoot > tr > td,
.p-datatable.p-datatable-resizable .p-datatable-data > tr > td {
  overflow: hidden;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tfoot > tr > td {
  font-weight: normal;
}

.p-datatable tbody {
  outline: 0;
}

.p-datatable .p-sortable-column {
  cursor: pointer;
}

.p-datatable .p-sortable-column-icon {
  display: inline-block;
  margin-left: .125em;
}

.p-datatable tr.p-highlight {
  cursor: pointer;
}

/* Scrollable */
.p-datatable-scrollable-body {
  overflow: auto;
  overflow-anchor: none;
  min-height: 0%;
}

.p-datatable-scrollable-header,
.p-datatable-scrollable-footer {
  overflow: hidden;
}

.p-datatable-scrollable .p-datatable-scrollable-header,
.p-datatable-scrollable .p-datatable-scrollable-footer {
  position: relative;
  border: 0 none;
}

.p-datatable-scrollable .p-datatable-scrollable-header td {
  font-weight: normal;
}

.p-datatable-scrollable-body .p-datatable-data,
.p-datatable-scrollable-body .p-datatable-data > tr:first-child {
  border-top-color: transparent;
}

.p-datatable .p-datatable-data tr.p-state-hover,
.p-datatable .p-datatable-data tr.p-highlight {
  border-color: inherit;
  font-weight: inherit;
  cursor: pointer;
}

.p-datatable .p-datatable-data tr.p-rowgroup-header td a,
.p-datatable .p-datatable-data tr.p-rowgroup-header td span.p-rowgroup-header-name {
  display: inline-block;
  vertical-align: middle;
}

.p-datatable-scrollable-theadclone {
  height: 0;
}

.p-datatable-scrollable-theadclone tr {
  height: 0;
}

.p-datatable-scrollable-theadclone th.p-state-default {
  height: 0;
  border-bottom-width: 0;
  border-top-width: 0;
  padding-top: 0;
  padding-bottom: 0;
  outline: 0 none;
}

.p-datatable-scrollable-theadclone th span.p-column-title {
  display: block;
  height: 0;
}

.p-datatable .p-paginator {
  padding: .125em;
}

.p-datatable .p-paginator-top {
  border-bottom-width: 0;
}

.p-datatable .p-paginator-bottom {
  border-top-width: 0;
}

.p-datatable-rtl {
  direction: rtl;
}

.p-datatable-rtl.p-datatable thead th,
.p-datatable-rtl.p-datatable tfoot td {
  text-align: right;
}

/* Row Toggler */
.p-row-toggler {
  cursor: pointer;
}

/* Resizable */
.p-datatable .p-column-resizer {
  display: block;
  position: absolute !important;
  top: 0;
  right: 0;
  margin: 0;
  width: .5em;
  height: 100%;
  padding: 0px;
  cursor: col-resize;
  border: 1px solid transparent;
}

.p-datatable .p-column-resizer-helper {
  width: 1px;
  position: absolute;
  z-index: 10;
  display: none;
}

.p-datatable-resizable {
  padding-bottom: 1px; /*fix for webkit overlow*/
  overflow: auto;
}

.p-datatable-resizable thead th,
.p-datatable-resizable tbody td,
.p-datatable-resizable tfoot td {
  white-space: nowrap;
}

.p-datatable-resizable th.p-resizable-column {
  background-clip: padding-box;
  position: relative;
}

/** Reflow **/
.p-datatable-reflow .p-datatable-data td .p-column-title {
  display: none;
}

/* Filter */
.p-datatable .p-column-filter {
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin-top: .25em;
}

/* Editing */
.p-datatable .p-editable-column input {
  width: 100%;
  outline: 0;
}

.p-datatable .p-datatable-data > tr > td.p-editable-column {
  padding: .5em;
}

.p-datatable .p-editable-column > .p-cell-editor {
  display: none;
}

.p-datatable .p-datatable-data > tr > td.p-editable-column.p-cell-editing {
  padding: 1px;
}

.p-datatable .p-editable-column.p-cell-editing > .p-cell-editor {
  display: block;
}

.p-datatable .p-editable-column.p-cell-editing > .p-cell-data {
  display: none;
}

.p-datatable-stacked thead th,
.p-datatable-stacked tfoot td {
  display: none !important;
}

.p-datatable.p-datatable-stacked .p-datatable-data > tr > td {
  text-align: left;
  display: block;
  border: 0 none;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  clear: left;
}

.p-datatable.p-datatable-stacked .p-datatable-data.p-widget-content {
  border: 0 none;
}

.p-datatable-stacked .p-datatable-data tr.p-widget-content {
  border-left: 0 none;
  border-right: 0 none;
}

.p-datatable-stacked .p-datatable-data td .p-column-title {
  padding: 0.4em;
  min-width: 30%;
  display: inline-block;
  margin: -0.4em 1em -0.4em -0.4em;
  font-weight: bold;
}

.p-datatable .p-selection-column .p-checkbox,
.p-datatable .p-selection-column .p-radiobutton {
  margin: 0;
  display: block;
}

.p-datatable .p-selection-column .p-checkbox-box,
.p-datatable .p-selection-column .p-radiobutton-box {
  display: block;
  box-sizing: border-box;
  margin: 0;
}

.p-datatable-scrollable-wrapper {
  position: relative;
}

.p-datatable-frozen-view .p-datatable-scrollable-body {
  overflow: hidden;
}

.p-datatable-unfrozen-view {
  position: absolute;
  top: 0px;
}

.p-datatable .p-datatable-load-status {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.p-datatable .p-datatable-virtual-table {
  position: absolute;
  top: 0px;
  left: 0px;
}

.p-datatable .p-datatable-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=10)';
  opacity: 0.1;
  z-index: 1;
}

.p-datatable .p-datatable-loading-content {
  position: absolute;
  left: 50%;
  top: 25%;
  z-index: 2;
}

@media (max-width: 35em) {
  .p-datatable-reflow thead th,
  .p-datatable-reflow tfoot td {
    display: none !important;
  }

  .p-datatable-reflow .p-datatable-data > tr > td {
    text-align: left;
    display: block;
    border: 0 none;
    width: 100% !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    clear: left;
  }

  .p-datatable-reflow .p-datatable-data.p-widget-content {
    border: 0 none;
  }

  .p-datatable-reflow .p-datatable-data tr.p-widget-content {
    border-left: 0 none;
    border-right: 0 none;
  }

  .p-datatable-reflow .p-datatable-data td .p-column-title {
    padding: 0.4em;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4em;
    font-weight: bold;
  }

  .p-datatable-reflow.p-datatable-scrollable .p-datatable-scrollable-body colgroup {
    display: block;
  }
}
/* stylelint-enable */

* {
  font-family: var(--primary-font);
}


@mixin btnStyle($background, $color, $boxShadowColor, $borderColor: '') {
  &:not(:disabled) {
    background-color: $background;
    color: $color;
    line-height: 1;
    box-shadow: 0 2px 4px 0 $boxShadowColor;
    border: 2px solid $borderColor;

    &:focus {
      outline: none;
    }
  }
}

@mixin btnHoverStyle($hoverBackground, $hoverColor, $boxShadowColorHover, $borderColorHover: '') {
  &:not(:disabled):hover,
  &:enabled:hover {
    background-color: $hoverBackground;
    color: $hoverColor;
    box-shadow: 0 2px 4px 0 $boxShadowColorHover;
    border-color: $borderColorHover;

    span:not(.p-button-icon) {
      text-decoration: underline;
    }
  }

  &:disabled {
    border: 2px solid transparent;
    background: $cap-button-disabled-background;
    box-shadow: 0 2px 4px 0 $cap-button-disabled-background;
    color: $cap-button-disabled-text-color;
    cursor: default;
    line-height: 1;
  }
}

.p-button.cap-button {
  border-radius: 3px;
  font-weight: bold;
  padding: 0.2rem 20px;
  font-size: 1em;
}

.cap-button.primary {
  @include btnStyle(var(--cap-primary-color), $cap-button-label-color, var(--cap-primary-color-shadow), var(--cap-primary-color));
  @include btnHoverStyle(var(--cap-primary-color), $cap-button-label-color, var(--cap-primary-color-shadow), var(--cap-primary-color));
}

.cap-button.secondary {
  @include btnStyle($cap-button-secondary-background, var(--cap-primary-color), var(--cap-primary-color-shadow), var(--cap-primary-color));
  @include btnHoverStyle($cap-button-secondary-background, var(--cap-primary-color), var(--cap-primary-color-shadow), var(--cap-primary-color));
}

.cap-button.success {
  @include btnStyle($cap-button-success-background, $cap-button-success-text-color, var(--success-color-shadow), $cap-button-success-background);
  @include btnHoverStyle($cap-button-success-background, $cap-button-success-text-color, var(--success-color-shadow), $cap-button-success-background);
}

.cap-button.error {
  @include btnStyle($cap-button-error-background, $cap-button-error-text-color, var(--error-color-shadow), $cap-button-error-background);
  @include btnHoverStyle($cap-button-error-background, $cap-button-error-text-color, var(--error-color-shadow), $cap-button-error-background);
}

.cap-button.warning {
  @include btnStyle($cap-button-warning-background, $cap-button-warning-text-color, var(--warning-color-shadow), $cap-button-warning-background);
  @include btnHoverStyle($cap-button-warning-background, $cap-button-warning-text-color, var(--warning-color-shadow), $cap-button-warning-background);
}

.cap-button.tertiary {
  @include btnStyle($cap-button-tertiary-background, $cap-button-tertiary-text-color, var(--tertiary-color-shadow), $cap-button-tertiary-background);
  @include btnHoverStyle($cap-button-tertiary-background, $cap-button-tertiary-text-color, var(--tertiary-color-shadow), $cap-button-tertiary-background);
}

.cap-button.default {
  background-color: $cap-light-gray;
  border: none;
  color: var(--cap-primary-color);
  outline: none;

  span.p-button-label.p-clickable {
    padding: 0.25em 1em 0.25em 0;
  }
}

.cap-button.round {
  border-radius: 16px;
}

.cap-button.cap-button--loading {
  opacity: 0.8;
  cursor: wait;
  text-decoration: none;
}

body .p-button.p-button-label-icon-left .p-button-label,
body .p-button.p-button-label-icon-right .p-button-label,
.p-button .p-button-label {
  padding-top: 0.429em; /* stylelint-disable-line number-max-precision */
  padding-bottom: 0.429em; /* stylelint-disable-line number-max-precision */
}

.p-button-icon-left {
  margin-right: 1em;
}

.p-button-icon-right {
  margin-left: 1em;
}

button.cap-button, [type='button'], [type='reset'], [type='submit'] {
  -webkit-appearance: unset; /* stylelint-disable-line */
}

.form-element {
  label {
    outline: 0;
    font-weight: $cap-form-element-label-font-weight;
    color: $cap-form-element-label-color;
    display: flex;
    align-items: center;
  }
}

::ng-deep { /* stylelint-disable-line selector-pseudo-element-no-unknown */
  @include media-breakpoint-down(sm) {
    .form-element {
      line-height: $cap-form-element-line-height;
      display: $cap-form-element-table-display;
      width: $cap-form-element-table-width;

      > :first-child {
        width: $cap-form-element-table-first-child-width;
        display: $cap-form-element-table-first-child-display;
        vertical-align: $cap-form-element-table-first-child-vertical-align;
      }

      label {
        font-size: toRem($cap-form-element-table-label);
      }

      input, .p-inputgroup-addon {
        height: $cap-form-element-table-input-height;
      }
    }
  }

  @include media-breakpoint-between(sm, lg) {
    .form-element {
      label {
        font-size: toRem(13px);
      }

      input, .p-inputgroup-addon {
        height: 30px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .form-element {
      label {
        font-size: toRem(16px);
      }

      input, .p-inputgroup-addon {
        height: $cap-input-height;
        font-size: toRem(14px);
      }
    }
  }

  /* Firefox, Chrome, Opera */
  ::placeholder {
    color: $cap-forms-placeholder-text-color;
    font-size: toRem(14px);
  }

  /* Internet Explorer 10-11 */
  :-ms-input-placeholder { /* stylelint-disable-line */
    color: $cap-forms-placeholder-text-color;
    font-size: toRem(14px);
  }

  /* Microsoft Edge */
  ::-ms-input-placeholder { /* stylelint-disable-line */
    color: $cap-forms-placeholder-text-color;
    font-size: toRem(14px);
  }
}

body .p-inputgroup {
  outline: none;

  input.p-inputtext,
  input.p-inputtext:hover,
  .p-inputtext:enabled:hover:not(.p-state-error),
  .p-inputtext:enabled:focus:not(.p-state-error) {
    border-radius: $cap-input-text-border-radius;
    padding-left: $cap-input-text-padding-left;
    font-size: toRem($cap-input-text-font-size);
    box-sizing: border-box;
    font-family: $cap-input-text-font-family;
    color: $cap-input-text-color;
    border: $cap-input-text-border;
    height: $cap-input-text-height + 5px;
    display: $cap-input-text-display;
    flex-grow: $cap-input-text-flex-grow;

    &[readonly],
    &.readonly {
      border: none;
      background: transparent;
    }

    &[disabled],
    &.p-disabled,
    &:disabled {
      opacity: 0.5;
    }

    &.content-left {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.content-right {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.cap-rounded {
      border-radius: $cap-input-text-rounded-border-radius;
      border-color: $cap-input-text-rounded-border-color;
      height: $cap-input-height;
      width: $cap-input-text-rounded-width;
    }
  }

  .p-inputgroup-addon {
    border: 1px solid $cap-input-group-addon-border-color;
    max-height: $cap-input-group-addon-max-height;
    height: $cap-input-group-addon-height;
    white-space: $cap-input-group-addon-white-space;
    background-color: $cap-input-group-addon-background-color;
    color: $cap-input-group-addon-color;
  }
}

.p-float-label {
  &.input-float-label {
    .cap-input.p-inputtext {
      border: $floating-input-border;
      border-radius: 3px;
      color: $floating-placeholder-text-color;
      font-family: $cap-primary-font;

      &:focus {
        outline: none;
      }
    }

    /* Styles for label when static */
    label {
      &.cpb-float-label {
        color: #b7b7b7;
        font-size: 1rem;
        font-weight: 400;
        font-family: $cap-primary-font;
      }
    }

    /* Styles for label when floating */
    .p-inputtext:enabled:focus:not(.p-state-error) + label,
    .populated + label {
      font-size: 0.81rem;

      &.cpb-float-label {
        position: absolute;
        background-color: $white;
        padding-right: 6px;
        padding-left: 6px;
      }
    }

    .p-inputtext:enabled:focus:not(.p-state-error) + label {
      &.cpb-float-label {
        transform: translateY(11px);
      }
    }

    .populated + label {
      &.cpb-float-label {
        transform: translateY(-20px);
      }
    }
  }
}

cap-input.ng-invalid.ng-touched {
  .cap-input.p-inputtext,
  .p-inputtext:enabled:focus,
  .p-inputtext:enabled:hover {
    border: 1px solid $cap-danger;
  }

  .p-inputtext:enabled:focus + label {
    &.cpb-float-label,
    .p-float-label.input-float-label label.cpb-float-label {
      color: $cap-danger;
    }
  }

  .populated + label.cpb-float-label {
    color: $cap-danger;
  }
}

$padding-top: 10px;

.collapsable-card {
  padding: $cap-collapsable-card-padding;
}

.collapsable-card-title {
  font-size: toRem($cap-collapsable-card-title-font-size);
  font-weight: $cap-collapsable-card-title-font-weight;
}

.collapsable-card-header {
  display: flex;
  justify-content: $cap-collapsable-card-header-justify-content;
  border-bottom: $cap-collapsable-card-header-border-bottom;
}

.collapseable-card-link {
  color: $cap-collapsable-card-link-color;
  cursor: pointer;
  user-select: none;
  font-weight: $cap-collapsable-card-link-font-weight;

  &:hover {
    text-decoration: underline;
  }
}

.collapsable-card-content {
  padding-top: $cap-collapsable-card-content-padding-top;
}

.collapsable-card-divider {
  border-bottom: 1px solid $cap-collapsable-card-nav-divider-color;
}

.app-horizontal-nav-bar {
  width: $cap-horizontal-navbar-width;
}

nav.navbar {
  z-index: $cap-horizontal-navbar-z-index;
  box-shadow: $box-shadow;
  padding: $cap-horizontal-navbar-padding;
  background-color: $cap-horizontal-navbar-background-color;

  .navbar-nav {
    margin: $cap-horizontal-navbar-margin;
  }

  .navbar-collapse {
    align-items: initial;
    justify-content: unset;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      justify-content: center;
      overflow-x: hidden;
      padding: 0 2rem !important; /* stylelint-disable-line declaration-no-important */
      margin: 0;
    }
  }

  .ng-star-inserted a.nav-item {
    padding: 15px 0;
  }

  a.nav-item {
    font-size: toRem($cap-horizontal-navbar-nav-item-font-size);
    font-weight: $cap-horizontal-navbar-nav-item-font-weight;
    border-bottom: $cap-horizontal-navbar-nav-item-border-bottom;
    color: $cap-horizontal-navbar-nav-item-color;
    width: $cap-horizontal-navbar-nav-item-width;
    position: $cap-horizontal-navbar-nav-item-position;

    @include media-breakpoint-up(lg) {
      width: 175px;
    }

    &:hover {
      .dropdown-menu {
        display: block;
      }
    }

    .dropdown-menu {
      text-align: $cap-horizontal-navbar-nav-item-dropdown-menu-text-align;
      width: $cap-horizontal-navbar-nav-item-dropdown-menu-width;
      padding: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border: 0;
      box-shadow: $box-shadow;

      a {
        font-size: toRem($cap-horizontal-navbar-nav-item-dropdown-menu-text-font-size);
        font-weight: $cap-horizontal-navbar-nav-item-dropdown-menu-text-font-weight;
        padding: $cap-horizontal-navbar-nav-item-dropdown-menu-text-padding;
        border-bottom: $cap-horizontal-navbar-nav-item-dropdown-menu-text-border-bottom;

        &:last-child {
          border-bottom: 0;
        }

        &:hover {
          cursor: pointer;
          background-color: $cap-horizontal-navbar-nav-item-dropdown-menu-text-hover-background-color;
          color: $cap-horizontal-navbar-nav-item-dropdown-menu-text-hover-color;
        }

        i {
          display: initial;
          float: $cap-horizontal-navbar-nav-item-dropdown-menu-icon-float;
          color: inherit;
          margin-top: $cap-horizontal-navbar-nav-item-dropdown-menu-icon-margin-top;
        }
      }
    }

    div {
      text-align: center;
      border-right: 0.5px solid $border-color;
      border-left: 0.5px solid $border-color;
    }

    &.active,
    &.active-item {
      color: var(--cap-primary-color);
      border-bottom-color: var(--cap-primary-color);
      background-color: (var(--cap-primary-color), 0.03);

      .nav-item-overlay {
        display: block;
      }

      i {
        color: var(--cap-primary-color);
      }
    }
  }

  i {
    color: $cap-horizontal-navbar-icon-color;
    display: block;
    margin: 0 auto 5px;
    font-size: 1.3em;
  }

  .nav-item-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: $cap-horizontal-navbar-nav-item-overlay-background;
    z-index: $cap-horizontal-navbar-nav-item-overlay-z-index;
    opacity: $cap-horizontal-navbar-nav-item-overlay-opacity;
    display: none;
  }
}

.nav-bar-gradient {
  position: absolute;
  right: 0;
  width: $cap-horizontal-navbar-nav-bar-gradient-width;
  top: 0;
  pointer-events: none;
  z-index: 1;
  bottom: 0;
  background: $cap-horizontal-navbar-nav-item-overlay-background;

  @include media-breakpoint-up(md) {
    width: 150px;
  }
}

.nav-bar-gradient-left {
  @extend .nav-bar-gradient;
  left: 0;
  right: unset;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.75) 50%, rgba(255, 255, 255, 0) 100%);
}

.cap-form-field-label {
  font-weight: $cap-form-field-label-font-weight;
}

$dropdown-hover-color: #eaeaea;

body .p-autocomplete {
  width: 100%;

  button {
    background-color: $cap-autocomplete-button-background-color;
    color: $cap-autocomplete-button-color;
    height: 100%;
    vertical-align: baseline;
    border: $cap-autocomplete-button-border;
  }

  .p-autocomplete-input {
    height: $cap-autocomplete-input-height;
    width: 100%;
    border: $cap-autocomplete-input-border;
    padding: $cap-autocomplete-input-padding;
    padding-left: $cap-autocomplete-button-width;
  }

  .p-button.p-button-icon-only {
    width: $cap-autocomplete-button-width;
    height: 100%;
    position: absolute;
    right: 0;
  }

  #p-highlighted-option { /* stylelint-disable */
    background-color: $cap-autocomplete-highlighted-option-background-color;
    color: $cap-autocomplete-highlighted-option-color;
  }

  button:enabled:hover {
    background-color: $cap-autocomplete-button-hover-background-color;
    color: $cap-autocomplete-button-hover-color;
    border-color: $cap-autocomplete-button-border-color;
  }

  .prime-input-style:enabled:focus:not(.p-state-error),
  .prime-input-style:enabled:hover:not(.p-state-error) {
    border-color: $cap-autocomplete-input-error-border-color;
  }
}

.p-float-label {
  &.autocomplete-float-label {
    .p-autocomplete {
      .p-autocomplete-input {
        border: $floating-input-border;
        border-radius: 3px;
        color: $floating-placeholder-text-color;
        font-family: SourceSansPro-Regular, sans-serif;
        font-size: 1rem;

        &:focus {
          outline: none;
        }
      }

      .p-autocomplete-loader {
        margin-left: 6px;
      }

      .p-autocomplete-dropdown {
        border-left: none;
        border-right: none;
        margin-right: 10px;
        width: unset;
        padding: 0;

        .p-button-icon {
          color: $floating-placeholder-text-color;
          font-size: 0.88rem;
        }
      }
    }

    /* Styles for label when static */
    label {
      &.cpb-float-label {
        color: #b7b7b7;
        font-size: 1rem;
        font-weight: 400;
        font-family: SourceSansPro-Regular, sans-serif;
      }
    }

    /* Styles for label when floating */
    .p-inputwrapper-focus + label,
    .p-inputwrapper-filled + label {
      font-size: 0.81rem;

      &.cpb-float-label {
        position: absolute;
        background-color: $white;
        padding-right: 6px;
        padding-left: 6px;
        transform: translateY(11px);
      }
    }
  }
}

/* Styles for required label when floating */
cap-autocomplete.ng-invalid {
  .p-float-label {
    p-autocomplete.ng-invalid.ng-touched {
      .p-autocomplete .p-autocomplete-input {
        border: 1px solid $cap-danger;
      }

      .p-autocomplete-dropdown {
        border-bottom-color: $cap-danger;
        border-top-color: $cap-danger;
      }
    }

    &.autocomplete-float-label .p-inputwrapper-focus.ng-invalid.ng-touched + label {
      &.cpb-float-label {
        color: $cap-danger;
      }
    }
  }
}

.cap-mobile-tab-bar {
  display: $cap-mobile-tab-bar-display;
  justify-content: $cap-mobile-tab-bar-justify-content;
  margin-top: $cap-mobile-tab-bar-margin-top;
  box-shadow: $cap-mobile-tab-bar-box-shadow;
  padding: $cap-mobile-tab-bar-padding;
  background-color: $cap-mobile-tab-bar-background-color;

  .shape {
    font-size: toRem($cap-mobile-tab-bar-shape-font-size);
  }

  .menu-item {
    margin-bottom: $cap-mobile-tab-bar-menu-item-margin-bottom;

    font: {
      size: $cap-mobile-tab-bar-menu-item-font-size;
      weight: $cap-mobile-tab-bar-menu-item-weight;
    };
  }

  .active i,
  .active p {
    color: $cap-mobile-tab-bar-active-text-color;
  }

  .tab-rectangle {
    text-align: $cap-mobile-tab-bar-rectangle-text-align;
    word-break: $cap-mobile-tab-bar-word-break;
    max-width: $cap-mobile-tab-bar-max-width;
    cursor: pointer;
  }

  .link:focus {
    outline: 0;
  }
}

.prime-file-upload {
  padding: $cap-file-upload-padding;
  width: $cap-file-upload-width;
  height: $cap-file-upload-height;
  background-color: $cap-file-upload-background-color;
  box-shadow: $cap-file-upload-box-shadow;
  display: $cap-file-upload-display;
  flex-direction: $cap-file-upload-flex-direction;
  justify-content: $cap-file-upload-justify-content;
  color: $cap-file-upload-color;
  cursor: pointer;
  font-weight: $cap-file-upload-font-weight;

  &.dragover {
    border: $cap-file-upload-dragover-border;
  }

  i {
    opacity: $cap-file-upload-icon-opacity;
    font-size: toRem($cap-file-upload-icon-font-size);
    display: $cap-file-upload-icon-display;
    margin-bottom: $cap-file-upload-icon-margin-bottom;
  }
}

.cap-dropdown.p-dropdown,
.cap-multi-select.p-multiselect {
  .p-inputtext {
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &,
  &.p-dropdown:not(.p-disabled):hover,
  &.p-dropdown:not(.p-disabled).p-focus,
  &.p-multiselect:not(.p-disabled):hover,
  &.p-multiselect:not(.p-disabled).p-focus {
    background: $cap-dropdown-background;
    border: $cap-dropdown-border;
    transition: $cap-dropdown-transition;
    width: 100%;
    height: $cap-dropdown-height;

    .p-multiselect-label-container,
    .p-dropdown-label-container {
      height: $cap-dropdown-label-container-height;
    }

    .p-dropdown-label,
    .p-multiselect-label {
      padding-right: $cap-dropdown-multiselect-label-padding-right;
      height: $cap-dropdown-multiselect-label-height;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include setRightGutters(5px);

      .p-multiselect-token {
        background: var(--cap-primary-color);
        color: $cap-white;
        padding: 2px 5px;
        border-radius: 25px;

        .p-multiselect-token-label {
          margin-right: 5px;
        }
      }

      .selected-img {
        width: $cap-dropdown-multiselect-selected-img-width;
        vertical-align: $cap-dropdown-multiselect-selected-img-vertical-align;
      }

      .selected-icon {
        font-size: $cap-dropdown-multiselect-selected-icon-font-size;
        color: var(--cap-primary-color);
        vertical-align: $cap-dropdown-multiselect-selected-img-vertical-align;
      }

      .selected-label-icon {
        vertical-align: $cap-dropdown-multiselect-selected-label-vertical-align;
        margin-left: $cap-dropdown-multiselect-selected-label-margin-left;
        line-height: $cap-dropdown-multiselect-selected-line-height;
      }

      .selected-label {
        vertical-align: $cap-dropdown-multiselect-selected-label-vertical-align;
        margin-left: $cap-dropdown-multiselect-selected-label-margin-left;
      }
    }

    .p-dropdown-trigger,
    .p-multiselect-trigger {
      background-color: $cap-dropdown-multiselect-trigger-background-color;
      width: $cap-dropdown-multiselect-trigger-width;
      line-height: $cap-dropdown-multiselect-trigger-line-height;
      text-align: $cap-dropdown-multiselect-trigger-text-align;
      padding: 0;
      color: $cap-dropdown-multiselect-trigger-color;
      border-left: $cap-dropdown-multiselect-trigger-border-left;
    }

    .p-dropdown-clear-icon,
    .p-multiselect-clear-icon {
      color: $cap-dropdown-multiselect-clear-icon;
    }

    .p-dropdown-clearable .p-dropdown-label {
      padding-right: $cap-dropdown-multiselect-cap-dropdown-label-padding-right;
    }
  }

  .p-multiselect-filter-container {
    border-bottom: none;
  }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding: $cap-dropdown-multiselect-input-padding;
    border: none;
  }

  .p-multiselected-icon {
    color: $cap-dropdown-multiselect-icon-color;
    margin-right: $cap-dropdown-multiselect-icon-margin-right;
  }
}

body .p-dropdown-panel {
  .p-dropdown-items-wrapper {
    .p-dropdown-items {
      .p-dropdown-item {
        .cap-dropdown-item-with-icon {
          .item-icon {
            margin-right: .5rem;
            color: var(--cap-primary-color);
          }
        }
      }
    }
  }
}

body .p-multiselect .p-checkbox .p-checkbox-box,
body .p-multiselect .p-checkbox .p-checkbox-box:hover {
  border: $cap-dropdown-multiselect-checkbox-border;
  background-color: $cap-dropdown-multiselect-checkbox-background-color;
  width: $cap-dropdown-multiselect-checkbox-width;
  height: $cap-dropdown-multiselect-checkbox-height;
  text-align: $cap-dropdown-multiselect-checkbox-text-align;
  border-radius: $cap-dropdown-multiselect-checkbox-border-radius;
  transition: $cap-dropdown-multiselect-checkbox-border-transition;

  .selectable-wrapper {
    overflow: hidden;
  }
}

body {
  .cap-dropdown-panel.p-dropdown-panel,
  .cap-multi-select-panel.p-multiselect-panel {
    padding: $cap-dropdown-multiselect-panel-padding;
    border: $cap-dropdown-multiselect-panel-border;
    background-color: $cap-dropdown-multiselect-panel-background-color;
    box-shadow: $cap-dropdown-multiselect-panel-box-shadow;

    .p-dropdown-items {
      padding: $cap-dropdown-multiselect-panel-items-padding;

      .p-dropdown-item,
      .p-multiselect-item,
      .p-dropdown-item-group {
        margin: $cap-dropdown-multiselect-panel-item-group-margin;
        padding: $cap-dropdown-multiselect-panel-item-group-padding;
        border: $cap-dropdown-multiselect-panel-item-group-border;
        color: $cap-dropdown-multiselect-panel-item-group-color;
        background-color: $cap-dropdown-multiselect-panel-item-group-background-color;
        border-radius: $cap-dropdown-multiselect-panel-item-group-border-radius;
        border-top: $cap-dropdown-multiselect-panel-item-group-border-top;

        &.p-highlight {
          color: $cap-dropdown-multiselect-panel-item-highlight-color;
          background-color: $cap-dropdown-multiselect-panel-item-highlight-background-color;
        }

        &:not(.p-highlight):not(.p-disabled):hover {
          color: var(--primary-text);
          background-color: $input-border-color;
        }
      }

      .custom-item {
        position: relative;
        height: $cap-dropdown-multiselect-panel-custom-item-height;

        .custom-img {
          width: $cap-dropdown-multiselect-panel-custom-img-width;
          position: absolute;
          top: $cap-dropdown-multiselect-panel-custom-img-top;
          left: $cap-dropdown-multiselect-panel-custom-img-left;
          transform: $cap-dropdown-multiselect-panel-custom-img-transform;
        }

        .custom-label {
          font-size: toRem($cap-dropdown-multiselect-panel-custom-label-font-size);
          top: $cap-dropdown-multiselect-panel-custom-label-top;
          left: $cap-dropdown-multiselect-panel-custom-label-left;
          position: $cap-dropdown-multiselect-panel-custom-label-position;
          transform: $cap-dropdown-multiselect-panel-custom-label-transform;
        }
      }
    }

    .p-dropdown-filter-container,
    .p-multiselect-filter-container {
      border-bottom: $cap-dropdown-multiselect-panel-filter-container-border-bottom;
      color: $cap-dropdown-multiselect-panel-filter-container-color;
      background-color: $cap-dropdown-multiselect-panel-filter-container-background-color;
      margin: $cap-dropdown-multiselect-panel-filter-container-margin;
      padding: $cap-dropdown-multiselect-panel-filter-container-padding;

      .p-multiselect-filter,
      .p-dropdown-filter,
      input.p-inputtext {
        padding: $cap-dropdown-multiselect-panel-filter-container-input-padding;
        width: $cap-dropdown-multiselect-panel-filter-container-input-width;
        border: $cap-dropdown-multiselect-panel-filter-container-input-border;
      }

      .p-dropdown-filter-icon,
      .p-multiselect-filter-icon {
        position: $cap-dropdown-multiselect-panel-filter-container-icon-position;
        left: $cap-dropdown-multiselect-panel-filter-container-icon-left;
        top: $cap-dropdown-multiselect-panel-filter-container-icon-top;
        pointer-events: none;
        color: $cap-dropdown-multiselect-panel-filter-container-icon-color;
        transform: none;
      }
    }

    div.p-multiselect-filter-container {
      border-bottom: none;
    }
  }
}

.cap-rounded.cap-multi-select.p-multiselect,
.cap-rounded.cap-dropdown.p-dropdown,
.cap-rounded.cap-dropdown.p-dropdown:not(.p-disabled).p-focus,
.cap-rounded.cap-multi-select.p-multiselect:not(.p-disabled):hover,
.cap-rounded.cap-dropdown.p-dropdown:not(.p-disabled):hover {
  border-color: var(--cap-primary-color);
  border-radius: 20px;
  height: $cap-input-height;
  font-size: 1em;

  .p-dropdown-label,
  .p-multiselect-label {
    padding-right: 2em;
    height: calc(#{$cap-input-height} - 2px);
    border-radius: 20px;
    font-size: 1em;
  }

  .p-dropdown-trigger,
  .p-multiselect-trigger {
    border-radius: 20px;
    border: none;
    height: calc(#{$cap-input-height} - 6px);
    margin-top: 2px;
    color: #5f6062;
    font-size: 0.9em;
    margin-right: 2px;
  }
}

/* stylelint-disable selector-type-case */
p-dropdown.readonly .cap-dropdown.p-dropdown,
p-dropdown.readonly .cap-dropdown.p-dropdown.p-focus,
p-dropdown.readonly .cap-dropdown.p-dropdown:not(.p-disabled):hover,
p-multiSelect.readonly .cap-multi-select.p-multiselect,
p-multiSelect.readonly .cap-multi-select.p-multiselect.p-focus,
p-multiSelect.readonly .cap-multi-select.p-multiselect:not(.p-disabled):hover {
  border: none;
  outline: none;
  background: transparent;

  .p-dropdown-label,
  .p-multiselect-label {
    background: transparent;
    outline: none;
  }

  .p-dropdown-trigger,
  .p-multiselect-trigger {
    display: none;
  }
}

.p-float-label {
  &.dropdown-float-label,
  &.multi-select-float-label {
    .cap-dropdown.p-dropdown,
    .cap-multi-select.p-multiselect {
      border-radius: 3px;
      border: $floating-input-border;

      &,
      &.p-dropdown:not(.p-disabled):hover,
      &.p-dropdown:not(.p-disabled).p-focus,
      &.p-multiselect:not(.p-disabled):hover,
      &.p-multiselect:not(.p-disabled).p-focus {
        .p-dropdown-trigger,
        .p-multiselect-trigger {
          width: unset;
        }
      }
    }

    .p-dropdown-label,
    .p-multiselect-label-container > div {
      color: $floating-placeholder-text-color;
      font-family: $cap-primary-font;
      margin-left: 13px;
      padding-left: 0;

      &:focus {
        outline: none;
      }
    }

    .p-dropdown-trigger,
    .p-multiselect-trigger {
      margin-right: 10px;

      .p-dropdown-trigger-icon,
      .p-multiselect-trigger-icon {
        color: $floating-placeholder-text-color;
        font-size: 0.88rem;
      }
    }

    /* Styles for label when static */
    label {
      &.cpb-float-label {
        color: #b7b7b7;
        font-size: 1rem;
        font-weight: 400;
        font-family: $cap-primary-font;
      }
    }

    /* Styles for label when floating */
    .p-inputwrapper-focus + label,
    .p-inputwrapper-filled + label {
      font-size: 0.81rem;

      &.cpb-float-label {
        position: absolute;
        transform: translateY(11px);
        transition: all 0.3s;
        background-color: $white;
        padding-right: 6px;
        padding-left: 6px;
      }
    }
  }
}

/* Styles for required label when floating */
cap-dropdown.ng-invalid.ng-touched,
cap-multi-select.ng-invalid.ng-dirty {
  .p-float-label {
    .cap-dropdown.p-dropdown,
    .cap-multi-select.p-multiselect {
      border: 1px solid $cap-danger;
    }
  }

  .p-inputwrapper-focus + label {
    &.cpb-float-label {
      color: $cap-danger;
    }
  }
}

body {
  .p-checkbox .p-checkbox-box {
    border: 1px solid $cap-checkbox-border-color;
    background: $cap-white;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

    &.p-disabled {
      cursor: not-allowed !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  .p-checkbox .p-checkbox-box:not(.p-disabled):hover {
    border-color: $cap-checkbox-border-color-hover;
  }

  .p-checkbox .p-checkbox-box.p-highlight,
  .p-checkbox .p-checkbox-box.p-highlight.p-focus,
  .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover,
  .p-checkbox .p-checkbox-box.p-highlight.p-focus:not(.p-disabled):hover {
    background: $cap-checkbox-state-active-background;
    border-color: $cap-checkbox-state-active-border-color;

    .p-checkbox-icon {
      color: var(--cap-primary-color);
      font-family: 'Font Awesome 5 Pro';
      font-weight: $cap-checkbox-state-active-icon-font-weight;
      font-size: toRem($cap-checkbox-state-active-icon-font-size);

      &:before {
        content: '\f0c8';
        color: var(--cap-primary-color);
      }
    }
  }
}

.p-datepicker .p-timepicker {
  display: none;
}

.p-datepicker .p-datepicker-header {
  border-bottom: $cap-calendar-datepicker-border-bottom;

  .p-datepicker-title {
    font-family: $cap-calendar-datepicker-title-font-family;
    font-size: toRem($cap-calendar-datepicker-title-font-size);
    font-weight: $cap-calendar-datepicker-title-font-weight;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: $cap-calendar-datepicker-title-text-align;
    color: $cap-calendar-datepicker-title-color;

    .p-datepicker-month {
      margin-right: 0.5rem;
    }
  }

  .p-datepicker-prev,
  .p-datepicker-next {
    color: $cap-calendar-datepicker-prev-next-color;
  }
}

.p-fluid .p-calendar.p-calendar-w-btn input.p-inputtext {
  width: $cap-calendar-input-text-width;
  outline: none;
  height: $cap-calendar-input-text-height;
}

.p-calendar.p-calendar-w-btn {
  width: 100%;

  > .p-inputtext {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: $cap-calendar-input-border;
    border-right: none;
    height: $cap-calendar-input-height;
    width: $cap-calendar-input-width;
  }

  > .p-inputtext:enabled:hover:not(.p-state-error),
  > .p-inputtext:enabled:focus:not(.p-state-error) {
    border: $cap-calendar-input-error-border;
    border-right: $cap-calendar-input-error-border-right;
  }

  .p-datepicker-trigger.p-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: $cap-calendar-datepicker-trigger-button-border;
    border-left: none;
    box-shadow: none;
    background: $cap-calendar-datepicker-trigger-background;
    color: $cap-calendar-datepicker-trigger-color;
    width: $cap-calendar-datepicker-trigger-width;
    height: $cap-calendar-datepicker-trigger-height;

    span.p-button-icon-left {
      font-size: toRem($cap-calendar-datepicker-trigger-icon-left);
    }
  }

  div.p-datepicker.p-component.p-widget-content {
    td {
      text-align: $cap-calendar-datepicker-widget-content-text-align;
      padding: 0;

      .p-state-default {
        width: $cap-calendar-datepicker-widget-content-state-default-width;
        display: inline-block;
        border-radius: $cap-calendar-datepicker-widget-content-state-default-border-radius;

        &:not(.p-highlight) {
          background: initial;
          box-shadow: none;
        }
      }

      > a.p-highlight {
        background-color: $cap-calendar-datepicker-widget-content-state-active-background-color;
        color: $cap-calendar-datepicker-widget-content-state-active-color;
      }

      a:not(.p-highlight):hover,
      a:not(.p-highlight):focus {
        background-color: $cap-calendar-datepicker-widget-content-state-not-active-background-color;
        color: $cap-calendar-datepicker-widget-content-state-not-active-color;
      }
    }
  }
}

.cap-calendar.p-calendar.cap-rounded:hover,
.cap-calendar.p-calendar.cap-rounded {
  input, input:enabled:hover:not(.p-state-error) {
    border-color: $cap-calendar-input-hover-border-color;
    border-top-left-radius: $cap-calendar-input-hover-border-tl-radius;
    border-bottom-left-radius: $cap-calendar-input-hover-border-bl-radius;
    height: $cap-calendar-input-hover-border-height;
  }

  .p-datepicker-trigger.p-button {
    border-color: $cap-calendar-input-hover-trigger-border-color;
    border-top-right-radius: $cap-calendar-input-hover-trigger-border-tr-radius;
    border-bottom-right-radius: $cap-calendar-input-hover-trigger-border-br-radius;
    height: $cap-calendar-input-hover-trigger-height;
    font-size: toRem($cap-calendar-input-hover-trigger-font-size);
    padding-top: $cap-calendar-input-hover-trigger-padding-top;

    span.p-button-icon-left {
      font-size: toRem($cap-calendar-input-hover-trigger-button-icon-left-font-size);
    }
  }
}

.double-date {
  .cap-calendar.p-calendar.cap-rounded:hover,
  .cap-calendar.p-calendar.cap-rounded {
    width: $cap-calendar-double-date-width;
  }
}

.cap-calendar.cap-rounded.p-calendar.p-calendar-w-btn,
.cap-calendar.cap-rounded.p-calendar.p-calendar-w-btn:hover {
  border: $cap-calendar-w-btn-hover-border;
  border-radius: $cap-calendar-w-btn-hover-border-radius;
  background-color: $cap-calendar-w-btn-hover-background-color;
  height: $cap-calendar-w-btn-hover-height;

  .p-datepicker-trigger.p-button {
    position: absolute;
    background-color: $cap-calendar-w-btn-hover-datepicker-background-color;
    color: $cap-calendar-w-btn-hover-datepicker-color;
    left: $cap-calendar-w-btn-hover-datepicker-left;
    border: none;
    border-radius: $cap-calendar-w-btn-hover-datepicker-border-radius;
    opacity: $cap-calendar-w-btn-hover-datepicker-opacity;
    height: 100%;
    width: $cap-calendar-w-btn-hover-datepicker-width;
  }

  input:enabled:hover:not(.p-state-error),
  input:enabled:focus:not(.p-state-error),
  input {
    position: absolute;
    left: $cap-calendar-w-btn-hover-input-left;
    width: $cap-calendar-w-btn-hover-input-width;
    border: none;
    border-radius: $cap-calendar-w-btn-hover-input-border-radius;
    height: 100%;
  }
}

.p-datepicker table td > span.p-highlight {
  background: var(--cap-primary-color);
}

.p-float-label {
  &.calendar-float-label {
    .p-calendar.p-calendar-w-btn > .p-inputtext {
      border: $floating-input-border;
      border-radius: 3px;
      color: $floating-placeholder-text-color;
      font-family: $cap-primary-font;

      &:focus {
        outline: none;
      }
    }

    /* Styles for label when static */
    label {
      &.cpb-float-label {
        color: #b7b7b7;
        font-size: 1rem;
        font-weight: 400;
        font-family: $cap-primary-font;
      }
    }

    /* Styles for label when floating */
    .p-inputwrapper-focus + label,
    .populated + label {
      font-size: 0.81rem;

      &.cpb-float-label {
        position: absolute;
        background-color: $white;
        padding-right: 6px;
        padding-left: 6px;
        transform: translateY(11px);
      }
    }
  }
}

cap-calendar.ng-invalid.ng-dirty {
  .p-calendar.p-calendar-w-btn > .p-inputtext,
  .p-calendar.p-calendar-w-btn > .p-inputtext:enabled:focus:not(.p-state-error),
  .p-calendar.p-calendar-w-btn > .p-inputtext:enabled:hover {
    border: 1px solid $cap-danger;
  }

  .p-inputwrapper-focus + label {
    &.cpb-float-label {
      color: $cap-danger;
    }
  }
}

body .cap-chips.p-chips {
  ul.p-inputtext,
  ul.p-inputtext:not(.p-disabled):hover,
  ul.p-inputtext:not(.p-disabled).p-focus {
    width: $cap-chips-width-hover;
    border-color: $cap-chips-border-color-hover;
    padding: $cap-chips-border-padding-hover;
  }

  ul.p-inputtext {
    &.p-chips-multiple-container {
      border-radius: 3px;
    }

    .p-chips-token {
      border-radius: 16px;
      border: 1px solid $cap-chips-token-border-color;
      padding: $cap-chips-token-padding;
      background-color: $cap-chips-token-background-color;
      color: $cap-chips-token-color;

      .p-chips-token-icon {
        font-family: 'Font Awesome 5 Pro';
        font-size: 1rem;
        margin-left: 0.5rem;

        &::before {
          content: '\f00d';
          font-weight: 300;
        }
      }
    }
  }
}

.timeline__cards {
  &.timeline__divider {
    padding-left: $cap-timeline-cards-divider-padding-left;
    position: $cap-timeline-cards-divider-position;

    &:before {
      content: '';
      position: $cap-timeline-cards-divider-before-position;
      top: $cap-timeline-cards-divider-before-top;
      left: $cap-timeline-cards-divider-before-left;
      width: $cap-timeline-cards-divider-before-width;
      height: $cap-timeline-cards-divider-before-height;
      border-left: $cap-timeline-cards-divider-before-border-left;
    }
  }
}

.timeline__card {
  margin-bottom: $cap-timeline-card-margin-bottom;

  &:last-of-type {
    margin-bottom: $cap-timeline-card-last-of-type-margin-bottom;
  }
}

.timeline__content {
  padding: $cap-timeline-content-padding;
  border-radius: $cap-timeline-content-border-radius;
  box-shadow: $cap-timeline-content-box-shadow;
  background-color: $cap-timeline-content-background-color;
}

.timeline__created {
  color: $cap-timeline-created-color;
  font-size: toRem($cap-timeline-created-font-size);
}

.timeline__title {
  font-weight: $cap-timeline-title-font-weight;
  color: $cap-timeline-title-color;
  font-size: toRem($cap-timeline-title-font-size);
}

.timeline__comment {
  span {
    font-size: toRem($cap-timeline-comment-font-size);
    color: $cap-timeline-comment-color;
  }

  a {
    color: $cap-timeline-comment-link-color;
  }
}

.cap-textarea {
  width: $cap-textarea-width;
  resize: $cap-textarea-resize;
  border-color: $cap-textarea-border-color;
  outline: none;
  border-radius: $cap-textarea-border-radius;
  height: $cap-textarea-border-height;
  padding: $cap-textarea-padding;
  font-size: toRem($cap-textarea-font-size);
  color: $cap-textarea-font-color;

  &[readonly] {
    background: transparent;
    border: none;
    resize: none;
  }
}

.p-float-label {
  &.textarea-float-label {
    .cap-textarea {
      border: $floating-input-border;
      border-radius: 3px;
      color: $floating-placeholder-text-color;
      font-family: $cap-primary-font;

      &:focus {
        outline: none;
      }
    }

    /* Styles for label when static */
    label {
      &.cpb-float-label {
        color: #b7b7b7;
        font-size: 1rem;
        font-weight: 400;
        font-family: $cap-primary-font;
      }
    }

    /* Styles for label when floating */
    .cap-textarea:enabled:focus:not(.p-state-error) + label,
    .populated + label {
      font-size: 0.81rem;

      &.cpb-float-label {
        position: absolute;
        background-color: $white;
        padding-right: 6px;
        padding-left: 6px;
      }
    }

    .cap-textarea:enabled:focus:not(.p-state-error) + label {
      &.cpb-float-label {
        transform: translateY(11px);
      }
    }

    .populated + label {
      &.cpb-float-label {
        transform: translateY(-17px);
      }
    }
  }
}

/* Styles for required label when floating */
cap-textarea.ng-invalid.ng-touched {
  .p-float-label {
    textarea {
      border: 1px solid $cap-danger;
    }

    &.textarea-float-label .cap-textarea:enabled:focus + label {
      &.cpb-float-label {
        color: $cap-danger;
      }
    }
  }
}

.pdf {
  background-color: $cap-pdf-viewer-background-color;
  box-shadow: $box-shadow;
  width: $cap-pdf-viewer-width;
  position: $cap-pdf-viewer-position;
}

.pdf-toolbar {
  padding: $cap-pdf-viewer-toolbar-padding;
  border-bottom: $border-width solid $border-color;

  cap-button button.cap-button {
    height: $cap-pdf-viewer-button-size;
    width: $cap-pdf-viewer-button-size;
    box-shadow: none;

    .p-clickable {
      margin-left: -0.5em;
    }
  }
}

pdf-viewer {
  max-height: $cap-pdf-viewer-max-height;
  display: $cap-pdf-viewer-display;
}

.info-row {
  margin: $cap-pdf-viewer-info-row-margin;
  width: $cap-pdf-viewer-info-row-width;
  text-align: $cap-pdf-viewer-info-row-text-align;
  display: $cap-pdf-viewer-info-row-display;
  flex-direction: $cap-pdf-viewer-info-row-flex-direction;
  justify-content: $cap-pdf-viewer-info-row-flex-justify-content;
}

.pdf:hover .page-controls {
  visibility: $cap-pdf-viewer-page-controls-hover-visibility;
}

.page-controls {
  display: $cap-pdf-viewer-page-controls-display;
  visibility: $cap-pdf-viewer-page-controls-visibility;
  width: $cap-pdf-viewer-page-controls-width;
  justify-content: $cap-pdf-viewer-page-controls-justify-content;
  opacity: $cap-pdf-viewer-page-controls-opacity;
  position: $cap-pdf-viewer-page-controls-position;
  top: $cap-pdf-viewer-page-controls-top;

  .page-nav {
    width: $cap-pdf-viewer-page-controls-nav-width;
    height: $cap-pdf-viewer-page-controls-nav-height;
    background-color: $cap-pdf-viewer-page-controls-nav-background-color;
    cursor: pointer;
    transition: $cap-pdf-viewer-page-controls-nav-transition;

    .fas {
      position: $cap-pdf-viewer-page-controls-nav-icon-position;
      top: $cap-pdf-viewer-page-controls-nav-icon-top;
      transform: translateY(-50%);
      color: $cap-pdf-viewer-page-controls-nav-icon-color;
    }
  }

  @mixin PreviousNextPage($Value) {
    border-radius: 100% / 50%;
    border-bottom-#{$Value}-radius: 0;
    border-top-#{$Value}-radius: 0;
    #{$Value}: $cap-pdf-viewer-toolbar-icon-right;

    .fas {
      #{$Value}: $cap-pdf-viewer-toolbar-icon-right;
    }
  }

  .previous-page {
    @include PreviousNextPage(left);
  }

  .next-page {
    @include PreviousNextPage(right);
  }
}

.pdf-toolbar.row {
  margin: $cap-pdf-viewer-toolbar-margin;

  .info-column {
    float: $cap-pdf-viewer-toolbar-info-column-float;
    padding: $cap-pdf-viewer-toolbar-info-column-padding;
    height: $cap-pdf-viewer-toolbar-info-column-height;
    justify-content: $cap-pdf-viewer-toolbar-info-column-justify-content;
    display: $cap-pdf-viewer-toolbar-info-column-display;
    flex-direction: $cap-pdf-viewer-toolbar-info-column-flex-direction;
    border-radius: $cap-pdf-viewer-toolbar-info-column-border-radius;
  }

  .cap-button {
    padding: 0;
  }
}

ngx-extended-pdf-viewer {
  #findbar {
    top: 62px !important; /* stylelint-disable-line declaration-no-important */
    left: unset !important; /* stylelint-disable-line declaration-no-important */
    right: 0;
    max-width: 250px;
    margin: 1.5rem 2.5rem;
    padding: 1rem;
    border: 1px solid $border-color;

    &:before,
    &:after {
      display: none;
    }
  }
}

.prime-notification-bar {
  background-color: $cap-notification-bar-background-color;
  box-shadow: $box-shadow;
  height: $cap-notification-bar-height;
  padding: $cap-notification-bar-padding;

  @include media-breakpoint-down(xs) {
    padding: 0;
    width: 100vw;
  }

  .head {
    display: $cap-notification-bar-head-display;
    justify-content: $cap-notification-bar-head-justify-content;
    align-items: $cap-notification-bar-head-align-items;
    margin: $cap-notification-bar-head-margin;

    @include media-breakpoint-down(xs) {
      margin: 0;
      box-shadow: $box-shadow;
    }

    .fas.fa-angle-left {
      color: $cap-notification-bar-icon-angle-left-color;
      font-size: toRem($cap-notification-bar-icon-angle-font-size);
    }

    .times {
      color: $cap-notification-bar-times-color;
      cursor: pointer;
      text-align: $cap-notification-bar-times-text-align;
      font-size: toRem($cap-notification-bar-times-font-size);
    }
  }

  .bell {
    width: $cap-notification-bar-bell-width;
    height: $cap-notification-bar-bell-height;
    border-radius: $cap-notification-bar-bell-border-radius;
    box-shadow: $cap-notification-bar-bell-box-shadow;
    background-color: $cap-notification-bar-bell-background-color;
    text-align: $cap-notification-bar-bell-center;
    position: $cap-notification-bar-bell-position;
    cursor: pointer;

    i {
      color: $cap-notification-bar-bell-color;
      padding-top: $cap-notification-bar-bell-padding-top;
    }
  }

  .mobile-icon-group {
    @include media-breakpoint-down(xs) {
      display: flex;
      width: 100%;
      margin: 2rem 0 1rem 2rem;
    }
  }

  .bottom-bar {
    z-index: $cap-notification-bottom-bar-z-index;
    box-shadow: $cap-notification-bottom-bar-box-shadow;
  }

  .notification-circle {
    @include notification-oval($cap-notification-bar-circle-size, $cap-notification-bar-circle-size, white, var(--cap-primary-color), 1px solid var(--cap-primary-color));
    text-align: $cap-notification-circle-text-align;
    border-radius: $cap-notification-circle-border-radius;
    line-height: $cap-notification-circle-line-height;
    position: $cap-notification-bar-circle-position;

    // Center circle in the top right corner of the element with the bell class independent of the size of the bell and circle.
    top: - math.div($cap-notification-bar-circle-size, 2);
    left: $cap-notification-bar-square-size - math.div($cap-notification-bar-square-size, 2);
    bottom: 0;
    right: 0;

    font: {
      size: $cap-notification-circle-font-size;
      weight: $cap-notification-circle-font-weight;
    }
  }

  .notification-bar-text {
    font-weight: $cap-notification-bar-text-font-weight;
    margin-bottom: $cap-notification-bar-text-margin-bottom;
  }

  ul {
    padding: 0;

    @include media-breakpoint-down(xs) {
      padding: 0 1.5rem;
    }
  }

  strong {
    color: $cap-notification-bar-text-strong-color;
  }

  .scroll {
    overflow-y: $cap-notification-bar-text-scroll;
  }

  .notification-item {
    list-style: $cap-notification-item-list-style;
    margin: $cap-notification-item-margin;
  }

  .trash-icon {
    width: $cap-notification-trash-icon-width;
    height: $cap-notification-trash-icon-height;
    line-height: $cap-notification-trash-icon-line-height;
    cursor: $cap-notification-trash-icon-cursor;
    color: $cap-notification-trash-icon-color;
    border: $cap-notification-trash-border;
    text-align: $cap-notification-trash-text-align;
    border-radius: $cap-notification-trash-border-radius;
    margin: $cap-notification-trash-margin;
  }

  .notification-item-card {
    display: $cap-notification-bar-item-card-display;
    align-items: $cap-notification-bar-item-card-align-items;
    padding: $cap-notification-bar-item-card-padding;
    margin-top: $cap-notification-bar-item-card-margin-top;
    background-color: $cap-notification-bar-item-card-background-color;
    border-radius: $cap-notification-bar-item-card-border-radius;

    .status-icon {
      text-align: $cap-notification-bar-item-card-status-icon-text-align;
      font-size: toRem($cap-notification-bar-item-card-status-icon-font-size);
      margin-right: $cap-notification-bar-item-card-status-icon-margin-right;
      line-height: $cap-notification-bar-item-card-status-icon-line-height;

      i {
        color: $cap-notification-bar-item-card-status-icon-color;
      }

      .notification-oval {
        @include notification-oval($cap-notification-bar-icon-size, $cap-notification-bar-icon-size, lightgray, null, double 5px white);
      }
    }

    .notification {
      word-break: $cap-notification-bar-item-card-word-break;
      line-height: $cap-notification-bar-item-card-line-height;
    }

    .title {
      font-weight: $cap-notification-bar-item-card-title-font-weight;
      font-size: toRem($cap-notification-bar-item-card-title-font-size);
      margin-bottom: $cap-notification-bar-item-card-title-margin-bottom;
      color: $cap-notification-bar-item-card-title-color;
    }

    .sub-title {
      font-weight: $cap-notification-bar-item-card-subtitle-font-weight;
      font-size: toRem($cap-notification-bar-item-card-subtitle-font-size);
      margin-bottom: $cap-notification-bar-item-card-subtitle-margin-bottom;
      margin-top: $cap-notification-bar-item-card-subtitle-margin-top;
      color: $cap-notification-bar-item-card-subtitle-color;
    }

    .toggle-notification-body {
      cursor: pointer;
    }

    .body {
      margin-bottom: $cap-notification-bar-item-card-body-margin-bottom;
      padding: $cap-notification-bar-item-card-body-padding;
      line-height: $cap-notification-bar-item-card-body-line-height;
      font-size: toRem($cap-notification-bar-item-card-body-font-size);
      color: $cap-notification-bar-item-card-body-color;
    }
  }

  .notification-unread {
    border: $cap-notification-unread-border;
  }

  .notification-read {
    border: solid 1px #ddd;
  }
}

.notification-tabs {
  padding-left: $cap-notification-tabs-padding-left;
  padding-right: $cap-notification-tabs-padding-right;

  > .col {
    padding-left: $cap-notification-tabs-col-padding-left;
    padding-right: $cap-notification-tabs-col-padding-right;
  }

  .notification-tabs__tab {
    border: $cap-notification-tabs-tab-border;
    padding: $cap-notification-tabs-tab-padding;
    background-color: $cap-notification-tabs-background-color;
    border-radius: $cap-notification-tabs-border-radius;
    text-align: $cap-notification-tabs-text-align;
    font-size: toRem($cap-notification-tabs-font-size);
    font-weight: $cap-notification-tabs-font-weight;
    cursor: pointer;
    transition: $cap-notification-tabs-transition;
    white-space: $cap-notification-tabs-white-space;
    margin-bottom: $cap-notification-tabs-margin-bottom;

    &:hover, &.notification-tabs__tab-selected {
      background-color: $cap-notification-tabs-tab-selected-background-color;
      color: $cap-notification-tabs-tab-selected-color;
    }
  }
}

.cap-mobile-tab-bar {
  display: $cap-mobile-tab-bar-display;
  justify-content: $cap-mobile-tab-bar-justify-content;
  margin-top: $cap-mobile-tab-bar-margin-top;
  box-shadow: $cap-mobile-tab-bar-box-shadow;
  padding: $cap-mobile-tab-bar-padding;
  background-color: $cap-mobile-tab-bar-background-color;

  .shape {
    font-size: toRem($cap-mobile-tab-bar-shape-font-size);
  }

  .menu-item {
    margin-bottom: $cap-mobile-tab-bar-menu-item-margin-bottom;

    font: {
      size: $cap-mobile-tab-bar-menu-item-font-size;
      weight: $cap-mobile-tab-bar-menu-item-weight;
    };
  }

  .active i,
  .active p {
    color: $cap-mobile-tab-bar-active-text-color;
  }

  .tab-rectangle {
    text-align: $cap-mobile-tab-bar-rectangle-text-align;
    word-break: $cap-mobile-tab-bar-word-break;
    max-width: $cap-mobile-tab-bar-max-width;
    cursor: pointer;
  }

  .link:focus {
    outline: 0;
  }
}

.prime-file-upload {
  padding: $cap-file-upload-padding;
  width: $cap-file-upload-width;
  height: $cap-file-upload-height;
  background-color: $cap-file-upload-background-color;
  box-shadow: $cap-file-upload-box-shadow;
  display: $cap-file-upload-display;
  flex-direction: $cap-file-upload-flex-direction;
  justify-content: $cap-file-upload-justify-content;
  color: $cap-file-upload-color;
  cursor: pointer;
  font-weight: $cap-file-upload-font-weight;

  &.dragover {
    border: $cap-file-upload-dragover-border;
  }

  i {
    opacity: $cap-file-upload-icon-opacity;
    font-size: toRem($cap-file-upload-icon-font-size);
    display: $cap-file-upload-icon-display;
    margin-bottom: $cap-file-upload-icon-margin-bottom;
  }
}

body .cap-select-button.p-selectbutton {
  border-color: $cap-select-button-border-color;

  .p-button:not(.p-disabled):not(.p-highlight):hover,
  .p-button {
    background-color: $cap-select-button-background-color;
    border-color: $cap-select-button-border-color;
    color: $cap-select-button-color;
    font-weight: $cap-select-button-font-weight;
    padding: $cap-select-button-padding;
    font-size: toRem($cap-select-button-font-size);

    &:not(.p-disabled):not(.p-highlight):hover {
      text-decoration: $cap-select-button-hover-text-decoration;
    }

    &.p-highlight {
      background-color: $cap-select-button-active-background-color;
      color: $cap-select-button-active-color;
    }
  }
}

.colorpicker-input {
  display: $cap-colorpicker-input-display;

  .p-inputtext.colorpicker-input-border {
    border-left: $cap-colorpicker-input-border-left;
  }
}

.colorpicker-with-input {
  border-right: $cap-colorpicker-with-input-border-right;
}

.p-colorpicker-preview {
  width: $cap-colorpicker-preview-width;
  height: $cap-colorpicker-preview-height;
}

.cap-link {
  color: $cap-link-color;
  font-size: toRem($cap-link-font-size);
  font-weight: $cap-link-font-weight;
}

.week-switcher {
  border: $cap-week-switcher-border;
  border-radius: $cap-week-switcher-border-radius;
  display: $cap-week-switcher-display;
}

.week-switcher-content {
  display: $cap-week-switcher-content-display;
  color: $cap-week-switcher-content-color;
  font-size: toRem($cap-week-switcher-content-font-size);
  font-weight: $cap-week-switcher-content-font-weight;
}

.week-switcher-button-divider {
  padding: $cap-week-switcher-button-divider-padding;
  width: $cap-week-switcher-button-divider-width;
  text-align: $cap-week-switcher-button-divider-text-align;
  border-right: $cap-week-switcher-button-divider-border-right;
  border-left: $cap-week-switcher-button-divider-border-left;
}

.week-switcher-button.cap-button.secondary:not(:disabled) {
  border: none;
  box-shadow: none;
  padding: $cap-week-switcher-button-not-disabled-padding;
}

.tox-statusbar__branding {
  display: none;
}

.cap-editor {
  .tox {
    border-radius: $cap-editor-tox-border-radius;
    border-color: $cap-editor-tox-border-color;

    .tox-edit-area {
      border-top: $cap-editor-tox-edit-area-border-top;
    }

    .tox-toolbar__primary {
      padding: $cap-editor-tox-toolbar-padding;
      border: $cap-editor-tox-toolbar-border;
      background: $cap-editor-tox-toolbar-background;
      color: $cap-editor-tox-toolbar-color;

      .tox-tbtn {
        margin: $cap-editor-tox-toolbar-btn-margin;

        &:hover {
          background: $cap-editor-tox-toolbar-btn-hover-background;
        }

        &.tox-tbtn--select {
          background: $cap-editor-tox-toolbar-btn-select-background;
        }
      }

      svg {
        fill: $cap-editor-svg-fill;
      }

      .tox-tbtn--enabled:hover,
      .tox-tbtn--enabled {
        background: var(--cap-primary-color);

        svg {
          fill: #fff;
        }
      }
    }
  }

  .tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) { /* stylelint-disable */
    border-width: 2px;
  }
}

.tox-notification.tox-notification--in, .tox-notifications-container {
  display: none;
}

cap-card {
  .card {
    border-radius: $cap-card-border-radius;
    box-shadow: $cap-card-box-shadow;
    border: $cap-card-border;
    background-color: $cap-card-background-color;
    padding: $cap-card-padding;

    .card__header {
      border-bottom: $cap-card-header-border-bottom;
      padding-bottom: $cap-card-header-padding-bottom;

      &.header--empty {
        display: none;
      }

      .header__title {
        margin: auto;

        h2 {
          font-size: toRem($cap-card-header-font-size);
          font-weight: $cap-card-header-font-weight;
          margin-bottom: 0;
        }
      }

      .header__cta {
        text-align: $cap-card-header-title-rs-text-align;

        i.settings {
          margin-left: $cap-card-header-settings-margin-left;
          color: $cap-card-header-settings-color;
          width: $cap-card-header-settings-width;
          height: $cap-card-header-settings-height;
          line-height: $cap-card-header-settings-line-height;
          text-align: $cap-card-header-settings-text-align;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .card__content {
      &.content--gutter {
        margin-top: $cap-card-content-gutter-margin-top;
      }
    }
  }
}

cap-accordion .p-accordion {
  .tab {
    .p-accordion-header {
      margin-bottom: 0;

      &:not(.p-disabled):not(.p-highlight):hover a {
        background-color: $cap-accordion-header-not-active-background-color;
        border: none;
        color: $cap-accordion-header-not-active-color;

        .p-accordion-toggle-icon {
          color: $cap-accordion-header-not-active-toggle-icon-color;
        }
      }

      &:not(.p-disabled).p-highlight:hover a,
      &:not(.p-disabled).p-highlight a {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        box-shadow: $cap-accordion-header-not-active-hover-bow-shadow;
        border: none;
        background-color: $cap-accordion-header-not-active-background-color;
        color: $cap-accordion-header-not-active-color;

        .p-accordion-toggle-icon {
          color: $cap-accordion-header-not-active-color-toggle-icon;
        }
      }

      a {
        color: $cap-accordion-header-color;
        font-size: toRem($cap-accordion-header-font-size);
        font-weight: $cap-accordion-header-font-weight;
        text-decoration: none;
        height: $cap-accordion-header-height;
        border-radius: $cap-accordion-header-border-radius;
        box-shadow: $cap-accordion-header-bow-shadow;
        background-color: $cap-accordion-header-background-color;
        border: none;
        padding: $cap-accordion-header-padding;
        line-height: $cap-accordion-header-line-height;
        transition: $cap-accordion-header-transition;
        outline: none;

        br {
          display: none;
        }

        .p-accordion-toggle-icon {
          color: $cap-accordion-icon-color;
        }
      }
    }

    .p-accordion-content {
      border-bottom-right-radius: $cap-accordion-content-border-br-radius;
      border-bottom-left-radius: $cap-accordion-content-border-bl-radius;
      padding: $cap-accordion-content-padding;
      border: none;
      background-color: $cap-accordion-content-background-color;
      color: $cap-accordion-content-color;

      &-wrapper {
        border-bottom-right-radius: $cap-accordion-content-wrapper-border-br-radius;
        border-bottom-left-radius: $cap-accordion-content-wrapper-border-bl-radius;
        box-shadow: $cap-accordion-content-wrapper-box-shadow;
      }
    }
  }
}

p-header {
  .accordion-tab-header {
    font-size: toRem($cap-accordion-tab-header-font-size);
  }

  span {
    font-size: toRem($cap-accordion-header-title-font-size);
  }
}

.warning-color {
  color: var(--warning-color);
}

.confirm-color {
  color: var(--confirm-color);
}

.error-color {
  color: var(--error-color);
}

.accordion-tab-header-block {
  display: inline-block;
  width: $cap-accordion-tab-header-block-width;
}

@include media-breakpoint-down(xs) {
  .accordion-tab-header-block-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@include media-breakpoint-down(sm) {
  .accordion-tab-header-block {
    vertical-align: middle;
  }

  .accordion-tab-header-block-inner {
    line-height: normal;
  }

  p-header span {
    display: block;
    font-size: toRem($cap-accordion-header-font-size);

    &.status {
      font-size: toRem($cap-accordion-header-status-font-size);
    }
  }

  .p-accordion .p-accordion-header a .p-accordion-toggle-icon {
    float: $cap-accordion-toggle-icon-float;
    transform: $cap-accordion-toggle-icon-transform;
  }
}

.menu-action {
  position: relative;
  text-align: right;
  color: var(--cap-primary-color);
  display: inline-block;

  &__button-icon {
    background-color: var(--cap-primary-color);
    display: flex !important; /* stylelint-disable-line declaration-no-important */
    color: $white;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 170, 226, 0.25);
    border: solid 1px var(--cap-primary-color);
    text-align: center;
    cursor: pointer;
    @include proportions(40px);
    @include flexCentering();
  }

  &__items {
    padding: 0;
    list-style: none;
    box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.15);
    background-color: $white;
    position: absolute;
    text-align: left;
    margin: 15px 0 0;
    z-index: 1;
    border-radius: 3px;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    cursor: pointer;

    @supports not (width: max-content) {
      width: 270px;
    }

    @supports (width: max-content) {
      width: max-content;
    }

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 15px solid $white;
      position: absolute;
      right: 10px;
      top: -10px;
      z-index: -1;
    }

    &--open {
      visibility: visible;
      height: auto;
      overflow: visible;
    }

    &.left-bottom {
      right: 0;

      &:before {
        right: 10px;
      }
    }

    &.right-bottom {
      right: auto;
      left: 0;

      &:before {
        right: auto;
        left: 10px;
      }
    }

    &.left-top {
      bottom: 60px;
      right: 0;

      &:before {
        bottom: -10px;
        left: auto;
        right: 10px;
        top: auto;
        border-bottom: none;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 15px solid $cap-white;
      }
    }

    &.right-top {
      bottom: 60px;
      left: 0;

      &:before {
        bottom: -10px;
        left: 10px;
        top: auto;
        border-bottom: none;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 15px solid $cap-white;
      }
    }

    li {
      display: flex;
      cursor: pointer;
      padding: 14px 16px;
      border-bottom: 1px solid $cap-gray;

      &.disabled {
        cursor: not-allowed;

        p.title,
        i {
          color: $cap-action-menu-disabled-text-color;
        }
      }

      &:hover {
        background-color: $cap-platinum;
      }

      &:last-child {
        border: none;
      }
    }
  }

  &__icon {
    font-size: toRem(16px);
    margin: auto 16px auto 0;
    width: 20px;

    i {
      margin: 0;
    }
  }

  &__info {
    margin: auto 0;

    p {
      margin: 0;
    }

    .title {
      color: $primary;
      font-size: toRem(16px);
      font-weight: bold;
    }
  }
}

.mat-stepper-horizontal {
  background-color: $cap-desktop-stepper-horizontal-background-color;
}

.mat-horizontal-stepper-header-container {
  white-space: $cap-desktop-stepper-horizontal-header-container-white-space;
  display: flex;
  align-items: $cap-desktop-stepper-horizontal-header-container-align-items;
  justify-content: $cap-desktop-stepper-horizontal-header-container-justify-content;

  .mat-step-header {
    overflow: hidden;
    outline: 0;
    cursor: pointer;
    position: relative;
    box-sizing: content-box;

    .horizontal-stepper-header {
      display: flex;
      height: $cap-desktop-stepper-horizontal-header-height;
      overflow: hidden;
      align-items: $cap-desktop-stepper-horizontal-align-items;

      .step-header-ripple {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
      }

      .step-icon-content {
        align-self: $cap-desktop-stepper-icon-content-align-self;
        i {
          line-height: $cap-desktop-stepper-icon-content-line-height;
        }
      }

      .mat-step-icon {
        border-radius: $cap-desktop-stepper-icon-border-radius;
        border: $cap-desktop-stepper-icon-border;
        height: $cap-desktop-stepper-icon-size;
        width: $cap-desktop-stepper-icon-width;
        flex-shrink: 0;
        position: relative;
      }

      .step-icon {
        background-color: $cap-desktop-stepper-step-icon-background-color;
        color: $cap-desktop-stepper-step-icon-color;
        display: flex;
        justify-content: $cap-desktop-stepper-step-icon-justify-content;
      }

      .completed {
        background-color: $cap-desktop-stepper-step-icon-completed-background-color;
        color: $cap-desktop-stepper-step-icon-color;
      }
    }
  }

  .stepper-horizontal-line:not(:last-child) {
    flex: auto;
    height: 0;
    min-width: $cap-desktop-stepper-horizontal-line-min-width;
    opacity: $cap-desktop-stepper-horizontal-line-opacity;
    border-top: $cap-desktop-stepper-horizontal-line-border-top;
    color: $cap-desktop-stepper-horizontal-line-color;
  }

  .step-label {
    font-size: toRem($cap-desktop-stepper-step-label-font-size);
  }

  .step-label-active {
    color: $cap-desktop-stepper-step-label-active-color;
  }

  .step-label-inactive {
    color: $cap-desktop-stepper-step-label-inactive-color;
  }
}

@mixin angle($side1, $side2, $size, $top: null, $right: null, $bottom: null, $left: null ) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  border-radius: 3px;
  width: $size;
  height: $size;
  color: var(--cap-primary-color);
  border-#{$side1}: solid 4px var(--cap-primary-color);
  border-#{$side2}: solid 4px var(--cap-primary-color);
}

/* barcode-scanner component */
@media screen and (min-width: 768px) {
  $margin-y: 15%;

  .angle-top-right {
    @include angle('top', 'right', $cap-scanner-angle-size, $margin-y, $cap-scanner-margin-x);
  }

  .angle-top-left {
    @include angle('top', 'left', $cap-scanner-angle-size, $margin-y, null, null, $cap-scanner-margin-x);
  }

  .angle-bottom-right {
    @include angle('bottom', 'right', $cap-scanner-angle-size, null, $cap-scanner-margin-x, $margin-y, null);
  }

  .angle-bottom-left {
    @include angle('bottom', 'left', $cap-scanner-angle-size, null, null, $margin-y, $cap-scanner-margin-x);
  }
}

@media screen and (max-width: 768px) {
  $margin-y: 30%;

  .angle-top-right {
    @include angle('top', 'right', $cap-scanner-angle-size, $margin-y, $cap-scanner-margin-x);
  }

  .angle-top-left {
    @include angle('top', 'left', $cap-scanner-angle-size, $margin-y, null, null, $cap-scanner-margin-x);
  }

  .angle-bottom-right {
    @include angle('bottom', 'right', $cap-scanner-angle-size, null, $cap-scanner-margin-x, $margin-y, null);
  }

  .angle-bottom-left {
    @include angle('bottom', 'left', $cap-scanner-angle-size, null, null, $margin-y, $cap-scanner-margin-x);
  }
}

.cap-barcode-scanner {
  .scan-container {
    width: $cap-scanner-scan-container-width;
    height: $cap-scanner-scan-container-height;
    position: $cap-scanner-scan-container-position;
    top: $cap-scanner-scan-container-top;
    left: $cap-scanner-scan-container-left;
    transform: $cap-scanner-scan-container-transform;
    background-color: $cap-scanner-scan-background-color;
  }

  .scan-header {
    color: $cap-scanner-scan-header-color;
    font-size: toRem($cap-scanner-scan-header-font-size);
    height: $cap-scanner-scan-header-height;
    box-shadow: $cap-scanner-scan-header-box-shadow;
    background-color: $cap-scanner-scan-header-background-color;

    i {
      cursor: $cap-scanner-scan-header-cursor;

      &:hover {
        font-size: toRem($cap-scanner-scan-header-hover-font-size);
        filter: $cap-scanner-scan-header-hover-filter;
      }
    }
  }

  .scan-message {
    @include media-breakpoint-up(md) {
      max-width: 28rem;
    }
    position: $cap-scanner-scan-message-position;
    right: $cap-scanner-scan-message-right;
    left: $cap-scanner-scan-message-left;
    z-index: $cap-scanner-scan-message-z-index;
    height: $cap-scanner-scan-message-height;
    max-width: $cap-scanner-scan-message-max-width;
    border-radius: $cap-scanner-scan-message-border-radius;
    box-shadow: $cap-scanner-scan-message-box-shadow;
    background-color: $cap-scanner-scan-message-background-color;

    p {
      overflow: $cap-scanner-scan-message-text-overflow;
      font-size: toRem($cap-scanner-scan-message-font-size);
    }

    i {
      color: var(--success-color);
      font-size: 1.5rem;
    }
  }

  .darken video {
    filter: brightness(30%);
  }

  zxing-scanner {
    position: $cap-scanner-zxing-position;
    top: $cap-scanner-zxing-top;
    height: $cap-scanner-zxing-height;
    width: $cap-scanner-zxing-width;
    z-index: $cap-scanner-zxing-z-index;
  }

  zxing-scanner video {
    width: $cap-scanner-zxing-video-width;
    height: $cap-scanner-zxing-video-height;
  }

  .error-message {
    position: $cap-scanner-error-message-position;
    z-index: $cap-scanner-error-message-z-index;
    top: $cap-scanner-error-message-top;
  }
}

/* scan-list component */
.scan-list {
  .strikethrough {
    text-decoration: $cap-scanner-scan-list-strikethrough-text-decoration;
  }

  i {
    color: $cap-scanner-scan-list-text-color;
    cursor: $cap-scanner-scan-list-text-cursor;
    font-size: toRem($cap-scanner-scan-list-text-font-size);

    &:hover {
      color: $cap-scanner-scan-list-text-hover-color;
      font-size: toRem($cap-scanner-scan-list-text-hover-font-size);
    }
  }
}

.slide-up-panel.animate {
  transition: $cap-slide-up-panel-transition;
}

.slide-up-panel {
  border-radius: $cap-slide-up-panel-border-radius;
  box-shadow: $cap-slide-up-panel-box-shadow;
  position: $cap-slide-up-panel-position;
  right: $cap-slide-up-panel-right;
  bottom: $cap-slide-up-panel-bottom;
  left: $cap-slide-up-panel-left;
  z-index: $cap-slide-up-panel-z-index;
  background-color: $cap-slide-up-panel-background-color;

  .panel-content {
    @include no-scrollbar;
    overflow: $cap-slide-up-panel-overflow;
  }

  .drag-handle-bar {
    cursor: $cap-slide-up-panel-drag-handle-bar-cursor;
  }

  .drag-handle-bar:active {
    cursor: $cap-slide-up-panel-drag-handle-bar-active-cursor;
  }

  .handle-bar {
    height: $cap-slide-up-panel-handle-bar-height;
    width: $cap-slide-up-panel-handle-bar-width;
    border-radius: $cap-slide-up-panel-handle-bar-border-radius;
    background-color: $cap-slide-up-panel-handle-bar-background-color;
  }
}

body cap-progress-bar .p-progressbar {
  border: $cap-progress-bar-border;
  height: $cap-progress-bar-height;
  background-color: $cap-progress-bar-background-color;

  .p-progressbar-value {
    height: $cap-progress-bar-progressbar-value-height;
    position: $cap-progress-bar-progressbar-value-position;
    border: $cap-progress-bar-progressbar-value-border;
    margin: $cap-progress-bar-progressbar-value-margin;
    background: $cap-progress-bar-progressbar-value-background;
  }

  .p-progressbar-label {
    height: $cap-progress-bar-label-height;
    width: $cap-progress-bar-label-width;
    position: $cap-progress-bar-label-position;
    margin-top: $cap-progress-bar-label-margin-top;
    text-align: $cap-progress-bar-label-text-align;
    font-weight: $cap-progress-bar-label-font-weight;
    color: $cap-progress-bar-label-color;
    line-height: $cap-progress-bar-label-line-height;
  }
}

.fab-button {
  width: $cap-fab-button-width;
  height: $cap-fab-button-size;
  background-color: $cap-fab-button-background-color;
  border-radius: $cap-fab-button-border-radius;
  display: $cap-fab-button-display;
  justify-content: $cap-fab-button-justify-content;
  align-items: $cap-fab-button-align-items;
  border: $cap-fab-button-border;
  position: $cap-fab-button-position;
  bottom: $cap-fab-button-bottom;
  z-index: $cap-fab-button-z-index;

  .fab-button-icon {
    width: $cap-fab-button-icon-width;
    height: $cap-fab-button-icon-height;
    font-size: toRem($cap-fab-button-icon-size);
    color: $cap-fab-button-icon-color;
    text-align: $cap-fab-button-text-align;
  }
}

.top-right {
  right: $cap-fab-button-top-right-right;
  top: $cap-fab-button-top-right-top;
}

.bottom-left {
  left: $cap-fab-button-bottom-left-left;
  bottom: $cap-fab-button-bottom-left-bottom;
}

.top-left {
  left: $cap-fab-button-bottom-top-left-left;
  top: $cap-fab-button-bottom-top-left-top;
}

.bottom-right {
  right: $cap-fab-button-bottom-right-right;
  bottom: $cap-fab-button-bottom-right-bottom;
}

label {
  font-weight: $cap-range-filters-label-font-weight;
  font-size: toRem($cap-range-filters-label-font-size);
  color: $cap-range-filters-label-color;
}

.range-filter-panel.p-overlaypanel {
  z-index: $cap-range-filters-overlay-panel-z-index;
  box-shadow: $cap-range-filters-overlay-panel-box-shadow;
  background-color: $cap-range-filters-overlay-panel-background-color;
  border-radius: $cap-range-filters-overlay-panel-border-radius;
  min-width: $cap-range-filters-overlay-panel-min-width;

  &:before,
  &:after {
    border: none;
    margin: 0;
    content: none;
  }
}

cap-input.border-left-0 {
  input.p-inputtext {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.range-filter-button {
  background-color: $cap-range-filters-button-background-color;
  color: $cap-range-filters-button-color;
  border: $cap-range-filters-button-border;
  border-radius: $cap-range-filters-button-border-radius;
  height: $cap-input-height;
  font-size: toRem($cap-range-filters-button-font-size);
  cursor: $cap-range-filters-button-cursor;
  padding: $cap-range-filters-button-padding;
  display: $cap-range-filters-button-display;
  flex-direction: $cap-range-filters-button-flex-direction;
  justify-content: $cap-range-filters-button-justify-content;

  i.pre-value-icon {
    color: $cap-range-filters-button-pre-value-icon-color;
    opacity: $cap-range-filters-button-pre-value-icon-opacity;
    margin-right: $cap-range-filters-button-pre-value-icon-margin-right;
  }

  i[class*='chevron'] {
    color: $cap-range-filters-button-icon-chevron-color;
    opacity: $cap-range-filters-button-icon-chevron-opacity;
    font-size: toRem($cap-range-filters-button-icon-chevron-font-size);
    margin-right: $cap-range-filters-button-icon-chevron-margin-right;
    margin-top: $cap-range-filters-button-icon-chevron-margin-top;
  }
}

cap-range-filter.has-icon {
  .range-filter-button {
    padding-left: $cap-range-filters-button-padding-left;
  }
}

.cap-progress-circle {
  position: $cap-progress-circle-position;
  display: $cap-progress-circle-display;

  .cap-pc-circle-custom {
    text-align: $cap-progress-circle-custom;
    position: $cap-progress-circle-position;

    &.cap-pc-content {
      top: $cap-progress-circle-content-top;
    }

    &.cap-pc-subtitle {
      top: $cap-progress-circle-subtitle-top;
    }
  }
}

.cap-table.p-datatable {
  .p-datatable-tbody > tr,
  .p-datatable-thead > tr {
    .table-actions-col {
      width: $cap-table-actions-col-width;
    }

    .table-expand-col {
      width: $cap-table-expand-col-width;
    }

    & > th,
    & > td {
      background-color: $cap-table-header-table-data-background-color;
      color: $cap-table-header-table-data-color;
      border: $cap-table-header-table-border;
      font-size: toRem($cap-table-header-table-font-size);
      border-bottom: 1px solid $table-border-color;
      padding: $cap-table-header-table-padding;
    }

    & > th {
      font-weight: $cap-table-header-font-weight;
      color: $cap-table-header-color;
      padding: $cap-table-header-padding;

      &:hover {
        background-color: $cap-table-header-hover-background-color;
      }
    }

    td.expand {
      padding: 0.5em 0 0;

      i {
        font-size: 1.5rem;
        line-height: 1rem;
      }
    }
  }

  .p-datatable-caption {
    background-color: $cap-table-caption-background-color;
    border: $cap-table-caption-border;
  }

  .p-inputgroup-addon {
    border-right: $cap-table-inputgroup-addon-border-right;
    color: $cap-table-inputgroup-addon-color;
  }

  .actions-wrapper {
    max-width: $cap-table-actions-wrapper-max-width;
  }

  .actions {
    i {
      color: $cap-table-actions-icon-color;
      margin-right: $cap-table-actions-margin-right;
      cursor: $cap-table-actions-cursor;
    }
  }

  .actions-right {
    padding-left: $cap-table-actions-right-padding-left;
  }

  .p-sortable-column-icon {
    color: $cap-table-sortable-column-icon-color;
  }

  .p-paginator {
    margin-top: $cap-table-paginator-margin-top;
    background-color: $cap-table-paginator-background-color;
    border: $cap-table-paginator-border;
    text-align: $cap-table-paginator-text-align;

    p-dropdown {
      display: $cap-table-dropdown-display;
    }

    .p-paginator-bottom {
      text-align: $cap-table-paginator-bottom-text-align;
    }

    .p-paginator-page {
      color: $cap-table-paginator-page-color;
      font-weight: $cap-table-paginator-font-weight;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &.p-highlight {
        background: var(--cap-primary-color);
        color: #fff;
      }
    }

    .p-paginator-element {
      background: $cap-table-paginator-element-background;
      color: $cap-table-paginator-element-color;
      border: 1px solid $table-border-color;
      border-left: none;
      font-weight: normal;
    }

    .p-paginator-last,
    .p-paginator-first {
      display: none;
    }

    .p-paginator-prev,
    .p-paginator-next {
      width: $cap-table-paginator-prev-next-width;
      line-height: $cap-table-paginator-prev-next-line-height;
      border: 1px solid $table-border-color;
      cursor: pointer;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &.p-disabled {
        color: $cap-table-text-color;
      }

      .pi {
        display: none;
      }
    }

    .p-paginator-prev {
      border-top-left-radius: $cap-table-paginator-prev-border-tl-radius;
      border-bottom-left-radius: $cap-table-paginator-prev-border-left-radius;

      &:before {
        position: absolute;
        top: 1px;
        left: 10px;
        content: '\f053';
        font-weight: 900;
        font-family: 'Font Awesome 5 Pro';
      }
    }

    .p-paginator-next {
      border-top-right-radius: $cap-table-paginator-next-border-tr-radius;
      border-bottom-right-radius: $cap-table-paginator-next-border-br-radius;

      &:before {
        content: '\f054';
        font-weight: 900;
        font-family: 'Font Awesome 5 Pro';
      }
    }
  }

  cap-dropdown {
    .form-element {
      display: inline-block;
    }
  }

  td div.divider cap-dropdown {
    .form-element {
      display: block;
    }
  }

  cap-dropdown .p-dropdown:not(.num-rows-dropdown) {
    width: 100% !important; /* stylelint-disable-line declaration-no-important */
  }
}

.filter-toggle {
  font-size: toRem($cap-table-filter-toggle-font-size);
  cursor: $cap-table-filter-toggle-cursor;
}

.filter-icon {
  position: $cap-table-filter-icon-position;
  top: $cap-table-filter-icon-top;
  left: $cap-table-filter-icon-left;
  height: $cap-table-filter-icon-height;
  display: $cap-table-filter-icon-display;
  flex-direction: $cap-table-filter-icon-flex-direction;
  justify-content: $cap-table-filter-icon-flex-justify-content;

  i {
    color: $cap-table-filter-icon-color;
    opacity: $cap-table-filter-icon-opacity;
    font-size: toRem($cap-table-filter-font-size);
    line-height: $cap-table-filter-line-height;
  }
}

.reset-filter {
  position: $cap-table-reset-filter-position;
  top: $cap-table-reset-filter-top;
  right: $cap-table-reset-filter-right;
  height: $cap-table-reset-filter-height;
  display: $cap-table-reset-filter-display;
  flex-direction: $cap-table-reset-filter-flex-direction;
  justify-content: $cap-table-reset-filter-justify-content;
  cursor: $cap-table-reset-filter-cursor;

  i {
    background: $cap-table-reset-filter-background;
    color: $cap-table-reset-filter-color;
    opacity: $cap-table-reset-filter-opacity;
    font-size: toRem($cap-table-reset-filter-font-size);
    line-height: $cap-table-reset-filter-line-height;
  }
}

cap-input.has-icon .p-inputgroup input.p-inputtext,
cap-input.has-icon .p-inputgroup input.p-inputtext:enabled:focus:not(.p-state-error),
cap-input.has-icon .p-inputgroup input.p-inputtext:enabled:hover:not(.p-state-error),
cap-multi-select.has-icon .p-multiselect .p-multiselect-label {
  padding-left: $cap-table-multiselect-label-padding-left;
}

div.cap-table-filter cap-input.has-icon .form-element .p-inputgroup input.p-inputtext {
  padding-left: $cap-table-multiselect-label-input-text-padding-left;
  height: auto;
  min-height: toRem(38px);
}

.table-filters {
  display: $cap-table-filters-display;

  .fa-stack {
    color: var(--cap-primary-color);
    font-size: toRem($cap-table-filters-font-size);
    cursor: $cap-table-filters-cursor;
  }

  .filters-label {
    display: $cap-table-filters-label-display;
    flex-grow: $cap-table-filters-label-flex-grow;
    margin-left: $cap-table-filters-label-margin-left;

    i {
      color: $cap-table-filters-label-color;
    }
  }
}

.table-filters-dialog {
  width: $cap-table-filters-dialog-width;

  .table-filters-settings {
    padding: $cap-table-filters-settings-padding;

    header {
      display: $cap-table-filters-header-display;
      justify-content: $cap-table-filters-header-justify-content;
      position: $cap-table-filters-header-position;
      margin-bottom: $cap-table-filters-header-margin-bottom;

      .close-filters {
        position: $cap-table-filters-header-close-filters-position;
        right: $cap-table-filters-header-close-filters-right;
      }
    }

    .items {
      min-height: $cap-table-filters-items-min-height;
      max-height: $cap-table-filters-items-max-height;
      overflow-y: $cap-table-filters-items-overflow-y;
      @include setBottomGutters(10px);

      .item {
        width: $cap-table-filters-item-width;
        border: $cap-table-filters-item-border;
        padding: $cap-table-filters-item-padding;
        border-radius: $cap-table-filters-item-border-radius;

        &.selected {
          border-color: $cap-table-filters-item-selected-border-color;
        }

        .p-checkbox .p-checkbox-box {
          border-color: $cap-table-filters-item-checkbox-border-color;
        }

        .p-checkbox .p-checkbox-box.p-highlight,
        .p-checkbox .p-checkbox-box.p-highlight.p-focus,
        .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover,
        .p-checkbox .p-checkbox-box.p-highlight.p-focus:not(.p-disabled):hover {
          border-color: var(--success-color);

          .p-checkbox-icon:before {
            color: var(--success-color);
          }
        }
      }
    }
  }
}

.item {
  cursor: $cap-table-item-cursor;

  .form-element {
    height: $cap-table-item-form-element-height;

    .item-label {
      font-weight: $cap-table-item-label-font-weight;
      cursor: $cap-table-item-label-cursor;
    }
  }
}

@include media-breakpoint-down(xs) {
  .table-filters-dialog {
    width: 100%;

    .table-filters-settings {
      padding: 5px;

      header {
        justify-content: space-between;

        .close-filters {
          position: relative;
        }
      }
    }
  }
}

.table-list {
  .columns-list {
    width: $cap-table-columns-list-width;
    text-align: $cap-table-columns-list-text-align;

    i {
      cursor: $cap-table-columns-list-icon-cursor;
      color: $cap-table-columns-list-color;
    }
  }
}

.cap-table:not(.rowsWithTheSameHeight) {
  .p-datatable-frozen-view,
  .p-datatable-unfrozen-view {
    td,
    th {
      .divider {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.p-datatable-frozen-view {
  .empty-message span {
    display: none;
  }

  .p-datatable-thead tr th:last-child {
    box-shadow: $cap-table-frozen-column-shadow;
  }
}

.p-datatable-scrollable-header {
  position: relative;

  th.columns-list {
    i {
      position: absolute;
      left: calc(100% - 30px);
      top: 10px;
    }
  }
}

.p-datatable-scrollable-body {
  .empty-manageable-column {
    display: none;
  }
}

@media screen and (max-width: 40em) {
  .p-datatable-frozen-view {
    display: none;
    width: 0 !important; /* stylelint-disable-line declaration-no-important */
  }

  .p-datatable-unfrozen-view {
    position: relative !important; /* stylelint-disable-line declaration-no-important */
    width: 100% !important; /* stylelint-disable-line declaration-no-important */
    left: auto !important; /* stylelint-disable-line declaration-no-important */

    .p-datatable-scrollable-body {
      max-height: none !important; /* stylelint-disable-line declaration-no-important */
    }

    .list-page-table-column {
      width: 100% !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.p-datatable-unfrozen-view {
  .frozen-cell {
    display: none;
  }

  @media screen and (max-width: 40em) {
    .frozen-cell {
      display: block;
    }

    .empty-message span {
      margin-left: auto !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}


@mixin selectorPosition($side) {
  #{$side}: $cap-image-viewer-navigator-desktop-spacing;

  @include media-breakpoint-down(lg) {
    #{$side}: $cap-image-viewer-navigator-table-spacing;
  }

  @include media-breakpoint-down(sm) {
    #{$side}: $cap-image-navigator-mobile-spacing;
  }
}

@mixin viewer-action-button {
  padding: 0 .5rem;
  border-width: 1px;
  width: 30px;
  height: 30px;
}

.viewer {
  position: $cap-image-viewer-position;
  width: $cap-image-viewer-width;
  height: $cap-image-viewer-height;
  left: $cap-image-viewer-left;
  top: 0;
  z-index: $cap-image-viewer-z-index;
  background: $cap-image-viewer-background;
  backdrop-filter: blur(3px);

  .p-galleria .p-galleria-thumbnail-container {
    background: rgba($cap-black, 0.9);
    padding: 10px 0.25rem;

    .p-galleria-thumbnail-prev,
    .p-galleria-thumbnail-next {
      color: $cap-white;
      margin: .5rem;
    }
  }

  &.fullscreen {
    .viewer__body-container {
      background: $cap-black;
      max-width: 100vw;
      height: 100vh;
      border-radius: 0;
      display: flex;
      flex-direction: column;
    }

    .viewer__preview {
      flex: 1;
    }

    .p-galleria {
      height: 100%;

      .p-galleria-content {
        height: 100%;
      }

      .p-galleria-item-wrapper {
        height: 100%;
        flex: 1;
      }
    }

    .viewer__body-actions {
      background-color: $cap-white;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 28px;
    }

    .viewer__preview-selector {
      i {
        color: $cap-white;
      }
    }
  }
}

.viewer__header {
  background: $cap-white;
  padding: $cap-image-viewer-header-padding;
  display: $cap-image-viewer-header-display;
  justify-content: $cap-image-viewer-header-justify-content;
}

.viewer__title {
  font-size: toRem(24px);
  font-weight: 700;

  p {
    margin: 0;
  }
}

.viewer__cta {
  display: flex;
}

.viewer__header-actions {
  @include setRightGutters(15px);
  display: flex;
  align-items: center;

  .cap-button {
    &.primary {
      cursor: pointer;
      color: var(--cap-primary-color);
      background-color: $cap-image-viewer-actions-button-background-color;
      border-color: $cap-image-viewer-actions-button-background-color;
      padding: .2rem 11px;
      font-size: 1rem;
    }
  }
}

.viewer__header-closer {
  margin-left: $cap-image-viewer-header-closer-margin-left;
  cursor: pointer;
  color: $cap-gray;
  font-size: 21px;
}

.viewer__body {
  @include setAbsoluteCenter();
  width: 100%;
}

.viewer .viewer__body-container {
  background: $cap-image-viewer-body-container-background;
  padding: 0;
  border-radius: $cap-image-viewer-body-wrapper-border-radius;
  overflow: hidden;
}

.viewer__preview-wrapper {
  width: $cap-image-viewer-header-preview-wrapper-width;
  height: $cap-image-viewer-header-preview-wrapper-height;
  overflow: $cap-image-viewer-header-preview-wrapper-overflow;

  img {
    @include setObjectFit(contain);
    transform-origin: center center;
    transition: transform 1s, filter 0.5s ease-out;
  }

  .p-galleria {
    height: 100%;
    .p-galleria-item-wrapper {
      height: 100%;

      .p-galleria-item-container {
        height: 100%;
        background-color: rgba($cap-black, 0.9);
      }
    }

    .p-galleria-content {
      height: 100%;

      p-galleriaitem {
        flex: 1;
        overflow: hidden;
      }
    }
  }

}

.viewer__body-actions {
  margin: 28px auto;
  position: relative;

  @supports not (width: max-content) {
    width: 350px;
  }

  @supports (width: max-content) {
    width: max-content;
  }
}

.viewer__body-wrapper {
  display: $cap-image-viewer-body-wrapper-display;
  padding: $cap-image-viewer-body-wrapper-padding;
  color: $cap-image-viewer-body-wrapper-color;
  align-items: $cap-image-viewer-body-wrapper-align-items;
}

.viewer__body-pagination {
  @include setRightGutters(10px);
  display: flex;
  align-items: center;

  @include media-breakpoint-between(xs, sm) {
    display: none;
  }

  p {
    margin-bottom: 0;
  }

  &-text {
    border-radius: 3px;
    border: solid 1px $cap-gray;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    padding: 0 .5rem;
    font-size: .8rem;
  }

  .cap-button,
  .cap-button.secondary:not(:disabled) {
    @include viewer-action-button;
  }
}

.viewer__body-zoom {
  display: flex;
  align-items: center;
  padding-right: 1rem;
  font-size: 1rem;

  @include media-breakpoint-up(md) {
    padding-right: 3rem;
  }

  .text {
    padding: 0 0.5rem;
  }

  i {
    cursor: pointer;
  }

  .cap-button,
  .cap-button.primary:not(:disabled) {
    @include viewer-action-button;
  }
}

.viewer__preview {
  position: relative;
  height: 67vh;
  padding-left: 64px;
  padding-right: 64px;
}

.viewer__preview-selector {
  @include setAbsoluteVerticalCenter();
  @include flexCentering();
  $size: 18px;
  width: $size;
  height: $size;
  cursor: pointer;
  transition: 0.5s all;
  z-index: 2;

  i {
    color: $cap-image-viewer-selector-color;
    font-size: 18px;
  }

  &.viewer__preview-selector--left {
    @include selectorPosition(left);
  }

  &.viewer__preview-selector--right {
    @include selectorPosition(right);
  }
}

.viewer__preview-thumbnail-item {
  width: 80px;
  height: 60px;

  img {
    object-fit: cover;
  }
}

.viewer__body-fullscreen {
  padding-left: 1rem;

  @include media-breakpoint-up(md) {
    padding-left: 3rem;
  }

  .cap-button,
  .cap-button.primary:not(:disabled) {
    @include viewer-action-button;
  }
}

.cap-loader-image {
  width: $cap-loader-image-width;
  margin: $cap-loader-image-margin;
}

.cap-loader {
  position: $cap-loader-position;
  top: $cap-loader-top;
  left: $cap-loader-left;
  bottom: $cap-loader-bottom;
  width: $cap-loader-width;
  background: $cap-loader-background;
  z-index: $cap-loader-z-index;
  text-align: $cap-loader-text-align;
  display: $cap-loader-display;
  flex-direction: $cap-loader-flex-direction;
  justify-content: $cap-loader-justify-content;

  .p-progress-spinner-circle {
    animation: p-progress-spinner-dash 1.5s ease-in-out infinite, cap-progress-spinner-color 6s ease-in-out infinite; /* stylelint-disable */
  }

  span.cap-loader-text {
    color: $cap-loader-text-color;
  }
}

@keyframes cap-progress-spinner-color {
  100%,
  0%,
  40%,
  66%,
  80%,
  90% {
    stroke: $cap-loader-progress-spinner-color-stroke;
  }
}

.cap-loader-text:after {
  content: ' .';
  animation: $cap-loader-text-animation;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: var(--cap-primary-color);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 var(--cap-primary-color), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 var(--cap-primary-color), 0.5em 0 0 var(--cap-primary-color);
  }
}

// TODO p-inputMask does not exists it should be p-inputmask selector type.
p-inputMask { /* stylelint-disable */
  width: $cap-input-mask-width;

  .p-inputtext {
    width: $cap-input-mask-text-width;
  }
}

body .p-inputgroup input.p-inputtext {
  width: auto;
}

cap-accordion-card {
  .accordion-card {
    .ac-header__left,
    .ac-header__right {
      width: $cap-accordion-card-header-width;
    }

    .ac-header__left {
      display: $cap-accordion-card-header-left-display;
    }

    .ac-header__right {
      text-align: $cap-accordion-card-header-left-text-align;
    }

    &__title {
      margin: 0;
      font-weight: $cap-accordion-card-title-font-weight;
      font-size: toRem($cap-accordion-card-title-font-size);
      min-height: $cap-accordion-card-title-min-height;
      @include truncateAndDots(100%);

      i.ac-icon {
        color: $cap-accordion-card-title-icon-color;
        margin-right: $cap-accordion-card-title-icon-margin-right;
      }
    }

    &__actions {
      margin-left: $cap-accordion-card-actions-margin-left;
      @include setRightGutters(10px);

      i {
        cursor: pointer;
        transition: $cap-accordion-card-actions-transition;

        &:hover {
          transform: $cap-accordion-card-actions-hover;
        }
      }
    }

    &__toggler {
      margin: 0;
      font-size: toRem($cap-accordion-card-toggler-font-size);
      text-decoration: underline;
      cursor: pointer;
      color: var(--cap-primary-color);
      display: inline-block;

      i {
        font-size: toRem($cap-accordion-card-toggler-font-size);
      }
    }

    &__header {
      display: $cap-accordion-card-header-display;
      justify-content: $cap-accordion-card-header-justify-content;
      padding: $cap-accordion-card-header-padding;
      border-radius: $cap-accordion-card-header-border-radius;
      background-color: $cap-accordion-card-header-background-color;
    }

    &__content {
      border-bottom-left-radius: $cap-accordion-card-content-border-bl-radius;
      border-bottom-right-radius: $cap-accordion-card-content-border-br-radius;
      height: 0;
      overflow: hidden;
      visibility: hidden;
      transition: $cap-accordion-card-content-transition;
      background-color: $cap-accordion-card-content-background-color;

      &--open {
        padding: $cap-accordion-card-content-open-padding;
        height: auto;
        overflow: visible;
        visibility: visible;
      }
    }

    &__delimiter {
      margin-bottom: $cap-accordion-card-delimiter-margin-bottom;
      width: 100%;
      height: 1px;
      background: $cap-accordion-card-delimiter-background;
    }
  }
}

.row-action {
  position: unset;
  text-align: $cap-row-action-text-align;

  &__items {
    padding: $cap-row-action-items-padding;
    list-style: $cap-row-action-items-list-style;
    box-shadow: $cap-row-action-items-box-shadow;
    background-color: $cap-row-action-items-background-color;
    position: $cap-row-action-items-position;
    text-align: $cap-row-action-items-text-align;
    right: $cap-row-action-items-right;
    margin: $cap-row-action-items-margin;
    z-index: $cap-row-action-items-z-index;
    border-radius: $cap-row-action-items-border-radius;
    visibility: $cap-row-action-items-visibility;
    height: $cap-row-action-items-height;
    overflow: $cap-row-action-items-overflow;
    cursor: $cap-row-action-items-cursor;

    @supports not (width: max-content) {
      width: 270px;
    }

    @supports (width: max-content) {
      width: max-content;
    }

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 15px solid $cap-white;
      position: absolute;
      right: 7px;
      top: -10px;
    }

    &--open {
      visibility: $cap-row-action-items-open-visibility;
      height: $cap-row-action-items-open-height;
      overflow: $cap-row-action-items-open-overflow;
    }

    li {
      display: $cap-row-action-item-display;
      cursor: $cap-row-action-item-cursor;
      padding: $cap-row-action-item-padding;
      border-bottom: $cap-row-action-item-border-bottom;

      &:hover {
        background: $cap-row-action-item-hover-background-color;
      }

      &:last-child {
        border: $cap-row-action-item-last-child-border;
      }
    }
  }

  &__icon {
    font-size: toRem($cap-row-action-items-icon-font-size);
    margin: $cap-row-action-items-icon-margin;
    color: $cap-row-action-items-icon-color;

    i {
      margin: $cap-row-action-items-icon-margin;
    }
  }

  &__info {
    margin: $cap-row-action-items-info-margin;

    p {
      margin: $cap-row-action-items-info-text-margin;
    }

    .title {
      font-size: toRem($cap-row-action-items-info-title-font-size);
      font-weight: $cap-row-action-items-info-title-font-weight;
    }

    .description {
      font-size: toRem($cap-row-action-items-info-title-font-size);
    }
  }

  & > i {
    padding: 15px;
  }
}

.checkbox-filter {
  height: $cap-checkbox-filter-height;
  border: $cap-checkbox-filter-border;
  border-radius: $cap-checkbox-filter-border-radius;
  position: relative;
  justify-content: $cap-checkbox-filter-justify-content;
  background-color: $cap-checkbox-filter-background-color;

  .filter-icon {
    left: $cap-checkbox-filter-icon-left;
  }

  .form-element {
    display: inline;
  }

  .checkbox-filter-label {
    padding: $cap-checkbox-filter-label-padding;
    color: $cap-checkbox-filter-label-color;
    width: $cap-checkbox-filter-label-width;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  cap-validator {
    display: none;
  }
}

@mixin applyToastStyles($class, $color, $background: $toast-background-color) {
  &.p-toast-message- { /* stylelint-disable */
    &#{$class} {
      border-left: 4px solid $color;
      background: $background;

      .toast-icon {
        color: $color;
      }
    }
  }
}

body .p-toast.cap-toast .p-toast-message {
  background: $toast-background-color;
  border-radius: $toast-border-radius;
  color: $cap-toast-text-color;
  font-family: $cap-primary-font;

  .p-toast-message-content {
    padding: $cap-toast-message-content-padding;
  }

  .p-toast-icon-close {
    outline: none;
    color: $cap-toast-close-icon-color;
    top: $cap-toast-close-icon-top;
    bottom: $cap-toast-close-icon-bottom;
    right: $cap-toast-close-icon-right;
    justify-content: $cap-toast-close-icon-justify-content;
    display: $cap-toast-close-icon-display;
    flex-direction: $cap-toast-close-icon-flex-direction;
    font-size: toRem($cap-toast-close-icon-flex-font-size);
    position: absolute;

    .p-toast-icon-close-icon {
      display: none;
    }

    &:before {
      font-family: 'Font Awesome 5 Pro';
      content: '\f00d';
    }
  }

  @include applyToastStyles('success', $cap-success, $cap-toast-background-color-success);
  @include applyToastStyles('error', $cap-danger, $cap-toast-background-color-error);
  @include applyToastStyles('warn', $cap-warning, $cap-toast-background-color-warning);
  @include applyToastStyles('info', var(--cap-primary-color), $cap-toast-background-color-info);

  .toast-icon {
    font-size: toRem($cap-toast-icon-font-size);
    margin-right: $cap-toast-icon-margin-right;
  }

  .toast-summary {
    font-size: toRem($cap-toast-summary-font-size);
    font-weight: $cap-toast-summary-font-weight;
    font-family: $cap-toast-summary-font-family;
    color: $cap-toast-summary-color;
  }

  .toast-detail {
    color: $cap-toast-detail-text-color;
    font-family: $cap-primary-font;
    font-size: toRem($cap-toast-detail-font-size);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  &.p-toast-message-error {
    background: $cap-toast-message-error-background;
    border-left: $cap-toast-message-error-border-left;
  }

  &.p-toast-message-info {
    background: $cap-toast-background-color-error;
    border-left: $cap-toast-message-info-border-left;
  }
}

label {
  vertical-align: top;
  margin: 0 0 0 0.5em;
  line-height: 1.75em;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: $cap-white;
  transform: translateX(1.25rem);
}

.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
}

.p-inputswitch .p-inputswitch-slider {
  background: $cap-white;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 30px;
}

.p-inputswitch .p-inputswitch-slider:before {
  background: #fff;
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  margin-top: -0.5rem; /* stylelint-disable-line number-max-precision */
  border-radius: 50%;
  transition-duration: 0.2s;
}

.data-cell {
  vertical-align: middle;
  padding-left: $cap-info-table-cell-padding;
  padding-right: $cap-info-table-cell-padding;
  border: $cap-info-table-cell-border;
  background-color: $cap-info-table-cell-bg;
}

.cap-info-table {
  &__column-header {
    max-width: 100%;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .th_column-header--editable-rows {
    width: 3.5rem;
  }

  &.p-datatable {
    table {
      border-collapse: separate;
      border-spacing: $cap-info-table-border-spacing;
    }

    .p-checkbox .p-checkbox-box.p-highlight,
    .p-checkbox .p-checkbox-box.p-highlight.p-focus,
    .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover,
    .p-checkbox .p-checkbox-box.p-highlight.p-focus:not(.p-disabled):hover {
      border-color: $cap-success;

      .p-checkbox-icon:before {
        color: $cap-success;
      }
    }

    .p-paginator {
      margin-top: $cap-table-paginator-margin-top;
      background-color: $cap-table-paginator-background-color;
      border: $cap-table-paginator-border;
      text-align: $cap-table-paginator-text-align;

      cap-validator {
        display: none;
      }

      .p-paginator-left-content {
        float: left;
      }

      .paginator-left {
        &__page-text {
          margin-left: 1rem;
          margin-right: 1rem;
        }

        &__total-text {
          margin-left: 1.5rem;
        }
      }

      .form-element {
        display: inline;
      }

      p-dropdown {
        display: $cap-table-dropdown-display;
      }

      .p-paginator-bottom {
        text-align: $cap-table-paginator-bottom-text-align;
      }

      .p-paginator-page {
        color: $cap-table-paginator-page-color;
        font-weight: $cap-table-paginator-font-weight;

        &:focus {
          outline: none;
          box-shadow: none;
        }

        &.p-highlight {
          background: var(--cap-primary-color);
          border: 0;
          color: #fff;
        }
      }

      .p-paginator-element {
        background: $cap-table-paginator-element-background;
        color: $cap-table-paginator-element-color;
        border: 1px solid $table-border-color;
        border-left: none;
        font-weight: normal;
      }

      .p-paginator-last,
      .p-paginator-first {
        display: none;
      }

      .p-paginator-prev,
      .p-paginator-next {
        width: $cap-table-paginator-prev-next-width;
        line-height: $cap-table-paginator-prev-next-line-height;
        border: 1px solid $table-border-color;
        cursor: pointer;

        &:focus {
          outline: none;
          box-shadow: none;
        }

        &.p-disabled {
          color: $cap-table-text-color;
        }

        .pi {
          display: none;
        }
      }

      .p-paginator-prev {
        border-top-left-radius: $cap-table-paginator-prev-border-tl-radius;
        border-bottom-left-radius: $cap-table-paginator-prev-border-left-radius;

        &:before {
          position: absolute;
          top: 1px;
          left: 10px;
          content: '\f053';
          font-weight: 900;
          font-family: 'Font Awesome 5 Pro';
        }
      }

      .p-paginator-next {
        border-top-right-radius: $cap-table-paginator-next-border-tr-radius;
        border-bottom-right-radius: $cap-table-paginator-next-border-br-radius;

        &:before {
          content: '\f054';
          font-weight: 900;
          font-family: 'Font Awesome 5 Pro';
        }
      }
    }

    .p-datatable-thead {
      position: relative;

      @media screen and (max-width: $cap-info-table-card-breakpoint) {
        display: none;
      }

      th {
        text-align: left;
        height: $cap-info-table-header-height;
        background-color: $cap-info-table-header-bg;
        border-bottom: 1px solid $cap-info-table-delimiter;
        white-space: nowrap;
        @extend .data-cell;

        &.p-frozen-column {
          position: sticky;
        }

        &.selectable-table-checkbox-column {
          width: 3rem;
        }

        &.reorderable-rows-header {
          width: 3rem;
        }

        span {
          background-color: $cap-info-table-header-bg;
          color: $cap-info-table-header-color;
          font-weight: bold;
          font-size: toRem(14px);
        }
      }
    }

    .p-datatable-tbody {
      tr {
        background-color: $cap-info-table-row-bg;

        @media screen and (max-width: $cap-info-table-card-breakpoint) {
          &.card-view {
            display: flex;
            flex-direction: column;
            margin-bottom: $cap-info-table-card-gutter;
            box-shadow: $cap-info-table-card-shadow;
          }
        }

        &.multiple-parents {
          & > td {
            background-color: $list-row-background-color;
          }
        }

        &.cap-table-row-selected {
          & > td:first-child {
            border-left: 1px solid $cap-success;
          }

          & > td:last-child {
            border-right: 1px solid $cap-success;
          }

          & > td {
            border-top: 1px solid $cap-success;
            border-bottom: 1px solid $cap-success;
          }
        }
      }

      td:not(.selectable-column):not(.reorderable-row) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      td {
        height: $cap-info-table-cell-height;
        text-align: left;
        @extend .data-cell;

        &.cap-info-table__column--clickable {
          cursor: pointer;
        }

        &.cap-info-table__column {
          .cap-info-table__mobile-column {
            display: none;

            @media screen and (max-width: $cap-info-table-card-breakpoint) {
              display: flex;
              font-size: toRem(14px);
              height: 100%;
              align-items: center;
            }
          }

          .cap-info-table__desktop-column {
            display: block;

            @media screen and (max-width: $cap-info-table-card-breakpoint) {
              display: none;
            }
          }

          .cap-info-table__mobile-header {
            text-align: left;
            font-weight: bold;
            color: $cap-info-table-header-color;
            margin-right: 10px;
          }
        }

        .cap-info-table__actions-column {

          &--flexed {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            border-bottom: 1px solid $cap-info-table-delimiter;
          }

          &--single {
            justify-content: flex-end;
          }

          .cap-info-table__column-mob-actions {
            display: none;

            @media screen and (max-width: $cap-info-table-card-breakpoint) {
              display: table-cell;
            }
          }
        }

        &.cap-info-table__column-actions {
          display: table-cell;

          &.cards-view {
            @media screen and (max-width: $cap-info-table-card-breakpoint) {
              display: none;
            }
          }
        }

        &:first-child {
          border-bottom-left-radius: $cap-info-table-border-radius;
          border-top-left-radius: $cap-info-table-border-radius;

          @media screen and (max-width: $cap-info-table-card-breakpoint) {
            border-bottom-left-radius: 0;
            border-top-left-radius: $cap-info-table-border-radius;
            border-top-right-radius: $cap-info-table-border-radius;
          }
        }

        &:last-child {
          border-bottom-right-radius: $cap-info-table-border-radius;
          border-top-right-radius: $cap-info-table-border-radius;

          @media screen and (max-width: $cap-info-table-card-breakpoint) {
            border-top-right-radius: 0;
            border-bottom-right-radius: $cap-info-table-border-radius;
            border-bottom-left-radius: $cap-info-table-border-radius;
          }
        }

        &.p-frozen-column {
          position: sticky;
        }

        &.reorderable-row {
          text-align: center;

          span {
            font-size: toRem(16px);
            padding: 5px 10px;
          }
        }
      }
    }
  }

  &__skeleton {
    .cap-info-table__skeleton-header {
      border-bottom: 1px solid $cap-info-table-delimiter;
      margin-bottom: 15px;
    }
  }

  &__head--sortable {
    cursor: pointer;
  }

  &__visibility-toggler {
    color: var(--cap-primary-color, $primary);
    font-size: 1.1rem;
    position: absolute;
    top: 0.5rem;
    right: 0;

    &:hover {
      cursor: pointer;
      opacity: 0.75;
    }
  }

  &__visibility-panel.p-overlaypanel {
    background: rgb(255, 255, 255);
    box-shadow: 0 5px 13px 0 rgba($black, 0.15);
    border-radius: 5px;
    border: 0;

    .p-overlaypanel-content {
      padding: 0;
    }

    .column {
      padding: 0.75rem;

      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }
    }
  }

  &--hidden {
    width: 0;
    height: 0;
    overflow: hidden;
    display: block;
  }

  &--auto-layout {
    table {
      table-layout: initial;
    }
  }

  &__card-header {
    font-weight: 600;
  }

  &__column-selectable {
    display: flex;
  }

  &__row-select {
    margin-right: 15px;
  }

  .editable-rows-buttons {
    .editable-rows-button {
      display: flex;
      padding: 0;
      align-items: center;
      justify-content: center;
      line-height: 1;
      font-size: 1rem;
      cursor: pointer;

      &:last-of-type {
        margin-top: .5rem;
      }

      &:first-of-type {
        margin-top: 0;
      }

      &.edit {
        i {
          color: var(--cap-primary-color);
        }
      }

      &.save {
        i {
          color: $cap-success;
        }
      }

      &.cancel {
        i {
          color: $cap-danger;
        }
      }

      .label {
        padding-left: .5rem;
      }
    }
  }
}

.cap-cell-tooltip-container {
  display: flex;

  span {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  }
}

body .cap-info-table-cell-tooltip {
  &.p-tooltip {
    overflow-wrap: break-word;
  }
}

.cap-file-preview-list {
  display: flex;
  flex-wrap: wrap;
  @media only screen and (min-width: 600px) {
    @include setRightGutters(15px);
  }

  &__item {
    background: $cap-file-preview-item-background-color;
    display: flex;
    border-radius: 7px;
    padding: 10px 50px 10px 10px;
    margin-bottom: 15px;
    font-size: 0.9em;
    width: 250px;
    position: relative;

    .loading-overlay {
      position: absolute;
      background: rgba(#000, 0.3);
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5px;

      .p-progressbar {
        height: 12px;
        border-radius: 12px;
        width: 90%;
        margin: 0 auto;

        .p-progressbar-determinate .p-progressbar-value-animate {
          transition: width 1s ease-in-out;
        }

        .p-progressbar-label {
          display: none;
        }
      }
    }

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  &__preview-image {
    height: 50px;
    min-width: 50px;
    margin-right: 10px;
    background-size: cover;
    background-position: center;
  }

  &__preview-icon {
    font-size: toRem(35px);
    min-width: 50px;
    color: var(--cap-primary-color);
    margin-right: 10px;
  }

  .file-actions {
    position: absolute;
    right: 10px;
    top: 10px;
    color: var(--cap-primary-color);
    cursor: pointer;

    .file-delete {
      i {
        color: $cap-danger;
      }
    }
  }
}

body .cap-file-preview-action-panel {
  border: none;

  .p-overlaypanel-content {
    padding: 0;
    border-radius: 3px;
    border: none;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);

    .cap-file-action-item {
      padding: 5px 10px;
      cursor: pointer;
      font-size: 1rem;
      font-weight: 600;
      border-bottom: 1px solid $input-border-color;

      &:hover {
        text-decoration: underline;
      }

      i {
        color: var(--cap-primary-color);
        margin-right: 10px;
      }

      &.file-delete i {
        color: $cap-danger;
      }
    }
  }
}


body .p-tabview {
  .p-tabview-panels {
    border: none;
    background: transparent;
  }

  .p-tabview-nav {
    border-bottom: 1px solid $cap-tab-view-bottom-border-color;

    li {
      padding: 10px 25px;
      font-weight: 600;
      font-size: toRem(14px);

      a.p-tabview-nav-link {
        color: $cap-tab-view-text-color;
      }

      &.p-highlight {
        background: $cap-tab-view-highlight-tab-background;
        margin-bottom: -1px;
        border: 1px solid $cap-tab-view-highlight-tab-border-color;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        border-bottom: none;
        position: relative;

        a.p-tabview-nav-link {
          color: var(--cap-primary-color);
        }
      }
    }
  }
}

body .p-paginator {
  display: block;
  border: none;
  float: right;
  background-color: inherit;
  text-align: right;
  width: 100%;
  padding: 15px 0;
  position: relative;

  @include media-breakpoint-down(xs) {
    text-align: center;
  }

  .p-paginator-element {
    padding: 0 7px;
    align-items: center;
    font-size: $cap-table-paginator-prev-next-font-size;
    line-height: $cap-table-paginator-prev-next-line-height;
    font-weight: normal;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }

  .p-paginator-first,
  .p-paginator-last {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }

  .p-paginator-next {
    background: $cap-white;
    border: 1px solid $cap-gray;
    border-left: none;

    &:not(.p-disabled) {
      color: var(--cap-primary-color);
    }

    .p-paginator-icon:before {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      content: '\f054';
    }
  }

  .p-paginator-prev {
    background: $cap-white;
    border: 1px solid $cap-gray;
    border-right: none;

    &:not(.p-disabled) {
      color: var(--cap-primary-color);
    }

    .p-paginator-icon:before {
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      content: '\f053';
    }
  }

  .p-paginator-pages {
    .p-paginator-element {
      color: var(--cap-primary-color);
      border: 1px solid $cap-gray;
      border-right: none;
      background-color: $cap-white;

      &.p-state-active {
        background: var(--cap-primary-color);
        color: $cap-white;
      }
    }
  }
}

body .p-disabled,
body .p-component:disabled {
  box-shadow: none;
}

.segmented-select {
  display: flex;
  border-radius: 20px;
  background-color: $cap-gray;
  position: relative;
  width: max-content;
  height: 40px;

  &__segment {
    cursor: pointer;
    z-index: 10;
    transition: all 0.5s;
    padding: 0 15px;

    span {
      @include flexCentering();
      color: rgba(51, 51, 51, 0.5);
      font-size: toRem(16px);
      font-weight: bold;
      position: relative;
      z-index: 10;
      width: 100%;
      height: 100%;
      transition: all 0.5s;
    }
  }

  &__animation {
    background-color: $primary;
    border-radius: 20px;
    box-shadow: 0 5px 13px 0 rgba($primary, 0.3);
    transition: all 0.5s;
    transform: scaleX(1.2);
    position: absolute;
    width: 100px;
    height: 100%;
  }

  .active span {
    color: $white;
  }
}

.navigation-button {
  display: inline-block;

  &__content {
    background: $cap-white;
    border-radius: 3px;
    border: 1px solid var(--cap-primary-color);
    box-shadow: 0 2px 4px 0 rgba(var(--cap-primary-color), 0.25);
    display: flex;
    align-items: center;
    height: 40px;
  }

  &__back,
  &__next {
    z-index: 901;
    cursor: pointer;
    width: 44px;
    height: 100%;
    color: var(--cap-primary-color);
    @include flexCentering();
  }

  &__back {
    border-right: 1px solid $cap-gray;
  }

  &__next {
    border-left: 1px solid $cap-gray;
  }

  &__title {
    color: var(--cap-primary-color);
    font-size: toRem(16px);
    font-weight: bold;
    padding: 0 25px;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

$sidebar-padding: 1.2rem;
$sidebar-header-height: 55px;
$sidebar-footer-height: 100px;

.cap-sidebar-header {
  background: var(--cap-primary-color);
  padding: 20px;
  font-weight: bold;
  font-size: 1.2em;
  color: $white;
  display: flex;
  justify-content: space-between;
  height: $sidebar-header-height;

  i {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

cap-sidebar {
  .p-sidebar-close-icon {
    display: none;
  }

  .cap-sidebar-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $sidebar-footer-height;
    padding: $sidebar-padding;
    background: $cap-white;
  }

  .cap-sidebar-content {
    padding: $sidebar-padding;
    height: calc(100% - #{$sidebar-header-height});
    overflow: auto;

    &.has-footer {
      height: calc(100% - #{$sidebar-header-height} - #{$sidebar-footer-height});
    }
  }
}

cap-sidebar p-sidebar {
  .p-sidebar {
    width: 350px;
    padding: 0;
    height: 100%;

    .p-sidebar-content {
      overflow: auto;
      height: 100vh;
    }
  }

  .p-sidebar-header {
    display: none;
  }
}

$padding: 15px 17px;

@mixin commonListStyle() {
  cursor: pointer;
  padding: $padding;
  font-size: toRem(16px);
  min-height: 16px;
  font-weight: 600;
  border-bottom: 1px solid $cap-gray;
}

.cap-user-header {
  display: flex;
  align-items: center;
}

.user-menu {
  min-width: 275px;

  &__items {
    li {
      @include commonListStyle();

      span {
        margin-left: 15px;
      }

      i {
        color: var(--cap-primary-color);
      }

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        background-color: $cap-user-menu-hover-color;
        color: var(--cap-primary-color);
      }
    }
  }
}

.user-info {
  display: inline-block;
  text-align: right;
  margin-right: 10px;

  .user-name {
    font-weight: bold;
    color: var(--cap-primary-color);
  }

  .user-email {
    margin-top: 3px;
    color: $cap-user-menu-subtext-color;
    font-size: toRem(10px);
  }
}

.menu-cta {
  width: $cap-user-menu-proportions;
  height: $cap-user-menu-proportions;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid var(--cap-primary-color);
  box-shadow: 0 2px 4px 0 rgba($cap-black, 0.25);
  @include flexCentering();

  i {
    margin: 0;
    color: var(--cap-primary-color);
  }
}

.amount-input {
  &--disabled {
    .amount-input__action {
      cursor: not-allowed;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    border: $cap-amount-input-border;
    border-radius: $cap-amount-input-border-radius;
    color: $cap-amount-input-text-color;
  }

  &__action {
    width: 38px;
    height: 38px;
    @include flexCentering();
    flex: 0 0 38px;
    cursor: pointer;
    transition: 0.25s all;
    border: 0;

    &:hover:not(.disabled) {
      background-color: $cap-platinum;
      color: var(--cap-primary-color);
    }
  }

  input {
    border-radius: 0;
    outline: none;
    border: 0;
    border-left: 1px solid $cap-gray;
    border-right: 1px solid $cap-gray;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
    flex: 1;
    align-self: stretch;
    color: $cap-amount-input-text-color;
    min-width: 0;
  }
}

.cap-sidebar {
  background-color: $cap-white;
  height: 100%;
  min-height: 100vh;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5);
  color: rgba(51, 51, 51, 0.8);
  position: relative;
  border-right: 1px solid $cap-sidebar-menu-delimiter-color;
  width: $cap-sidebar-menu-initial-width;
  transition: 0.25s all;

  &--collapsed {
    width: $cap-sidebar-menu-collapsed-width;

    li {
      text-align: center;

      i {
        margin: 0;
      }
    }
  }

  &__brand {
    text-align: center;
    padding: 40px 0;

    .brand-wrapper {
      cursor: pointer;
      height: 55px;
      outline: none;

      img {
        @include setObjectFit(contain);
      }
    }
  }

  &__title {
    color: #333;
    font-size: toRem(20px);
    font-weight: bold;
    margin-top: 10px;
  }

  &__menus {
    padding: 0;
    list-style: none;

    li {
      padding: 18px 18px 18px 22px;
      color: rgba(51, 51, 51, 0.8);
      font-size: toRem(20px);
      font-weight: 600;
      cursor: pointer;
      outline: none;
      border-left: 2px solid transparent;

      i {
        margin-right: 10px;
        width: 30px;
        text-align: center;
        color: var(--cap-primary-color);
        opacity: 0.5;
      }

      &:hover,
      &.active {
        background-color: rgba(99, 49, 137, 0.05);
        border-color: var(--cap-primary-color);
        color: var(--cap-primary-color);

        i {
          color: var(--cap-primary-color);
          opacity: 1;
        }
      }

      &.active {
        cursor: default;
      }
    }
  }

  &__button {
    visibility: hidden;
    width: $cap-sidebar-menu-button-proportions;
    height: $cap-sidebar-menu-button-proportions;
    background-color: var(--cap-primary-color);
    color: $cap-white;
    @include flexCentering;
    @include setAbsoluteVerticalCenter;
    right: -(math.div($cap-sidebar-menu-button-proportions, 2));
    border-radius: 50%;
    cursor: pointer;
    transition: 0.25s all;

    i {
      color: rgba($cap-white, 0.8);
    }

    &:hover {
      i {
        color: $cap-white;
      }
    }
  }

  &:hover {
    .cap-sidebar__button {
      visibility: visible;
    }
  }
}

.cap-image-gallery {
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.cap-image-gallery-container {
  display: grid;
  grid-template-columns: repeat(4,minmax(0,1fr));
  gap: .25rem;
}

.cap-image-gallery-item {
  cursor: pointer;

  &:first-of-type {
    grid-row: span 2/span 2;
    grid-column: span 2/span 2;
  }
}

.cap-image-gallery-more {
  background-color: rgba($cap-white, 0.6);
  display: inline-flex;
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1rem;
  color: var(--cap-primary-color);
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
}

.collapsable-text {
  .collapsable-text-content {
    position: relative;
    margin-bottom: 0;
    word-wrap: break-word;
    overflow: hidden;

    &[aria-expanded="true"] {
      &:after {
        display: none;
      }
    }

    &:after {
      position: absolute;
      width: 100%;
      height: 6rem;
      bottom: 0;
      left: 0;
      content: '';
      background: linear-gradient(hsla(0,0%,100%,0), var(--collapsed-gradient));
    }
  }

  .cap-button {
    &.primary.link,
    &.primary.link:hover,
    &.primary.link:focus {
      background-color: transparent;
      border: 0;
      box-shadow: none;
      color: var(--cap-primary-color);
      padding: 0;
      font-weight: 400;
    }
  }
}

$input-border-color: #ddd !default;
$cap-input-border-color: #ddd !default;
$cap-input-height: 30px !default;
$cap-light-gray: #f0f0f0 !default;
$cap-dark-gray: #333 !default;
$cap-accordion-icon-color: #848484 !default;
$cap-white: #fff !default;
$cap-black: #000 !default;
$cap-gray: #ddd !default;
$cap-success: #68e76f !default;
$cap-warning: #faa200 !default;
$cap-danger: #fa4331 !default;
$cap-platinum: #f9f9f9 !default;
$cap-primary-font: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif !default; /* stylelint-disable-line value-keyword-case */

// Toast
$cap-toast-background-color: #fff !default;
$cap-toast-text-color: #333 !default;
$cap-toast-background-color-success: $cap-toast-background-color !default;
$cap-toast-background-color-warning: $cap-toast-background-color !default;
$cap-toast-background-color-info: $cap-toast-background-color !default;
$cap-toast-background-color-error: $cap-toast-background-color !default;
$cap-toast-summary-font-size: 18px !default;
$cap-toast-summary-font-weight: 600 !default;
$cap-toast-summary-font-family: $cap-primary-font !default;
$cap-toast-summary-color: $cap-toast-text-color !default;

$cap-toast-detail-font-size: 14px !default;
$cap-toast-detail-text-color: #4c4c4c !default;

$cap-toast-close-icon-color: rgba(51, 51, 51, 0.15) !default;
$cap-toast-close-icon-top: 0 !default;
$cap-toast-close-icon-bottom: 0 !default;
$cap-toast-close-icon-right: 15px !default;
$cap-toast-close-icon-justify-content: center !default;
$cap-toast-close-icon-display: flex !default;
$cap-toast-close-icon-flex-direction: column !default;
$cap-toast-close-icon-flex-font-size: 20px !default;

$cap-toast-icon-font-size: 29px !default;
$cap-toast-icon-margin-right: 30px !default;
$cap-toast-border-radius: 5px !default;
$cap-toast-message-content-padding: 15px 20px !default;

$cap-toast-message-error-background: $cap-toast-background-color-error !default;
$cap-toast-message-error-border-left: 4px solid $cap-danger !default;

$cap-toast-message-info-background: $cap-toast-background-color-error !default;
$cap-toast-message-info-border-left: 4px solid var(--cap-primary-color) !default;

// Action Menu
$cap-action-menu-disabled-text-color: #776f6f;

// Fab Button
$cap-fab-button-size: 56px !default;
$cap-fab-button-margin-size: 16px !default;
$cap-fab-button-icon-size: 22px !default;

$cap-fab-button-width: $cap-fab-button-size !default;
$cap-fab-button-height: $cap-fab-button-size !default;
$cap-fab-button-background-color: var(--cap-primary-color) !default;
$cap-fab-button-border-radius: 50% !default;
$cap-fab-button-display: flex !default;
$cap-fab-button-justify-content: center !default;
$cap-fab-button-align-items: center !default;
$cap-fab-button-border: none !default;
$cap-fab-button-position: fixed !default;
$cap-fab-button-bottom: $cap-fab-button-margin-size !default;
$cap-fab-button-z-index: 10 !default;

$cap-fab-button-icon-width: $cap-fab-button-icon-size !default;
$cap-fab-button-icon-height: $cap-fab-button-icon-size !default;
$cap-fab-button-icon-font-size: $cap-fab-button-icon-size !default;
$cap-fab-button-icon-color: $cap-white !default;
$cap-fab-button-text-align: center !default;

$cap-fab-button-top-right-right: $cap-fab-button-margin-size !default;
$cap-fab-button-top-right-top: $cap-fab-button-margin-size !default;

$cap-fab-button-bottom-left-left: $cap-fab-button-margin-size !default;
$cap-fab-button-bottom-left-bottom: $cap-fab-button-margin-size !default;

$cap-fab-button-bottom-top-left-left: $cap-fab-button-margin-size !default;
$cap-fab-button-bottom-top-left-top: $cap-fab-button-margin-size !default;

$cap-fab-button-bottom-right-right: $cap-fab-button-margin-size !default;
$cap-fab-button-bottom-right-bottom: $cap-fab-button-margin-size !default;

// File Upload
$cap-file-upload-padding: 15px !default;
$cap-file-upload-width: 100% !default;
$cap-file-upload-height: 140px !default;
$cap-file-upload-background-color: #fff !default;
$cap-file-upload-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !default;
$cap-file-upload-display: flex !default;
$cap-file-upload-flex-direction: column !default;
$cap-file-upload-justify-content: center !default;
$cap-file-upload-color: var(--cap-primary-color) !default;
$cap-file-upload-font-weight: 400 !default;

$cap-file-upload-dragover-border: 1px dashed var(--cap-primary-color) !default;
$cap-file-upload-icon-opacity: 0.5 !default;
$cap-file-upload-icon-font-size: 32px !default;
$cap-file-upload-icon-display: block !default;
$cap-file-upload-icon-margin-bottom: 8px !default;

// Form field
$cap-form-field-label-font-weight: 600 !default;

// Desktop Stepper
$cap-desktop-stepper-icon-size: 24px !default;
$cap-desktop-stepper-horizontal-background-color: transparent !default;
$cap-desktop-stepper-horizontal-header-container-white-space: nowrap !default;
$cap-desktop-stepper-horizontal-header-container-align-items: center !default;
$cap-desktop-stepper-horizontal-header-container-justify-content: space-between !default;
$cap-desktop-stepper-horizontal-header-height: 30px !default;
$cap-desktop-stepper-horizontal-align-items: center !default;

$cap-desktop-stepper-icon-content-align-self: center !default;
$cap-desktop-stepper-icon-content-line-height: 1.5 !default;
$cap-desktop-stepper-icon-border-radius: 50% !default;
$cap-desktop-stepper-icon-border: 1px solid var(--cap-primary-color) !default;
$cap-desktop-stepper-icon-height: $cap-desktop-stepper-icon-size !default;
$cap-desktop-stepper-icon-width: $cap-desktop-stepper-icon-size !default;
$cap-desktop-stepper-step-icon-background-color: $cap-white !default;
$cap-desktop-stepper-step-icon-color: var(--cap-primary-color) !default;
$cap-desktop-stepper-step-icon-justify-content: center !default;
$cap-desktop-stepper-step-icon-completed-background-color: var(--cap-primary-color) !default;
$cap-desktop-stepper-step-icon-color: white !default;

$cap-desktop-stepper-horizontal-line-border-top-style: solid !default;
$cap-desktop-stepper-horizontal-line-min-width: 32px !default;
$cap-desktop-stepper-horizontal-line-opacity: 0.3 !default;
$cap-desktop-stepper-horizontal-line-border-top: 5px solid var(--cap-primary-color) !default;
$cap-desktop-stepper-horizontal-line-color: var(--cap-primary-color) !default;

$cap-desktop-stepper-step-label-font-size: 10px !default;
$cap-desktop-stepper-step-label-active-color: var(--cap-primary-color) !default;
$cap-desktop-stepper-step-label-inactive-color: var(--text-color-2) !default;

// Dropdown
$cap-dropdown-background: $cap-white !default;
$cap-dropdown-border: 1px solid $input-border-color !default;
$cap-dropdown-transition: border-color 0.2s !default;
$cap-dropdown-height: $cap-input-height !default;
$cap-dropdown-label-container-height: $cap-input-height !default;

$cap-dropdown-multiselect-label-padding: .95rem .7rem !default;
$cap-dropdown-multiselect-label-padding-right: 2em !default;
$cap-dropdown-multiselect-label-height: calc(#{$cap-input-height} - 2px) !default;
$cap-dropdown-multiselect-justify-content: center !default;
$cap-dropdown-multiselect-flex-direction: column !default;

$cap-dropdown-multiselect-selected-img-width: 16px !default;
$cap-dropdown-multiselect-selected-line-height: 16px !default;
$cap-dropdown-multiselect-selected-icon-font-size: 16px !default;
$cap-dropdown-multiselect-selected-img-vertical-align: middle !default;
$cap-dropdown-multiselect-selected-label-vertical-align: middle !default;
$cap-dropdown-multiselect-selected-label-margin-left: 0.5em !default;

$cap-dropdown-multiselect-trigger-background-color: $cap-white !default;
$cap-dropdown-multiselect-trigger-width: 2rem !default;
$cap-dropdown-multiselect-trigger-line-height: 2rem !default;
$cap-dropdown-multiselect-trigger-text-align: center !default;
$cap-dropdown-multiselect-trigger-color: var(--primary-text) !default;
$cap-dropdown-multiselect-trigger-border-left: 1px solid transparent !default;

$cap-dropdown-multiselect-clear-icon: var(--primary-text) !default;
$cap-dropdown-multiselect-cap-dropdown-label-padding-right: 4em !default;
$cap-dropdown-multiselect-input-padding: 8px 12px 8px 40px !default;
$cap-dropdown-multiselect-icon-color: var(--cap-primary-color) !default;
$cap-dropdown-multiselect-icon-margin-right: 5px !default;

$cap-dropdown-multiselect-checkbox-border: 1px solid $input-border-color !default;
$cap-dropdown-multiselect-checkbox-background-color: $cap-white !default;
$cap-dropdown-multiselect-checkbox-width: 20px !default;
$cap-dropdown-multiselect-checkbox-height: 20px !default;
$cap-dropdown-multiselect-checkbox-text-align: center !default;
$cap-dropdown-multiselect-checkbox-border-radius: 3px !default;
$cap-dropdown-multiselect-checkbox-border-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s !default;

$cap-dropdown-multiselect-panel-padding: 0 !default;
$cap-dropdown-multiselect-panel-border: 1px solid $input-border-color !default;
$cap-dropdown-multiselect-panel-background-color: $cap-white !default;
$cap-dropdown-multiselect-panel-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !default;
$cap-dropdown-multiselect-panel-items-padding: 0 !default;

$cap-dropdown-multiselect-panel-item-group-margin: 0 !default;
$cap-dropdown-multiselect-panel-item-group-padding: 8px 12px !default;
$cap-dropdown-multiselect-panel-item-group-border: 0 none !default;
$cap-dropdown-multiselect-panel-item-group-color: var(--primary-text) !default;
$cap-dropdown-multiselect-panel-item-group-background-color: transparent !default;
$cap-dropdown-multiselect-panel-item-group-border-radius: 0 !default;
$cap-dropdown-multiselect-panel-item-group-border-top: 1px solid rgba(0, 0, 0, 0.15) !default;
$cap-dropdown-multiselect-panel-item-highlight-color: $cap-white !default;
$cap-dropdown-multiselect-panel-item-highlight-background-color: var(--cap-primary-color) !default;

$cap-dropdown-multiselect-panel-custom-item-height: 18px !default;
$cap-dropdown-multiselect-panel-custom-img-width: 24px !default;
$cap-dropdown-multiselect-panel-custom-img-top: 50% !default;
$cap-dropdown-multiselect-panel-custom-img-left: 0 !default;
$cap-dropdown-multiselect-panel-custom-img-transform: translateY(-50%) !default;

$cap-dropdown-multiselect-panel-custom-label-font-size: 14px !default;
$cap-dropdown-multiselect-panel-custom-label-top: 50% !default;
$cap-dropdown-multiselect-panel-custom-label-left: 30px !default;
$cap-dropdown-multiselect-panel-custom-label-position: absolute !default;
$cap-dropdown-multiselect-panel-custom-label-transform: translateY(-50%) !default;

$cap-dropdown-multiselect-panel-filter-container-border-bottom: 1px solid #eaeaea !default;
$cap-dropdown-multiselect-panel-filter-container-color: var(--primary-text) !default;
$cap-dropdown-multiselect-panel-filter-container-background-color: $cap-white !default;
$cap-dropdown-multiselect-panel-filter-container-margin: 0 !default;
$cap-dropdown-multiselect-panel-filter-container-padding: 0 !default;

$cap-dropdown-multiselect-panel-filter-container-input-padding: 8px 12px 8px 40px !default;
$cap-dropdown-multiselect-panel-filter-container-input-width: 100% !default;
$cap-dropdown-multiselect-panel-filter-container-input-border: none !default;

$cap-dropdown-multiselect-panel-filter-container-icon-position: absolute !default;
$cap-dropdown-multiselect-panel-filter-container-icon-left: 12px !default;
$cap-dropdown-multiselect-panel-filter-container-icon-top: 50% !default;
$cap-dropdown-multiselect-panel-filter-container-icon-color: var(--cap-primary-color) !default;

// Floating labels
$floating-input-border: 1px solid $input-border-color !default;
$floating-placeholder-text-color: #5f6062 !default;

// Editor
$cap-editor-tox-border-radius: 3px !default;
$cap-editor-tox-border-color: $cap-input-border-color !default;
$cap-editor-tox-edit-area-border-top: none !default;
$cap-editor-tox-toolbar-padding: 10px 0 !default;
$cap-editor-tox-toolbar-border: none !default;
$cap-editor-tox-toolbar-background: #ddd !default;
$cap-editor-tox-toolbar-color: rgba(51, 51, 51, 0.5) !default;

$cap-editor-tox-toolbar-btn-margin: 0 5px !default;
$cap-editor-tox-toolbar-btn-hover-background: darken(#ddd, 5%) !default;
$cap-editor-tox-toolbar-btn-select-background: #fff !default;

$cap-editor-svg-fill: rgba(51, 51, 51, 0.5) !default;

// PDF Viewer
$cap-pdf-viewer-button-size: 30px !default;
$cap-pdf-viewer-toolbar-icon-right: 15px !default;
$cap-pdf-viewer-toolbar-padding: 10px !default;

$cap-pdf-viewer-background-color: $cap-white !default;
$cap-pdf-viewer-width: 100% !default;
$cap-pdf-viewer-max-height: 100% !default;
$cap-pdf-viewer-display: block !default;
$cap-pdf-viewer-position: relative !default;

$cap-pdf-viewer-info-row-margin: 0 auto !default;
$cap-pdf-viewer-info-row-width: 100% !default;
$cap-pdf-viewer-info-row-text-align: center !default;
$cap-pdf-viewer-info-row-display: flex !default;
$cap-pdf-viewer-info-row-flex-direction: row !default;
$cap-pdf-viewer-info-row-flex-justify-content: center !default;

$cap-pdf-viewer-page-controls-hover-visibility: visible !default;
$cap-pdf-viewer-page-controls-display: inline-flex !default;
$cap-pdf-viewer-page-controls-visibility: hidden !default;
$cap-pdf-viewer-page-controls-width: 100% !default;
$cap-pdf-viewer-page-controls-justify-content: space-between !default;

$cap-pdf-viewer-page-controls-opacity: 0.4 !default;
$cap-pdf-viewer-page-controls-position: absolute !default;
$cap-pdf-viewer-page-controls-nav-width: 75px !default;
$cap-pdf-viewer-page-controls-nav-height: 200px !default;
$cap-pdf-viewer-page-controls-nav-background-color: var(--cap-primary-color) !default;
$cap-pdf-viewer-page-controls-nav-transition: opacity 0.25s linear !default;
$cap-pdf-viewer-page-controls-nav-icon-position: absolute !default;
$cap-pdf-viewer-page-controls-nav-icon-top: 50% !default;
$cap-pdf-viewer-page-controls-nav-icon-transform: translateY(-50%) !default;
$cap-pdf-viewer-page-controls-nav-icon-color: $cap-white !default;
$cap-pdf-viewer-page-controls-top: calc(50% - 0.5 * #{$cap-pdf-viewer-page-controls-nav-height}) !default;

$cap-pdf-viewer-toolbar-margin: 0 !default;
$cap-pdf-viewer-toolbar-info-column-float: left !default;
$cap-pdf-viewer-toolbar-info-column-padding: 10px !default;
$cap-pdf-viewer-toolbar-info-column-height: 100% !default;
$cap-pdf-viewer-toolbar-info-column-justify-content: center !default;
$cap-pdf-viewer-toolbar-info-column-display: flex !default;
$cap-pdf-viewer-toolbar-info-column-flex-direction: column !default;
$cap-pdf-viewer-toolbar-info-column-border-radius: 3px !default;

// Progress Bar
$cap-progress-bar-border: 0 none !default;
$cap-progress-bar-height: 24px !default;
$cap-progress-bar-background-color: #eaeaea !default;

$cap-progress-bar-progressbar-value-height: 100% !default;
$cap-progress-bar-progressbar-value-position: absolute !default;
$cap-progress-bar-progressbar-value-border: 0 none !default;
$cap-progress-bar-progressbar-value-margin: 0 !default;
$cap-progress-bar-progressbar-value-background: var(--cap-primary-color) !default;

$cap-progress-bar-label-height: 100% !default;
$cap-progress-bar-label-width: 100% !default;
$cap-progress-bar-label-position: absolute !default;
$cap-progress-bar-label-margin-top: 0 !default;
$cap-progress-bar-label-text-align: center !default;
$cap-progress-bar-label-font-weight: bold !default;
$cap-progress-bar-label-color: var(--primary-text) !default;
$cap-progress-bar-label-line-height: 24px !default;

// Progress Circle
$cap-progress-circle-position: relative !default;
$cap-progress-circle-display: inline-block !default;
$cap-progress-circle-custom: center !default;
$cap-progress-circle-position: absolute !default;
$cap-progress-circle-content-top: 0 !default;
$cap-progress-circle-subtitle-top: 50% !default;

// Loader
$cap-loader-image-width: fit-content !default;
$cap-loader-image-margin: 0 auto !default;

$cap-loader-position: fixed !default;
$cap-loader-top: 0 !default;
$cap-loader-left: 0 !default;
$cap-loader-bottom: 0 !default;
$cap-loader-width: 100% !default;
$cap-loader-background: rgba(0, 0, 0, 0.65) !default;
$cap-loader-z-index: 99 !default;
$cap-loader-text-align: center !default;
$cap-loader-display: flex !default;
$cap-loader-flex-direction: column !default;
$cap-loader-justify-content: center !default;
$cap-loader-text-color: var(--cap-primary-color) !default;
$cap-loader-progress-spinner-color-stroke: var(--cap-primary-color) !default;
$cap-loader-text-animation: dots 1s steps(5, end) infinite !default;

// Mobile Tab Bar
$cap-mobile-tab-bar-margin: 0.3rem !default;
$cap-mobile-tab-bar-display: flex !default;
$cap-mobile-tab-bar-justify-content: space-evenly !default;
$cap-mobile-tab-bar-margin-top: $cap-mobile-tab-bar-margin !default;
$cap-mobile-tab-bar-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.3) !default;
$cap-mobile-tab-bar-padding: 10px 0 !default;
$cap-mobile-tab-bar-background-color: #fff !default;
$cap-mobile-tab-bar-shape-font-size: 2rem !default;
$cap-mobile-tab-bar-menu-item-margin-bottom: $cap-mobile-tab-bar-margin !default;
$cap-mobile-tab-bar-menu-item-font-size: 0.8rem !default;
$cap-mobile-tab-bar-menu-item-weight: 600 !default;

$cap-mobile-tab-bar-active-text-color: var(--cap-primary-color) !default;
$cap-mobile-tab-bar-rectangle-text-align: center !default;
$cap-mobile-tab-bar-word-break: break-all !default;
$cap-mobile-tab-bar-max-width: 20% !default;

// Notification Bar
$cap-notification-bar-square-size: 2rem !default;
$cap-notification-bar-circle-size: 1.2rem !default;
$cap-notification-bar-icon-size: 50px !default;
$cap-notification-bar-box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.2) !default;

$cap-notification-bar-background-color: #fbfbfb !default;
$cap-notification-bar-height: 100% !default;
$cap-notification-bar-padding: 0 1.5rem !default;

$cap-notification-bar-head-display: flex !default;
$cap-notification-bar-head-justify-content: space-between !default;
$cap-notification-bar-head-align-items: center !default;
$cap-notification-bar-head-margin: 2.5rem 0 2rem 0 !default;

$cap-notification-bar-icon-angle-left-color: var(--text-color-2) !default;
$cap-notification-bar-icon-angle-font-size: 2rem !default;
$cap-notification-bar-times-color: var(--cap-primary-color) !default;
$cap-notification-bar-times-text-align: center !default;
$cap-notification-bar-times-font-size: 1.3rem !default;

$cap-notification-bar-bell-width: $cap-notification-bar-square-size !default;
$cap-notification-bar-bell-height: $cap-notification-bar-square-size !default;
$cap-notification-bar-bell-border-radius: 3px !default;
$cap-notification-bar-bell-box-shadow: 0 1px 1px 0 dimgrey !default;
$cap-notification-bar-bell-background-color: var(--cap-primary-color) !default;
$cap-notification-bar-bell-center: center !default;
$cap-notification-bar-bell-position: relative !default;
$cap-notification-bar-bell-color: white !default;
$cap-notification-bar-bell-padding-top: 25% !default;

$cap-notification-bottom-bar-z-index: 1 !default;
$cap-notification-bottom-bar-box-shadow: 0 -12px 10px -10px rgba(0, 0, 0, 0.2) !default;

$cap-notification-circle-text-align: center !default;
$cap-notification-circle-border-radius: 50% !default;
$cap-notification-circle-line-height: $cap-notification-bar-circle-size !default;
$cap-notification-bar-circle-position: absolute !default;
$cap-notification-circle-font-size: 0.65rem !default;
$cap-notification-circle-font-weight: 700 !default;

$cap-notification-bar-text-font-weight: 600 !default;
$cap-notification-bar-text-margin-bottom: 0 !default;
$cap-notification-bar-text-strong-color: var(--text-color-2) !default;
$cap-notification-bar-text-scroll: scroll !default;
$cap-notification-item-list-style: none !default;
$cap-notification-item-margin: 1rem 0 !default;
$cap-notification-trash-icon-width: $cap-notification-bar-square-size !default;
$cap-notification-trash-icon-height: $cap-notification-bar-square-size !default;
$cap-notification-trash-icon-line-height: $cap-notification-bar-square-size !default;
$cap-notification-trash-icon-cursor: pointer !default;
$cap-notification-trash-icon-color: var(--cap-primary-color) !default;
$cap-notification-trash-border: solid 1px var(--cap-primary-color) !default;
$cap-notification-trash-text-align: center !default;
$cap-notification-trash-border-radius: 4px !default;
$cap-notification-trash-margin: 1.5rem 1.5rem 1rem 0 !default;

$cap-notification-bar-item-card-display: flex !default;
$cap-notification-bar-item-card-align-items: center !default;
$cap-notification-bar-item-card-padding: 1rem !default;
$cap-notification-bar-item-card-margin-top: 1rem !default;
$cap-notification-bar-item-card-background-color: white !default;
$cap-notification-bar-item-card-border-radius: 4px !default;

$cap-notification-bar-item-card-status-icon-text-align: center !default;
$cap-notification-bar-item-card-status-icon-font-size: 1.3rem !default;
$cap-notification-bar-item-card-status-icon-margin-right: 1rem !default;
$cap-notification-bar-item-card-status-icon-line-height: 2.6rem !default;
$cap-notification-bar-item-card-status-icon-color: white !default;
$cap-notification-bar-item-card-word-break: break-word !default;
$cap-notification-bar-item-card-line-height: 1em !default;

$cap-notification-bar-item-card-title-font-weight: bold !default;
$cap-notification-bar-item-card-title-font-size: 18px !default;
$cap-notification-bar-item-card-title-margin-bottom: 0 !default;
$cap-notification-bar-item-card-title-color: var(--text-color-2) !default;

$cap-notification-bar-item-card-subtitle-font-weight: 500 !default;
$cap-notification-bar-item-card-subtitle-font-size: 12px !default;
$cap-notification-bar-item-card-subtitle-margin-bottom: 0.35rem !default;
$cap-notification-bar-item-card-subtitle-margin-top: 0.25rem !default;
$cap-notification-bar-item-card-subtitle-color: var(--text-color-3) !default;

$cap-notification-bar-item-card-body-margin-bottom: 0 !default;
$cap-notification-bar-item-card-body-padding: 0 !default;
$cap-notification-bar-item-card-body-line-height: 1em !default;
$cap-notification-bar-item-card-body-font-size: 13px !default;
$cap-notification-bar-item-card-body-color: var(--text-color-2) !default;
$cap-notification-unread-border: solid 1px var(--cap-primary-color) !default;
$cap-notification-tabs-padding-left: 10px !default;
$cap-notification-tabs-padding-right: 10px !default;
$cap-notification-tabs-col-padding-left: 5px !default;
$cap-notification-tabs-col-padding-right: 5px !default;

$cap-notification-tabs-tab-border: 1px solid var(--cap-primary-color) !default;
$cap-notification-tabs-tab-padding: 10px 15px !default;
$cap-notification-tabs-background-color: white !default;
$cap-notification-tabs-border-radius: 4px !default;
$cap-notification-tabs-text-align: center !default;
$cap-notification-tabs-font-size: 15px !default;
$cap-notification-tabs-font-weight: bold !default;
$cap-notification-tabs-transition: all ease 200ms !default;
$cap-notification-tabs-white-space: nowrap !default;
$cap-notification-tabs-margin-bottom: 10px !default;

$cap-notification-tabs-tab-selected-background-color: var(--cap-primary-color) !default;
$cap-notification-tabs-tab-selected-color: $cap-white !default;

// Forms
$cap-forms-placeholder-text-color: #5f6062 !default;
$cap-form-element-label-font-weight: 600 !default;
$cap-form-element-label-color: var(--primary-text) !default;

$cap-form-element-line-height: 26px !default;
$cap-form-element-table-display: table !default;
$cap-form-element-table-width: 100% !default;

$cap-form-element-table-first-child-width: 30% !default;
$cap-form-element-table-first-child-display: table-cell !default;
$cap-form-element-table-first-child-vertical-align: top !default;

$cap-form-element-table-label: 12px !default;
$cap-form-element-table-input-height: 26px !default;

// Horizontal Navbar
$cap-horizontal-navbar: #333 !default;
$cap-horizontal-navbar-width: 100% !default;
$cap-horizontal-navbar-z-index: 9 !default;
$cap-horizontal-navbar-padding: 0 !default;
$cap-horizontal-navbar-background-color: $cap-white !default;
$cap-horizontal-navbar-margin: 0 auto !default;

$cap-horizontal-navbar-nav-item-font-size: 1em !default;
$cap-horizontal-navbar-nav-item-font-weight: 600 !default;
$cap-horizontal-navbar-nav-item-border-bottom: 2px solid transparent !default;
$cap-horizontal-navbar-nav-item-color: $cap-horizontal-navbar !default;
$cap-horizontal-navbar-nav-item-width: 120px !default;
$cap-horizontal-navbar-nav-item-position: relative !default;

$cap-horizontal-navbar-nav-item-dropdown-menu-text-align: left !default;
$cap-horizontal-navbar-nav-item-dropdown-menu-width: 100% !default;

$cap-horizontal-navbar-nav-item-dropdown-menu-text-font-size: 1em !default;
$cap-horizontal-navbar-nav-item-dropdown-menu-text-font-weight: bold !default;
$cap-horizontal-navbar-nav-item-dropdown-menu-text-padding: 10px 10px 10px 35px !default;
$cap-horizontal-navbar-nav-item-dropdown-menu-text-border-bottom: 1px solid var(--cap-primary-color) !default;

$cap-horizontal-navbar-nav-item-dropdown-menu-text-hover-background-color: var(--cap-primary-color) !default;
$cap-horizontal-navbar-nav-item-dropdown-menu-text-hover-color: $cap-white !default;

$cap-horizontal-navbar-nav-item-dropdown-menu-icon-float: right !default;
$cap-horizontal-navbar-nav-item-dropdown-menu-icon-margin-top: 3px !default;

$cap-horizontal-navbar-nav-item-overlay-background: var(--cap-primary-color) !default;
$cap-horizontal-navbar-nav-item-overlay-z-index: -1 !default;
$cap-horizontal-navbar-nav-item-overlay-opacity: 0.03 !default;

$cap-horizontal-navbar-nav-bar-gradient-width: 75px !default;
$cap-horizontal-navbar-nav-item-overlay-background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 50%, rgba(255, 255, 255, 1) 100%) !default;
$cap-horizontal-navbar-icon-color: var(--cap-primary-color-shadow);

// Image Viewer
$cap-image-viewer-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.5) !default;
$cap-image-viewer-navigator-desktop-spacing: 24px !default;
$cap-image-viewer-navigator-table-spacing: -25px !default;
$cap-image-navigator-mobile-spacing: 5px !default;
$cap-image-viewer-selector-color: #4c4c4c !default;

$cap-image-viewer-position: fixed !default;
$cap-image-viewer-width: 100% !default;
$cap-image-viewer-height: 100% !default;
$cap-image-viewer-left: 0 !default;
$cap-image-viewer-z-index: 1000 !default;
$cap-image-viewer-background: rgba(51, 51, 51, 0.6) !default;

$cap-image-viewer-header-padding: 18px 25px !default;
$cap-image-viewer-header-display: flex !default;
$cap-image-viewer-header-justify-content: space-between !default;
$cap-image-viewer-actions-button-background-color: #f6f6f6;

$cap-image-viewer-title-font-weight: 900 !default;
$cap-image-viewer-header-closer-margin-left: 22px !default;
$cap-image-viewer-header-preview-wrapper-width: 100% !default;
$cap-image-viewer-header-preview-wrapper-height: 100% !default;
$cap-image-viewer-header-preview-wrapper-box-shadow: $cap-image-viewer-shadow !default;
$cap-image-viewer-header-preview-wrapper-overflow: auto !default;
$cap-image-viewer-header-preview-wrapper-background: $cap-white !default;

$cap-image-viewer-body-wrapper-display: flex !default;
$cap-image-viewer-body-wrapper-background: $cap-black !default;
$cap-image-viewer-body-wrapper-padding: 0 27px !default;
$cap-image-viewer-body-wrapper-border-radius: 5px !default;
$cap-image-viewer-body-wrapper-box-shadow: $cap-image-viewer-shadow !default;
$cap-image-viewer-body-wrapper-color: #4c4c4c !default;
$cap-image-viewer-body-wrapper-align-items: center !default;

$cap-image-viewer-body-container-background: $cap-white !default;

// Range Filters
$cap-range-filters-label-font-weight: 600 !default;
$cap-range-filters-label-font-size: 14px !default;
$cap-range-filters-label-color: var(--primary-text) !default;

$cap-range-filters-overlay-panel-z-index: 100 !default;
$cap-range-filters-overlay-panel-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.1) !default;
$cap-range-filters-overlay-panel-background-color: #fff !default;
$cap-range-filters-overlay-panel-border-radius: 3px !default;
$cap-range-filters-overlay-panel-min-width: 200px !default;

$cap-range-filters-button-background-color: #fff !default;
$cap-range-filters-button-color: var(--primary-text) !default;
$cap-range-filters-button-border: 1px solid var(--cap-primary-color) !default;
$cap-range-filters-button-border-radius: 20px !default;
$cap-range-filters-button-height: $cap-input-height !default;
$cap-range-filters-button-font-size: 1em !default;
$cap-range-filters-button-cursor: pointer !default;
$cap-range-filters-button-padding: 5px 0 5px 22px !default;
$cap-range-filters-button-display: flex !default;
$cap-range-filters-button-flex-direction: column !default;
$cap-range-filters-button-justify-content: center !default;

$cap-range-filters-button-pre-value-icon-color: var(--cap-primary-color) !default;
$cap-range-filters-button-pre-value-icon-opacity: 0.5 !default;
$cap-range-filters-button-pre-value-icon-margin-right: 8px !default;

$cap-range-filters-button-icon-chevron-color: #5f6062 !default;
$cap-range-filters-button-icon-chevron-opacity: 1 !default;
$cap-range-filters-button-icon-chevron-font-size: 1em !default;
$cap-range-filters-button-icon-chevron-margin-right: 12px !default;
$cap-range-filters-button-icon-chevron-margin-top: 3px !default;
$cap-range-filters-button-padding-left: 27px !default;

// Row Action
$cap-row-action-position: relative !default;
$cap-row-action-text-align: right !default;

$cap-row-action-items-padding: 0 !default;
$cap-row-action-items-list-style: none !default;
$cap-row-action-items-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.15) !default;
$cap-row-action-items-background-color: $cap-white !default;
$cap-row-action-items-position: absolute !default;
$cap-row-action-items-text-align: left !default;
$cap-row-action-items-right: 0 !default;
$cap-row-action-items-margin: 10px 0 0 !default;
$cap-row-action-items-z-index: 1 !default;
$cap-row-action-items-border-radius: 3px !default;
$cap-row-action-items-visibility: hidden !default;
$cap-row-action-items-height: 0 !default;
$cap-row-action-items-overflow: hidden !default;
$cap-row-action-items-cursor: pointer !default;

$cap-row-action-items-open-visibility: visible !default;
$cap-row-action-items-open-height: auto !default;
$cap-row-action-items-open-overflow: visible !default;

$cap-row-action-item-display: flex !default;
$cap-row-action-item-cursor: pointer !default;
$cap-row-action-item-padding: 10px 12px !default;
$cap-row-action-item-border-bottom: 1px solid #ddd !default;
$cap-row-action-item-hover-background-color: #f9f9f9 !default;
$cap-row-action-item-last-child-border: none !default;

$cap-row-action-items-icon-font-size: 18px !default;
$cap-row-action-items-icon-margin: auto 14px auto 0 !default;
$cap-row-action-items-icon-color: var(--cap-primary-color) !default;
$cap-row-action-items-icon-margin: 0 !default;

$cap-row-action-items-info-margin: auto 0 !default;
$cap-row-action-items-info-text-margin: 0 !default;

$cap-row-action-items-info-title-font-size: 14px !default;
$cap-row-action-items-info-title-font-weight: 700 !default;
$cap-row-action-items-info-title-font-size: 12px !default;

// Scanner
$cap-scanner-header-height: 4rem !default;
$cap-scanner-angle-size: 3rem !default;
$cap-scanner-margin-x: 15% !default;

$cap-scanner-scan-container-width: 100% !default;
$cap-scanner-scan-container-height: 100% !default;
$cap-scanner-scan-container-position: absolute !default;
$cap-scanner-scan-container-top: 50% !default;
$cap-scanner-scan-container-left: 50% !default;
$cap-scanner-scan-container-transform: translate(-50%, -50%) !default;
$cap-scanner-scan-background-color: #fff !default;

$cap-scanner-scan-header-color: var(--cap-primary-color) !default;
$cap-scanner-scan-header-font-size: 2rem !default;
$cap-scanner-scan-header-height: $cap-scanner-header-height !default;
$cap-scanner-scan-header-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.15) !default;
$cap-scanner-scan-header-background-color: #fff !default;
$cap-scanner-scan-header-cursor: pointer !default;
$cap-scanner-scan-header-hover-font-size: 1.1em !default;
$cap-scanner-scan-header-hover-filter: grayscale(20%) !default;

$cap-scanner-scan-message-position: absolute !default;
$cap-scanner-scan-message-right: 0 !default;
$cap-scanner-scan-message-left: 0 !default;
$cap-scanner-scan-message-z-index: 1 !default;
$cap-scanner-scan-message-height: 3rem !default;
$cap-scanner-scan-message-max-width: 22rem !default;
$cap-scanner-scan-message-border-radius: 25px !default;
$cap-scanner-scan-message-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.15) !default;
$cap-scanner-scan-message-background-color: #fff !default;

$cap-scanner-scan-message-text-overflow: hidden !default;
$cap-scanner-scan-message-font-size: 1rem !default;

$cap-scanner-zxing-position: absolute !default;
$cap-scanner-zxing-top: $cap-scanner-header-height !default;
$cap-scanner-zxing-height: calc(100% - #{$cap-scanner-header-height}) !default;
$cap-scanner-zxing-width: 100vw !default;
$cap-scanner-zxing-z-index: 1 !default;

$cap-scanner-zxing-video-width: 100% !default;
$cap-scanner-zxing-video-height: 100% !default;

$cap-scanner-error-message-position: relative !default;
$cap-scanner-error-message-z-index: 1 !default;
$cap-scanner-error-message-top: 45vh !default;

$cap-scanner-scan-list-strikethrough-text-decoration: line-through !default;
$cap-scanner-scan-list-text-color: var(--error-color) !default;
$cap-scanner-scan-list-text-cursor: pointer !default;
$cap-scanner-scan-list-text-font-size: 1.2rem !default;
$cap-scanner-scan-list-text-hover-color: #f00 !default;
$cap-scanner-scan-list-text-hover-font-size: 1.3em !default;

// Input Mask
$cap-input-mask-width: 100% !default;
$cap-input-mask-text-width: 100% !default;

// Inputs
$cap-input-group-addon-background-color: $cap-white !default;
$cap-input-group-addon-color: var(--primary-text) !default;
$cap-input-group-addon-border-color: #ddd !default;
$cap-input-group-addon-max-height: $cap-input-height !default;
$cap-input-group-addon-height: auto !default;
$cap-input-group-addon-white-space: nowrap !default;

$cap-input-text-border-radius: 3px !default;
$cap-input-text-padding-left: 11px !default;
$cap-input-text-font-size: 1em !default;
$cap-input-text-font-family: var(--primary-font) !default;
$cap-input-text-color: var(--primary-text) !default;
$cap-input-text-border: 1px solid $input-border-color !default;
$cap-input-text-height: $cap-input-height !default;
$cap-input-text-display: flex !default;
$cap-input-text-flex-grow: 1 !default;

$cap-input-text-rounded-border-radius: 20px !default;
$cap-input-text-rounded-border-color: var(--cap-primary-color) !default;
$cap-input-text-rounded-height: $cap-input-height !default;
$cap-input-text-rounded-width: 100% !default;

// Links
$cap-link-color: var(--cap-primary-color) !default;
$cap-link-font-size: 1em !default;
$cap-link-font-weight: 600 !default;

// Card
$cap-card-border-radius: 8px !default;
$cap-card-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !default;
$cap-card-border: solid 1px $cap-light-gray !default;
$cap-card-background-color: $cap-white !default;
$cap-card-padding: 20px !default;

$cap-card-header-border-bottom: 1px solid $cap-light-gray !default;
$cap-card-header-padding-bottom: 10px !default;
$cap-card-header-font-size: 22px !default;
$cap-card-header-font-weight: 600 !default;
$cap-card-header-title-rs-text-align: right !default;
$cap-card-header-settings-margin-left: 5px !default;
$cap-card-header-settings-color: var(--cap-primary-color) !default;
$cap-card-header-settings-width: 2rem !default;
$cap-card-header-settings-height: 2rem !default;
$cap-card-header-settings-line-height: 2rem !default;
$cap-card-header-settings-text-align: center !default;

$cap-card-content-gutter-margin-top: 25px !default;

// Accordion
$cap-accordion-header-not-active-background-color: $cap-white !default;
$cap-accordion-header-not-active-color: $cap-dark-gray !default;
$cap-accordion-header-not-active-toggle-icon-color: $cap-dark-gray !default;
$cap-accordion-header-not-active-hover-bow-shadow: 0 5px 0 $cap-white, 0 1px 3px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.1) !default;
$cap-accordion-header-not-active-background-color: $cap-white !default;
$cap-accordion-header-not-active-color: $cap-black !default;
$cap-accordion-header-not-active-color-toggle-icon: $cap-black !default;

$cap-accordion-header-color: var(--primary-text) !default;
$cap-accordion-header-font-size: 16px !default;
$cap-accordion-header-font-weight: 700 !default;
$cap-accordion-header-height: 64px !default;
$cap-accordion-header-border-radius: 3px !default;
$cap-accordion-header-bow-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1) !default;
$cap-accordion-header-background-color: $cap-white !default;
$cap-accordion-header-padding: 0 1em !default;
$cap-accordion-header-line-height: 64px !default;
$cap-accordion-header-transition: background-color 0.2s !default;

$cap-accordion-content-border-br-radius: 3px !default;
$cap-accordion-content-border-bl-radius: 3px !default;
$cap-accordion-content-padding: 0 1em 1em 0.57em !default;
$cap-accordion-content-background-color: $cap-white !default;
$cap-accordion-content-color: var(--primary-text) !default;
$cap-accordion-content-wrapper-border-br-radius: 3px !default;
$cap-accordion-content-wrapper-border-bl-radius: 3px !default;
$cap-accordion-content-wrapper-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1) !default;

$cap-accordion-tab-header-font-size: 16px !default;
$cap-accordion-header-title-font-size: 13px !default;

$cap-accordion-tab-header-block-width: calc(100% - 2em) !default;
$cap-accordion-header-font-size: 15px !default;
$cap-accordion-header-status-font-size: 13px !default;
$cap-accordion-toggle-icon-float: right !default;
$cap-accordion-toggle-icon-transform: translateY(150%) !default;

// Accordion card.
$cap-accordion-card-header-width: 50% !default;
$cap-accordion-card-header-left-display: flex !default;
$cap-accordion-card-header-left-text-align: right !default;
$cap-accordion-card-title-font-weight: bold !default;
$cap-accordion-card-title-font-size: 16px !default;
$cap-accordion-card-title-min-height: 17px !default;
$cap-accordion-card-title-icon-color: var(--cap-primary-color) !default;
$cap-accordion-card-title-icon-margin-right: 10px !default;

$cap-accordion-card-actions-margin-left: 10px !default;
$cap-accordion-card-actions-transition: all 0.5s ease !default;
$cap-accordion-card-actions-hover: scale(1.1) !default;

$cap-accordion-card-toggler-font-size: 14px !default;
$cap-accordion-card-toggler-color: var(--cap-primary-color) !default;
$cap-accordion-card-toggler-font-size: 10px !default;

$cap-accordion-card-header-display: flex !default;
$cap-accordion-card-header-justify-content: space-between !default;
$cap-accordion-card-header-padding: 15px 20px !default;
$cap-accordion-card-header-border-radius: 3px !default;
$cap-accordion-card-header-background-color: $cap-white !default;

$cap-accordion-card-content-border-bl-radius: 3px !default;
$cap-accordion-card-content-border-br-radius: 3px !default;
$cap-accordion-card-content-transition: all 0.25s ease !default;
$cap-accordion-card-content-background-color: $cap-white !default;
$cap-accordion-card-content-open-padding: 0 15px 20px !default;

$cap-accordion-card-delimiter-margin-bottom: 15px !default;
$cap-accordion-card-delimiter-background: #ddd !default;

// Autocomplete
$cap-dropdown-hover-color: #eaeaea !default;
$cap-autocomplete-button-background-color: $cap-white !default;
$cap-autocomplete-button-color: $cap-black !default;
$cap-autocomplete-button-border: 1px solid $input-border-color !default;

$cap-autocomplete-input-height: 38px !default;
$cap-autocomplete-input-border: 1px solid $input-border-color !default;
$cap-autocomplete-input-padding: 0.4em !default;
$cap-autocomplete-input-error-border-color: $input-border-color !default;

$cap-autocomplete-button-width: 2em !default;
$cap-autocomplete-highlighted-option-background-color: $cap-dropdown-hover-color !default;
$cap-autocomplete-highlighted-option-color: var(--primary-text) !default;

$cap-autocomplete-button-hover-background-color: $cap-white !default;
$cap-autocomplete-button-hover-color: $cap-black !default;
$cap-autocomplete-button-border-color: $input-border-color !default;

// Button
$cap-button-disabled-background: #ddd !default;
$cap-button-disabled-text-color: #fff !default;
$cap-button-label-color: #fff !default;
$cap-button-success-text-color: #fff !default;
$cap-button-success-background: $cap-success !default;
$cap-button-warning-text-color: #fff !default;
$cap-button-warning-background: $cap-warning !default;
$cap-button-error-text-color: #fff !default;
$cap-button-error-background: $cap-danger !default;
$cap-button-secondary-text-color: var(--cap-primary-color) !default;
$cap-button-secondary-background: #fff !default;
$cap-button-tertiary-text-color: var(--cap-primary-color) !default;
$cap-button-tertiary-background: #f0f0f0 !default;

// Calendar
$cap-calendar-input-border-radius: 20px !default;
$cap-calendar-input-height: $cap-input-height !default;
$cap-calendar-datepicker-border-bottom: 1px solid $input-border-color !default;
$cap-calendar-datepicker-title-font-family: var(--primary-font) !default;
$cap-calendar-datepicker-title-font-size: 17px !default;
$cap-calendar-datepicker-title-font-weight: 600 !default;
$cap-calendar-datepicker-title-text-align: center !default;
$cap-calendar-datepicker-title-color: var(--primary-text) !default;

$cap-calendar-datepicker-prev-next-color: #5f6062 !default;
$cap-calendar-datepicker-trigger-button-border: 1px solid $input-border-color !default;
$cap-calendar-datepicker-trigger-background: var(--cap-primary-color) !default;
$cap-calendar-datepicker-trigger-color: $cap-white !default;
$cap-calendar-datepicker-trigger-width: $cap-input-height !default;
$cap-calendar-datepicker-trigger-height: $cap-input-height !default;
$cap-calendar-datepicker-trigger-icon-left: 2em !default;

$cap-calendar-input-text-width: calc(100% - 2.357em) !default; /* stylelint-disable-line number-max-precision */
$cap-calendar-input-text-height: $cap-input-height !default;

$cap-calendar-input-border: 1px solid $input-border-color !default;
$cap-calendar-input-height: $cap-input-height !default;
$cap-calendar-input-width: calc(100% - #{$cap-input-height}) !default;
$cap-calendar-input-error-border: 1px solid $input-border-color !default;
$cap-calendar-input-error-border-right: 0 none !default;

$cap-calendar-datepicker-widget-content-text-align: center !default;
$cap-calendar-datepicker-widget-content-state-default-width: 34.5714px !default; /* stylelint-disable-line number-max-precision */
$cap-calendar-datepicker-widget-content-state-default-border-radius: 50% !default;
$cap-calendar-datepicker-widget-content-state-active-background-color: var(--cap-primary-color) !default;
$cap-calendar-datepicker-widget-content-state-active-color: $cap-white !default;
$cap-calendar-datepicker-widget-content-state-not-active-background-color: var(--cap-primary-color) !default;
$cap-calendar-datepicker-widget-content-state-not-active-color: $cap-white !default;

$cap-calendar-input-hover-border-color: var(--cap-primary-color) !default;
$cap-calendar-input-hover-border-tl-radius: $cap-calendar-input-border-radius !default;
$cap-calendar-input-hover-border-bl-radius: $cap-calendar-input-border-radius !default;
$cap-calendar-input-hover-border-height: $cap-calendar-input-height !default;

$cap-calendar-input-hover-trigger-border-color: var(--cap-primary-color) !default;
$cap-calendar-input-hover-trigger-border-tr-radius: $cap-calendar-input-border-radius !default;
$cap-calendar-input-hover-trigger-border-br-radius: $cap-calendar-input-border-radius !default;
$cap-calendar-input-hover-trigger-height: $cap-calendar-input-height !default;
$cap-calendar-input-hover-trigger-font-size: 0.8em !default;
$cap-calendar-input-hover-trigger-padding-top: 1px !default;
$cap-calendar-input-hover-trigger-button-icon-left-font-size: 1.5em !default;

$cap-calendar-double-date-width: 105% !default;

$cap-calendar-w-btn-hover-border: 1px solid var(--cap-primary-color) !default;
$cap-calendar-w-btn-hover-border-radius: 20px !default;
$cap-calendar-w-btn-hover-background-color: $cap-white !default;
$cap-calendar-w-btn-hover-height: $cap-calendar-input-height !default;

$cap-calendar-w-btn-hover-datepicker-background-color: $cap-white !default;
$cap-calendar-w-btn-hover-datepicker-color: var(--cap-primary-color) !default;
$cap-calendar-w-btn-hover-datepicker-left: 4px !default;
$cap-calendar-w-btn-hover-datepicker-border-radius: 20px 0 0 20px !default;
$cap-calendar-w-btn-hover-datepicker-opacity: 0.5 !default;
$cap-calendar-w-btn-hover-datepicker-width: 21px !default;

$cap-calendar-w-btn-hover-input-left: 21px !default;
$cap-calendar-w-btn-hover-input-width: calc(100% - 21px) !default;
$cap-calendar-w-btn-hover-input-border-radius: 0 20px 20px 0 !default;

// Time picker
$cap-timepicker-input-hover-border-color: #a6a6a6 !default;

// Checkbox filter
$cap-checkbox-filter-height: $cap-input-height !default;
$cap-checkbox-filter-border: 1px solid var(--cap-primary-color) !default;
$cap-checkbox-filter-border-radius: 25px !default;
$cap-checkbox-filter-padding: 5px 20px 0 !default;
$cap-checkbox-filter-justify-content: center !default;
$cap-checkbox-filter-background-color: $cap-white !default;
$cap-checkbox-filter-icon-left: 8px !default;

$cap-checkbox-filter-label-padding: 0 10px !default;
$cap-checkbox-filter-label-color: #5f6062 !default;
$cap-checkbox-filter-label-width: 80% !default;

// Checkbox
$cap-checkbox-border-color: $input-border-color !default;
$cap-checkbox-border-color-hover: $input-border-color !default;
$cap-checkbox-state-active-background: $cap-white !default;
$cap-checkbox-state-active-border-color: var(--cap-primary-color) !default;
$cap-checkbox-state-active-icon-margin-top: 1px !default;
$cap-checkbox-state-active-icon-font-weight: 900 !default;
$cap-checkbox-state-active-icon-font-size: 16px !default;

// Chips
$cap-chips-width-hover: 100% !default;
$cap-chips-border-color-hover: $input-border-color !default;
$cap-chips-border-padding-hover: .4rem !default;
$cap-chips-token-padding: .25rem .75rem !default;
$cap-chips-token-background-color: $cap-platinum !default;
$cap-chips-token-color: #5f6062 !default;
$cap-chips-token-border-color: $input-border-color !default;

// Collapsable-card
$cap-collapsable-card-nav-divider-color: #e9ecef !default;
$cap-collapsable-card-header-border-bottom: #e9ecef !default;
$cap-collapsable-card-padding-top: 10px !default;
$cap-collapsable-card-padding: $cap-collapsable-card-padding-top 20px 20px 20px !default;
$cap-collapsable-card-title-font-size: 20px !default;
$cap-collapsable-card-title-font-weight: bold !default;

$cap-collapsable-card-header-justify-content: space-between !default;

$cap-collapsable-card-link-color: var(--cap-primary-color) !default;
$cap-collapsable-card-link-font-weight: 600 !default;
$cap-collapsable-card-content-padding-top: $cap-collapsable-card-padding-top !default;

// Color-picker
$cap-colorpicker-input-display: inline-flex !default;
$cap-colorpicker-input-border-left: none !default;
$cap-colorpicker-with-input-border-right: none !default;
$cap-colorpicker-preview-width: 38px !default;
$cap-colorpicker-preview-height: 38px !default;

// Select Button
$cap-select-button-border-color: var(--cap-primary-color) !default;
$cap-select-button-background-color: $cap-white !default;
$cap-select-button-border-color: var(--cap-primary-color) !default;
$cap-select-button-color: var(--cap-primary-color) !default;
$cap-select-button-font-weight: bold !default;
$cap-select-button-padding: 5px 25px !default;
$cap-select-button-font-size: 1em !default;
$cap-select-button-hover-text-decoration: underline !important !default;
$cap-select-button-active-background-color: var(--cap-primary-color) !default;
$cap-select-button-active-color: $cap-white !default;

// Slide up panel
$cap-slide-up-panel-transition: height 0.3s ease-out 0s !default;
$cap-slide-up-panel-border-radius: 10px !default;
$cap-slide-up-panel-box-shadow: 0 -6px 13px 0 rgba(0, 0, 0, 0.15) !default;
$cap-slide-up-panel-position: fixed !default;
$cap-slide-up-panel-right: 0 !default;
$cap-slide-up-panel-bottom: 0 !default;
$cap-slide-up-panel-left: 0 !default;
$cap-slide-up-panel-z-index: 50 !default;
$cap-slide-up-panel-background-color: $cap-white !default;
$cap-slide-up-panel-overflow: scroll !default;

$cap-slide-up-panel-drag-handle-bar-cursor: grab !default;
$cap-slide-up-panel-drag-handle-bar-active-cursor: grabbing !default;

$cap-slide-up-panel-handle-bar-height: 0.33rem !default;
$cap-slide-up-panel-handle-bar-width: 2.5rem !default;
$cap-slide-up-panel-handle-bar-border-radius: 2.5px !default;
$cap-slide-up-panel-handle-bar-background-color: $cap-white !default;

$toast-background-color: #fff !default;
$toast-text-color: #333 !default;
$toast-background-color-success: $toast-background-color !default;
$toast-background-color-warning: $toast-background-color !default;
$toast-background-color-info: $toast-background-color !default;
$toast-background-color-error: $toast-background-color !default;
$toast-summary-font-size: 18px !default;
$toast-detail-font-size: 14px !default;
$toast-detail-text-color: #4c4c4c !default;
$toast-close-icon-color: rgba(51, 51, 51, 0.15) !default;
$toast-border-radius: 5px !default;

//Week switcher
$cap-week-switcher-divider-border: 0.5px solid #ddd !default;
$cap-week-switcher-border: 2px solid var(--cap-primary-color) !default;
$cap-week-switcher-border-radius: 3px !default;
$cap-week-switcher-display: inline-block !default;
$cap-week-switcher-content-display: inline-block !default;
$cap-week-switcher-content-color: var(--cap-primary-color) !default;
$cap-week-switcher-content-font-size: 16px !default;
$cap-week-switcher-content-font-weight: bold !default;
$cap-week-switcher-button-divider-padding: 10px 0 !default;
$cap-week-switcher-button-divider-width: 120px !default;
$cap-week-switcher-button-divider-text-align: center !default;
$cap-week-switcher-button-divider-border-right: $cap-week-switcher-divider-border !default;
$cap-week-switcher-button-divider-border-left: $cap-week-switcher-divider-border !default;
$cap-week-switcher-button-not-disabled-padding: 0 15px !default;

//Timeline
$cap-timeline-cards-divider-padding-left: 20px !default;
$cap-timeline-cards-divider-position: relative !default;
$cap-timeline-cards-divider-before-position: absolute !default;
$cap-timeline-cards-divider-before-top: 0 !default;
$cap-timeline-cards-divider-before-left: 0 !default;
$cap-timeline-cards-divider-before-width: 1px !default;
$cap-timeline-cards-divider-before-height: 100% !default;
$cap-timeline-cards-divider-before-border-left: 2px dashed var(--cap-primary-color) !default;
$cap-timeline-card-margin-bottom: 10px !default;
$cap-timeline-card-last-of-type-margin-bottom: 0 !default;
$cap-timeline-content-padding: 10px 15px !default;
$cap-timeline-content-border-radius: 3px !default;
$cap-timeline-content-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !default;
$cap-timeline-content-background-color: #fff !default;
$cap-timeline-created-color: rgba(51, 51, 51, 0.5) !default;
$cap-timeline-created-font-size: 13px !default;
$cap-timeline-title-font-weight: bold !default;
$cap-timeline-title-color: var(--primary-text) !default;
$cap-timeline-title-font-size: 15px !default;
$cap-timeline-comment-font-size: 15px !default;
$cap-timeline-comment-color: var(--text-color-2) !default;
$cap-timeline-comment-link-color: var(--cap-primary-color) !default;

//Textarea
$cap-textarea-width: 100% !default;
$cap-textarea-resize: vertical !default;
$cap-textarea-border-color: $input-border-color !default;
$cap-textarea-border-radius: 3px !default;
$cap-textarea-border-height: 100px !default;
$cap-textarea-padding: 10px !default;
$cap-textarea-font-size: 14px !default;
$cap-textarea-font-color: var(--primary-text) !default;

// Table
$cap-table-border-color: #ddd !default;
$cap-table-text-color: #5f6062 !default;
$cap-table-sort-icon-color: #ddd;
$cap-table-actions-col-width: 80px !default;
$cap-table-expand-col-width: 30px !default;
$cap-table-header-table-data-background-color: $cap-white !default;
$cap-table-header-table-data-color: #5f6062 !default;
$cap-table-header-table-border: none !default;
$cap-table-header-table-font-size: 1em !default;
$cap-table-header-table-padding: 0.571em 0 0.857em 0 !default; /* stylelint-disable-line number-max-precision */

$cap-table-header-font-weight: 600 !default;
$cap-table-header-color: #333 !default;
$cap-table-header-padding: 0.571em 0 0.857em 0 !default; /* stylelint-disable-line number-max-precision */
$cap-table-header-hover-background-color: $cap-white !default;

$cap-table-caption-background-color: $cap-white !default;
$cap-table-caption-border: none !default;

$cap-table-inputgroup-addon-border-right: none !default;
$cap-table-inputgroup-addon-color: var(--cap-primary-color) !default;

$cap-table-actions-wrapper-max-width: 100px !default;
$cap-table-actions-icon-color: var(--cap-primary-color) !default;
$cap-table-actions-margin-right: 10px !default;
$cap-table-actions-cursor: pointer !default;
$cap-table-actions-right-padding-left: 10px !default;

$cap-table-sortable-column-icon-color: var(--cap-primary-color) !important !default;

$cap-table-paginator-margin-top: 10px !default;
$cap-table-paginator-background-color: transparent !default;
$cap-table-paginator-border: none !default;
$cap-table-paginator-text-align: right !default;
$cap-table-paginator-bottom-text-align: right !default;
$cap-table-paginator-page-color: var(--cap-primary-color) !default;
$cap-table-paginator-font-weight: normal !default;
$cap-table-paginator-element-background: $cap-white !default;
$cap-table-paginator-element-color: var(--cap-primary-color) !default;

$cap-table-paginator-prev-next-width: 25px !default;
$cap-table-paginator-prev-next-line-height: 2rem !default;
$cap-table-paginator-prev-next-font-size: 1rem !default;

$cap-table-paginator-prev-margin-right: -1px !default;
$cap-table-paginator-prev-border-tl-radius: 3px !default;
$cap-table-paginator-prev-border-left-radius: 3px !default;

$cap-table-paginator-next-margin-left: -2px !default;
$cap-table-paginator-next-border-tr-radius: 3px !default;
$cap-table-paginator-next-border-br-radius: 3px !default;

$cap-table-filter-toggle-font-size: 0.8em !default;
$cap-table-filter-toggle-cursor: pointer !default;

$cap-table-filter-icon-position: absolute !default;
$cap-table-filter-icon-top: 0 !default;
$cap-table-filter-icon-left: 25px !default;
$cap-table-filter-icon-height: 100% !default;
$cap-table-filter-icon-display: flex !default;
$cap-table-filter-icon-flex-direction: column !default;
$cap-table-filter-icon-flex-justify-content: center !default;
$cap-table-filter-icon-color: var(--cap-primary-color) !default;
$cap-table-filter-icon-opacity: 0.5 !default;
$cap-table-filter-font-size: 0.9em !default;
$cap-table-filter-line-height: 0.9em !default;

$cap-table-reset-filter-position: absolute !default;
$cap-table-reset-filter-top: 0 !default;
$cap-table-reset-filter-right: 23px !default;
$cap-table-reset-filter-height: 100% !default;
$cap-table-reset-filter-display: flex !default;
$cap-table-reset-filter-flex-direction: column !default;
$cap-table-reset-filter-justify-content: center !default;
$cap-table-reset-filter-cursor: pointer !default;
$cap-table-reset-filter-background: $cap-white !default;
$cap-table-reset-filter-color: var(--error-color) !default;
$cap-table-reset-filter-opacity: 1 !default;
$cap-table-reset-filter-font-size: 0.9em !default;
$cap-table-reset-filter-line-height: 0.9em !default;

$cap-table-multiselect-label-padding-left: 27px !default;
$cap-table-multiselect-label-input-text-padding-left: 36px !important !default;

$cap-table-dropdown-display: inline-block !default;

$cap-table-filters-display: block !default;
$cap-table-filters-icon-color: var(--cap-primary-color) !default;
$cap-table-filters-font-size: 0.8em !default;
$cap-table-filters-cursor: pointer !default;

$cap-table-filters-label-display: inline !default;
$cap-table-filters-label-flex-grow: 1 !default;
$cap-table-filters-label-margin-left: 5px !default;
$cap-table-filters-label-color: var(--cap-primary-color) !default;

$cap-table-filters-dialog-width: 50% !default;
$cap-table-filters-settings-padding: 20px 40px !default;

$cap-table-filters-header-display: flex !default;
$cap-table-filters-header-justify-content: center !default;
$cap-table-filters-header-position: relative !default;
$cap-table-filters-header-margin-bottom: 15px !default;

$cap-table-filters-header-close-filters-position: absolute !default;
$cap-table-filters-header-close-filters-right: 0 !default;

$cap-table-filters-items-min-height: 300px !default;
$cap-table-filters-items-max-height: 300px !default;
$cap-table-filters-items-overflow-y: auto !default;

$cap-table-filters-item-width: 100% !default;
$cap-table-filters-item-border: 1px solid $cap-input-border-color !default;
$cap-table-filters-item-padding: 10px !default;
$cap-table-filters-item-border-radius: 3px !default;
$cap-table-filters-item-selected-border-color: var(--success-color) !default;
$cap-table-filters-item-checkbox-border-color: $cap-input-border-color !default;

$cap-table-item-cursor: default !default;
$cap-table-item-form-element-height: 23px !default;
$cap-table-item-label-font-weight: normal !default;
$cap-table-item-label-cursor: pointer !default;

$cap-table-columns-list-width: 10px !default;
$cap-table-columns-list-text-align: right !default;
$cap-table-columns-list-icon-cursor: pointer !default;
$cap-table-columns-list-color: var(--cap-primary-color) !default;

$cap-table-frozen-column-shadow: inset -7px 0 5px -5px $input-border-color;

/* Info table */
$cap-info-table-delimiter: #ddd;
$cap-info-table-header-color: rgba(58, 58, 58, 0.5);
$cap-info-table-header-bg: transparent;
$cap-info-table-header-height: 38px;
$cap-info-table-cell-height: 50px;
$cap-info-table-cell-padding: 10px;
$cap-info-table-cell-border: none;
$cap-info-table-cell-bg: #fff;
$cap-info-table-row-bg: #fff;
$cap-info-table-border-radius: 3px;
$cap-info-table-border-spacing: 0 10px;
$cap-info-table-card-breakpoint: 767.98px;
$cap-info-table-card-gutter: 10px;
$cap-info-table-card-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.2);

/* File preview list */
$cap-file-preview-item-background-color: #f9f9f9;

/* Tab view */
$cap-tab-view-bottom-border-color: #ccc;
$cap-tab-view-text-color: #4f5152;
$cap-tab-view-highlight-tab-background: #f9f9f9;
$cap-tab-view-highlight-tab-border-color: #ccc;

/* User menu */
$cap-user-menu-proportions: 38px;
$cap-user-menu-subtext-color: #4f5152;
$cap-user-menu-hover-color: #f9f9f9;
$cap-tab-view-highlight-tab-border-color: #ccc;

/* Sidebar menu */
$cap-sidebar-menu-initial-width: 250px;
$cap-sidebar-menu-collapsed-width: 90px;
$cap-sidebar-menu-button-proportions: 33px;
$cap-sidebar-menu-delimiter-color: #e4e4e4;

// Amount Input
$cap-amount-input-border: 1px solid var(--cap-primary-color) !default;
$cap-amount-input-border-radius: 3px !default;
$cap-amount-input-text-color: var(--cap-primary-color) !default;

// Form Card
$cap-form-card-border-radius: 10px !default;
$cap-form-card-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !default;
$cap-form-card-border: solid 1px $cap-light-gray !default;
$cap-form-card-background-color: $cap-white !default;
$cap-form-card-padding: 1.5rem !default;

$cap-form-card-header-border-bottom: 1px solid #dddddd !default;
$cap-form-card-header-padding-bottom: 10px !default;
$cap-form-card-header-font-size: 1.25rem !default;
$cap-form-card-header-font-weight: 700 !default;

$cap-form-card-content-padding-top: 26px !default;

$cap-media-breakpoints: (
        xs: '(max-width: 599.98px)',
        sm: '(min-width: 600px) and (max-width: 959.98px)',
        md: '(min-width: 960px) and (max-width: 1279.98px)',
        lg: '(min-width: 1280px) and (max-width: 1919.98px)',
        xl: '(min-width: 1920px)',
        handset: '(max-width: 599.98px) and (orientation: portrait), (max-width: 959.98px) and (orientation: landscape)',
        tablet: '(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)',
        web: '(min-width: 840px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape)',
        handset-portrait: '(max-width: 599.98px) and (orientation: portrait)',
        tablet-portrait: '(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait)',
        web-portrait: '(min-width: 840px) and (orientation: portrait)',
        handset-landscape: '(max-width: 959.98px) and (orientation: landscape)',
        tablet-landscape: '(min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)',
        web-landscape: '(min-width: 1280px) and (orientation: landscape)',
) !default;

/* break points */
$breakpoint-handheld: '(max-width: 599.98px) and (orientation: portrait), (max-width: 959.98px) and (orientation: landscape)';
$breakpoint-tablet: '(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)';

// Filters
$cap-dynamic-filter-border-radius: 25px !default;
$cap-dynamic-filters-hide-filters-on-mobile: false !default;
$cap-dynamic-filters-filter-flex: unset !default;
$cap-dynamic-filters-flex-wrap: wrap !default;
$cap-dynamic-filters-filter-height: 33px !default;
$cap-dynamic-filters-filter-width: 175px !default;


.cap-image-card-image-container {
  background-size: cover;
  background-position: center;
  height: 150px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.cap-image-card-footer-container {
  background-color: var(--cap-primary-color);
  padding: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.cap-image-card-content-container {
  padding: 10px;
}

.cap-image-card {
  border-radius: 5px;
  box-shadow: $cap-card-box-shadow;
}

cap-form-card {
  .form-card {
    border-radius: $cap-form-card-border-radius;
    box-shadow: $cap-form-card-box-shadow;
    border: $cap-form-card-border;
    background-color: $cap-form-card-background-color;
    padding: $cap-form-card-padding;

    .form-card__header {
      display: flex;
      align-items: center;
      border-bottom: $cap-form-card-header-border-bottom;
      padding-bottom: $cap-form-card-header-padding-bottom;

      &.header--empty {
        display: none;
      }

      .header__title {
        flex: 1;

        h2 {
          font-size: $cap-form-card-header-font-size;
          font-weight: $cap-form-card-header-font-weight;
          margin-bottom: 0;
        }
      }

      .header__cta {
        .cap-button {
          margin-left: .5rem;
        }

        .icon-only {
          .cap-button {
            padding: 0;
            border-radius: 50%;
            width: 36px;
            height: 36px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            line-height: 1;
          }
        }
      }
    }

    .form-card__content {
      padding-top: $cap-form-card-content-padding-top;
    }
  }
}

.cap-dynamic-filter-layout {
  cap-validator {
    display: none;
  }
}

cap-sidebar p-sidebar .p-sidebar.cap-dynamic-filters-sidebar {
  width: 480px;
}

.cap-dynamic-input-filter {
  .p-inputgroup {
    input.p-inputtext,
    input.p-inputtext:enabled:focus:not(.p-state-error),
    input.p-inputtext:enabled:hover:not(.p-state-error) {
      padding-right: 40px;
      padding-left: 36px;
      border-radius: $cap-dynamic-filter-border-radius;
      height: $cap-dynamic-filters-filter-height;

      &:placeholder-shown {
        padding-right: 0;
      }
    }
  }
}

.dynamic-dropdown-filter {
  cap-dropdown {
    .p-dropdown,
    .p-dropdown-label {
      font-size: 1rem;
      border-radius: $cap-dynamic-filter-border-radius;
    }

    .cap-dropdown.p-dropdown,
    .cap-dropdown.p-dropdown:not(.p-disabled),
    .cap-dropdown.p-dropdown:not(.p-disabled):hover,
    .cap-dropdown.p-dropdown:hover {
      height: $cap-dynamic-filters-filter-height;

      .p-dropdown-trigger {
        border-top-right-radius: $cap-dynamic-filter-border-radius;
        border-bottom-right-radius: $cap-dynamic-filter-border-radius;
        border-left: none;

        .pi {
          font-size: 1rem;
        }
      }

      .p-dropdown-label {
        padding-left: 35px;
        padding-right: 1.2em;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
        display: block;
      }

      .p-placeholder {
        padding-right: 0;
      }
    }
  }

  .cap-dynamic-filter-layout .delete-filter {
    right: 30px;
  }
}

.dynamic-multiselect-filter {
  cap-multi-select {
    .p-multiselect,
    .p-multiselect-label {
      font-size: 0.9rem;
      border-radius: $cap-dynamic-filter-border-radius;
    }

    .cap-multi-select.p-multiselect,
    .cap-multi-select.p-multiselect:not(.p-disabled),
    .cap-multi-select.p-multiselect:not(.p-disabled):hover,
    .cap-multi-select.p-multiselect:hover {
      font-size: 1rem;
      height: $cap-dynamic-filters-filter-height;

      .p-multiselect-trigger {
        border-top-right-radius: $cap-dynamic-filter-border-radius;
        border-bottom-right-radius: $cap-dynamic-filter-border-radius;
        border-left: none;
      }

      .p-multiselect-label {
        padding-right: 1.2em;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
        display: block;
      }

      .p-placeholder {
        padding-right: 0;
      }

      .pi {
        font-size: 1rem;
      }
    }
  }

  .cap-dynamic-filter-layout .delete-filter {
    right: 30px;
  }
}

.dynamic-calendar-filter {
  .p-calendar {
    input[type='text'].p-inputtext.p-component {
      padding-left: 36px;
      left: 0;
      width: 100%;
      border-top-left-radius: $cap-dynamic-filter-border-radius;
      border-bottom-left-radius: $cap-dynamic-filter-border-radius;
      font-size: 1em;
      height: $cap-dynamic-filters-filter-height;
    }

    &.p-calendar-w-btn .p-datepicker-trigger.p-button {
      height: $cap-dynamic-filters-filter-height;
      padding-right: 13px;
      width: 2.5rem;


      .p-button-icon {
        font-size: 1rem;
      }
    }
  }

  .cap-dynamic-filter-layout .delete-filter {
    right: 30px;
  }

  .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
    background: $cap-white;
    color: var(--primary-text);

    border-top-right-radius: $cap-dynamic-filter-border-radius;
    border-bottom-right-radius: $cap-dynamic-filter-border-radius;
  }
}

cap-dynamic-filters {
  width: 100%;

  .cap-filters {
    display: flex;
    justify-content: space-between;

    .sidebar-filter-button {
      display: none;
    }

    &.sidebar-filtering {
      .sidebar-filter-button:not(.hide-filter-button) {
        display: flex;
      }
    }

    .filter-list {
      display: flex;
      margin: -5px 0 0 0;
      flex-wrap: $cap-dynamic-filters-flex-wrap;
    }

    @if $cap-dynamic-filters-hide-filters-on-mobile {
      @media only screen and (max-width: 839.98px) {
        .filter-list {
          flex-grow: 1;

          .filter {
            max-width: unset;
          }
        }

        .filter-list:not(.sidebar-filter-list) > .filter:not(:first-child) {
          display: none;
        }

        .sidebar-filter-button {
          display: flex;
        }

        .filter-button {
          width: 10%;
        }
      }
    }

    .filter {
      margin-top: 5px;
      align-self: center;
      max-width: $cap-dynamic-filters-filter-width;
      padding-left: 0;
      min-width: $cap-dynamic-filters-filter-width;
      padding-right: 10px;
      position: relative;
      flex: $cap-dynamic-filters-filter-flex;

      .p-inputgroup input.p-inputtext {
        width: inherit;
      }

      @include cap-media-breakpoint(handset) {
        padding-right: 0;
      }

      &.sidebar-filter {
        margin-bottom: 10px;
        width: 100%;
        max-width: unset;
      }
    }

    .filter-button {
      display: flex;
      flex-wrap: nowrap;

      &.hide-filter-button {
        display: none;
      }

      & > cap-button:first-child,
      & > cap-button.refresh-filter-button {
        margin-right: 10px;
      }

      .p-button {
        height: $cap-dynamic-filters-filter-height;
        border-radius: $cap-dynamic-filter-border-radius;
      }

      @include cap-media-breakpoint(handset) {
        &.hide-filter-button {
          display: flex;
        }

        .reset-filter-button {
          display: none;
        }

        & > cap-button:first-child {
          margin-right: 0;
          margin-left: 5px;
        }

        .p-button.cap-button {
          padding: 0.2rem 5px;
        }
      }
    }
  }

  .p-chips > ul.p-inputtext.p-chips-multiple-container {
    background: transparent;
    border: none;
    padding-left: 0;

    .p-chips-token {
      background: #f0f0f0;
      border-radius: 25px;
      color: #5f6062;
      border: 1px solid #ddd;
      padding: 2px 10px;
      margin: 0 8px 0 0;
    }

    .pi-times-circle {
      margin-left: 0.75rem;
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400;
      font-size: 0.9rem;

      &:before {
        content: '\f00d';
      }
    }
  }

  .shown-filters {
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
  }
}

.filters.handset {
  cap-dynamic-filters {
    .filter:not(.search) {
      display: none;
    }
  }
}

body cap-checkbox-filter {
  padding-left: 0;

  .cap-dynamic-filter-layout .cap-dynamic-filter-layout-content {
    flex-direction: row-reverse;
    justify-content: start;
  }

  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider,
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: $cap-success;
  }
}
