@import 'abstracts';

cap-login {
  .login-wrapper {
    .content-wrapper {
      .content-left {
        padding: 70px;
      }
    }
  }

  .content-right .login-form .login-actions {
    .login-link {
      font-size: 13px;
      color: $primary;

      .login-separator {
        color: $borders-color;
      }
    }
  }
}

app-blank .content-right {
  cap-reset-password {
    > .col-12 {
      padding: 0;
    }

    .login-form {
      .title {
        font-size: 1rem;
        text-align: center;
        font-weight: 600;
        margin-bottom: .60rem;
      }
  
      .subtitle {
        font-size: 1rem;
        text-align: center;
        margin-bottom: 1rem;
      }
  
      .form-element {
        label {
          display: none;
        }

        .cap-input,
        .cap-input:hover,
        .cap-input:hover:not(.p-state-error),
        .cap-input:enabled:focus:not(.p-state-error) {
          padding: .375rem .75rem;
          border-radius: 1px;
          font-size: .815rem;
        }

        cap-validator {
          opacity: 1;
          color: $danger-color;

          .validator {
            opacity: 0;

            &.show {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}