:root {
  --color-value-difference: 5%;

  --primary-color-value-h: 181; // #00999c
  --primary-color-value-l: 31%;

  --primary-color: hsl(var(--primary-color-value-h), 100%, var(--primary-color-value-l));
  --primary-lighten-color: hsl(var(--primary-color-value-h), 100%, calc(var(--primary-color-value-l) + var(--color-value-difference)));
  --primary-darken-color: hsl(var(--primary-color-value-h), 100%, calc(var(--primary-color-value-l) - var(--color-value-difference)));
}

/* Import custom Blueprint variables  */
@import 'abstracts/variables.scss';
@import '../../../styles/main.scss';

.wrapper-content {
  padding: 20px 40px 40px;
}

cpb-config-list,
cpb-release-list {
  div {
    &.align-items-center,
    &.text-right {
      padding: 20px 40px 40px;
    }
  }

  cpb-list-renderer {
    div.cpb-list-renderer {
      padding: 20px 40px 40px;
    }
  }
}

.scroll-fix {
  height: 85vh;
  max-height: 85vh;
  overflow-y: scroll;
}

.login-bg {
  color: $info-text-color;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .3;
    z-index: -1;
    background-color: #fff;
  }
}

.float-right {
  float: right;
}

ng-table .table.dataTable tr:hover {
  cursor: pointer;
}

.loading-container {
  position: relative;
}

.loading-container app-loading {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}

.loading-container.loading::after {
  content: '';
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.p-progress-spinner-svg {
  fill: transparent;
}

.loading-container.loading app-loading {
  display: block;
}

//@mixin icon($icon) {
//  @extend .fa;
//  @extend .fa-#{$icon}:before;
//}

em-table .with-delete tr td:last-child {
  font-size: 0;

  //&:before {
  //  @include icon(trash);
  //  font-size: 13px;
  //}
}

.striped-forms .ibox-item {
  transition: all linear 0.3s;

  .form-group {
    margin: 0;
    padding: 10px 0;
    transition: all linear 0.3s;
  }
}

.header-first {
  margin-top: 14px !important;
}

h5 {
  color: $primary-color;
}

.control-label {
  padding-top: 0 !important;
}

.tile {
  background-color: $white;
  border: 1px solid $borders-color;
  border-radius: 3px;
}

//##### inventory #####

/*primeNG changes*/
.ui-multiselect {
  width: 100% !important;

  &.has-error {
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }

  &.ui-corner-all {
    border-radius: 0 !important;
  }

  .ui-multiselect-header {
    display: none;
  }

  .ui-multiselect-label {
    height: 34px;
    margin-bottom: 0;
    padding: .25em !important;
    font-weight: normal;
    color: #222222;
    line-height: 2em !important;
  }
}

p-multiselect.ng-invalid .ui-multiselect .ui-multiselect-label {
  color: #999;
}

.ui-datatable tbody > tr.ui-widget-content.ui-state-highlight,
.ui-table .ui-table-tbody > tr.ui-state-highlight {
    background-color: $primary-color;
}

.ui-chkbox {
  .ui-chkbox-box.ui-state-active {
    background: $primary-color;
  }
}

.ui-steps {
  ul {
    width: 100%;
  }

  .ui-steps-item {
    min-width: calc(100% / #{$numberOfNavigationTabs}); // 100% / # of navigation tabs

    &.ui-state-highlight {
      background: $primary-color;
    }

    @media (max-width: 960px) {
      font-size: 11px;
      line-height: 18px;
    }

    &.ui-state-default {
      &::before {
        border-left-color: #f3f3f4;
      }
      &:hover {
        ::after {
          border-left-color: #eeeeee;
        }
      }
    }

    &.ui-state-active {
      background: $primary-color;
    }

    .ui-menuitem-link {
      padding: 1em 0;

      &.ui-state-active {
        background: $primary-color;
        color: #fff;
        margin-right: 1px;
        border: none;

        &::after {
          border-left-color: $primary-color;
        }
      }
    }
  }

  .ui-steps-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
    border-color: #d6d6d6;
  }
}

.ui-inputswitch-on {
  background: $primary-color !important;
}

.ui-state-default {
  font-weight: normal;
}

//primeNG paginator
//.ui-paginator {
//  .ui-paginator-element {
//    padding: 5px 10px !important;
//    background: #fff;
//    margin: 5px 5px 5px 0 !important;
//  }
//  .ui-paginator-pages {
//    padding: 0 !important;
//    margin: 0 !important;
//    .ui-paginator-page.ui-state-active{
//      background: $primary-color;
//      border-color: $primary-color;
//    }
//  }
//  &.ui-paginator-bottom{
//    background: #fff;
//  }
//  .ui-paginator-rpp-options {
//    padding: 3px;
//    background: #fff;
//  }
//}

.ui-widget-overlay {
  opacity: .1;
}

.ui-widget-content .ui-button, .ui-widget.ui-button, .ui-button {
  background: $primary-color !important;
  border-color: $primary-color !important;
  &:hover, &:active {
    background: $primary-color;
    border-color: $primary-color;
  }
}

.ui-inputtext {
  display: block;
  height: 34px;
  width: 100%;
  border-color: #e5e6e7;
}

.ui-placeholder {
  color: #898989;
}

// don't show empty dropown items
.ui-dropdown .ui-dropdown-item-empty, .ui-dropdown .ui-dropdown-label-empty {
  display: none;
}

.ibox {
  .ui-calendar {
    display: block;
  }

  .ui-datepicker.ui-widget {
    width: auto;
  }
}

.fa-question-circle {
  color: $primary-color;
}

canvas {
  border-bottom: 2px solid #ddd;
}

input.form-control {
  margin-bottom: 5px;
}

.form-control {
  font-size: 13px;
  height: auto;
}

textarea {
  resize: none;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-20 {
  margin-right: 20px;
}
