@import 'abstracts';

p-steps .p-steps {
  &::before {
    display: none;
  }
  
  ul {
    height: 40px;
  }

  .p-steps-item, .p-steps-item.higlight {
      &::before {
        display: none;
      }
    
    .p-menuitem-link {
      display: flex !important;
      flex-direction: row;
      background-color: $gray-light;
      border: 1px solid $borders-color;
      border-bottom: 1px solid $borders-color;
      border-top: none;
      border-radius: 0;
      font-weight: 500;
      flex: 1;
      justify-content: center;
      padding: 0 5px;

      &:focus {
        box-shadow: none !important;
      }
      
      .p-steps-number {
        width: auto;
        height: auto;
        background: none;
        border: 0;
        min-width: auto;
        min-height: auto;
        line-height: 1;
        display: flex;
        font-weight: 500;
        font-size: 14px;
        color: $understated-text-color;
        top: 0;
        margin: 0;
        
        &::after {
          content: '. ';
        }
      }

      .p-steps-title {
        font-weight: 500;
        color: $understated-text-color;
        font-size: 14px;
        margin: 0 0 0 4px;
      }
    }

    .p-menuitem-link-active, .p-menuitem-link:hover {
      border-bottom: 3px solid var(--cap-primary-color);
      background-color: $stepper-active-background;

      .p-steps-number {
        color: $stepper-title-color;
      }

      .p-steps-title {
        color: $stepper-title-color;
      }
    }
  }
}

.wrapper-content {
  .ibox, .ibox-title, .ibox-content {
    background-color: $gray-light !important;
  }
  
  .ibox-title {
    border-top: 0;

    h5 {
      font-size: 1.125rem;
    }
  }

  .ibox-footer {
    border: 0;
    background-color: transparent;
  }

  .main-step-content {
    .ibox-content {
      border-width: 0;
      padding: 0;
    }
  }
}

app-inventory-flow {
  cap-button {
    button {
      span.p-button-label {
        font-size: 16px;
      }
    }
  }
}
