.new-inventory-item {
  border: 1px solid $borders-color;
  width: 100%;
  height: 130px;
  background: #fff;
  color: $primary-text-color;
  border-radius: 3px;
  text-align: center;
  display: flex;
  place-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px 15px;

  &.disabled {
    opacity: 0.5;
  }

  &.small {
    height: 73px;
  }

  .tile-header {
    border-bottom: 1px solid $borders-color;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 5px 12px;

    span {
      font-size: 16px;
      font-weight: 600;

      &.fa-stack {
        color: $alto;
      }
    }
  }

  .tile-footer {
    margin-top: 15px;
    width: 100%;
    justify-content: space-between;

    span:not(.p-button-icon) {
      font-size: 15px;
      font-weight: 600;
    }
  }
}

app-inventory-header {
  cap-dropdown {
    .form-element {
      height: 40px;
    }
  }
}

.border-bottom {
  border-bottom: 1px solid $borders-color !important;
}

.hidden-input {
  display: none;
}

.inventory-item-details-image {
  margin-right: 20px;

  .fa-image {
    font-size: 30px;
    vertical-align: middle;
  }
}

.inventory-item-details {
  .modal-header {
    padding: 20px 40px !important;
  }

  .modal-body {
    padding: 45px 40px;
  }

  .uploaded-image {
    width: 200px;
    height: 130px;
    border-radius: 15px;
    object-fit: cover;
  }

  section.details {
    font-size: 15px;
    font-weight: 600;
    align-items: center;
    padding-bottom: 30px;
    justify-content: space-between;
  }

  section.description {
    margin-top: 35px;
    
    cap-textarea {
      margin-right: 25px;
      width: 60%;
    }
  }

  cap-checkbox {
    cap-validator {
      display: none;
    }
  }

  .icons {
    margin-right: 20px;

    i {
      background-color: $fade-button-background-color;
      border-radius: 10px;
      height: 60px;
      width: 60px;
      display: flex;
      align-content: center;
      align-items: center;
      text-align: center;
      justify-content: center;
      font-size: 22px;
      color: $fade-button-text-color;

      &:first-child {
        margin-bottom: 13px;
      }
    }
  }
}

.form-element label {
  font-size: 15px;
}

.modal-footer {
  padding: 20px 40px;
  border: none;
}

.row {
  label:not(.p-checkbox-label) {
    margin: 0;
  }

  &.form-group {
    label {
      height: 36px;
      display: flex;
      align-items: center;
    }
  }
}

app-inventory-work-assignment-list, app-inventory-work-assignment-detail {
  div, input, span, p, label {
    &:not(.p-button-label):not(.p-button-icon) {
      color: $fade-text-color;
      font-size: 15px !important;
    }
  }

  h5 {
    margin-bottom: 10px;
    text-transform: capitalize;
    font-size: 16px !important;
    color: $fade-text-color;
    font-weight: bold;
  }
}

.p-dropdown-item.p-highlight {
  span {
    color: $white !important;
  }
}


app-inventory-address-detail,
app-inventory-contacts-detail {
  .form-horizontal {
    .divider {
      border-bottom: 1px solid $borders-color;
      margin-bottom: 1rem;
    }
    .form-group {
      margin-left: -15px;
      margin-right: -15px;
      display: flex;
      flex-wrap: wrap;

      label {
        margin-left: 0;
      }

      .housenumber-addition {
        display: flex;
      }
    }
  }
}