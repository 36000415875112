@import 'abstracts';

$add-action-button-width: 100px;

body .cpb-list-renderer {
  padding: 15px;
}

cpb-list-renderer-header-actions {
  .header-action__items li {
    position: absolute;

    cap-button {
      p-button {
        button.cap-button.secondary {
          color: $white;
          background-color: $primary;
        }
      }
    }
  }
}

cpb-list-renderer-filters {
  cap-checkbox-filter .cap-dynamic-filter-layout .cap-dynamic-filter-layout-content {
    display: flex;

    .cap-checkbox-filter-label {
      margin-top: 5px;
      margin-left: 10px;
    }
  }

  .table-filters.cap-table-filter {
    .p-placeholder,
    ::-webkit-input-placeholder {
      color: $understated-text-color;
      font-size: $filter-font-size;
      font-weight: 600;
    }

    .filters .table-filter-row {
      .filter {
        .filter-icon {
          transform: translateY(-50%);
        }

        input.cap-rounded {
          border: 1px solid $borders-color;
          font-size: $filter-font-size;
          min-height: 33px !important; /* stylelint-disable-line declaration-no-important */

          &:hover {
            border-color: $borders-color;
          }

          &::placeholder {
            color: $understated-text-color;
            font-size: $filter-font-size;
            font-weight: 600;
          }
        }

        .cap-multi-select.cap-rounded, .cap-calendar.cap-rounded {
          border-color: $border-color;
          font-size: $filter-font-size;

          input.p-inputtext.p-component {
            height: 33px;
            border-color: $border-color;
            font-size: $filter-font-size;
          }

          &:hover {
            padding: 0;
            font-size: $filter-font-size;

            input.p-inputtext.p-component {
              border-radius: 20px;
              position: relative;
              left: 0;
              height: 33px;
            }
          }
        }

        .p-datepicker-trigger {
          display: none;
        }

        .p-multiselect-label {
          padding: 0;
          font-size: $filter-font-size;
        }

        .cap-dropdown.cap-rounded {
          border: 1px solid $borders-color;
          font-size: $filter-font-size;

          .p-dropdown-label {
            padding-top: 10px;
            background-color: transparent;
          }

          .p-dropdown-trigger-icon, .p-multiselect-trigger-icon {
            &:before {
              font-size: 10px;
              font-weight: bold;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }

  .actions {
    .round {
      padding: 2px 20px;

      .p-button-label {
        font-size: 14px;
      }
    }
  }
}

cpb-list-renderer[key='list_installers'], cpb-list-renderer[key='list_distributor_installers'] {
  cap-info-table-header {
    tr {
      th:first-child {
        width: 70px;
      }
    }
  }
}

cpb-list-renderer[key='list_distributor_installers'] {
  thead {
    display: none;
  }

  tr.cap-info-table__row {
    border: 1px solid $borders-color;
  }

  .cap-info-table__column-category {
    width: 80px;
  }

  cap-cell-data {
    color: $body-color;
    font-size: 14px;

    app-installer-category-widget {
      i {
        font-size: 20px;
        display: flex;
        padding: 0;
        justify-content: center;
      }
    }
  }

  cpb-list-renderer-filters[ng-reflect-key='list_distributor_installers'] {
    .table-filters .filters .table-filter-row .filter {
      width: 33%;
    }
  }
}

cpb-list-renderer-filters[ng-reflect-key='list_installers'] {
  .table-filters.cap-table-filter {
    max-width: calc(100% - #{$add-action-button-width});
  }
}

.cap-info-table.p-datatable .p-datatable-thead th {
  overflow: hidden;
  text-overflow: ellipsis;
}

cpb-list-renderer, app-table-filter {
  .cap-dropdown-panel.p-dropdown-panel,
  .cap-multi-select-panel,
  .p-datepicker:not(.p-datepicker-inline) {
    z-index: 998 !important; // Important is needed because it sets a style on the element itself
  }
}
