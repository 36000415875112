@import 'abstracts';

body .p-dialog-mask.p-dialog-mask-scrollblocker {
  
}

.p-dialog.p-confirm-dialog {
  border-radius: 5px;
  background-color: $white;
  padding: 1em;
  min-width: 425px;

  .p-dialog-header {
    span.p-dialog-title {
      font-size: 1rem;
      font-weight: $bold-weight;
    }
  }

  .p-dialog-titlebar,
  .p-dialog-content,
  .p-dialog-footer {
    border: none;
    border-radius: inherit;
    color: $primary;
    padding: 1em;

    p-footer {
      @include flexCentering();

      /* Yes/No buttons tend to be kind of small */
      .cap-button {
        min-width: 6em;
      }
    }
  }

  .p-dialog-titlebar {
    @include straightBottom();
    font-weight: $semi-bold-weight;

    .p-dialog-title {
      font-size: 1.5rem;
      font-weight: $bold-weight;
    }
  }

  .p-dialog-content {
    .p-confirm-dialog-message {
      width: 100%;
      color: var(--primary-text);
    }
  }
}

div.p-overlaypanel {
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 50%);
  border: none;

  .p-overlaypanel-content {
    padding: 0;
  }
}
