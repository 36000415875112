@import 'bootstrap/scss/bootstrap-grid';
@import 'abstracts';

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1900px;
  }

  #headerContainer.container {
    height: $menu-height;
    max-width: 1730px;
  }
}

html,
body {
  height: 100% !important;
  overflow: hidden !important;
}

#wrapper {
  overflow-x: hidden;
}

.m-t {
  margin-top: 15px;
}

.m-b {
  margin-bottom: 15px;
}

.mr-3 {
  margin-right: 1rem;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.full-width {
  display: block;
  width: 100%;
}

.form-group {
  margin-bottom: 10px;
}

.form-control {
  border-radius: 1px;
}

.btn-primary {
  background-color: $primary-color;
  border-color: $primary-color;
  border-radius: 3px;
}

.btn-white {
  background: $white;
  border-radius: 3px;
  border: 1px solid $secondary-color;
}

.btn-white:hover {
  background: $white;
  border-radius: 3px;
  border: 1px solid $secondary-color;
}

.row {
  > cap-button {
    width: auto;
  }

  [class*='col-'] {
    position: relative;
  }
}
