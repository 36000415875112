/* Base */
@import './base/reset';
@import './base/alignment';
@import './base/fonts';
@import './base/typography';
@import './base/base';

/* Vendor */
@import './vendor/bootstrap';
@import './vendor/prime';
@import './vendor/capturum-ui';
@import './vendor/font-awesone';

/* Components */
@import 'components/action-menu';
@import 'components/buttons';
@import 'components/dashboard-info-table';
@import 'components/login';
@import 'components/cpb-list-renderer';
@import 'components/cpb-form-renderer';
@import 'components/cards';
@import 'components/calendar';
@import 'components/chartjs';
@import 'components/confirm-dialogs';
@import 'components/dialogs';
@import 'components/dropdowns';
@import 'components/input';
@import 'components/tooltips';
@import 'components/stepper';
@import 'components/switch-options';
@import 'components/popup-modal';
@import 'components/select-button';
@import 'components/inventory';
@import 'components/dynamic-filters';

html, body {
  overflow: hidden;
}
