/* Blueprint colors */
$primary: #002547;
$primary-dark: #51001e;
$primary-darker: #7452a9;
$primary-light: #d64751;
$error-color: #ff3b30;

/* General colors */
$gray-light: #f9f9f9;
$gray-dark: #333;
$body-color: #5f6062;
$white: #fff;
$platinum: #e6e6e6;
$concrete: #f2f2f2;

/* Named colors */
$ultramarine: #071a8c;
$alto: #d3dbe5;
$liver: #4f5152;
$alabaster: #f9f9f9;
$half-opacity-mineshaft: rgba(51, 51, 51, 0.5);
$silver: #c0c0c0;
$gold: #d7be69;
$bronze: #a97142;

$stepper-title-color: #272a52;
$stepper-active-background: #ededf3;
$understated-text-color: rgba($stepper-title-color, 0.38);

/* Project colors */
$divider-color: $concrete;
$separator-color: #d3dbe5;
$separator-text-color: #687481;
$title-text-color: #424242;
$primary-text-color: $gray-dark;
$info-text-color: #3c3c3b;
$borders-color: $alto;
$table-text-color: #767679;
$fade-text-color: #656d7e;
$fade-button-background-color: #f6f8fa;
$fade-button-text-color: #454a91;
$list-row-background-color: #d4e6ff;

/* Disabled colors */
$disabled-color: $alto;
$input-disabled-background-color: $alabaster;

$external-page-background-color: #f4f4f4;

$light-background: rgba(80, 84, 154, 0.04);
