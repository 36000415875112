@import 'abstracts';

em-switch .btn-group {
  background: $light-background;
  border: 1px solid $borders-color !important;
  border-color: $borders-color !important;
  border-radius: 3px;
  padding: 4px;

  em-switch-option {
    &[value="1"] {
      button {
        margin: 0;
      }
    }
    
    button {
      border: 0;
      font-size: 15px;
      height: 32px;
      min-width: 144px;
  
      &:focus {
        box-shadow: none !important;
      }

      &.active:not(.selected) {
        background-color: transparent !important;
        border-color: transparent !important;
        color: var(--primary-text) !important;
      }
      
      &.selected, &.active.selected {
        background-color: var(--cap-primary-color) !important;
        border-color: var(--cap-primary-color) !important;
        color: $white !important;
      }

      i {
        display: none;
      }
    }
  }
}