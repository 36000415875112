@import 'abstracts';

cap-select-button p-selectbutton .cap-select-button{
  width: fit-content;
  background: $light-background;
  border-color: $borders-color !important;
  border: 1px solid $borders-color;
  padding: 4px;
  border-radius: 5px;

  .p-button:not(.p-highlight), .p-button:not(.p-highlight):hover, .p-button:not(.p-highlight):active, .p-button:not(.p-highlight):focus {
    border: none !important;
    background-color: transparent !important;
  }
}

app-inventory-quotation {
  .p-selectbutton {
    display: flex;
    width: 100% !important;
    
    .p-element {
      flex: 1;
    }
  }
}
