@import 'abstracts';

input {
  outline: none !important; /* stylelint-disable-line declaration-no-important */
}

body {
  cap-input {
    div.p-inputgroup {
      input,
      input.p-inputtext,
      input.p-inputtext:hover,
      input.p-inputtext:enabled:hover:not(.p-state-error),
      input.p-inputtext:enabled:focus:not(.p-state-error) {
        font-size: 14px;

        &::placeholder {
          color: $title-text-color;
        }

        &[disabled],
        &.p-disabled,
        &:disabled {
          opacity: 1;
        }

        &.has-icon {
          padding-left: 36px;
        }
      }
    }
  }
}

/* stylelint-disable property-no-vendor-prefix */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  // Hide arrows for input type number
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

cap-textarea.form-control {
  padding: 0;
  border: 0;
  display: block;
  height: fit-content;
}

cap-input-number {
  border: 1px solid $borders-color;
  border-radius: 3px;

  .form-element {
    height: 38px;
  }

  input {
    width: 80px !important;
    border-radius: 0;
    border: none !important;
    border-left: 1px solid $borders-color;
    border-right: 1px solid $borders-color;
  }
}

cap-input {
  cap-validator, .validators.show {
    opacity: 0;
  }
}

.form-control:disabled, .form-control[readonly] {
  background-color: $fade-button-background-color;
}

cap-checkbox {
  cap-validator {
    display: none;
  }
}

.cost_price, .additional_costs {
  cap-input-number {
    border: 0;
    border-radius: 0;

    .form-element {
      height: 38px;
    }

    input {
      width: 100% !important; // to override the default border from _input.scss
      border-radius: 3px;
      border: 1px solid $borders-color !important; // to override the default border from _input.scss
    }
  }
}