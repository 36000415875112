@import 'abstracts';

cap-button .cap-button.primary:enabled:hover, cap-button .cap-button.primary:not(:disabled):hover {
  opacity: 0.8;
  text-decoration: none;
}

.cap-button.default, .cap-button.default:enabled:hover {
  background-color: $fade-button-background-color;
  border: none;

  .p-button-icon {
    color: $fade-button-text-color;
  }
}

.p-button-label {
  font-size: 15px;
}

app-inventory-quotation {
  cap-button .cap-button, cap-button button {
    width: 100%;
  }
}
