@import 'variables/font_variables';
@import 'variables/color_variables';
@import 'variables/dimension_variables';
@import 'variables/spacing_variables';
@import 'variables/input_variables';
@import 'variables/button_variables';

$header-height: 87px;

// Basic Colors
$primary-color: var(--cap-primary-color);
$primary-lighten-color: var(--primary-lighten-color);
$primary-darken-color: var(--primary-darken-color);
$cap-dark-gray: #333;

$secondary-color: #272a52;

$info-color: #23c6c8 !default;
$warning-color: #f8ac59 !default;
$danger-color: #CD2525 !default;

// Navigation
$numberOfNavigationTabs: 10;
$border-color: #e7eaec;
