.cap-filters {
  .p-inputgroup {
    > .p-component,
    > .p-element,
    > .p-component > .p-inputtext,
    > .p-float-label > .p-component {
      &:focus {
        z-index: auto;
      }
    }
  }

  .p-dropdown-filter-container {
    height: $cap-dynamic-filters-filter-height;

    input {
      text-indent: 0;
    }
  }
}

.filter-buttons .p-button {
  height: 33px;
  border-radius: 25px;

  &:first-child {
    margin-right: 0.5rem;
  }
}