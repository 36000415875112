@import 'abstracts';

cpb-form-renderer[formkey='form_distributor-detail'],
cpb-form-renderer[formkey='form_installer-detail'],
cpb-form-renderer[formkey='form_lead-detail'] {
  .form-renderer-actions {
    display: none;
  }
}

cpb-form-renderer {
  formly-form {
    padding: .5rem;
    display: block;
  }

  cpb-form-renderer-header {
    .form-renderer-header {
      padding: 15px 0;
      margin: 0 10px 15px;

      .form-renderer-title {
        font-size: 14px;
        font-weight: $bold-weight;
        color: $title-text-color;
      }
    }
  }

  cpb-content-wrapper {
    padding: 0 15px;
    display: block;

    label {
      color: $gray-dark;
    }
  }

  .readonly-container {
    border: 1px solid $borders-color;
    border-radius: 3px;
    background-color: $input-disabled-background-color;
    padding-left: 11px;
    font-size: 14px;
  }
}
