.p-dropdown-empty-message,
.p-multiselect-empty-message {
  padding: 10px 15px;
}

.p-dropdown {
  timesicon {
    svg {
      width: 21px;
    }
  }
}
