@import 'abstracts';

.popup-outer {
  background: rgba(221, 221, 221, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 5001;
  display: flex;
  height: 100%;
  margin: 0;
  width: 100vw !important;
  max-width: 100vw !important;
  
  .popup-inner {
    display: flex;
    width: 50vw;
    max-height: 80%;
    align-items: center;
    margin: auto;
    background-color: $white;
    border-radius: 5px;
    
    .box-content-wrap {
      box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.2);
    }
  
    .ibox-title, .ibox-content, .ibox-footer {
      padding: 20px 30px;
    }
  
    .ibox-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: none;
      border: none;
      background-color: var(--cap-primary-color) !important;
      border-radius: 5px 5px 0 0;
      
      h5 {
        color: $white;
        font-size: 18px;
        font-weight: bold;
      }
  
      .cancel-button {
        font-size: 20px;
        color: $white;
        cursor: pointer;
      }

      .close {
        color: $white;
      }
    }
  
    .ibox-footer {
      display: flex;
      justify-content: space-between;
      border-radius: 0 0 5px 5px;
      border-bottom: 1px solid $borders-color;
      
      cap-button {
        .p-button {
          height: 40px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) { 
  .popup-outer {
    .popup-inner {
      width: 90vw;
      max-height: 90vh;
      
      .box-content-wrap {
        height: 100%;
        display: flex;
        flex-direction: column;

        .ibox-content {
          height: 100%;
          flex: 1;
        }
      }
    }
  }
}

signature-pad  {
  canvas {
    width: 100%;
  }
}
