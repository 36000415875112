@import 'abstracts';

.tooltip-chartjs {
  display: flex;
  padding: 12px; 
  min-width: 235px; 
  flex-direction: column; 
  background-color: $white;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2); 
  position: relative;
  
  .arrow {
    width: 10px;
    position: absolute;
    height: 5px;
    display: block;
    border-left: 10px solid transparent;
    background-color: transparent;
    border-right: 15px solid transparent;
    border-bottom: 10px solid $white;
    top: -5px;
    left: 10px;
  }

  .title {
    padding-bottom: 10px;
    font-weight: 600;
    border-bottom: 1px solid $borders-color;
    color: $primary-text-color;
    font-size: 14px;
  }

  .body {
    .info {
      display: flex;
      justify-content:space-between;
      color: $understated-text-color;
      font-size: 14px;

      &.lead {
        margin: 10px 0;
      }
    }
  }
}

app-leads-statuses-chart {
  app-card-layout .card-layout {
    max-height: $dashboard-widget-height;
    min-height: $dashboard-widget-height;
  }
}